import { AxiosRequestConfig } from 'axios';
import { useEffect, useRef } from 'react';

import { AppointmentSuggestion } from '@boTypes/appointmentSuggestion';
import { AgeLevel, Categories } from '@boTypes/cms';
import { FertiTip } from '@boTypes/fertiTip';
import Folder from '@boTypes/folder';
import ItemSuggestion from '@boTypes/ItemSuggestion';
import PregnancyQuestion from '@boTypes/PregnancyQuestion';
import { PregnancyWeekContent } from '@boTypes/pregnancyWeekContent';
import { TodoListSuggestion } from '@boTypes/todoListSuggestion';
import {
  QueryKey,
  useCMSQuery,
  useInfiniteSearchQuery,
  useSearchQuery,
} from '@hooks/queryWrappers';
import { keepPreviousData } from '@tanstack/react-query';
import { getSmallestImageUrlAvailableFor } from '@utils/cmsFiles';

import { formatSearchFilters } from './formatSearchFilters';
import { ContentType } from '../entities/library/contentTextList';
import { IndexUid } from '../pages/common/contentTranslation/utils';
import { DailyTip, Guide, Macro, Masterclass, Post, WeeklyTip } from '../types';

const MEILISEARCH_MAX_HITS = 1000;
const getListCMSRequest = ({ url }: { url: string }): AxiosRequestConfig => {
  return {
    url,
    method: 'GET',
  };
};

const getDetailCMSRequest = ({
  lookup,
  url,
  params,
}: {
  lookup: string | number;
  url: string;
  params?: Record<string, string>;
}): AxiosRequestConfig => {
  return {
    url: url + '/' + lookup,
    params,
  };
};

const fetchCategoriesList = ({ pregnancy }) =>
  getListCMSRequest({
    url: `/pro/categories${
      pregnancy !== undefined
        ? '?filters[pregnancy]=' + (pregnancy ? 'true' : 'false')
        : ''
    }`,
  });

export const usePostItem = (lookup: string) => {
  return useCMSQuery<Post>(['post-item', lookup], () =>
    getDetailCMSRequest({ lookup, url: '/pro/posts' }),
  );
};

export const useGuideItem = (
  lookup: string,
  locale?: string,
  enabled?: boolean,
) => {
  return useCMSQuery<Guide>(
    ['guide-item', lookup],
    () =>
      getDetailCMSRequest({ lookup, url: '/pro/guides', params: { locale } }),
    enabled !== undefined ? { enabled } : {},
  );
};

export const useMasterclassItem = (lookup: number | string) => {
  const { data, isLoading } = useCMSQuery<Masterclass>(
    ['masterclass-item', lookup],
    getDetailCMSRequest({ lookup, url: '/pro/masterclasses' }),
    {
      select: (res) => {
        res.trackTitles = res.tracks.map((track) => track.title);
        return res;
      },
    },
  );
  return { data, isLoading };
};

const formatMacros = (macroInit: Macro): Macro => {
  const macro = { ...macroInit };
  const { suggestionsContents, suggestionsIds, suggestionsTitles } = macro;
  if (!macro.suggestions?.length && suggestionsContents?.length) {
    macro.suggestions = suggestionsContents.map(
      (content: string, index: number) => ({
        content,
        title: suggestionsTitles[index],
        id: suggestionsIds[index],
      }),
    );
  }
  return macro;
};

export const useFolderItem = (lookup: string) => {
  return useCMSQuery<Folder>(
    ['folder-item', lookup],
    () => getDetailCMSRequest({ lookup, url: '/pro/programs' }),
    {
      select: ({ program_steps, ...rest }) => {
        rest.programSteps = program_steps.map((el) => {
          el.content = el.content.map((_el) => {
            if (_el.__component === 'step.article-wrapper') {
              _el.post.coverThumbnailUrl = getSmallestImageUrlAvailableFor(
                _el.post.cover,
              );
            }
            return _el;
          });
          return el;
        });

        return rest;
      },
    },
  );
};

export const useDailyTipItem = (lookup: number | string) => {
  const { data, isLoading } = useCMSQuery<DailyTip>(
    ['daily-tip-item', lookup],
    getDetailCMSRequest({ lookup, url: '/pro/daily-tips' }),
    { staleTime: 5 * 60 * 1000 },
  );
  return { data, isLoading };
};

export const useWeeklyTipItem = (lookup: number | string) => {
  const { data, isLoading } = useCMSQuery<WeeklyTip>(
    ['weekly-tip-item', lookup],
    getDetailCMSRequest({ lookup, url: '/pro/weekly-tips' }),
    { staleTime: 5 * 60 * 1000 },
  );
  return { data, isLoading };
};

export const useAgeLevelsList = () =>
  useCMSQuery<AgeLevel[]>(
    ['age-levels'],
    getListCMSRequest({ url: '/pro/age-levels?pregnancy=false' }),
    {
      staleTime: 5 * 60 * 1000,
    },
  );

export const useCategoriesList = (pregnancy: boolean) =>
  useCMSQuery<Categories[]>(
    ['categories', pregnancy],
    () => fetchCategoriesList({ pregnancy }),
    {
      staleTime: 5 * 60 * 1000,
    },
  );

const contentToIndexUid: Record<ContentType, IndexUid> = {
  [ContentType.APPOINTMENT_SUGGESTIONS]: IndexUid.APPOINTMENT_SUGGESTION,
  [ContentType.DAILY_TIPS]: IndexUid.DAILY_TIPS,
  [ContentType.FERTI_TIPS]: IndexUid.FERTILITY_TIPS,
  [ContentType.FOLDER]: IndexUid.PROGRAMS,
  [ContentType.GUIDES]: IndexUid.GUIDE,
  [ContentType.ITEM_SUGGESTIONS]: IndexUid.ITEM_SUGGESTIONS,
  [ContentType.MACRO]: IndexUid.MACRO,
  [ContentType.MASTERCLASSES]: IndexUid.MASTERCLASS,
  [ContentType.POSTS]: IndexUid.POST,
  [ContentType.PREGNANCY_QUESTIONS]: IndexUid.PREGNANCY_QUESTIONS,
  [ContentType.PREGNANCY_WEEK_CONTENT]: IndexUid.PREGNANCY_WEEK_CONTENT,
  [ContentType.TODO_LIST_SUGGESTIONS]: IndexUid.TODO_LIST_SUGGESTION,
  [ContentType.WEEKLY_TIPS]: IndexUid.WEEKLY_TIPS,
};

const hasStandardFilter: Record<IndexUid, boolean> = {
  'appointment-suggestions': true,
  'daily-tips': true,
  'ferti-tips': false,
  'item-suggestions': true,
  'pregnancy-questions': true,
  'pregnancy-week-contents': true,
  'todo-list-suggestions': false,
  'weekly-tips': true,
  guides: true,
  macros: true,
  masterclasses: true,
  posts: true,
  programs: true,
};

type Output<Kind extends ContentType> = Kind extends ContentType.MACRO
  ? Macro
  : Kind extends ContentType.GUIDES
    ? Guide
    : Kind extends ContentType.POSTS
      ? Post
      : Kind extends ContentType.MASTERCLASSES
        ? Masterclass
        : Kind extends ContentType.PREGNANCY_QUESTIONS
          ? PregnancyQuestion
          : Kind extends ContentType.ITEM_SUGGESTIONS
            ? ItemSuggestion
            : Kind extends ContentType.FOLDER
              ? Folder
              : Kind extends ContentType.DAILY_TIPS
                ? DailyTip
                : Kind extends ContentType.WEEKLY_TIPS
                  ? WeeklyTip
                  : Kind extends ContentType.FERTI_TIPS
                    ? FertiTip
                    : Kind extends ContentType.PREGNANCY_WEEK_CONTENT
                      ? PregnancyWeekContent
                      : Kind extends ContentType.APPOINTMENT_SUGGESTIONS
                        ? AppointmentSuggestion
                        : Kind extends ContentType.TODO_LIST_SUGGESTIONS
                          ? TodoListSuggestion
                          : never;

export type OneSearchQueryData<T> = {
  hits: T[];
  nbHits: number;
  exhaustiveNbHits: boolean;
  query: string;
  limit: number;
  offset: number;
  processingTimeMs: number;
};

export type OneWholeSearchQueryData<T> = {
  results: T[];
  offset: number;
  limit: number;
  total: number;
};

export const useOneKindList = <Kind extends ContentType>(
  selectedContentType: Kind,
  {
    search,
    category,
    ageLevel,
    pregnancy = null,
    searchKey = '',
    enabled = true,
    fetchWholeList = false,
    locale,
  }: {
    search?: string;
    category?: number;
    ageLevel?: number;
    pregnancy?: boolean | null;
    searchKey?: string;
    enabled?: boolean;
    fetchWholeList?: boolean;
    locale?: string;
  },
) => {
  let filter = hasStandardFilter[contentToIndexUid[selectedContentType]]
    ? formatSearchFilters({ category, ageLevel, pregnancy })
    : undefined;
  if (locale) {
    filter = filter
      ? `${filter} AND locale = '${locale}'`
      : `locale = '${locale}'`;
  }
  const limit = fetchWholeList ? 1000 : 20; // max hits on meilisearch by default
  return useInfiniteSearchQuery<
    OneSearchQueryData<Output<Kind>>,
    any,
    Output<Kind>[],
    QueryKey,
    { offset: number; limit: number }
  >({
    enabled: !!selectedContentType && !!searchKey && enabled,
    queryKey: search
      ? [
          'library',
          selectedContentType,
          ageLevel,
          category,
          pregnancy,
          search,
          fetchWholeList,
          locale,
        ]
      : [
          'library',
          selectedContentType,
          ageLevel,
          category,
          pregnancy,
          fetchWholeList,
          locale,
        ],
    initialPageParam: { offset: 0, limit },
    queryFn: ({ signal, pageParam }) => ({
      url: `/indexes/${contentToIndexUid[selectedContentType]}/search`,
      data: {
        q: search,
        filter,
        ...pageParam,
      },
      method: 'POST',
      headers: {
        Authorization: `Bearer ${searchKey}`,
      },
      signal,
    }),
    getNextPageParam: (lastPage) => {
      if (lastPage.hits.length < lastPage.limit) {
        return undefined;
      }
      return { offset: lastPage.offset + lastPage.limit, limit };
    },
    select: (data) =>
      selectedContentType === ContentType.MACRO
        ? (data.pages.flatMap((page) =>
            (page.hits as Macro[]).map(formatMacros),
          ) as Output<Kind>[])
        : data.pages.flatMap((page) => page.hits),
    staleTime: 5 * 60 * 1000,
    placeholderData: keepPreviousData,
  });
};

export const useOneKindWholeList = <Kind extends ContentType>(
  selectedContentType: Kind,
  {
    searchKey,
    enabled = true,
  }: {
    enabled?: boolean;
    searchKey: string;
  },
) => {
  return useSearchQuery<
    OneWholeSearchQueryData<Output<Kind>>,
    any,
    Output<Kind>[],
    QueryKey
  >(
    ['whole', selectedContentType],
    ({ signal }) => ({
      url: `/indexes/${contentToIndexUid[selectedContentType]}/documents/fetch`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${searchKey}`,
      },
      data: {},
      signal,
    }),
    {
      enabled: !!selectedContentType && enabled,
      select: (data) => data.results,
      staleTime: 5 * 60 * 1000,
    },
  );
};

type MultiSearchInput = {
  queries: {
    q?: string;
    indexUid: IndexUid;
    filter?: string;
    attributesToRetrieve?: string[];
    attributesToHighlight?: string[];
    highlightPreTag?: string;
    highlightPostTag?: string;
    federationOptions?: { weight?: number };
    sort?: string[];
    limit?: number;
  }[];
};

type BaseHit = {
  estimatedTotalHits: number;
  query: string;
  limit: number;
  offset: number;
};
type MultiSearchHits =
  | ({ indexUid: 'macros'; hits: Macro[] } & BaseHit)
  | ({ indexUid: 'guides'; hits: Guide[] } & BaseHit)
  | ({ indexUid: 'posts'; hits: Post[] } & BaseHit)
  | ({ indexUid: 'masterclasses'; hits: Masterclass[] } & BaseHit)
  | ({ indexUid: 'pregnancy-questions'; hits: PregnancyQuestion[] } & BaseHit)
  | ({ indexUid: 'item-suggestions'; hits: ItemSuggestion[] } & BaseHit)
  | ({ indexUid: 'programs'; hits: Folder[] } & BaseHit)
  | ({ indexUid: 'daily-tips'; hits: DailyTip[] } & BaseHit)
  | ({ indexUid: 'weekly-tips'; hits: WeeklyTip[] } & BaseHit)
  | ({ indexUid: 'ferti-tips'; hits: FertiTip[] } & BaseHit);

export type CMSSearchResults = {
  macros: Macro[];
  guides: Guide[];
  posts: Post[];
  masterclasses: Masterclass[];
  folders: Folder[];
  'daily-tips': DailyTip[];
  'weekly-tips': WeeklyTip[];
};

const libraryMultiSearch = [
  IndexUid.MACRO,
  IndexUid.GUIDE,
  IndexUid.POST,
  IndexUid.MASTERCLASS,
  IndexUid.DAILY_TIPS,
  IndexUid.WEEKLY_TIPS,
  IndexUid.PROGRAMS,
];

export const useMultiSearch = ({
  search,
  searchKey,
  ageLevel,
  category,
  pregnancy,
  limit: initialLimit = 10000,
  enabled = true,
  idsMapping,
  locale,
}: {
  search: string;
  searchKey?: string;
  ageLevel?: number;
  category?: number;
  pregnancy?: boolean;
  limit?: number;
  enabled?: boolean;
  idsMapping?: Record<ContentType, number[]>;
  locale?: string;
}) => {
  // keep limits for each indexUid
  const initialPageParam = useRef(
    Object.values(contentToIndexUid)
      .filter(
        (indexUid) =>
          // We ignore item-suggestion +
          // when in library, we only search for a few content.
          // We exclude extra indexIds for performance reasons
          indexUid !== 'item-suggestions' &&
          (idsMapping || libraryMultiSearch.includes(indexUid)),
      )
      .reduce(
        (acc, indexUid) => {
          acc[indexUid] = {
            offset: 0,
            limit: Math.min(initialLimit, MEILISEARCH_MAX_HITS),
          };
          return acc;
        },
        {} as Record<IndexUid, { offset: number; limit: number }>,
      ),
  ).current;

  const result = useInfiniteSearchQuery<
    { results: MultiSearchHits[] },
    any,
    CMSSearchResults,
    QueryKey,
    Record<IndexUid, { offset: number; limit: number }>
  >({
    initialPageParam,
    queryKey: [
      'multi-search',
      search,
      ageLevel,
      category,
      pregnancy,
      idsMapping,
      locale,
    ],
    queryFn: ({ signal, pageParam }) => ({
      url: '/multi-search',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${searchKey}`,
      },
      data: {
        queries: Object.values(contentToIndexUid)
          .filter((indexUid) => {
            if (indexUid === 'item-suggestions') {
              return false;
            }
            if (idsMapping) {
              return (
                idsMapping[indexUid]?.length > 0 &&
                idsMapping[indexUid]?.length > pageParam[indexUid].offset &&
                pageParam[indexUid].limit > 0
              );
            }
            // We ignore item-suggestion +
            // when in library, we only search for a few content.
            // We exclude extra indexIds for performance reasons
            return (
              libraryMultiSearch.includes(indexUid) &&
              pageParam[indexUid].limit > 0
            );
          })
          .map((indexUid) => {
            let filter = hasStandardFilter[indexUid]
              ? formatSearchFilters({
                  category,
                  ageLevel,
                  pregnancy,
                  ids: idsMapping?.[indexUid]?.slice(
                    pageParam[indexUid].offset,
                    pageParam[indexUid].offset + pageParam[indexUid].limit,
                  ),
                })
              : formatSearchFilters({
                  pregnancy: null,
                  ids: idsMapping?.[indexUid]?.slice(
                    pageParam[indexUid].offset,
                    pageParam[indexUid].offset + pageParam[indexUid].limit,
                  ),
                });
            if (locale) {
              filter = filter
                ? `${filter} AND locale = '${locale}'`
                : `locale = '${locale}'`;
            }
            return {
              indexUid,
              q: search,
              filter,
              offset: idsMapping ? 0 : pageParam[indexUid].offset,
              limit: pageParam[indexUid].limit,
            };
          }),
      } satisfies MultiSearchInput,
      signal,
    }),
    enabled: !!searchKey && enabled,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      let withNext = false;
      const nextParams = Object.keys(lastPageParam).reduce(
        (acc, indexUid) => {
          const indexResult = lastPage.results.find(
            (res) => res.indexUid === indexUid,
          );
          const hasNext =
            indexResult &&
            indexResult.hits.length &&
            indexResult.hits.length >= lastPageParam[indexUid].limit;
          const nextOffset =
            lastPageParam[indexUid].offset + (indexResult?.hits.length || 0);
          withNext = withNext || (hasNext && nextOffset < initialLimit);
          acc[indexUid] = hasNext
            ? {
                offset: nextOffset,
                limit: lastPageParam[indexUid].limit,
              }
            : {
                offset: 0,
                limit: 0,
              };
          return acc;
        },
        {} as Record<IndexUid, { offset: number; limit: number }>,
      );

      return withNext ? nextParams : undefined;
    },
    select: (data) =>
      data.pages.reduce(
        (acc, curr) => {
          curr.results.forEach((res) => {
            if (res.indexUid === 'macros') {
              acc.macros = (acc.macros || []).concat(
                res.hits.map(formatMacros),
              );
            } else {
              acc[res.indexUid] = (acc[res.indexUid] || []).concat(res.hits);
            }
          });
          return acc;
        },
        {
          macros: [],
          guides: [],
          posts: [],
          masterclasses: [],
          folders: [],
          'daily-tips': [],
          'weekly-tips': [],
        },
      ),
    staleTime: 5 * 60 * 1000,
    placeholderData: keepPreviousData,
  });

  const { hasNextPage, fetchNextPage, isFetchingNextPage } = result;
  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return result;
};
