import dayjs from 'dayjs';
import { remove as removeDiacritics } from 'diacritics';
import { useMemo } from 'react';
import { useTranslate } from 'react-admin';

import { StaffUserInvoice } from '@boTypes/staffUserInvoice';
import { Download, EuroSymbol } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { formatTZ } from '@utils/date';

import { s3URL } from '../../common/url';
import {
  useGetInvoicesSepa,
  useValidateInvoices,
} from '../../entities/plannings/hooks/invoices';

export const robustFileNames = (fileName: string): string => {
  return encodeURIComponent(
    removeDiacritics(fileName.replace(/[\s'"]+/g, '_')),
  );
};

const onDownload = ({
  files,
  fileNames,
}: {
  files: string[];
  fileNames: string[];
}) => {
  const link = document.createElement('a');
  link.href = `${s3URL}/zip?${files
    .map((file) => `files[]=${file}`)
    .join('&')}&${fileNames
    .map((fileName) => `fileNames[]=${fileName}`)
    .join('&')}`;
  link.download = `invoices-${dayjs().format('YYYY-MM-DD')}.zip`;
  link.click();
};

const GridToolbar = ({
  invoiceIds,
  invoices: allInvoices = [],
}: {
  invoiceIds: StaffUserInvoice['id'][];
  invoices?: StaffUserInvoice[];
}) => {
  const translate = useTranslate();

  const { mutate: getSepa } = useGetInvoicesSepa();
  const { mutate: validateInvoices } = useValidateInvoices();

  const idsToInvoices = useMemo(() => {
    return allInvoices.reduce<Record<StaffUserInvoice['id'], StaffUserInvoice>>(
      (acc, current) => {
        acc[current.id] = current;
        return acc;
      },
      {},
    );
  }, [allInvoices]);

  const count = invoiceIds.length;
  const invoices = useMemo(() => {
    return invoiceIds.reduce<{
      files: string[];
      fileNames: string[];
    }>(
      (acc, current) => {
        const invoice = idsToInvoices[current];
        acc.files.push(invoice.invoiceURL);
        acc.fileNames.push(
          [
            formatTZ(invoice.planning.begin, 'YYYY_MM_DD'),
            invoice.planning.title,
            invoice.staffUser.firstName,
            invoice.staffUser.lastName,
          ]
            .map(robustFileNames)
            .join('-') + '.pdf',
        );
        return acc;
      },
      { files: [], fileNames: [] },
    );
  }, [invoiceIds, idsToInvoices]);

  return (
    <>
      <Button
        disabled={count === 0}
        size="small"
        onClick={() => onDownload(invoices)}
        startIcon={<Download />}
        color="success"
        variant="outlined"
      >
        {translate('invoice.adminList.downloadButton', { smart_count: count })}
      </Button>
      <Button
        disabled={count === 0}
        size="small"
        onClick={() => {
          validateInvoices({ invoiceIds });
          getSepa({ invoiceIds });
        }}
        startIcon={<EuroSymbol />}
        color="success"
        variant="outlined"
      >
        {translate('invoice.adminList.validateAndSepa')}
      </Button>
    </>
  );
};

export const CustomToolbar = ({
  invoiceIds,
  invoices,
}: {
  invoiceIds: StaffUserInvoice['id'][];
  invoices?: StaffUserInvoice[];
}) => (
  <GridToolbarContainer sx={{ mb: 1 }}>
    <GridToolbar invoiceIds={invoiceIds} invoices={invoices} />
  </GridToolbarContainer>
);
