import { FunctionField, FunctionFieldProps } from 'react-admin';

import { getFullName } from '../../utils/getFullName';

type NamedRecord = Record<'id' | 'firstName' | 'lastName', string>;

interface FullNameFieldProps extends Omit<FunctionFieldProps, 'render'> {
  source?: string;
  label?: string;
}

export const FullNameField = ({
  source,
  label,
  ...rest
}: FullNameFieldProps) => (
  <FunctionField
    render={(rec: NamedRecord) => getFullName(rec)}
    source={source}
    {...rest}
  />
);
