import { Dispatch, SetStateAction } from 'react';

import { AvatarAndText } from '@components/fields/AvatarField';
import { useGetVaryingMany } from '@hooks/useGetVaryingMany';
import { Article } from '@mui/icons-material';
import { Box, Link } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { TranslationTask } from './types';
import {
  contentTypeToIndexUid,
  translationTypeToCMSContentType,
} from './utils';
import {
  CMSContent,
  ContentType,
} from '../../../entities/library/contentTextList';
import { secondary } from '../../../themes';
import { ContentLine } from '../../admin/sections/contentTranslation/translationList';

export function renderCellContent({
  multiSearchIdMapping,
  setSelectedContent,
  setTranslatedSelectedContent,
  setSelectedType,
  open,
  setOpen,
  ...params
}: GridRenderCellParams<TranslationTask, string> & {
  multiSearchIdMapping?: Record<number, any>;
  setSelectedContent: Dispatch<SetStateAction<CMSContent<ContentType> | null>>;
  setTranslatedSelectedContent?: Dispatch<
    SetStateAction<CMSContent<ContentType> | null>
  >;
  setSelectedType: Dispatch<SetStateAction<ContentType | null>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  if (!multiSearchIdMapping) {
    return '';
  }
  const translationType = params.row.translationDocument?.type;
  const type = contentTypeToIndexUid(translationType);
  const cmsType = translationTypeToCMSContentType(translationType);
  const uploadId = params.row.upload?.createdDocumentId;

  if (type === '') {
    return 'NOT SUPPORTED';
  }
  const doc =
    multiSearchIdMapping[type]?.[params.row.translationDocument?.documentId];
  const translatedDocument = uploadId
    ? multiSearchIdMapping[type]?.[uploadId]
    : undefined;
  return multiSearchIdMapping ? (
    <ContentLine
      item={doc}
      setItem={setSelectedContent}
      setTranslatedItem={setTranslatedSelectedContent}
      type={cmsType}
      setOpen={setOpen}
      setSelectedType={setSelectedType}
      translatedDocument={translatedDocument}
    />
  ) : (
    ''
  );
}

export const editableDocumentURLRender = (
  params: GridRenderCellParams<any, string>,
) =>
  params.row.editableDocumentURL ? (
    <Link
      href={params.row.editableDocumentURL}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        width: '40px',
        height: '32px',
        maxWidth: 'unset',
        maxHeight: 'unset',
        display: 'inline-flex',
        verticalAlign: 'middle',
      }}
    >
      <Article sx={{ fontSize: '1.5rem', color: secondary.southSea }} />
    </Link>
  ) : (
    '⏳'
  );

const AvatarForReviewer = ({ userId }: { userId: number }) => {
  const { data: user } = useGetVaryingMany('users', [String(userId)], {
    enabled: !!userId,
  });
  return (
    <Box sx={{ display: 'flex', p: 1 }}>
      <AvatarAndText user={user[0]} />
    </Box>
  );
};

export function renderCellPairReviewer({
  userId,
}: GridRenderCellParams<TranslationTask, string> & {
  userId?: number;
}) {
  if (!userId) {
    return '-';
  }
  return <AvatarForReviewer userId={userId} />;
}
