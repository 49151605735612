import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useNotify } from 'react-admin';

import { Availability } from '@boTypes/availability';
import { StaffUser } from '@boTypes/staffUser';
import { CalendarEvent } from '@components/Calendar';
import { useMutation, useQuery, useQueryClient } from '@hooks/queryWrappers';
import { teal, deepOrange } from '@mui/material/colors';

import { useSelector } from '../store';
import { getFullName } from '../utils/getFullName';

export type ConsultationSlot = {
  start: Date;
  end: Date;
};

export const useConsultationPlanning = () => {
  const notify = useNotify();

  const userId = Number(useSelector((state) => state.user?.userId));

  const { data: availabilities, isLoading: isAvailabilityLoading } = useQuery<
    Availability[]
  >(['availabilities'], () => ({
    method: 'GET',
    url: '/api/availabilities',
  }));

  const staffUserIds = useMemo(() => {
    let _staffUserIds = availabilities?.map((a) => a.staffUserId) ?? [];
    if (userId) {
      _staffUserIds.push(userId);
    }
    return [...new Set(_staffUserIds)];
  }, [availabilities, userId]);

  const { data: staffUsers, isLoading: isUsersLoading } = useQuery<
    StaffUser[],
    any,
    Record<StaffUser['id'], StaffUser>
  >(
    ['staffUsers', staffUserIds],
    () => ({
      method: 'GET',
      url: '/api/users',
      enabled: !!staffUserIds.length && !isAvailabilityLoading,
      params: {
        filter: JSON.stringify({
          id: staffUserIds,
        }),
      },
    }),
    {
      select: (data) =>
        data.reduce(
          (acc, curr) => {
            acc[curr.id] = curr;
            return acc;
          },
          {} as Record<number, StaffUser>,
        ),
    },
  );

  const events = useMemo(() => {
    if (
      !staffUsers ||
      !availabilities ||
      isAvailabilityLoading ||
      isUsersLoading
    ) {
      return [];
    }

    return availabilities.map((a) => {
      const staffUser = staffUsers[a.staffUserId];
      return {
        id: a.id,
        staffUserId: staffUser.id,
        title: getFullName(staffUser),
        start: new Date(a.start),
        end: new Date(a.end),
        color: staffUser.id === userId ? deepOrange['300'] : teal['300'],
      } as CalendarEvent;
    });
  }, [
    staffUsers,
    availabilities,
    isAvailabilityLoading,
    isUsersLoading,
    userId,
  ]);

  const queryClient = useQueryClient();
  const { mutateAsync: create } = useMutation<
    CalendarEvent,
    AxiosError<{ message?: string }>,
    ConsultationSlot
  >(
    ['availabilities', 'create'],
    (data: ConsultationSlot) => ({
      method: 'POST',
      url: `/api/availabilities`,
      data,
    }),
    {
      onSuccess: (data: CalendarEvent) => {
        queryClient.setQueryData(
          ['availabilities'],
          (previousValue: CalendarEvent[] | undefined) => {
            return previousValue?.concat([data]) ?? [];
          },
        );
        notify('planning.event.created', {
          type: 'success',
          undoable: false,
        });
      },
      onError: (error) => {
        const msg = error?.response?.data?.message;
        notify(
          msg
            ? Array.isArray(msg)
              ? msg.join(' - ')
              : msg
            : 'planning.event.error',
          {
            type: 'error',
            undoable: false,
          },
        );
      },
    },
  );

  const { mutateAsync: remove } = useMutation<
    Availability,
    AxiosError<{ message?: string }>,
    CalendarEvent
  >(
    ['availabilities', 'delete'],
    (av) => ({
      method: 'DELETE',
      url: `/api/availabilities/${av.id}`,
    }),
    {
      onSuccess: (_deleted) => {
        queryClient.setQueryData(
          ['availabilities'],
          (previousValue: Availability[] | undefined) => {
            return previousValue?.filter((a) => a.id !== _deleted.id);
          },
        );
        notify('planning.event.deleted', {
          type: 'success',
          undoable: false,
        });
      },
      onError: () => {
        notify('planning.event.deleteError', {
          type: 'error',
          undoable: false,
        });
      },
    },
  );

  const { mutateAsync: update } = useMutation<
    Availability,
    AxiosError<{ message?: string }>,
    CalendarEvent
  >(
    ['availabilities', 'update'],
    (av) => ({
      method: 'PUT',
      url: `/api/availabilities/${av.id}`,
      data: av,
    }),
    {
      onSuccess: (_updated) => {
        queryClient.setQueryData(
          ['availabilities'],
          (previousValue: Availability[] | undefined) => {
            return previousValue?.map((a) =>
              a.id === _updated.id ? { ...a, ..._updated } : a,
            );
          },
        );
        notify('planning.event.updated', {
          type: 'success',
          undoable: false,
        });
      },
      onError: (error) => {
        const msg = error?.response?.data?.message;
        notify(
          msg
            ? Array.isArray(msg)
              ? msg.join(' - ')
              : msg
            : 'planning.event.error',
          {
            type: 'error',
            undoable: false,
          },
        );
      },
    },
  );

  return {
    events,
    isLoading: isAvailabilityLoading || isUsersLoading,
    staffUsers,
    create,
    remove,
    update,
  };
};
