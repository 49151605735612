import { useEffect } from 'react';
import { useGetIdentity, useTranslate } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { Box } from '@mui/material';

import { AuthCard } from './components/authCard';
import { useLoginSteps } from './login/hooks';
import { OneFAForm } from './OneFAForm';
import { TwoFA } from './TwoFA';
import { useSelector } from '../store';
import { secondary } from '../themes';
import Aurora from './components/aurora';

export const Login = () => {
  const translate = useTranslate();
  const {
    form,
    oneFAOK,
    forceSms,
    twoFAType,
    handleOneFASubmit,
    handleLogin2FA,
    handleSwitchSms,
  } = useLoginSteps();

  // Redirect to home if the user is already logged in
  const navigate = useNavigate();
  const { email } = useSelector((state) => state.user);
  const identity = useGetIdentity();
  useEffect(() => {
    if (email && identity?.data?.email === email) {
      navigate('/');
    }
  }, [email, identity?.data?.email, navigate]);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
      >
        <Aurora
          colorStops={[secondary.lilac, secondary.southSea, secondary.mandarin]}
          speed={0.5}
          amplitude={1}
        />
      </Box>

      {/* Content on top */}
      <Box
        sx={{
          zIndex: 1,
          width: '100%',
          height: '100%',
        }}
      >
        <FormProvider {...form}>
          <AuthCard
            hint={
              oneFAOK
                ? translate(
                    forceSms || twoFAType === 'sms'
                      ? 'auth.login.2FA'
                      : 'auth.login.TOTP',
                  )
                : ''
            }
          >
            {!oneFAOK ? (
              <OneFAForm onSubmit={handleOneFASubmit} />
            ) : (
              <TwoFA
                onSubmit={handleLogin2FA}
                withTrusted={true}
                type={forceSms ? 'sms' : twoFAType}
                onToggleSms={twoFAType === 'totp' ? handleSwitchSms : undefined}
              />
            )}
          </AuthCard>
        </FormProvider>
      </Box>
    </Box>
  );
};
