import { zip } from 'lodash';
import { useTranslate } from 'react-admin';
import ReactMarkdown from 'react-markdown';

import { FertiTip } from '@boTypes/fertiTip';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import { DialogTitle } from '../generic/Dialog';

export const WIDTH = 148;
export const HEIGHT = 120;

const InnerFertiTip = ({ item }: { item: FertiTip }) => {
  const translate = useTranslate();
  const authors = zip(item.authorNames, item.authorImages).map(
    ([name, image]) => (
      <Box
        key={name}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={image}
          alt={name}
          sx={{
            width: 24,
            height: 24,
            borderWidth: 1,
            borderColor: 'background.grey',
            marginRight: 1,
          }}
        />
        <Typography variant="body2">{name}</Typography>
      </Box>
    ),
  );

  return (
    <>
      {authors}
      <Typography variant="body2" sx={{ marginTop: 1 }}>
        {translate('fertiTip.cycleNumber', { cycleNumber: item.cycleNumber })}
      </Typography>
      <Typography variant="body2">
        {translate('fertiTip.cyclePosition', {
          cyclePosition: item.cyclePosition,
        })}
      </Typography>
      <Box
        sx={{
          maxWidth: '100%',
          '& p > img': { width: '100%' },
          '& p': { wordWrap: 'break-word' },
        }}
      >
        <ReactMarkdown>{item.content}</ReactMarkdown>
      </Box>
    </>
  );
};

export const FertiTipItemDialog = ({
  item,
  translatedItem,
  open,
  close,
}: {
  item: FertiTip;
  translatedItem?: FertiTip;
  open: boolean;
  close: () => void;
}) => (
  <Dialog
    open={open}
    onClose={close}
    aria-labelledby={item.title}
    aria-describedby={item.title}
    maxWidth={translatedItem ? 'xl' : 'md'}
  >
    <DialogTitle onClose={close}>
      {translatedItem ? (
        <Stack direction="row" gap={6} sx={{ width: '100%' }}>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            {item.title}
          </Stack>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            {translatedItem.title}
          </Stack>
        </Stack>
      ) : (
        item.title
      )}
    </DialogTitle>
    <DialogContent>
      {translatedItem ? (
        <Stack
          direction="row"
          justifyContent={'space-between'}
          gap={1}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{ width: '100%' }}
        >
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            <InnerFertiTip item={item} />
          </Stack>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            <InnerFertiTip item={translatedItem} />
          </Stack>
        </Stack>
      ) : (
        <InnerFertiTip item={item} />
      )}
    </DialogContent>
  </Dialog>
);
