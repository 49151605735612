import ReactMarkdown from 'react-markdown';

import ItemSuggestion from '@boTypes/ItemSuggestion';
import { Dialog, DialogContent, Divider, Stack } from '@mui/material';

import { DialogTitle } from '../generic/Dialog';

export const ItemSuggestionDialog = ({
  item,
  translatedItem,
  open,
  close,
}: {
  item: ItemSuggestion;
  translatedItem?: ItemSuggestion;
  open: boolean;
  close: () => void;
}) => (
  <Dialog
    open={open}
    onClose={close}
    aria-labelledby={item.name}
    aria-describedby={item.name}
    maxWidth={translatedItem ? 'xl' : 'md'}
  >
    <DialogTitle onClose={close}>
      {translatedItem ? (
        <Stack direction="row" gap={6} sx={{ flex: 1 }}>
          <Stack direction="column" sx={{ flex: 1 }}>
            {item.name}
          </Stack>
          <Stack direction="column" sx={{ flex: 1 }}>
            {translatedItem.name}
          </Stack>
        </Stack>
      ) : (
        item.name
      )}
    </DialogTitle>
    <DialogContent>
      {translatedItem ? (
        <Stack
          direction="row"
          justifyContent={'space-between'}
          gap={1}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack direction="column" sx={{ flex: 1 }}>
            <ReactMarkdown>{item.intro}</ReactMarkdown>
          </Stack>
          <Stack direction="column" sx={{ flex: 1 }}>
            <ReactMarkdown>{translatedItem.intro}</ReactMarkdown>
          </Stack>
        </Stack>
      ) : (
        <ReactMarkdown>{item.intro}</ReactMarkdown>
      )}
    </DialogContent>
  </Dialog>
);
