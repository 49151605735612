import { StaffUser } from '@boTypes/staffUser';

export enum UserLocale {
  EN = 'en',
  ES = 'es',
  FR = 'fr',
}

export enum ContentType {
  APPOINTMENT_SUGGESTIONS = 'appointment_suggestion',
  DAILY_TIPS = 'dailytips',
  POST = 'post',
  MASTERCLASS = 'masterclass',
  WEEKLY_TIPS = 'weeklytips',
  FERTILITY_TIPS = 'fertitips',
  PREGNANCY_WEEK_CONTENT = 'pregnancy_week_content',
  CHILD_MONTH_CONTENT = 'child_month_content',
  TODO_LIST_SUGGESTIONS = 'todo_list_suggestion',
  PREGNANCY_QUESTIONS = 'pregnancy_question',
  MACRO = 'macro',
  GUIDE = 'guide',
}

export enum TranslationEditLevel {
  FEW_CHANGES = 'few_changes',
  SOME_CHANGES = 'some_changes',
  MANY_CHANGES = 'many_changes',
  NO_CHANGES = 'no_changes',
  COMPLETE_REWRITE = 'complete_rewrite',
}

export type TranslationDocument = {
  id: number;
  type: ContentType;
  documentId: number;
  referenceLocale: UserLocale;
};

export enum TranslationScope {
  MIDWIFE = 'midwife',
  TRANSLATOR = 'translator',
  GYNECOLOGIST = 'gynecologist',
  HEALTH_VISITOR = 'health_visitor',
  PEDIATRIC_NURSE = 'pediatric_nurse',
  PEDIATRICIAN = 'pediatrician',
  OTHER = 'other',
}

export enum TaskStatus {
  CREATED = 'created',
  AI_TRANSLATED = 'ai_translated',
  HUMAN_REVIEWED = 'human_reviewed',
  PAIR_REVIEWED = 'pair_reviewed',
  CHANGES_REQUESTED = 'changes_requested',
  VALIDATED = 'validated',
  CMS_AUTO_UPLOADED = 'cms_auto_uploaded',
  CMS_UPLOAD_FAILED = 'cms_upload_failed',
  CMS_UPLOADED = 'cms_uploaded',
}

export type TranslationTaskStatus = {
  id: string;
  translationTaskId: string;
  ownerId: number;
  owner?: StaffUser;
  status: TaskStatus;
  comments?: string;
  timeSpent?: number;
  createdAt?: Date;
};

export type TranslationUpload = {
  id: string;
  translationTaskId: string;
  imageIds: string[];
  uploadedImageUrls: string[];
  createdDocumentId: number;
};

export type TranslationTask = {
  id: string;
  translationDocumentId: string;
  translationDocument?: TranslationDocument;
  editableDocumentURL?: string;
  targetLocale: UserLocale;
  ownerId: number;
  owner?: StaffUser;
  approverId?: number;
  approver?: StaffUser;
  pairReviewerId?: number;
  scope: TranslationScope;
  active: boolean;
  status?: TranslationTaskStatus[];
  editLevel: TranslationEditLevel;
  substanceEdited: boolean;
  comments: string;
  plagiarismChecked: boolean;
  completeRewrite: boolean;
  paid: boolean;
  allStatus?: TranslationTaskStatus[];
  upload?: TranslationUpload;
};

export type TranslationTaskStatusComments = {
  comments: string;
};

export enum TaskStatusMode {
  REQUEST_CHANGES = 'request_changes',
  VALIDATE = 'validate',
}

export type TranslationExtraTask = {
  id: string;
  staffUserId: StaffUser['id'];
  comments: string;
  timeSpent: number;
  date: string;
};
