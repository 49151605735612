import { SetStateAction, useCallback } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { TextInput } from '@components/forms/textInput';
import { DialogTitle } from '@components/generic/Dialog';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import { useSubmitTask } from './hooks';
import { TranslationTask } from '../common/contentTranslation/types';

type TimeTrackingInput = {
  timeSpent?: number;
  comments?: string;
};

export const TimeTrackingDialog = ({
  taskId,
  setTaskId,
  refetch,
}: {
  taskId?: TranslationTask['id'];
  setTaskId: React.Dispatch<SetStateAction<TranslationTask['id'] | null>>;
  refetch: () => void;
}) => {
  const translate = useTranslate();
  const notify = useNotify();
  const { control, handleSubmit } = useForm<TimeTrackingInput>({
    defaultValues: {
      timeSpent: 0,
      comments: '',
    },
  });

  const { submitTask } = useSubmitTask(taskId);

  const onSubmit = useCallback(
    async (data: TimeTrackingInput) => {
      try {
        await submitTask({
          ...data,
          timeSpent: Math.round(Number(data.timeSpent)),
        });
        refetch();
        notify('contentTranslation.timeTrackingSuccess', { type: 'success' });
        setTaskId(null);
      } catch {
        notify('contentTranslation.timeTrackingError', { type: 'error' });
      }
    },
    [submitTask, notify, setTaskId, refetch],
  );
  const onClose = useCallback(() => {
    setTaskId(null);
  }, [setTaskId]);

  if (!taskId) {
    return null;
  }

  return (
    <Dialog open={!!taskId} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('contentTranslation.timeTrackingTitle')}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextInput
            type="number"
            name="timeSpent"
            label={translate('contentTranslation.fields.timeSpent')}
            control={control}
          />
          <TextInput
            name="comments"
            label={translate('contentTranslation.fields.comments')}
            control={control}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {translate('common.validate')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
