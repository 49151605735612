import { useTranslate } from 'react-admin';

import { Roles } from '@boTypes/user';
import {
  Checklist,
  Money,
  Receipt,
  Recycling,
  Translate,
} from '@mui/icons-material';
import {
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { useSelector } from '../../../../store';

export const ContentTranslationCard = () => {
  const translate = useTranslate();
  const roles = useSelector((state) => state.user.roles);
  const isAdmin = roles.includes(Roles.ADMIN);
  const email = useSelector((state) => state.user.email);

  return (
    <Card sx={{ padding: 2 }}>
      <Typography variant="subtitle1" color="primary" sx={{ paddingBottom: 1 }}>
        {translate('admin.contentTranslation.title')}
      </Typography>
      <List dense>
        <ListItem disablePadding>
          <ListItemButton
            disableGutters
            component="a"
            href="/#/admin/content-translation/add-content"
          >
            <ListItemIcon sx={{ minWidth: '2rem' }}>
              <Translate fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={translate('admin.contentTranslation.addContent')}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            disableGutters
            component="a"
            href="/#/admin/content-translation/dashboard"
          >
            <ListItemIcon sx={{ minWidth: '2rem' }}>
              <Checklist fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={translate('admin.contentTranslation.dashboard')}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton disableGutters component="a" href="/#/">
            <ListItemIcon sx={{ minWidth: '2rem' }}>
              <Receipt fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translate('invoice.title')} />
          </ListItemButton>
        </ListItem>
        {isAdmin && (
          <ListItem disablePadding>
            <ListItemButton disableGutters component="a" href="/#/">
              <ListItemIcon sx={{ minWidth: '2rem' }}>
                <Money fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={translate('admin.contentTranslation.pricing')}
              />
            </ListItemButton>
          </ListItem>
        )}
        {email === 'adrien@may-sante.com' ||
          (email === 'matthieu@may-sante.com' && (
            <ListItem disablePadding>
              <ListItemButton
                disableGutters
                component="a"
                href="/#/admin/content-translation/external-source"
              >
                <ListItemIcon sx={{ minWidth: '2rem' }}>
                  <Recycling fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={translate('admin.contentTranslation.externalSource')}
                />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Card>
  );
};
