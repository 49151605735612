import { SetStateAction, useCallback } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { TextInput } from '@components/forms/textInput';
import { DialogTitle } from '@components/generic/Dialog';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import { useTaskStatus } from './hooks';
import {
  TaskStatusMode,
  TranslationTask,
  TranslationTaskStatusComments,
} from '../../../common/contentTranslation/types';

export const TaskCommentsDialog = ({
  taskId,
  setTaskId,
  mode,
  refetch,
}: {
  taskId?: TranslationTask['id'];
  setTaskId: React.Dispatch<SetStateAction<TranslationTask['id'] | null>>;
  mode: string;
  refetch: () => void;
}) => {
  const translate = useTranslate();
  const notify = useNotify();
  const { control, handleSubmit, reset } =
    useForm<TranslationTaskStatusComments>({
      defaultValues: {
        comments: '',
      },
    });

  const { validateTask, requestChanges } = useTaskStatus(taskId);

  const onSubmit = useCallback(
    async (data: TranslationTaskStatusComments) => {
      try {
        if (mode === TaskStatusMode.VALIDATE) {
          await validateTask(data);
        } else if (mode === TaskStatusMode.REQUEST_CHANGES) {
          await requestChanges(data);
        } else {
          return;
        }

        refetch();
        notify('contentTranslation.commentsSuccess', { type: 'success' });
        reset();
        setTaskId(null);
      } catch {
        notify('contentTranslation.commentsError', { type: 'error' });
      }
    },
    [notify, setTaskId, refetch, validateTask, requestChanges, mode, reset],
  );
  const onClose = useCallback(() => {
    setTaskId(null);
  }, [setTaskId]);

  if (!taskId) {
    return null;
  }

  return (
    <Dialog open={!!taskId} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('contentTranslation.commentsTitle')}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextInput
            name="comments"
            label={translate('contentTranslation.fields.comments')}
            control={control}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {translate('common.validate')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
