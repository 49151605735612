import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useForm, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { PlanningJob } from '@boTypes/planning';
import { StaffUserInvoice } from '@boTypes/staffUserInvoice';
import {
  AutocompleteSelectInput,
  SelectInput,
} from '@components/forms/selectInput';
import { ToggleButtonsInput } from '@components/forms/toggleButtonsInput';
import { Loader } from '@components/Loader';
import { Chat, Close, FindInPage } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { useInvoicesAdminList, useStaffUsers, usePlanning } from './list.hook';
import { CustomToolbar } from './list.toolbar';
import { filterPanelProps } from '../common/dataGrid/styles';
import { InvoiceDialog } from '../dashboard/invoice.viewer';

type Form = {
  planning: string[];
  staffUser: number[];
  job: PlanningJob;
  validated: boolean;
  amountValidated: boolean;
};

const AdminInvoicesList = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  const [filters, setFilters] = useSearchParams();

  const { data: { data: invoices, total } = {}, isLoading } =
    useInvoicesAdminList(filters, paginationModel);
  const { data: plannings, isLoading: isPlanningLoading } = usePlanning();
  const { data: staffUsers, isLoading: isStaffUsersLoading } = useStaffUsers();

  const translate = useTranslate();

  const [selectedInvoice, setSelectedInvoice] =
    useState<StaffUserInvoice | null>(null);
  const [openViewerDialog, setOpenViewerDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [invoiceForComments, setInvoiceForComments] = useState<
    StaffUserInvoice['id'] | undefined
  >();
  const handleInvoiceClick = (invoice: StaffUserInvoice) => {
    setSelectedInvoice(invoice);
    setOpenViewerDialog(true);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    invoiceId: StaffUserInvoice['id'],
  ) => {
    setAnchorEl(event.currentTarget);
    setInvoiceForComments(invoiceId);
    event.stopPropagation();
  };

  const openComments = Boolean(anchorEl);
  const handleCloseComments = () => {
    setAnchorEl(null);
    setInvoiceForComments(undefined);
  };

  const toggleButtonOptions = useMemo(
    () => [
      {
        label: translate('common.all'),
        value: 'all',
      },
      {
        label: translate('invoice.adminList.validated'),
        value: true,
      },
      {
        label: translate('invoice.adminList.notValidated'),
        value: false,
      },
    ],
    [translate],
  );

  const columns: GridColDef<any, StaffUserInvoice>[] = useMemo(() => {
    return [
      {
        field: 'title',
        headerName: translate('invoice.adminList.staffUser'),
        flex: 1,
        sortable: false,
        valueGetter: (_params, row) => {
          return [row.staffUser.firstName, row.staffUser.lastName]
            .filter(Boolean)
            .join(' ')
            .trim();
        },
      },
      {
        field: 'planning',
        headerName: translate('invoice.adminList.planning'),
        flex: 1,
        sortable: false,
        valueGetter: (_params, row) => {
          return row.planning.title;
        },
      },
      {
        field: 'createdAt',
        headerName: translate('invoice.adminList.createdAt'),
        sortable: false,
        flex: 1,
        valueGetter: (_params, row) => {
          return dayjs(row.createdAt).format('DD/MM/YYYY HH:mm');
        },
      },
      {
        field: 'comments',
        headerName: translate('invoice.adminList.comments'),
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <>
              {params.row.comments ? (
                <Box
                  onClick={(ev) => handleClick(ev, params.row.id)}
                  sx={{ color: 'gray' }}
                >
                  <Chat sx={{ fontSize: '1rem' }} />
                </Box>
              ) : null}
              <Popover
                id={`popover-${params.row.id}`}
                open={openComments && invoiceForComments === params.row.id}
                anchorEl={anchorEl}
                onClose={handleCloseComments}
              >
                <Typography sx={{ p: 1 }}>{params.row.comments}</Typography>
              </Popover>
            </>
          );
        },
      },
      {
        field: 'amountValidated',
        headerName: translate('invoice.adminList.amountValidatedShort'),
        sortable: false,
        flex: 1,
        valueGetter: (_params, row) => {
          return row.amountValidated ? '✅' : '❌';
        },
      },
      {
        field: 'validated',
        headerName: translate('invoice.adminList.validatedShort'),
        sortable: false,
        flex: 1,
        valueGetter: (_params, row) => {
          return row.validated ? '✅' : '❌';
        },
      },
      {
        field: 'invoice',
        headerName: translate('invoice.adminList.invoice'),
        sortable: false,
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <Button
              variant="text"
              startIcon={<FindInPage />}
              onClick={(ev) => {
                ev.stopPropagation();
                handleInvoiceClick(params.row);
              }}
            >
              {translate('invoice.see')}
            </Button>
          );
        },
      },
    ];
  }, [translate, openComments, anchorEl, invoiceForComments]);

  const filterDict = useRef(
    (() => {
      const planning = filters.get('planning');
      const staffUser = filters.get('staffUser');
      const job = filters.get('job');
      const validated = filters.get('validated');
      const amountValidated = filters.get('amountValidated');
      const res = {} as Form;
      if (planning) {
        res.planning = JSON.parse(planning);
      }
      if (staffUser) {
        res.staffUser = JSON.parse(staffUser);
      }
      if (job) {
        res.job = JSON.parse(job);
      }
      if (validated) {
        res.validated = JSON.parse(validated);
      }
      if (amountValidated) {
        res.amountValidated = JSON.parse(amountValidated);
      }

      return res;
    })(),
  ).current;

  const { control, setValue } = useForm<Form>({
    defaultValues: filterDict,
  });

  const data = useWatch({
    control,
    name: ['planning', 'staffUser', 'job', 'validated', 'amountValidated'],
  });

  useEffect(() => {
    // sync searchParams
    data?.[0] !== undefined && data?.[0]?.length
      ? filters.set('planning', JSON.stringify(data?.[0]))
      : filters.delete('planning');
    data?.[1] && data?.[1]?.length
      ? filters.set('staffUser', JSON.stringify(data?.[1]))
      : filters.delete('staffUser');
    data?.[2] !== undefined && data?.[2]
      ? filters.set('job', JSON.stringify(data?.[2]))
      : filters.delete('job');
    data?.[3] !== undefined
      ? filters.set('validated', JSON.stringify(data?.[3]))
      : filters.delete('validated');
    data?.[4] !== undefined
      ? filters.set('amountValidated', JSON.stringify(data?.[4]))
      : filters.delete('amountValidated');
    setFilters(filters);
  }, [data, setFilters, filters]);

  const resetFilters = useCallback(() => {
    // form state + URLS
    setValue('planning', []);
    setValue('staffUser', []);
    setValue('job', undefined);
    setValue('validated', undefined);
    setValue('amountValidated', undefined);
    setFilters(new URLSearchParams());
  }, [setFilters, setValue]);

  const [rowSelectionModel, setRowSelectionModel] = useState<
    StaffUserInvoice['id'][]
  >([]);

  const Toolbar = useCallback(() => {
    return <CustomToolbar invoiceIds={rowSelectionModel} invoices={invoices} />;
  }, [rowSelectionModel, invoices]);

  if (isPlanningLoading || isStaffUsersLoading) {
    return <Loader />;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={4} sm={12}>
          <Card sx={{ p: 2 }} elevation={2}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1rem',
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {translate('invoice.adminList.filters')}
              </Typography>
              <Button
                variant="text"
                color="inherit"
                sx={{ color: 'primary.main50' }}
                onClick={() => resetFilters()}
                startIcon={<Close />}
              >
                {translate('invoice.adminList.resetFilters')}
              </Button>
            </Box>
            {plannings ? (
              <AutocompleteSelectInput
                loading={isPlanningLoading}
                name="planning"
                control={control}
                label={translate('invoice.adminList.planning')}
                fullWidth
                multiple
                options={plannings ?? []}
              />
            ) : null}
            {staffUsers ? (
              <AutocompleteSelectInput
                loading={isStaffUsersLoading}
                name="staffUser"
                control={control}
                label={translate('invoice.adminList.staffUser')}
                fullWidth
                multiple
                options={staffUsers ?? []}
              />
            ) : null}
            <SelectInput
              name="job"
              control={control}
              label={translate('invoice.adminList.job.title')}
              fullWidth
              variant="outlined"
            >
              <MenuItem value={''} key={'None'}>
                {translate('common.all')}
              </MenuItem>
              <MenuItem value={PlanningJob.DOCTOR} key={PlanningJob.DOCTOR}>
                {translate('invoice.adminList.job.doctor')}
              </MenuItem>
              <MenuItem value={PlanningJob.MIDWIFE} key={PlanningJob.MIDWIFE}>
                {translate('invoice.adminList.job.midwife')}
              </MenuItem>
              <MenuItem value={PlanningJob.NURSE} key={PlanningJob.NURSE}>
                {translate('invoice.adminList.job.nurse')}
              </MenuItem>
            </SelectInput>
            <ToggleButtonsInput
              control={control}
              name="amountValidated"
              label={translate('invoice.adminList.amountValidated')}
              options={toggleButtonOptions}
              sx={{ mb: 1 }}
            />
            <ToggleButtonsInput
              control={control}
              name="validated"
              label={translate('invoice.adminList.validated')}
              options={toggleButtonOptions}
              sx={{ mb: 1 }}
            />
          </Card>
        </Grid>
        <Grid item md={8} sm={12}>
          <DataGrid
            rows={invoices ?? []}
            checkboxSelection
            disableColumnFilter
            columns={columns}
            loading={isLoading}
            paginationMode="server"
            pageSizeOptions={[100]}
            rowCount={total ?? 0}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(
                newRowSelectionModel as StaffUserInvoice['id'][],
              );
            }}
            rowSelectionModel={rowSelectionModel}
            slots={{
              toolbar: Toolbar,
            }}
            slotProps={{ filterPanel: filterPanelProps }}
          />
        </Grid>
      </Grid>
      <InvoiceDialog
        open={openViewerDialog}
        onClose={() => setOpenViewerDialog(false)}
        invoiceUrl={selectedInvoice?.invoiceURL ?? ''}
        withTotal
        planningId={selectedInvoice?.planningId}
        staffUserId={selectedInvoice?.staffUserId}
      />
    </Box>
  );
};

export default AdminInvoicesList;
