import { Box } from '@mui/material';

import { secondary } from '../themes';
import Aurora from './components/aurora';
import { AuthCard } from './components/authCard';
import { ResetForm } from './reset/ResetForm';

export const ResetScreen = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
      >
        <Aurora
          colorStops={[secondary.lilac, secondary.southSea, secondary.mandarin]}
          speed={0.5}
          amplitude={1}
        />
      </Box>

      {/* Content on top */}
      <Box
        sx={{
          zIndex: 1,
          width: '100%',
          height: '100%',
          padding: 2,
        }}
      >
        <AuthCard>
          <ResetForm />
        </AuthCard>
      </Box>
    </Box>
  );
};
