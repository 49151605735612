import { useState } from 'react';
import {
  DateField,
  ReferenceField,
  ChipField,
  ArrayField,
  SingleFieldList,
  useTranslate,
  TextField,
  useLocale,
} from 'react-admin';
import { Link } from 'react-router-dom';

import { CalendarMonth } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from '@mui/material';

import { consultationTabs, TO_DO } from '../../common/constants';
import {
  AvatarField,
  ConsultationMedia,
  StatusChipField,
  FullNameField,
} from '../../components/fields';
import { List } from '../../components/generic/List';
import { TabsDatagrid } from '../../components/TabsDatagrid';
import type { TabObject } from '../../types';

export const ConsultationList = (props) => {
  const [tab, setTab] = useState<TabObject>({ name: TO_DO, id: TO_DO });
  const translate = useTranslate();
  const locale = useLocale();

  return (
    <Paper sx={{ padding: '1rem', minHeight: '100%' }} elevation={0}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '0.5rem',
        }}
      >
        <Typography variant="h1" color="primary" sx={{ fontWeight: 'bold' }}>
          {translate('consultations.title')}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<CalendarMonth />}
          component={Link}
          to={'/consultations-planning'}
          size="small"
        >
          {translate('consultations.availabilities')}
        </Button>
      </Box>
      <List
        {...props}
        title="Consultations"
        actions={null}
        pagination={null}
        filterDefaultValues={{ status: TO_DO }}
        sort={{ field: 'start', order: 'ASC' }}
        sx={{ marginHorizontal: 0 }}
      >
        <TabsDatagrid
          tabs={consultationTabs()}
          rowClick="edit"
          tab={tab}
          setTab={setTab}
        >
          <TextField label="N°" source="id" />
          <ReferenceField
            source="staffUserId"
            reference="users"
            link={false}
            label={translate('common.pro')}
          >
            <AvatarField />
          </ReferenceField>
          <DateField
            locales={locale}
            showTime
            label={translate('common.date')}
            source="start"
            options={{
              dateStyle: 'short',
              timeStyle: 'short',
            }}
          />
          <ReferenceField
            source="childId"
            reference="children"
            link={false}
            label={translate('common.parent')}
          >
            <ReferenceField
              source="family.id"
              reference="family"
              link={false}
              label={translate('common.parent')}
            >
              <ArrayField source="appUsers" label={translate('common.parent')}>
                <SingleFieldList
                  resource="appUsers"
                  linkType={false}
                  sx={{ gap: 0 }}
                >
                  <FullNameField
                    source="firstName"
                    sx={{
                      ':not(:last-child)': {
                        '&::after': {
                          content: '", "',
                          whiteSpace: 'pre',
                        },
                      },
                    }}
                  />
                </SingleFieldList>
              </ArrayField>
            </ReferenceField>
          </ReferenceField>
          <ReferenceField
            source="childId"
            reference="children"
            link={false}
            label={translate('common.child')}
          >
            <FullNameField />
          </ReferenceField>
          <ConsultationMedia />
          <ReferenceField
            source="consultationCategoryId"
            reference="consultation-categories"
            link={false}
            label={translate('consultations.category')}
          >
            <ChipField source="name" />
          </ReferenceField>
          <StatusChipField label={translate('consultations.status')} />
        </TabsDatagrid>
      </List>
    </Paper>
  );
};
