import { Identifier, usePermissions, useTranslate } from 'react-admin';

import { useMutation, useQuery, useQueryClient } from '@hooks/queryWrappers';
import PhoneLockedIcon from '@mui/icons-material/PhoneLocked';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { Roles } from '../../../types/user';

const useWhiteListQuery = (id: Identifier) => {
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(Roles.ADMIN) ?? false;

  const queryClient = useQueryClient();

  const { mutate: putOnWhiteList } = useMutation<
    { whitelist: boolean },
    any,
    void
  >(
    ['appUser', id, 'whitelist'],
    () => ({
      method: 'PUT',
      url: `/api/appUsers/${id}/whitelist`,
    }),
    {
      onSuccess(data) {
        queryClient.setQueryData(['appUser', id, 'whitelist'], data);
      },
    },
  );

  const { mutate: putOffWhiteList } = useMutation<
    { whitelist: boolean },
    any,
    void
  >(
    ['appUser', id, 'whitelist'],
    () => ({
      method: 'PUT',
      url: `/api/appUsers/${id}/unWhitelist`,
    }),
    {
      onSuccess(data) {
        queryClient.setQueryData(['appUser', id, 'whitelist'], data);
      },
    },
  );

  const queryRes = useQuery<{ whitelist: boolean }>(
    ['appUser', id, 'whitelist'],
    () => ({
      method: 'GET',
      url: `/api/appUsers/${id}/whitelist`,
    }),
    { enabled: isAdmin },
  );

  return { ...queryRes, putOnWhiteList, putOffWhiteList };
};

export const PhoneForm = ({ id }: { id: Identifier }) => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(Roles.ADMIN) ?? false;
  const { data, isLoading, isError, putOffWhiteList, putOnWhiteList } =
    useWhiteListQuery(id);

  if (!isAdmin) {
    return null;
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'center',
        }}
      >
        <CircularProgress size={14} sx={{ mh: 1 }} />;
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography color="error" sx={{ m: 1 }}>
        {translate('common.failToFetch')}
      </Typography>
    );
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
      {data?.whitelist ? (
        <Button
          startIcon={<PhoneLockedIcon />}
          onClick={() => putOffWhiteList()}
          variant="text"
          size="small"
        >
          {translate('appUsers.removeFromWhitelist')}
        </Button>
      ) : (
        <Button
          startIcon={<PhoneLockedIcon />}
          onClick={() => putOnWhiteList()}
          variant="text"
          size="small"
        >
          {translate('appUsers.addToPhoneWhitelist')}
        </Button>
      )}
    </Box>
  );
};
