import { useTranslate } from 'react-admin';
import ReactMarkdown from 'react-markdown';

import { TodoListSuggestion } from '@boTypes/todoListSuggestion';
import {
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import { DialogTitle } from '../generic/Dialog';

export const WIDTH = 148;
export const HEIGHT = 120;

const InnerTodoListSuggestion = ({ item }: { item: TodoListSuggestion }) => {
  const translate = useTranslate();
  return (
    <>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {translate('todoListSuggestion.todoTasks')}
      </Typography>
      <ReactMarkdown>{item.todoTasks}</ReactMarkdown>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {translate('common.description')}
      </Typography>
      <ReactMarkdown>{item.description}</ReactMarkdown>
    </>
  );
};

export const TodoListSuggestionDialog = ({
  item,
  translatedItem,
  open,
  close,
}: {
  item: TodoListSuggestion;
  translatedItem?: TodoListSuggestion;
  open: boolean;
  close: () => void;
}) => (
  <Dialog
    open={open}
    onClose={close}
    aria-labelledby={item.title}
    aria-describedby={item.title}
    maxWidth={translatedItem ? 'xl' : 'md'}
  >
    <DialogTitle onClose={close}>
      {translatedItem ? (
        <Stack direction="row" gap={6} sx={{ width: '100%' }}>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            {item.title}
          </Stack>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            {translatedItem.title}
          </Stack>
        </Stack>
      ) : (
        item.title
      )}
    </DialogTitle>
    <DialogContent>
      {translatedItem ? (
        <Stack
          direction="row"
          justifyContent={'space-between'}
          gap={1}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{ width: '100%' }}
        >
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            <InnerTodoListSuggestion item={item} />
          </Stack>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            <InnerTodoListSuggestion item={translatedItem} />
          </Stack>
        </Stack>
      ) : (
        <InnerTodoListSuggestion item={item} />
      )}
    </DialogContent>
  </Dialog>
);
