import { useCallback, useMemo, useState } from 'react';
import { useTranslate } from 'react-admin';

import { TabObject } from '@boTypes/tab';
import {
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { ExtraTasks } from './extraTasks';
import { useTasks } from './hooks';
import { Summary } from './summary';
import { TimeTrackingDialog } from './timeTrackingDialog';
import { i18nProvider } from '../../App';
import {
  CMSContent,
  ContentType,
} from '../../entities/library/contentTextList';
import { ContentDetail } from '../admin/sections/contentTranslation/translationList';
import {
  editableDocumentURLRender,
  renderCellContent,
} from '../common/contentTranslation/gridUtils';
import { GridCellStatusExpand } from '../common/contentTranslation/taskStatusCard';
import {
  TaskStatus,
  TranslationTask,
  TranslationTaskStatus,
} from '../common/contentTranslation/types';
import { contentTypeToIndexUid } from '../common/contentTranslation/utils';
import { filterPanelProps } from '../common/dataGrid/styles';

export function renderCellStatusExpand(
  params: GridRenderCellParams<any, string>,
) {
  return (
    <GridCellStatusExpand
      value={params.row.allStatus.filter(
        (s: TranslationTaskStatus) =>
          s.status !== TaskStatus.AI_TRANSLATED &&
          s.status !== TaskStatus.CREATED,
      )}
      width={params.colDef.computedWidth}
      ownerId={params.row.ownerId}
    />
  );
}

export enum TranslationTab {
  IN_PROGRESS = 'inProgress',
  SUMMARY = 'summary',
  EXTRA = 'extra',
}

export const translationsTabs = () => [
  {
    id: TranslationTab.IN_PROGRESS,
    name: i18nProvider.translate(
      `contentTranslation.tab.${TranslationTab.IN_PROGRESS}`,
    ),
  },
  {
    id: TranslationTab.SUMMARY,
    name: i18nProvider.translate(
      `contentTranslation.tab.${TranslationTab.SUMMARY}`,
    ),
  },
  {
    id: TranslationTab.EXTRA,
    name: i18nProvider.translate(
      `contentTranslation.tab.${TranslationTab.EXTRA}`,
    ),
  },
];

export const ContentTranslation = () => {
  const translate = useTranslate();
  const [selectedTaskId, setSelectedTaskId] = useState<
    TranslationTask['id'] | null
  >(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedType, setSelectedType] = useState<ContentType | null>(null);
  const [selectedContent, setSelectedContent] =
    useState<CMSContent<ContentType> | null>(null);

  // Tab state
  const [tab, setTab] = useState<TabObject>({
    name: translate(`contentTranslation.tab.${TranslationTab.IN_PROGRESS}`),
    id: TranslationTab.IN_PROGRESS,
  });
  const tabs = useMemo(translationsTabs, []);
  const handleTabChange = useCallback(
    (_, status: TranslationTab) => {
      tab.id !== status && setTab(tabs.find((t) => t.id === status));
    },
    [tabs, setTab, tab.id],
  );

  const { tasks, isPending, multiSearchIdMapping, refetch } = useTasks();

  const timeTracking = useCallback(
    ({ id }: TranslationTask) => {
      setSelectedTaskId(id);
    },
    [setSelectedTaskId],
  );

  const columns = useMemo<GridColDef<TranslationTask>[]>(
    () => [
      {
        field: 'state',
        width: 70,
        headerName: translate('contentTranslation.fields.state'),
        renderCell: (params) => {
          const hasChangesRequested = params.row?.allStatus.some(
            (status) => status.status === TaskStatus.CHANGES_REQUESTED,
          );
          return hasChangesRequested ? (
            <Chip label="♻️" size="small" color="info" />
          ) : (
            <Chip label="NEW" size="small" color="primary" />
          );
        },
      },
      {
        field: 'status',
        width: 180,
        headerName: translate('contentTranslation.fields.status'),
        valueGetter: (value: TranslationTaskStatus[]) => {
          const status = value?.[0]?.status;
          return status ? translate(`contentTranslation.status.${status}`) : '';
        },
      },
      {
        field: 'documentTitle',
        headerName: translate('contentTranslation.fields.documentTitle'),
        flex: 1,
        renderCell: (args: GridRenderCellParams<any, string>) =>
          renderCellContent({
            ...args,
            open: openDetail,
            setOpen: setOpenDetail,
            setSelectedContent,
            setSelectedType,
            multiSearchIdMapping,
          }),
        valueGetter: (_value, row) => {
          const document =
            multiSearchIdMapping?.[
              contentTypeToIndexUid(row.translationDocument?.type)
            ]?.[row.translationDocument?.documentId];
          return document?.title;
        },
      },
      {
        field: 'editableDocumentURL',
        headerName: translate('contentTranslation.fields.documentUrl'),
        width: 60,
        renderCell: editableDocumentURLRender,
      },
      {
        field: 'allStatus',
        align: 'center',
        headerName: translate('contentTranslation.fields.allStatus'),
        renderCell: renderCellStatusExpand,
        filterable: false,
      },
      {
        field: 'type',
        headerName: translate('contentTranslation.fields.type'),
        width: 100,
        valueGetter: (_value, row) => {
          return translate(
            `admin.contentTranslation.contentType.${row.translationDocument?.type}`,
          );
        },
      },
      {
        field: 'validate',
        headerName: translate('common.validate'),
        renderCell: (params) => {
          return (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => timeTracking(params.row)}
            >
              {translate('common.validate')}
            </Button>
          );
        },
        filterable: false,
      },
    ],
    [multiSearchIdMapping, translate, timeTracking, openDetail],
  );

  return (
    <Paper sx={{ padding: '1rem', minHeight: '100%' }} elevation={0}>
      <Typography variant="h1" color="primary" sx={{ fontWeight: 'bold' }}>
        {translate('contentTranslation.dashboardTitle')}
      </Typography>
      <Tabs
        variant="fullWidth"
        centered
        value={tab.id}
        onChange={handleTabChange}
        indicatorColor="primary"
      >
        {translationsTabs().map(({ id, name }) => (
          <Tab key={id} label={name} value={id} />
        ))}
      </Tabs>
      <Divider />
      {tab.id === TranslationTab.IN_PROGRESS && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <DataGrid
              rows={tasks}
              columns={columns}
              loading={isPending}
              density="compact"
              keepNonExistentRowsSelected
              ignoreDiacritics
              slotProps={{ filterPanel: filterPanelProps }}
            />
          </Box>
          <TimeTrackingDialog
            taskId={selectedTaskId}
            setTaskId={setSelectedTaskId}
            refetch={refetch}
          />
        </>
      )}
      {tab.id === TranslationTab.SUMMARY && <Summary />}
      {tab.id === TranslationTab.EXTRA && <ExtraTasks />}
      <ContentDetail
        type={selectedType}
        open={openDetail}
        setOpen={setOpenDetail}
        item={selectedContent}
      />
    </Paper>
  );
};
