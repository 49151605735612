import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import chunk from 'lodash/chunk';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { useEffect, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useWindowDimensions } from 'react-native';

import { useCurrentCountryContext } from '@hooks/useCurrentCountry';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  CircularProgress,
  Drawer,
  Fab,
  List,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { CARD_MAX_WIDTH, PlanningCard } from './components/PlanningCard';
import { PlanningForm } from './form';
import { usePlannings } from '../../hooks/plannings';
import {
  jobToString,
  Planning,
  PlanningJob,
  PlanningStatus,
  statusToString,
} from '../../types/planning';
dayjs.extend(LocalizedFormat);

const PlanningByYear = ({
  plannings,
  cardPerLine,
  setOpen,
  setEditedPlanning,
}: {
  plannings: Planning[];
  cardPerLine: number;
  setOpen: (open: boolean) => void;
  setEditedPlanning: (planning: Planning) => void;
}) => {
  if (!plannings.length) {
    return null;
  }

  const groupByYear = groupBy(plannings, (planning) =>
    dayjs(planning.begin).year(),
  );
  const years = Object.keys(groupByYear)
    .map((year) => parseInt(year, 10))
    .sort((a, b) => b - a);

  return (
    <>
      {years.map((year) => (
        <Box key={year} sx={{ paddingBottom: '0.5rem' }}>
          <Typography
            color="primary"
            variant="subtitle1"
            sx={{ fontWeight: 500, marginLeft: '-0.25rem' }}
          >
            ● {year}
          </Typography>
          <PlanningByMonth
            plannings={groupByYear[year]}
            cardPerLine={cardPerLine}
            setOpen={setOpen}
            setEditedPlanning={setEditedPlanning}
          />
        </Box>
      ))}
    </>
  );
};

const PlanningByMonth = ({
  plannings,
  cardPerLine,
  setOpen,
  setEditedPlanning,
}: {
  plannings: Planning[];
  cardPerLine: number;
  setOpen: (open: boolean) => void;
  setEditedPlanning: (planning: Planning) => void;
}) => {
  const groupByMonth = groupBy(plannings, (planning) =>
    dayjs(planning.begin).month(),
  );
  const months = Object.keys(groupByMonth)
    .map((month) => parseInt(month, 10))
    .sort((a, b) => b - a);

  return (
    <Box>
      {months.map((month) => (
        <Box key={month}>
          <Typography variant="subtitle2" sx={{ ml: '0.5rem' }}>
            {dayjs().month(month).format('MMMM').toUpperCase()}
          </Typography>
          {chunk(sortBy(groupByMonth[month], 'job'), cardPerLine).map(
            (_plannings, idx) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
                key={idx}
              >
                {_plannings.map((planning) => (
                  <PlanningCard
                    key={planning.id}
                    planning={planning}
                    onEdit={() => {
                      setOpen(true);
                      setEditedPlanning(planning);
                    }}
                  />
                ))}
              </Box>
            ),
          )}
        </Box>
      ))}
    </Box>
  );
};

export const PlanningList = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const [status, setStatus] = useState<PlanningStatus | 'all'>('all');
  const [job, setJob] = useState<PlanningJob | 'all'>('all');
  const [country] = useCurrentCountryContext();
  const {
    data = [],
    isLoading,
    refetch,
    error,
  } = usePlannings({
    status,
    job,
    countries: !country || country === 'all' ? 'all' : [country],
  });

  useEffect(() => {
    if (!isLoading && error) {
      notify('planning.errorLoading', { type: 'error' });
    }
  }, [error, isLoading, notify]);
  const [open, setOpen] = useState(false);
  const [editedPlanning, setEditedPlanning] = useState<Planning | null>(null);
  const { width } = useWindowDimensions();
  const cardPerLine = Math.floor(width / CARD_MAX_WIDTH);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: '1rem',
        overflow: 'auto',
      }}
    >
      <Fab
        color="primary"
        aria-label="add"
        sx={(theme) => ({
          position: 'fixed',
          right: theme.spacing(2),
          bottom: theme.spacing(2),
        })}
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </Fab>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h5"
          color="primary"
          sx={{ fontWeight: 'bold', fontFamily: 'Lora' }}
        >
          {translate('planning.titleAdmin')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography>
            {translate('common.status') + translate('common.colon')}
          </Typography>
          <Select<PlanningStatus | 'all'>
            variant="outlined"
            value={status}
            onChange={(event) =>
              setStatus(event.target.value as PlanningStatus | 'all')
            }
            size="small"
          >
            <MenuItem value={'all'}>{translate('common.all')}</MenuItem>
            {Object.values(PlanningStatus).map((s, i) => (
              <MenuItem key={i} value={s}>
                {translate(statusToString[s])}
              </MenuItem>
            ))}
          </Select>
          <Typography>
            {translate('common.country') + translate('common.colon')}
          </Typography>
          <Typography>
            {translate('common.job') + translate('common.colon')}
          </Typography>
          <Select<PlanningJob | 'all'>
            value={job}
            variant="outlined"
            onChange={(event) =>
              setJob(event.target.value as PlanningJob | 'all')
            }
            size="small"
          >
            <MenuItem value={'all'}>{translate('common.all')}</MenuItem>
            {Object.values(PlanningJob).map((s) => (
              <MenuItem key={s} value={s}>
                {translate(jobToString[s])}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Drawer
        PaperProps={{
          sx: {
            minWidth: 480,
          },
        }}
        open={open}
        ModalProps={{
          keepMounted: false,
        }}
        anchor="right"
        onClose={() => {
          setOpen(false);
          setEditedPlanning(null);
          refetch();
        }}
      >
        <PlanningForm
          onClose={() => {
            setOpen(false);
            setEditedPlanning(null);
            refetch();
          }}
          planning={editedPlanning ?? undefined}
        />
      </Drawer>
      {isLoading && <CircularProgress />}
      {!data?.length && (
        <Typography variant="h6">{translate('planning.noMatch')}</Typography>
      )}
      <List>
        <PlanningByYear
          plannings={data}
          cardPerLine={cardPerLine}
          setOpen={setOpen}
          setEditedPlanning={setEditedPlanning}
        />
      </List>
    </Box>
  );
};
