import { useTranslate } from 'react-admin';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';

import { Button } from '@components/generic/Button';
import { Row } from '@components/generic/Row';
import { usePrompt } from '@hooks/usePrompt';
import Delete from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import { Box, CircularProgress, Drawer } from '@mui/material';
import {
  Question,
  QuestionRule,
  QuestionType,
  Template,
} from '@teammay/form-core';

import { TargetProvider } from './TargetProvider';

export type EditComponent = React.ComponentType<{
  onBlur: () => void;
  onCancel: () => void;
}>;

export type EditWrapperProps<
  Content extends Partial<Question> | Partial<Template> | Partial<QuestionRule>,
> = {
  content: Content;
  FormComponent: EditComponent;
  open: boolean;
  onCancel: () => void;
  onSave: (content: Content) => void;
  onChange?: (content: Content) => void;
  defaultValues?: Partial<Content>;
  onDelete?: () => void;
  targetObject: {
    type: 'question' | 'template' | 'step';
    id: string;
  };
};

function EditWrapperInner<
  Content extends Partial<Question> | Partial<Template> | Partial<QuestionRule>,
>({
  content,
  onChange,
  onSave,
  onCancel,
  FormComponent,
  defaultValues = {},
  onDelete,
  targetObject,
}: Omit<EditWrapperProps<Content>, 'open'>) {
  const translate = useTranslate();
  const form = useForm<Content>({
    defaultValues: {
      ...defaultValues,
      ...content,
    } as DefaultValues<Content>,
    mode: 'onTouched',
  });
  const { handleSubmit, reset, formState } = form;

  const { isLoading, isDirty, isValid } = formState;
  usePrompt(translate('generic.notSaved'), isDirty);

  const reorderData = (
    rawData: Content,
    callback: (content: Content) => void,
  ) => {
    const { maxSelection, ...data } = rawData as Content & {
      maxSelection?: number;
    };
    if (targetObject.type === 'question') {
      const question_ = data as Partial<Question>;
      if (question_.type === QuestionType.SCALE) {
        const question = data as Partial<Question<QuestionType.SCALE>>;
        // @ts-ignore
        callback({
          ...question,
          options: {
            ...question.options,
            min: Number(question.options.min),
            max: Number(question.options.max),
            step: Number(question.options.step),
          },
        });
      } else if (question_.type === QuestionType.IMAGE) {
        const question = data as Partial<Question<QuestionType.IMAGE>>;
        // @ts-ignore
        callback({
          ...question,
          options: {
            primaryKey: self.crypto.randomUUID(),
            secondaryKey: self.crypto.randomUUID(),
            ...question.options,
          },
        });
      } else if (question_.type === QuestionType.SELECT) {
        const question = data as Partial<Question<QuestionType.SELECT>>;
        // @ts-ignore
        callback({
          ...question,
          maxSelection: maxSelection ? Number(maxSelection) : null,
        });
      } else {
        // @ts-ignore
        callback(question_);
      }
    } else {
      // @ts-ignore
      callback(data);
    }
  };
  const handleChange = handleSubmit((rawData: Content) =>
    reorderData(rawData, onChange),
  );
  const submit = handleSubmit((data: Content) => reorderData(data, onSave));

  return (
    <>
      <Box
        sx={{
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          py: 2,
          px: 4,
        }}
      >
        <TargetProvider value={targetObject}>
          <FormProvider {...form}>
            <FormComponent onBlur={handleChange} onCancel={onCancel} />
            <Row>
              <Button
                variant={'contained'}
                size="small"
                color="primary"
                onClick={() => submit()}
                startIcon={
                  isLoading ? (
                    <CircularProgress size="small" />
                  ) : (
                    <SaveIcon sx={{ color: 'white' }} />
                  )
                }
                loading={isLoading}
                disabled={!isValid}
              >
                {translate('generic.save')}
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 1,
                }}
              >
                {onDelete && (
                  <Button
                    variant="text"
                    size="small"
                    color="error"
                    onClick={onDelete}
                    startIcon={<Delete />}
                  >
                    {translate('common.delete')}
                  </Button>
                )}
                <Button
                  variant={'text'}
                  size="small"
                  color="inherit"
                  onClick={() => {
                    reset();
                  }}
                  startIcon={
                    isLoading ? (
                      <CircularProgress size="small" />
                    ) : (
                      <RestartAltIcon />
                    )
                  }
                  loading={isLoading}
                >
                  {translate('generic.reset')}
                </Button>
              </Box>
            </Row>
          </FormProvider>
        </TargetProvider>
      </Box>
    </>
  );
}

export function EditWrapper<
  Content extends Partial<Question> | Partial<Template> | Partial<QuestionRule>,
>({ open, onCancel, ...props }: EditWrapperProps<Content>) {
  return (
    <Drawer
      open={open}
      anchor="left"
      onClose={(_) => {
        onCancel();
      }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          minWidth: '60%',
          maxWidth: '80%',
        },
      }}
      SlideProps={{
        unmountOnExit: true,
      }}
    >
      <EditWrapperInner onCancel={onCancel} {...props} />
    </Drawer>
  );
}
