import { useTranslate } from 'react-admin';
import { useForm, useWatch } from 'react-hook-form';

import { SelectInput } from '@components/forms/selectInput';
import { TextInput } from '@components/forms/textInput';
import { Button } from '@components/generic/Button';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
} from '@mui/material';

import {
  ANSWER_PAID_ORIGIN,
  FormActions,
  OnboardingMobileScreens,
  ParsedPaywallAction,
  ParsedUserProfileAction,
  userAnswersKeys,
  ANSWERS_SEX,
  ANSWERS_MARITAL_STATUS,
  ANSWERS_SOURCE,
  ParsedExternalDataAction,
  ParsedScheduleSubscriptionUpdateAction,
  ParsedPaymentScreenAction,
} from './onboardingTypes';

export interface OnboardingTagForm {
  action: FormActions;
  [FormActions.NEXT_SCREEN]: {
    screenType: 'remoteConfig' | 'screen';
    screenName: string;
  };
  [FormActions.USER_PROFILE]: ParsedUserProfileAction['arguments'];
  [FormActions.PAYWALL]: ParsedPaywallAction['arguments'];
  [FormActions.EXTERNAL_DATA]: ParsedExternalDataAction['arguments'];
  [FormActions.NEXT_FORM]: {
    slugType: 'remoteConfig' | 'formSlug';
    slug: string;
  };
  [FormActions.SCHEDULE_SUBSCRIPTION_UPDATE]: ParsedScheduleSubscriptionUpdateAction['arguments'];
  [FormActions.PAYMENT_SCREEN]: ParsedPaymentScreenAction['arguments'];
  [FormActions.EVENT_TRACKING]: { event: string; other: string };
  profileKey: (typeof userAnswersKeys)[number];
  remoteConfig: string;
}

const generateTag = (data: OnboardingTagForm) => {
  switch (data.action) {
    case FormActions.NEXT_SCREEN:
      if (data[FormActions.NEXT_SCREEN].screenType === 'remoteConfig') {
        return `${data.action}_==_remoteConfig:${data.remoteConfig}`;
      }
      return `${data.action}_==_screen:${data[FormActions.NEXT_SCREEN].screenName}`;
    case FormActions.USER_PROFILE:
      return `${data.action}_==_${data.profileKey}:${data[FormActions.USER_PROFILE][data.profileKey]}`;
    case FormActions.EXTERNAL_DATA:
      return `${data.action}_==_${data[FormActions.EXTERNAL_DATA].key}:${data[FormActions.EXTERNAL_DATA].value}`;
    case FormActions.PAYWALL:
      return `${data.action}_==_${Object.keys(data[FormActions.PAYWALL])
        .filter((key) => !!data[FormActions.PAYWALL][key])
        .map((key) => `${key}:${data[FormActions.PAYWALL][key]}`)
        .join(',')}`;
    case FormActions.NEXT_FORM:
      if (data[FormActions.NEXT_FORM].slugType === 'remoteConfig') {
        return `${data.action}_==_remoteConfig:${data.remoteConfig}`;
      }
      return `${data.action}_==_formSlug:${data[FormActions.NEXT_FORM].slug}`;
    case FormActions.SCHEDULE_SUBSCRIPTION_UPDATE:
      return `${data.action}_==_planId:${data[FormActions.SCHEDULE_SUBSCRIPTION_UPDATE].planId},promotionCode:${data[FormActions.SCHEDULE_SUBSCRIPTION_UPDATE].promotionCode}`;
    case FormActions.PAYMENT_SCREEN:
      return `${data.action}_==_planId:${data[FormActions.PAYMENT_SCREEN].planId},promotionCode:${data[FormActions.PAYMENT_SCREEN].promotionCode}`;
    case FormActions.EVENT_TRACKING:
      return `${data.action}_==_event:${data[FormActions.EVENT_TRACKING].event},${data[FormActions.EVENT_TRACKING].other}`;
    default:
      return data.action;
  }
};

export const OnboardingTagDialog = ({
  isOpen,
  onClose,
  onSave,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSave: (tag: string) => void;
}) => {
  const { control, handleSubmit, formState } = useForm<OnboardingTagForm>();
  const action = useWatch({ control, name: 'action' });
  const slugType = useWatch({
    control,
    name: `${FormActions.NEXT_FORM}.slugType`,
  });
  const screenType = useWatch({
    control,
    name: `${FormActions.NEXT_SCREEN}.screenType`,
  });
  const profileKey = useWatch({
    control,
    name: 'profileKey',
  });
  const t = useTranslate();
  const { isValid } = formState;
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t('forms.scoringRule.onboardingTag')}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit((data) => onSave(generateTag(data)))}>
          <Stack
            direction={'column'}
            spacing={2}
            minWidth={500}
            p={2}
            alignItems={'stretch'}
          >
            <SelectInput
              control={control}
              name="action"
              variant="outlined"
              rules={{ required: true }}
            >
              {Object.entries(FormActions).map(([key, value]) => (
                <MenuItem key={value} value={value}>
                  {t(`forms.scoringRule.actions.${key}`)}
                </MenuItem>
              ))}
            </SelectInput>

            {action === FormActions.NEXT_SCREEN && (
              <SelectInput
                control={control}
                name={`${FormActions.NEXT_SCREEN}.screenType`}
                label={t('forms.scoringRule.screenName')}
                variant="outlined"
              >
                <MenuItem value="screen">screen</MenuItem>
                <MenuItem value="remoteConfig">remoteConfig</MenuItem>
              </SelectInput>
            )}

            {action === FormActions.NEXT_SCREEN && screenType === 'screen' && (
              <SelectInput
                control={control}
                name={`${FormActions.NEXT_SCREEN}.screenName`}
                label={t('forms.scoringRule.screenName')}
                variant="outlined"
              >
                {Object.entries(OnboardingMobileScreens).map(([key, value]) => (
                  <MenuItem key={value} value={value}>
                    {t(`forms.scoringRule.screenNames.${key}`)}
                  </MenuItem>
                ))}
              </SelectInput>
            )}

            {action === FormActions.USER_PROFILE && (
              <SelectInput
                control={control}
                name={`profileKey`}
                label={t('forms.scoringRule.profile')}
                variant="outlined"
              >
                {userAnswersKeys.map((key) => (
                  <MenuItem key={key} value={key}>
                    {t(`forms.scoringRule.profileKeys.${key}`)}
                  </MenuItem>
                ))}
              </SelectInput>
            )}

            {action === FormActions.USER_PROFILE && profileKey === 'sex' && (
              <SelectInput
                control={control}
                name={`${FormActions.USER_PROFILE}.sex`}
                label={t('forms.scoringRule.profileKeys.sex')}
                variant="outlined"
              >
                {Object.entries(ANSWERS_SEX).map(([key, value]) => (
                  <MenuItem value={value}>
                    {t(`forms.scoringRule.sexOptions.${key}`)}
                  </MenuItem>
                ))}
              </SelectInput>
            )}

            {action === FormActions.USER_PROFILE &&
              profileKey === 'maritalStatus' && (
                <SelectInput
                  control={control}
                  name={`${FormActions.USER_PROFILE}.maritalStatus`}
                  label={t('forms.scoringRule.profileKeys.maritalStatus')}
                  variant="outlined"
                >
                  {Object.entries(ANSWERS_MARITAL_STATUS).map(
                    ([key, value]) => (
                      <MenuItem value={value}>
                        {t(`forms.scoringRule.maritalStatusOptions.${key}`)}
                      </MenuItem>
                    ),
                  )}
                </SelectInput>
              )}

            {action === FormActions.USER_PROFILE && profileKey === 'source' && (
              <SelectInput
                control={control}
                name={`${FormActions.USER_PROFILE}.source`}
                label={t('forms.scoringRule.profileKeys.source')}
                variant="outlined"
              >
                {Object.entries(ANSWERS_SOURCE).map(([key, value]) => (
                  <MenuItem value={value}>
                    {t(`forms.scoringRule.sourceOptions.${key}`)}
                  </MenuItem>
                ))}
              </SelectInput>
            )}

            {action === FormActions.USER_PROFILE && profileKey === 'paid' && (
              <SelectInput
                control={control}
                name={`${FormActions.USER_PROFILE}.paid`}
                label={t('forms.scoringRule.profileKeys.paid')}
                variant="outlined"
              >
                {Object.entries(ANSWER_PAID_ORIGIN).map(([key, value]) => (
                  <MenuItem value={value}>
                    {t(`forms.scoringRule.paidOriginOptions.${key}`)}
                  </MenuItem>
                ))}
              </SelectInput>
            )}

            {action === FormActions.USER_PROFILE && profileKey === 'intent' && (
              <TextInput
                control={control}
                name={`${FormActions.USER_PROFILE}.intent`}
                label={t('forms.scoringRule.profileKeys.intent')}
                variant="outlined"
              />
            )}

            {action === FormActions.PAYWALL && (
              <>
                <TextInput
                  control={control}
                  name={`${FormActions.PAYWALL}.SubscribeModalName`}
                  label={t('forms.scoringRule.subscribeModalName')}
                  variant="outlined"
                  inputMode="numeric"
                />
                <TextInput
                  control={control}
                  name={`${FormActions.PAYWALL}.promoCode`}
                  label={t('forms.scoringRule.promoCode')}
                  variant="outlined"
                />
                <TextInput
                  control={control}
                  name={`${FormActions.PAYWALL}.targetTab`}
                  label={t('forms.scoringRule.targetTab')}
                  variant="outlined"
                  inputMode="numeric"
                />
              </>
            )}

            {action === FormActions.EXTERNAL_DATA && (
              <>
                <TextInput
                  control={control}
                  name={`${FormActions.EXTERNAL_DATA}.key`}
                  label={t('forms.scoringRule.externalDataKey')}
                  variant="outlined"
                />
                <TextInput
                  control={control}
                  name={`${FormActions.EXTERNAL_DATA}.value`}
                  label={t('forms.scoringRule.externalDataValue')}
                  variant="outlined"
                />
              </>
            )}

            {action === FormActions.SCHEDULE_SUBSCRIPTION_UPDATE && (
              <>
                <TextInput
                  control={control}
                  name={`${FormActions.SCHEDULE_SUBSCRIPTION_UPDATE}.planId`}
                  label={t('forms.scoringRule.planId')}
                  variant="outlined"
                  rules={{ required: true }}
                />
                <TextInput
                  control={control}
                  name={`${FormActions.SCHEDULE_SUBSCRIPTION_UPDATE}.promotionCode`}
                  label={t('forms.scoringRule.promotionCode')}
                  variant="outlined"
                />
              </>
            )}

            {action === FormActions.PAYMENT_SCREEN && (
              <>
                <TextInput
                  control={control}
                  name={`${FormActions.PAYMENT_SCREEN}.planId`}
                  label={t('forms.scoringRule.planId')}
                  variant="outlined"
                  rules={{ required: true }}
                />
                <TextInput
                  control={control}
                  name={`${FormActions.PAYMENT_SCREEN}.promotionCode`}
                  label={t('forms.scoringRule.promotionCode')}
                  variant="outlined"
                />
              </>
            )}

            {action === FormActions.EVENT_TRACKING && (
              <>
                <TextInput
                  control={control}
                  name={`${FormActions.EVENT_TRACKING}.event`}
                  label={t('forms.scoringRule.event')}
                  variant="outlined"
                  rules={{ required: true }}
                />
                <TextInput
                  control={control}
                  name={`${FormActions.EVENT_TRACKING}.other`}
                  label={t('forms.scoringRule.trackedData')}
                  variant="outlined"
                />
              </>
            )}

            {action === FormActions.NEXT_FORM && (
              <SelectInput
                control={control}
                name={`${FormActions.NEXT_FORM}.slugType`}
                label={t('forms.scoringRule.slugType')}
                variant="outlined"
              >
                <MenuItem value="formSlug">
                  {t('forms.scoringRule.formSlug')}
                </MenuItem>
                <MenuItem value="remoteConfig">
                  {t('forms.scoringRule.remoteConfig')}
                </MenuItem>
              </SelectInput>
            )}

            {action === FormActions.NEXT_FORM && slugType === 'formSlug' && (
              <TextInput
                control={control}
                name={`${FormActions.NEXT_FORM}.slug`}
                label={t('forms.scoringRule.formSlug')}
                variant="outlined"
              />
            )}

            {(action === FormActions.NEXT_SCREEN &&
              screenType === 'remoteConfig') ||
              (action === FormActions.NEXT_FORM &&
                slugType === 'remoteConfig' && (
                  <TextInput
                    control={control}
                    name="remoteConfig"
                    label={t('forms.scoringRule.remoteConfig')}
                  />
                ))}

            <Stack direction={'row'} spacing={2} alignSelf={'stretch'}>
              <Button
                variant="outlined"
                color="primary"
                onClick={onClose}
                sx={{ flex: 1 }}
              >
                {t('common.cancel')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ flex: 1 }}
                disabled={!isValid}
              >
                {t('common.save')}
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};
