import { Fragment } from 'react';
import {
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  List,
  useTranslate,
  useLocale,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import { MayTopToolbar } from '@components/mayTopToolbar';
import { Box, Typography } from '@mui/material';

import { EditButton } from '../../../../components/generic/EditButton';

const Toolbar = () => {
  const translate = useTranslate();
  return (
    <MayTopToolbar>
      <Typography variant="subtitle2">
        {translate('pregnancy.plural')}
      </Typography>
    </MayTopToolbar>
  );
};

const EmptyList = () => {
  const translate = useTranslate();
  return (
    <Box sx={{ paddingTop: 1, width: '100%' }}>
      <Toolbar />
      <Typography variant="body2" sx={{ ml: 0.5 }}>
        {translate('pregnancy.empty')}
      </Typography>
    </Box>
  );
};

export const InlinePregnanciesList = () => {
  const { id } = useParams<'id'>();
  const locale = useLocale();
  const translate = useTranslate();

  return (
    <List
      resource="pregnancies"
      exporter={false}
      pagination={null}
      perPage={50}
      filter={{ appUser: id, active: true }}
      actions={<Toolbar />}
      empty={<EmptyList />}
      title={<Fragment />}
    >
      {/* We use a custom edit button mainly to hack the redirection */}
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="id" />
        <DateField
          locales={locale}
          source="expectedEnd"
          label={translate('pregnancy.expectedEnd')}
        />
        <BooleanField source="active" label="✔️" />
        <BooleanField
          source="multiple"
          label={translate('pregnancy.multipleVeryShort')}
        />
        <BooleanField
          source="interrupted"
          label={translate('pregnancy.interrupted')}
        />
        <BooleanField source="born" label={translate('pregnancy.born')} />
        <EditButton />
      </Datagrid>
    </List>
  );
};
