import { useTranslate } from 'react-admin';
import ReactMarkdown from 'react-markdown';

import { PregnancyWeekContent } from '@boTypes/pregnancyWeekContent';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { DialogTitle } from '../generic/Dialog';

export const WIDTH = 148;
export const HEIGHT = 120;

const StyledImage = styled('img')({
  marginLeft: '-24px',
  marginRight: '-24px',
  width: 'calc(100% + 48px)',
  height: '250px',
  objectFit: 'cover',
});

const InnerPregnancyWeekContent = ({
  item,
}: {
  item: PregnancyWeekContent;
}) => {
  const translate = useTranslate();
  const author = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Avatar
        src={item.authorImage}
        sx={{
          width: 24,
          height: 24,
          borderWidth: 1,
          borderColor: 'background.grey',
          marginRight: 1,
        }}
      />
      <Typography variant="body2">{item.authorName}</Typography>
    </Box>
  );
  return (
    <>
      <StyledImage src={item?.illustration} alt={item.title} />
      {author}
      <ReactMarkdown>{item.sizeDescription}</ReactMarkdown>
      <Typography variant="body2" sx={{ marginTop: 1 }}>
        {translate('pregnancyWeekContent.height')}
        {translate('common.colon')}
        {item.height}
      </Typography>
      <Typography variant="body2">
        {translate('pregnancyWeekContent.weight')}
        {translate('common.colon')}
        {item.weight}
      </Typography>
      <Box
        sx={{
          maxWidth: '100%',
          '& p > img': { width: '100%' },
          '& p': { wordWrap: 'break-word' },
        }}
      >
        <ReactMarkdown>{item.content}</ReactMarkdown>
      </Box>
    </>
  );
};

export const PregnancyWeekContentDialog = ({
  item,
  translatedItem,
  open,
  close,
}: {
  item: PregnancyWeekContent;
  translatedItem?: PregnancyWeekContent;
  open: boolean;
  close: () => void;
}) => (
  <Dialog
    open={open}
    onClose={close}
    aria-labelledby={item.title}
    aria-describedby={item.title}
    maxWidth={translatedItem ? 'xl' : 'md'}
  >
    <DialogTitle onClose={close}>
      {translatedItem ? (
        <Stack direction="row" gap={6} sx={{ flex: 1, width: '100%' }}>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            {item.title}
          </Stack>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            {translatedItem.title}
          </Stack>
        </Stack>
      ) : (
        item.title
      )}
    </DialogTitle>
    <DialogContent>
      {translatedItem ? (
        <Stack
          direction="row"
          justifyContent={'space-between'}
          gap={1}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{ width: '100%' }}
        >
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            <InnerPregnancyWeekContent item={item} />
          </Stack>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            <InnerPregnancyWeekContent item={translatedItem} />
          </Stack>
        </Stack>
      ) : (
        <InnerPregnancyWeekContent item={item} />
      )}
    </DialogContent>
  </Dialog>
);
