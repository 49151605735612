import { cloneDeep, merge } from 'lodash';
import { useRef } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { DialogButton } from '@components/DialogButton/DialogButton';
import { SwitchInput } from '@components/forms/switchInput';
import { Button } from '@components/generic/Button';
import { DialogTitle } from '@components/generic/Dialog';
import {
  FormSettingsUpdate,
  useFormDelete,
  useFormDetail,
  useFormDetailUpdate,
  useFormUpdate,
} from '@hooks/form-builder';
import { useQueryClient } from '@hooks/queryWrappers';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
} from '@mui/material';
import { Condition, Form } from '@teammay/form-core';

import { SelectInput } from '../../../components/forms/selectInput';
import { TextInput } from '../../../components/forms/textInput';

export const FormDuplicateForm = ({
  originalForm,
  successCallback,
}: {
  originalForm?: Omit<
    Form,
    'id' | 'firstStep' | 'steps' | 'createdAt' | 'updatedAt'
  >;
  successCallback: (
    data: Omit<Form, 'id' | 'firstStep' | 'steps'>,
  ) => Promise<any>;
}) => {
  const { control, handleSubmit, formState } = useForm<
    Omit<Form, 'id' | 'firstStep' | 'steps'>
  >({
    defaultValues: {
      title: originalForm.title,
      slug: originalForm.slug,
      version: originalForm.version,
      description: originalForm.description,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      status: 'draft' as const,
      illustration: originalForm.illustration,
      questionCount: originalForm.questionCount,
      sentInChat: originalForm.sentInChat,
    },
  });

  const { isSubmitting } = formState;

  const onSubmit = handleSubmit(async (data) => {
    data.questionCount = Number(data.questionCount);
    await successCallback(data);
  });

  const translate = useTranslate();

  return (
    <>
      <DialogContent sx={{ minWidth: 500 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'stretch',
            justifyContent: 'center',
            p: 0,
          }}
        >
          <TextInput
            control={control}
            name="title"
            label={translate('forms.settings.titleField')}
          />
          <TextInput
            control={control}
            name="description"
            label={translate('forms.settings.description')}
          />
          <TextInput
            control={control}
            name="slug"
            label={translate('forms.settings.slug')}
          />
          <TextInput
            control={control}
            name="illustration"
            label={translate('forms.settings.illustration')}
          />
          <SelectInput
            control={control}
            name="status"
            label={translate('forms.settings.status')}
            variant={'outlined'}
          >
            {['draft', 'published', 'archived'].map((status) => (
              <MenuItem value={status} key={status}>
                {status}
              </MenuItem>
            ))}
          </SelectInput>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mx: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          loading={isSubmitting}
          value={translate('common.save')}
          onClick={onSubmit}
        />
      </DialogActions>
    </>
  );
};

export const FormCreateForm = ({
  successCallback,
}: {
  successCallback: () => void;
}) => {
  const navigate = useNavigate();
  const defaultId = useRef(self.crypto.randomUUID()).current;
  const defaultFirstStep = useRef({
    id: self.crypto.randomUUID(),
    title: 'First Step',
    links: [],
    formId: defaultId,
  }).current;
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      id: defaultId,
      title: 'New Form',
      slug: 'new-form',
      version: '1.0.0',
      description: '',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      firstStep: defaultFirstStep,
      steps: [defaultFirstStep],
      status: 'draft' as const,
      illustration: null,
      questionCount: 0,
      sentInChat: false,
    },
  });

  const { mutateAsync: create } = useFormDetailUpdate({ isNew: true });

  const { isSubmitting } = formState;

  const onSubmit = handleSubmit(async (data) => {
    data.questionCount = Number(data.questionCount);
    await create({ form: data, questions: [], templates: [] });
    successCallback();
    navigate(`/forms/${defaultId}`);
  });

  const translate = useTranslate();

  return (
    <>
      <DialogContent sx={{ minWidth: 500 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'stretch',
            justifyContent: 'center',
            p: 0,
          }}
        >
          <TextInput
            control={control}
            name="title"
            label={translate('forms.settings.titleField')}
          />
          <TextInput
            control={control}
            name="description"
            label={translate('forms.settings.description')}
          />
          <TextInput
            control={control}
            name="slug"
            label={translate('forms.settings.slug')}
          />
          <TextInput
            control={control}
            name="illustration"
            label={translate('forms.settings.illustration')}
          />
          <SwitchInput
            control={control}
            name="sentInChat"
            label={translate('forms.settings.sentInChat')}
          />
          <SelectInput
            control={control}
            name="status"
            label={translate('forms.settings.status')}
            variant={'outlined'}
          >
            {['draft', 'published', 'archived'].map((status) => (
              <MenuItem value={status} key={status}>
                {status}
              </MenuItem>
            ))}
          </SelectInput>
          <TextInput
            control={control}
            name="questionCount"
            type="number"
            label={translate('forms.settings.questionCount')}
            helperText={translate('forms.settings.questionCountHelper')}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mx: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          loading={isSubmitting}
          value={translate('common.save')}
          onClick={onSubmit}
        />
      </DialogActions>
    </>
  );
};

export const FormSettingsForm = ({
  form,
  onSave,
  onCancel,
  onDelete,
}: {
  form: FormSettingsUpdate;
  onSave: (form: FormSettingsUpdate) => Promise<any>;
  onCancel: () => any;
  onDelete: (formId: string) => any;
}) => {
  const { control, handleSubmit, formState } = useForm({
    defaultValues: form,
  });

  const { isSubmitting } = formState;

  const onSubmit = handleSubmit(async (data) => {
    if (Object.keys(data).some((key) => data[key] !== form[key])) {
      await onSave(data);
    } else {
      onCancel();
    }
  });

  const translate = useTranslate();

  return (
    <>
      <DialogContent sx={{ minWidth: 500 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'stretch',
            justifyContent: 'center',
            p: 0,
          }}
        >
          <TextInput
            control={control}
            name="title"
            label={translate('forms.settings.titleField')}
          />
          <TextInput
            control={control}
            name="description"
            label={translate('forms.settings.description')}
          />
          <TextInput
            control={control}
            name="slug"
            label={translate('forms.settings.slug')}
          />
          <TextInput
            control={control}
            name="illustration"
            label={translate('forms.settings.illustration')}
          />
          <SwitchInput
            control={control}
            name="sentInChat"
            label={translate('forms.settings.sentInChat')}
          />
          <SelectInput
            control={control}
            name="status"
            label={translate('forms.settings.status')}
            variant={'outlined'}
          >
            {['draft', 'published', 'archived'].map((status) => (
              <MenuItem value={status} key={status}>
                {status}
              </MenuItem>
            ))}
          </SelectInput>
          <TextInput
            control={control}
            name="questionCount"
            type="number"
            label={translate('forms.settings.questionCount')}
            helperText={translate('forms.settings.questionCountHelper')}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mx: 2,
        }}
      >
        <DialogButton
          title={translate('forms.settings.deleteTitle')}
          text={translate('forms.settings.deleteText')}
          disabled={!!isSubmitting}
          onClick={() => onDelete(form.id)}
          color="error"
        >
          {translate('common.delete')}
        </DialogButton>
        <Button
          variant="contained"
          color="primary"
          loading={isSubmitting}
          value={translate('common.save')}
          onClick={onSubmit}
        />
      </DialogActions>
    </>
  );
};

export const FormSettingsModal = ({
  form,
  onClose,
}: {
  form?: FormSettingsUpdate | null;
  onClose: () => void;
}) => {
  const translate = useTranslate();
  const { mutateAsync: update } = useFormUpdate();
  const { mutateAsync: onDelete } = useFormDelete();
  const queryClient = useQueryClient();

  return (
    <Dialog open={!!form} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('forms.edit.title')}
      </DialogTitle>
      {form && (
        <FormSettingsForm
          form={form}
          onSave={async ({
            id,
            description,
            illustration,
            slug,
            status,
            title,
            version,
            sentInChat,
            questionCount,
          }: FormSettingsUpdate) => {
            await update({
              id,
              description,
              illustration,
              slug,
              status,
              title,
              version,
              sentInChat,
              questionCount: Number(questionCount),
            });
            onClose();
          }}
          onCancel={() => onClose()}
          onDelete={() =>
            onDelete(form?.id, {
              onSuccess: () => {
                onClose();
                queryClient.invalidateQueries({ queryKey: ['forms'] });
              },
            })
          }
        />
      )}
    </Dialog>
  );
};

export const FormCreateModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const translate = useTranslate();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('forms.edit.title')}
      </DialogTitle>
      <FormCreateForm successCallback={onClose} />
    </Dialog>
  );
};

const traverseCondition = (
  oldToNewId: Record<string, string>,
  condition?: Condition,
) => {
  if (!condition) {
    return;
  }
  if (!oldToNewId[condition.id]) {
    const newId = self.crypto.randomUUID();
    oldToNewId[condition.id] = newId;
    oldToNewId[newId] = newId; // Add id mapping to not recreate a new id for the same condition
  }
  condition.id = oldToNewId[condition.id];
  condition.dataRules?.forEach((rule) => {
    if (!oldToNewId[rule.id]) {
      const newId = self.crypto.randomUUID();
      oldToNewId[rule.id] = newId;
      oldToNewId[newId] = newId;
    }
    rule.id = oldToNewId[rule.id];
  });
  condition.questionRules?.forEach((rule) => {
    if (!oldToNewId[rule.id]) {
      const newId = self.crypto.randomUUID();
      oldToNewId[rule.id] = newId;
      oldToNewId[newId] = newId;
    }
    rule.id = oldToNewId[rule.id];
    rule.questionId = oldToNewId[rule.questionId];
  });
  condition.externalRules?.forEach((rule) => {
    if (!oldToNewId[rule.id]) {
      const newId = self.crypto.randomUUID();
      oldToNewId[rule.id] = newId;
      oldToNewId[newId] = newId;
    }
    rule.id = oldToNewId[rule.id];
  });
  condition.children?.forEach((child) => {
    traverseCondition(oldToNewId, child);
  });
};

export const FormDuplicateModal = ({
  originalForm,
  onClose,
}: {
  originalForm?: Omit<Form, 'firstStep' | 'createdAt' | 'updatedAt'>;
  onClose: () => void;
}) => {
  const translate = useTranslate();
  const { data: formDetail } = useFormDetail(originalForm?.id ?? '');
  const { mutateAsync: submit } = useFormDetailUpdate();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const onSubmit = async (data: Omit<Form, 'id' | 'firstStep' | 'steps'>) => {
    let oldToNewId = {};

    if (!formDetail) {
      return;
    }

    const newForm = merge(cloneDeep(formDetail.form), data);
    const newQuestions = cloneDeep(formDetail.questions);
    const newTemplates = cloneDeep(formDetail.templates);

    // Generate new ids for the form
    const formId = self.crypto.randomUUID();

    newForm.id = formId;

    const firstStepId = self.crypto.randomUUID();
    oldToNewId[formDetail?.form?.firstStep?.id] = firstStepId;
    oldToNewId[firstStepId] = firstStepId;
    newForm.firstStep.id = firstStepId;
    // @ts-ignore
    newForm.firstStepId = firstStepId;

    newForm.steps.forEach((step) => {
      if (!oldToNewId[step.id]) {
        const newId = self.crypto.randomUUID();
        oldToNewId[step.id] = newId;
        oldToNewId[newId] = newId;
      }
      step.id = oldToNewId[step.id];
      // @ts-expect-error override existing key to be sure back does not use it
      step.formId = formId;
      step.links.forEach((link) => {
        if (!oldToNewId[link.id]) {
          const newId = self.crypto.randomUUID();
          oldToNewId[link.id] = newId;
          oldToNewId[newId] = newId;
        }
        link.id = oldToNewId[link.id];
      });
    });
    newQuestions.forEach((question) => {
      if (!oldToNewId[question.id]) {
        const newId = self.crypto.randomUUID();
        oldToNewId[question.id] = newId;
        oldToNewId[newId] = newId;
      }
      question.id = oldToNewId[question.id];
      question.stepId = oldToNewId[question.stepId];
    });

    newForm.steps.forEach((step) => {
      step.links.forEach((link) => {
        link.stepId = oldToNewId[link.stepId];
        link.nextStepId = oldToNewId[link.nextStepId];
        traverseCondition(oldToNewId, link.condition);
      });
    });

    newQuestions.forEach((question) => {
      question.rules?.forEach((rule) => {
        if (!oldToNewId[rule.id]) {
          const newId = self.crypto.randomUUID();
          oldToNewId[rule.id] = newId;
          oldToNewId[newId] = newId;
        }
        rule.id = oldToNewId[rule.id];
        rule.questionId = oldToNewId[rule.questionId];
      });
      question.scoringRules?.forEach((rule) => {
        if (!oldToNewId[rule.id]) {
          const newId = self.crypto.randomUUID();
          oldToNewId[rule.id] = newId;
          oldToNewId[newId] = newId;
        }
        rule.id = oldToNewId[rule.id];
        rule.questionId = oldToNewId[rule.questionId];
      });
      traverseCondition(oldToNewId, question.hideCondition);
    });
    newTemplates.forEach((template) => {
      if (!oldToNewId[template.id]) {
        const newId = self.crypto.randomUUID();
        oldToNewId[template.id] = newId;
        oldToNewId[newId] = newId;
      }
      template.id = oldToNewId[template.id];
      template.stepId = oldToNewId[template.stepId];
      traverseCondition(oldToNewId, template.hideCondition);
    });

    try {
      await submit({
        form: newForm,
        questions: newQuestions,
        templates: newTemplates,
      });
    } catch {
      notify('forms.errors.generic_error', { type: 'error' });
    }
    queryClient.invalidateQueries({ queryKey: ['forms'] });
    onClose();
  };

  return (
    <Dialog open={!!originalForm} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('forms.duplicate.title')}
      </DialogTitle>
      {originalForm && ( // necessary to recreate the form when duplicated form changes
        <FormDuplicateForm
          originalForm={originalForm}
          successCallback={onSubmit}
        />
      )}
    </Dialog>
  );
};
