import * as React from 'react';
import { useLocale, useRecordContext, useTranslate } from 'react-admin';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory';

import { DialogTitle } from '@components/generic/Dialog';
import { useQuery } from '@hooks/queryWrappers';
import { Box, Button, Dialog, DialogContent } from '@mui/material';

import { colorTokens } from '../../themes';

interface AffiliationChartFieldProps {
  label?: string;
  lookupField: 'companyPlanId' | 'companyPlanCodeId';
}

interface AffiliationCount {
  count: string;
  month: string;
}

export const AffiliationChartField = ({
  lookupField,
}: AffiliationChartFieldProps) => {
  const translate = useTranslate();
  const locale = useLocale();
  const record = useRecordContext<Record<string, any>>();
  const id = record.id;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { data: affiliations = [] } = useQuery<AffiliationCount[]>(
    ['affiliations', lookupField, id],
    {
      url: `/api/affiliations/countByMonth?filter={"${lookupField}":"${id}"}`,
      method: 'get',
    },
    {
      enabled: Boolean(open),
    },
  );

  return (
    <Box>
      <Button onClick={handleClickOpen}>
        {translate('affiliations.usage')}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {translate('affiliations.usagePerMonth')}
        </DialogTitle>
        <DialogContent>
          <VictoryChart theme={VictoryTheme.material} domainPadding={20}>
            <VictoryAxis
              tickFormat={(t) =>
                new Date(t).toLocaleDateString(locale, {
                  month: 'short',
                  year: '2-digit',
                })
              }
              style={{
                tickLabels: {
                  fontSize: 10,
                  angle: -45,
                  textAnchor: 'end',
                },
              }}
            />
            <VictoryAxis dependentAxis />
            <VictoryBar
              x="month"
              y="count"
              style={{ data: { fill: colorTokens.content.secondary } }}
              data={affiliations}
              labels={({ datum }) => datum.count}
            />
          </VictoryChart>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
