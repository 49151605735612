import { AxiosResponse } from 'axios';
import { useMemo, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useSearchParams } from 'react-router-dom';

import { CountryCodeToCountryFlag } from '@boTypes/common';
import { Planning, PlanningJob } from '@boTypes/planning';
import { useQuery } from '@hooks/queryWrappers';
import { ChildCare, LocalHospital, PregnantWoman } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { keepPreviousData } from '@tanstack/react-query';
import { dayjsTz, getReadableTimeRange } from '@utils/date';
import { apiRequestWithFullResponse } from '@utils/httpClients';

import { DISPLAY } from '.';
import { colorTokens } from '../../themes';
import { filterPanelProps } from '../common/dataGrid/styles';

const getIcon = (job: PlanningJob) => {
  switch (job) {
    case PlanningJob.NURSE:
      return <ChildCare sx={{ color: colorTokens.primitives.nurse }} />;
    case PlanningJob.MIDWIFE:
      return <PregnantWoman sx={{ color: colorTokens.primitives.midwife }} />;
    case PlanningJob.DOCTOR:
      return <LocalHospital sx={{ color: colorTokens.primitives.doctor }} />;
    default:
      return null;
  }
};

interface InvoiceSummary {
  totalInvoices: number;
  totalValidated: number;
  totalInvalidAmount: number;
  totalExpectedInvoices: number;
}

const usePlanningWithInvoices = (paginationModel: GridPaginationModel) => {
  return useQuery<
    AxiosResponse<(Planning & InvoiceSummary)[]>,
    any,
    { data: (Planning & InvoiceSummary)[]; total: number }
  >(
    ['invoices-admin-summary', paginationModel.page],
    () => ({
      method: 'GET',
      url: '/api/users-invoices/admin/summary',
      params: {
        range: JSON.stringify([
          paginationModel.page * paginationModel.pageSize,
          (paginationModel.page + 1) * paginationModel.pageSize - 1,
        ]),
      },
      placeholderData: keepPreviousData,
    }),
    {
      select: (res) => {
        const contentRangeCount = Number(
          res?.headers['content-range']?.split('/')?.[1] ?? 0,
        );
        return { data: res.data, total: contentRangeCount };
      },
    },
    apiRequestWithFullResponse<(Planning & InvoiceSummary)[]>,
  );
};

const displayTagsOnlyAfterInvoicingPeriod = (end: string | Date) => {
  const now = dayjsTz();
  const tenDaysBeforeEnd = dayjsTz(end).subtract(10, 'day');
  return now.isAfter(tenDaysBeforeEnd);
};

const AdminInvoicesSummary = ({
  setDisplay,
}: {
  setDisplay: (arg: DISPLAY) => void;
}) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const { data: { data: planningsWithInvoicesCount, total } = {}, isLoading } =
    usePlanningWithInvoices(paginationModel);
  const translate = useTranslate();
  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'title',
        headerName: translate('planning.fields.title'),
        flex: 2,
        sortable: true,
        renderCell: (params) => {
          return (
            <Box
              onClick={() => {
                searchParams.set('planning', JSON.stringify([params.row.id]));
                setSearchParams(searchParams);
                setDisplay(DISPLAY.LIST);
              }}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Typography>{params.row.title}</Typography>
            </Box>
          );
        },
        valueGetter: (value: string) => value,
      },
      {
        field: 'job',
        headerName: translate('common.job'),
        flex: 0,
        sortable: true,
        renderCell: (params) => {
          // Pick emoji for each job
          return (
            <Box
              onClick={() => {
                searchParams.set('planning', JSON.stringify([params.row.id]));
                searchParams.set('job', JSON.stringify(params.row.job));
                setSearchParams(searchParams);
                setDisplay(DISPLAY.LIST);
              }}
              sx={{
                width: '100%',
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {getIcon(params.row.job)}
            </Box>
          );
        },
        type: 'singleSelect',
        valueOptions: Object.values(PlanningJob).map((job) => ({
          value: job,
          label: getIcon(job),
        })),
      },
      {
        field: 'country',
        headerName: translate('common.country'),
        flex: 0,
        type: 'singleSelect',
        renderCell: (params) => {
          return CountryCodeToCountryFlag[params.row.country];
        },
        valueOptions: Object.keys(CountryCodeToCountryFlag).map((country) => ({
          value: country,
          label: CountryCodeToCountryFlag[country],
        })),
      },
      {
        field: 'dates',
        type: 'string',
        headerName: translate('planning.fields.dates'),
        sortable: false,
        flex: 1,
        valueGetter: (_params, row: Planning & InvoiceSummary) =>
          getReadableTimeRange(row.begin, row.end),
      },
      {
        field: 'totalExpectedInvoices',
        headerName: translate('planning.fields.totalExpectedInvoices'),
        sortable: false,
        flex: 0,
      },
      {
        field: 'totalInvoices',
        headerName: translate('planning.fields.totalInvoices'),
        sortable: false,
        flex: 0,
        valueGetter(value, row: Planning & InvoiceSummary) {
          return `${value}${
            displayTagsOnlyAfterInvoicingPeriod(row.end)
              ? row.totalExpectedInvoices === value
                ? ' ✅'
                : ' ⏳'
              : ''
          }`;
        },
      },
      {
        field: 'totalValidated',
        headerName: translate('planning.fields.totalValidated'),
        sortable: false,
        flex: 0,
        renderCell: (params) => {
          return (
            <Box
              onClick={() => {
                searchParams.set('planning', JSON.stringify([params.row.id]));
                searchParams.set('validated', JSON.stringify(true));
                setSearchParams(searchParams);
                setDisplay(DISPLAY.LIST);
              }}
              sx={{
                width: '100%',
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography>
                {params.row.totalValidated}
                {displayTagsOnlyAfterInvoicingPeriod(params.row.end)
                  ? params.row.totalExpectedInvoices ===
                    params.row.totalValidated
                    ? ' ✅'
                    : ' ⏳'
                  : ''}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: 'totalInvalidAmount',
        headerName: translate('planning.fields.totalInvalidAmount'),
        sortable: false,
        flex: 0,
        renderCell: (params) => {
          return (
            <Box
              onClick={() => {
                searchParams.set('planning', JSON.stringify([params.row.id]));
                searchParams.set('amountValidated', JSON.stringify(false));
                setSearchParams(searchParams);
                setDisplay(DISPLAY.LIST);
              }}
              sx={{
                width: '100%',
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography>{params.row.totalInvalidAmount}</Typography>
            </Box>
          );
        },
      },
    ] as const;
  }, [translate, searchParams, setSearchParams, setDisplay]);

  // API client return undefined while loading
  // Following lines are here to prevent `rowCount` from being undefined during the loading
  const rowCountRef = useRef(total || 0);
  const rowCount = useMemo(() => {
    if (total !== undefined) {
      rowCountRef.current = total;
    }
    return rowCountRef.current;
  }, [total]);

  return (
    <Box>
      <DataGrid
        rows={planningsWithInvoicesCount ?? []}
        disableRowSelectionOnClick
        isRowSelectable={() => false}
        columns={columns}
        loading={isLoading}
        paginationMode="server"
        pageSizeOptions={[100]}
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slotProps={{ filterPanel: filterPanelProps }}
      />
    </Box>
  );
};

export default AdminInvoicesSummary;
