import { useCallback, useState } from 'react';
import {
  EmailField,
  Identifier,
  Show,
  SimpleShowLayout,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { useForm } from 'react-hook-form';

import { TextInput } from '@components/forms/textInput';
import { DialogTitle } from '@components/generic/Dialog';
import { useMutation, useQueryClient } from '@hooks/queryWrappers';
import { Email } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  List,
  ListItem,
} from '@mui/material';

import { Roles } from '../../../types/user';

const useMailUpdate = (id: Identifier) => {
  const { mutateAsync: updateMail } = useMutation<
    { email: string },
    any,
    { email: string }
  >(['appUser', 'updateEmail'], (data) => ({
    method: 'PUT',
    url: `/api/patients/change-email/${id}`,
    data,
  }));

  return { updateMail };
};

export const EmailUpdateForm = ({ id }: { id: Identifier }) => {
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(Roles.ADMIN) ?? false;
  const queryClient = useQueryClient();
  const notify = useNotify();

  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => setOpen(false), []);
  const translate = useTranslate();
  const { updateMail } = useMailUpdate(id);

  const { control, formState, handleSubmit, reset } = useForm<{
    email: string;
  }>({
    defaultValues: { email: '@gmail.com' },
  });

  const { isSubmitting } = formState;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateMail(data);
      await queryClient.invalidateQueries({ queryKey: ['appUser', id] });
      await queryClient.invalidateQueries({ queryKey: ['patients'] });
      notify('appUsers.updateMailSuccess', { type: 'success' });
      reset();
      onClose();
    } catch (error: any) {
      error?.response?.data?.message
        ? notify(error.response.data.message, { type: 'error' })
        : notify('appUsers.updateMailError', { type: 'error' });
    }
  });

  if (!isAdmin) {
    return null;
  }
  return (
    <>
      <Button
        startIcon={<Email />}
        onClick={() => setOpen(true)}
        variant="text"
        size="small"
      >
        {translate('common.update')}
      </Button>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle onClose={onClose}>
          {translate('appUsers.updateMailTitle')}
        </DialogTitle>
        <DialogContent>
          <Alert severity="info">
            <AlertTitle>
              {translate('appUsers.updateMailAlertTitle')}
            </AlertTitle>
            <List dense>
              <ListItem>{translate('appUsers.updateMailAlertItem1')}</ListItem>
              <ListItem>{translate('appUsers.updateMailAlertItem2')}</ListItem>
              <ListItem>{translate('appUsers.updateMailAlertItem3')}</ListItem>
              <ListItem>{translate('appUsers.updateMailAlertItem4')}</ListItem>
            </List>
            <Show resource="patients" component="span" actions={null}>
              <SimpleShowLayout>
                <EmailField
                  source="email"
                  label={translate('appUsers.currentEmail')}
                />
              </SimpleShowLayout>
            </Show>
          </Alert>
          <FormControl>
            <TextInput
              control={control}
              name={'email'}
              label={translate('appUsers.newMail')}
            />
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {translate('common.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
