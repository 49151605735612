import ReactMarkdown from 'react-markdown';

import PregnancyQuestion from '@boTypes/PregnancyQuestion';
import { Dialog, DialogContent, Divider, Stack } from '@mui/material';

import { DialogTitle } from '../generic/Dialog';

export const PregnancyQuestionDialog = ({
  item,
  translatedItem,
  open,
  close,
}: {
  item: PregnancyQuestion;
  translatedItem?: PregnancyQuestion;
  open: boolean;
  close: () => void;
}) => (
  <Dialog
    open={open}
    onClose={close}
    aria-labelledby={item.title}
    aria-describedby={item.title}
    maxWidth={translatedItem ? 'xl' : 'md'}
  >
    <DialogTitle onClose={close}>
      {translatedItem ? (
        <Stack direction="row" gap={6} sx={{ width: '100%' }}>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            {item.title}
          </Stack>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            {translatedItem.title}
          </Stack>
        </Stack>
      ) : (
        item.title
      )}
    </DialogTitle>
    <DialogContent>
      {translatedItem ? (
        <Stack
          direction="row"
          justifyContent={'space-between'}
          gap={1}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{ width: '100%' }}
        >
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            <ReactMarkdown>{item.content}</ReactMarkdown>
          </Stack>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            <ReactMarkdown>{translatedItem.content}</ReactMarkdown>
          </Stack>
        </Stack>
      ) : (
        <ReactMarkdown>{item.content}</ReactMarkdown>
      )}
    </DialogContent>
  </Dialog>
);
