import {
  DateInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';

import { FormProps } from '../../types';
import { countries, currencies, titleChoices } from '../common';

export const PricingProGenericForm = (props: FormProps) => {
  const translate = useTranslate();

  return (
    <SimpleForm {...props}>
      <TextInput
        source="name"
        validate={required()}
        variant="standard"
        label={translate('common.name')}
      />
      <SelectInput
        source="jobTitle"
        variant="standard"
        choices={titleChoices()}
        label={translate('pricingProGeneric.job')}
      />
      <TextInput
        source="pricePerUnitBase"
        variant="standard"
        validate={required()}
        label={translate('pricingProGeneric.base')}
      />
      <TextInput
        source="pricePerUnitNight"
        variant="standard"
        validate={required()}
        label={translate('pricingProGeneric.night')}
      />
      <TextInput
        source="pricePerUnitSundayAndHoliday"
        variant="standard"
        validate={required()}
        label={translate('pricingProGeneric.sundayHoliday')}
      />
      <TextInput
        label={translate('pricingProGeneric.rateOnCall')}
        source="onCallNonActivatedRate"
        variant="standard"
        validate={required()}
      />
      <SelectInput
        source="country"
        choices={countries()}
        validate={required()}
        variant="standard"
        label={translate('common.country')}
      />
      <SelectInput
        source="currency"
        choices={currencies()}
        validate={required()}
        variant="standard"
        label={translate('common.currency')}
      />
      <DateInput
        source="startAt"
        validate={required()}
        variant="standard"
        label={translate('common.startAt')}
      />
    </SimpleForm>
  );
};
