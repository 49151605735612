import { shuffle } from 'lodash';
import { Loading } from 'ra-ui-materialui';

import { useQuery } from '@hooks/queryWrappers';
import {
  Box,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Container,
} from '@mui/material';

import CountUp from './countUp';
import Severance from '../../../../assets/severance.webp';
import { useSelector } from '../../../../store';

const motos = [
  'Every number has a purpose. So do you.',
  'You are where you are meant to be.',
  'The data reveals who you are.',
  'Together, we sort the chaos.',
  'Compliance is care.',
  'We serve the cure.',
  'What’s done below need not trouble what’s above.',
  'Your work is not the work. It is the refining of the self.',
  'The you inside is not the you outside.',
];

export const LeaderBoard = () => {
  const { data } = useQuery(['leaderboard'], () => ({
    method: 'GET',
    url: '/api/admin/content-translation/tasks/leaderboard',
  }));
  const userId = useSelector((state) => state.user.userId);

  const isLeader = data?.[0]?.id && data?.[0]?.id === userId;

  return (
    <Box
      sx={{
        backgroundColor: '#c9dbc9',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
        fontFamily: "'Roboto Mono', monospace",
      }}
    >
      <Container maxWidth="sm">
        <Card
          sx={{
            borderRadius: 4,
            boxShadow: 4,
            backgroundColor: '#f5f5f5',
            border: '1px solid #aaa',
          }}
        >
          <CardContent>
            <Box display="flex" justifyContent="center" mb={3}>
              <img
                src={Severance}
                alt="Severance"
                width={300}
                height={300}
                style={{ borderRadius: '25%' }}
              />
            </Box>
            <p
              style={{
                fontSize: '1.5rem',
                textAlign: 'center',
                width: '100%',
                fontWeight: 'bold',
                letterSpacing: 1,
                marginBottom: 0,
              }}
              className="shiny-text"
            >
              LUMAY Leaderboard
            </p>
            <Typography variant="body2" align="center" mb={2}>
              {shuffle(motos)[0]}
            </Typography>
            {isLeader && <Typography>You're simply the best. 🏆 💪</Typography>}
            <List>
              {data ? (
                (data as { name: string; distinctTaskCount: number }[]).map(
                  (entry, index) => (
                    <ListItem
                      key={index}
                      divider
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <ListItemText primary={entry.name} />
                      <Typography variant="body1" fontWeight="bold">
                        <CountUp from={0} to={entry.distinctTaskCount ?? 0} />
                      </Typography>
                    </ListItem>
                  ),
                )
              ) : (
                <Loading />
              )}
            </List>
            <Typography
              variant="body2"
              align="center"
              sx={{ marginTop: 2, color: '#555' }}
            >
              This leaderboard page is sponsored by the Lumay industry. Si vous
              ne comprenez rien, regardez la série Severance.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};
