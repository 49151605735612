import throttle from 'lodash/throttle';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Identifier } from 'react-admin';

import { countryCode } from '@boTypes/common';
import { CancelTypingEventDTO, IsTypingEventDTO } from '@boTypes/discussion';
import { SocketEvents } from '@boTypes/socket';
import {
  getDiscussionRoom,
  getDiscussionFromRoom,
} from '@hooks/useUsersInRoom';

import { useSelector } from '../../../store';
import { useGateway } from '../../../utils/gateway';

// if no one added a letter for 7s consider he is not typing
const notTypingAnyMoreAfter = 7 * 1000;

export const useSendTypingEvent = (
  discussionId: Identifier,
  hasText: boolean,
  country: countryCode,
) => {
  const socket = useGateway();
  const currentUserId = useSelector((state) => state.user.userId);

  // Throttle socket event to limit network solicitations
  const sendIsTyping = useMemo(
    () =>
      socket && discussionId && currentUserId
        ? throttle(
            () => {
              socket.emit(SocketEvents.USER_IS_TYPING, {
                room: getDiscussionRoom(discussionId.toString(), country),
                userId: currentUserId.toString(),
              });
            },
            500,
            { leading: true, trailing: false },
          )
        : () => {},
    [discussionId, socket, currentUserId, country],
  );
  const sendCanceledTyping = useMemo(
    () =>
      socket && discussionId && currentUserId
        ? throttle(
            () =>
              socket.emit(SocketEvents.USER_CANCELED_TYPING, {
                room: getDiscussionRoom(discussionId.toString(), country),
                userId: currentUserId.toString(),
              }),
            500,
            { leading: true, trailing: false },
          )
        : () => {},
    [discussionId, socket, currentUserId, country],
  );

  // if unmounted send not typing
  useEffect(() => {
    return () => sendCanceledTyping();
  }, [sendCanceledTyping]);

  useEffect(() => {
    hasText ? sendIsTyping() : sendCanceledTyping();
  }, [hasText, sendCanceledTyping, sendIsTyping]);
};

export const useTypingEvent = (
  discussionId: Identifier,
): [string[], number] => {
  const socket = useGateway();
  const isTypingMap = useRef<Record<string, number>>({});
  const [isTypingList, setIsTypingList] = useState<string[]>([]);
  const [isPatientTyping, setIsPatientTyping] = useState<number>(0);
  const currentUserId = useSelector((state) => state.user.userId);

  // subscribe to typing events
  useEffect(() => {
    if (!socket || !discussionId) {
      return;
    }
    const handleIsTyping = ({ room, userId, isPatient }: IsTypingEventDTO) => {
      if (getDiscussionFromRoom(room) === discussionId.toString()) {
        if (isPatient) {
          setIsPatientTyping(Number(userId));
        } else if (currentUserId.toString() !== userId) {
          const newList = !isTypingMap.current[userId];
          isTypingMap.current[userId] = Date.now();
          if (newList) {
            setIsTypingList(Object.keys(isTypingMap.current));
          }
        }
      }
    };
    const handleCancelTyping = ({
      room,
      userId,
      isPatient,
    }: CancelTypingEventDTO) => {
      if (getDiscussionFromRoom(room) === discussionId.toString()) {
        if (isPatient) {
          setIsPatientTyping(0);
        } else if (currentUserId.toString() !== userId) {
          const newList = !!isTypingMap.current[userId];
          delete isTypingMap.current[userId];
          if (newList) {
            setIsTypingList(Object.keys(isTypingMap.current));
          }
        }
      }
    };
    socket.on(SocketEvents.USER_IS_TYPING, handleIsTyping);
    socket.on(SocketEvents.USER_CANCELED_TYPING, handleCancelTyping);

    return () => {
      setIsTypingList([]);
      socket.off(SocketEvents.USER_IS_TYPING, handleIsTyping);
      socket.off(SocketEvents.USER_CANCELED_TYPING, handleCancelTyping);
    };
  }, [discussionId, socket, currentUserId]);

  // Forget users that are not typing over 15s.
  useEffect(() => {
    if (!socket) {
      return;
    }
    const forgetHandler = setInterval(() => {
      const limit = Date.now() - notTypingAnyMoreAfter;
      let hasDeleted = false;
      Object.keys(isTypingMap.current).forEach((key: string) => {
        if (isTypingMap.current[key] < limit) {
          delete isTypingMap.current[key];
          hasDeleted = true;
        }
      });
      if (hasDeleted) {
        setIsTypingList(Object.keys(isTypingMap.current));
      }
    }, 500);
    return () => clearInterval(forgetHandler);
  }, [socket]);

  useEffect(() => {
    if (!socket) {
      return;
    }
  }, [socket]);

  return [isTypingList, isPatientTyping];
};
