import { type UUID } from 'crypto';
import { useState } from 'react';
import { useTranslate } from 'react-admin';

import { Family } from '@boTypes/index';
import { useQuery } from '@hooks/queryWrappers';
import { useDiscussionContext } from '@hooks/useDiscussionContext';
import { Bedtime } from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Chip, IconButton, SxProps, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Form, FormAnswers } from '@teammay/form-core';
import { openNewSecureTab } from '@utils/urls';

import { FollowupList } from './followupList';
import { FormList } from './formList';
import {
  logPatientFileClick,
  logViewTherapyFromDiscussion,
} from '../../analytics/events';
import { DiscussionContext } from '../../common';
import { colorTokens } from '../../themes';

const useTherapyItems = (familyId: Family['id']) => {
  return useQuery<TherapyItem[]>(
    ['therapyItemsByFamily', familyId],
    {
      url: `/api/therapy-item/by-family/${familyId}`,
      method: 'get',
    },
    {
      enabled: Boolean(familyId),
    },
  );
};

export class TherapyItem {
  id: UUID;
  title: string; // it's a hack from the back, therapyItems do not hold information about the therapy
  appUserId: number;
  childId: number;
  includeConsultation: boolean;
  consultationId: number;
  therapySlug: string;
}

export const TherapyChips = ({
  therapyItems,
}: {
  therapyItems: TherapyItem[];
}) => {
  const translate = useTranslate();
  if (!therapyItems?.length) {
    return null;
  }

  const onClick = (therapyItem: TherapyItem) => () => {
    if (therapyItem?.includeConsultation && therapyItem?.consultationId) {
      logViewTherapyFromDiscussion(therapyItem.therapySlug);
      openNewSecureTab(`/#/consultations/${therapyItem?.consultationId}`);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '0.5rem',
        padding: '0.5rem',
      }}
    >
      {therapyItems.map((item) => (
        <Chip
          label={`${item.title}${item.includeConsultation ? ` (${translate('therapy.withConsultation')})` : ''}${item.includeConsultation && item.consultationId ? ' 🔗' : ''}`}
          key={item.id}
          size="small"
          onClick={
            item?.includeConsultation && item?.consultationId
              ? onClick(item)
              : undefined
          }
        />
      ))}
    </Box>
  );
};

export const PatientFile = ({
  sx,
  onChartPress,
  onSleepPress,
  onFormSelected,
  familyId,
}: {
  sx?: SxProps;
  onChartPress?: () => void;
  onSleepPress?: () => void;
  onFormSelected?: (arg: {
    form: Pick<Form, 'id'>;
    formAnswers: Pick<FormAnswers, 'id'>;
  }) => void;
  familyId: Family['id'];
}) => {
  const t = useTranslate();
  const [displayed, setDisplayed] = useState<'followUp' | 'forms'>('followUp');
  const discussionContext = useDiscussionContext();
  // follow up list
  const [create, setCreate] = useState<boolean>(false);
  const { data: therapyItems } = useTherapyItems(familyId);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
          boxShadow: `0 3px 5px -4px ${colorTokens.content.subtleBackground}`,
        }}
      >
        <Typography
          variant="body1"
          style={{ fontSize: '1rem', fontWeight: 'bold' }}
        >
          {t('patientFile.title')}
        </Typography>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <IconButton onClick={() => setCreate(true)}>
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              logPatientFileClick();
              setDisplayed('followUp');
            }}
          >
            <FamilyRestroomIcon
              sx={{
                color:
                  displayed === 'followUp' ? 'primary.main' : 'background.grey',
              }}
            />
          </IconButton>
          {discussionContext === DiscussionContext.MIDWIFE && ( // TODO remove this condition when the feature is ready
            <IconButton onClick={() => setDisplayed('forms')}>
              <AccountTreeIcon
                sx={{
                  color:
                    displayed === 'forms' ? 'primary.main' : 'background.grey',
                }}
              />
            </IconButton>
          )}
          {onChartPress && (
            <IconButton onClick={onChartPress}>
              <ShowChartIcon sx={{ color: 'background.grey' }} />
            </IconButton>
          )}
          {onSleepPress && (
            <IconButton onClick={onSleepPress}>
              <Bedtime sx={{ color: 'background.grey' }} />
            </IconButton>
          )}
        </Box>
      </Box>
      {displayed === 'followUp' && (
        <>
          <TherapyChips therapyItems={therapyItems} />
          <FollowupList create={create} setCreate={setCreate} />
        </>
      )}
      {displayed === 'forms' && <FormList onFormSelected={onFormSelected} />}
    </Box>
  );
};
