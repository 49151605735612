import { useMemo, useState } from 'react';

import { Child } from '@boTypes/child';
import { useQuery } from '@hooks/queryWrappers';
import { dayjsTz } from '@utils/date';

import { ChildFeedingDTO, ChildSleepDTO, FEED_TYPE, SLEEP_TYPE } from './dto';
import {
  computeBottleQuantity,
  computeBreastfeedDuration,
  computeSleepDuration,
  transformBottle,
  transformBreastfeed,
  transformSleep,
} from './utils';
import {
  CalendarAggregatedDayData,
  CalendarDayMap,
} from '../WeekCalendar/types';
import { getLastSevenDays } from '../WeekCalendar/utils/getLastSevenDays';

export const useFeedingAndSleepData = (
  child: Child,
  filters: { start: Date; end: Date },
) => {
  const { data, isLoading } = useQuery<
    {
      sleep: ChildSleepDTO[];
      feeding: ChildFeedingDTO[];
    },
    any,
    {
      sleep: ChildSleepDTO[];
      feeding: ChildFeedingDTO[];
    }
  >(
    ['child-feeding-sleep', child?.id, filters],
    () => ({
      method: 'GET',
      url: `/api/child-feeding-sleep/${child?.id}`,
      params: {
        filter: JSON.stringify(filters),
      },
    }),
    {
      enabled: !!child?.id,
    },
  );
  return { data, isLoading };
};

export const getCalendarData = (
  measureList: {
    sleep: ChildSleepDTO[];
    feeding: ChildFeedingDTO[];
  },
  period: { start: Date; end: Date },
): {
  calendarData: CalendarDayMap;
  aggregatedData: CalendarAggregatedDayData;
} => {
  const concatList = (measureList.sleep as (ChildFeedingDTO | ChildSleepDTO)[])
    .concat(measureList.feeding)
    .filter((measure) => {
      if (
        measure.type === FEED_TYPE.BREAST ||
        measure.type === FEED_TYPE.BOTTLE
      ) {
        return (
          new Date(measure.date) >= period.start &&
          new Date(measure.date) <= period.end
        );
      } else if (
        measure.type === SLEEP_TYPE.NAP ||
        measure.type === SLEEP_TYPE.NIGHT
      ) {
        return (
          new Date(measure.start) >= period.start &&
          new Date(measure.end) <= period.end
        );
      }
      return false;
    });

  const aggregatedData = concatList.reduce((acc, measure) => {
    if (measure.type === FEED_TYPE.BREAST) {
      const day = dayjsTz(measure.date).format('YYYY-MM-DD');
      acc[day] ??= { sleep: 0, bottle: 0, breast: 0 };
      acc[day].breast += computeBreastfeedDuration(measure);
    } else if (measure.type === FEED_TYPE.BOTTLE) {
      const day = dayjsTz(measure.date).format('YYYY-MM-DD');
      acc[day] ??= { sleep: 0, bottle: 0, breast: 0 };
      acc[day].bottle += computeBottleQuantity(measure);
    } else if (
      measure.type === SLEEP_TYPE.NAP ||
      measure.type === SLEEP_TYPE.NIGHT
    ) {
      const sleepDays = computeSleepDuration(measure);
      Object.keys(sleepDays).forEach((day) => {
        acc[day] ??= { sleep: 0, bottle: 0, breast: 0 };
        acc[day].sleep += sleepDays[day];
      });
    }
    return acc;
  }, {} as CalendarAggregatedDayData);

  const calendarData = concatList.reduce((acc, measure) => {
    if (measure.type === FEED_TYPE.BREAST) {
      return transformBreastfeed(acc, measure);
    } else if (measure.type === FEED_TYPE.BOTTLE) {
      return transformBottle(acc, measure);
    } else if (
      measure.type === SLEEP_TYPE.NAP ||
      measure.type === SLEEP_TYPE.NIGHT
    ) {
      return transformSleep(acc, measure);
    }
    return acc;
  }, {} as CalendarDayMap);

  return { calendarData, aggregatedData };
};

export const useFeedingAndSleep = (child: Child) => {
  const [selectedWeek, setSelectedWeek] = useState(
    getLastSevenDays(new Date()),
  );

  const period = useMemo(
    () => ({
      start: dayjsTz(selectedWeek[0]).startOf('day').toDate(),
      end: dayjsTz(selectedWeek[6]).endOf('day').toDate(),
    }),
    [selectedWeek],
  );

  const childFeedingAndSleep = useFeedingAndSleepData(child, period);
  const { calendarData, aggregatedData } = useMemo(() => {
    if (!child || !childFeedingAndSleep?.data) {
      return { calendarData: {}, aggregatedData: {} };
    }
    return getCalendarData(childFeedingAndSleep.data, period);
  }, [childFeedingAndSleep?.data, child, period]);

  return { selectedWeek, setSelectedWeek, calendarData, aggregatedData };
};
