import { useMemo } from 'react';

import { useMultiSearch } from '@hooks/cms';
import { useSearchKey } from '@utils/search';

import { TranslationTask } from './types';
import { contentTypeToIndexUid, IndexUid } from './utils';

export const useContentFromTasks = (tasks: TranslationTask[]) => {
  const idsMapping = useMemo(() => {
    return tasks?.reduce(
      (acc, task) => {
        if (task.translationDocument) {
          const type = contentTypeToIndexUid(task.translationDocument.type);
          acc[type].push(task.translationDocument.documentId);
          if (task.upload) {
            acc[type].push(task.upload.createdDocumentId);
          }
        }
        return acc;
      },
      {
        [IndexUid.APPOINTMENT_SUGGESTION]: [],
        [IndexUid.DAILY_TIPS]: [],
        [IndexUid.FERTILITY_TIPS]: [],
        [IndexUid.GUIDE]: [],
        [IndexUid.MACRO]: [],
        [IndexUid.MASTERCLASS]: [],
        [IndexUid.POST]: [],
        [IndexUid.PREGNANCY_QUESTIONS]: [],
        [IndexUid.PREGNANCY_WEEK_CONTENT]: [],
        [IndexUid.PROGRAMS]: [],
        [IndexUid.TODO_LIST_SUGGESTION]: [],
        [IndexUid.WEEKLY_TIPS]: [],
      } as Record<string, number[]>,
    );
  }, [tasks]);

  const searchKey = useSearchKey();

  const { data: multiSearch } = useMultiSearch({
    search: '',
    searchKey,
    enabled: !!searchKey && !!idsMapping,
    pregnancy: null,
    idsMapping,
    limit: 10000,
  });

  const multiSearchIdMapping = useMemo(() => {
    if (!multiSearch) {
      return {};
    }
    return Object.entries(multiSearch).reduce(
      (acc, [key, value]) => {
        acc[key] = value.reduce((acc2, item) => {
          acc2[item.id] = item;
          return acc2;
        }, {});
        return acc;
      },
      {} as Record<number, any>,
    );
  }, [multiSearch]);

  return { multiSearchIdMapping };
};
