import { useTranslate } from 'react-admin';
import { Control, useForm, useWatch } from 'react-hook-form';

import { countryCode } from '@boTypes/common';
import { PlanningJob } from '@boTypes/planning';
import { CheckboxInput } from '@components/forms/checkBoxInput';
import { SelectInput } from '@components/forms/selectInput';
import { AutocompleteStaffSelectInput } from '@components/forms/staffSelectInput';
import {
  activeUsersFilter,
  useAllStaffUsersSorted,
} from '@hooks/useAllStaffUsers';
import { useByIds } from '@hooks/useByIds';
import { Box, MenuItem } from '@mui/material';

import { jobColors } from './components/healthProColors';
import { useSelector } from '../../store';
import { JobTitle, User, titleToPlanningJob } from '../../types/user';
enum DisplayType {
  JOB = 'job',
  USER = 'user',
}

type Form = {
  displayType: DisplayType;
  doc: boolean;
  nurse: boolean;
  midwife: boolean;
  selectedUser: User['id'];
  country: countryCode | 'all' | undefined;
};

const isJobSelected = (planningJob: PlanningJob, userJob: JobTitle) =>
  titleToPlanningJob[userJob] === planningJob ||
  titleToPlanningJob[userJob] === 'all';

export const useToolbar = () => {
  const { userId, jobTitle: userJob } = useSelector((state) => state.user);

  const { data: _staffUsers, isLoading: isLoadingStaff } =
    useAllStaffUsersSorted(activeUsersFilter);
  const staffUsers = useByIds(_staffUsers, Number);
  const { control } = useForm<Form>({
    defaultValues: {
      displayType: DisplayType.JOB,
      doc: isJobSelected(PlanningJob.DOCTOR, userJob),
      nurse: isJobSelected(PlanningJob.NURSE, userJob),
      midwife: isJobSelected(PlanningJob.MIDWIFE, userJob),
      selectedUser: userId,
    },
  });

  const selectedJobs = useWatch({
    control,
    name: ['nurse', 'midwife', 'doc'],
  });
  const [displayType, selectedUser] = useWatch({
    control,
    name: ['displayType', 'selectedUser'],
  });

  return {
    userId,
    userJob,
    staffUsers,
    staffUsersRawList: _staffUsers,
    isLoadingStaff,
    control,
    selectedJobs,
    displayType,
    selectedUser,
  };
};

export const Toolbar = ({
  displayType,
  control,
  staffUsers,
  isLoadingStaff,
}: {
  displayType: 'job' | 'user';
  control: Control<Form, any>;
  staffUsers: User[];
  isLoadingStaff: boolean;
}) => {
  const translate = useTranslate();

  const byJob = displayType === DisplayType.JOB;
  return (
    <>
      <SelectInput
        name="displayType"
        control={control}
        label={''}
        size="small"
        variant="outlined"
        sx={{ mr: 3 }}
      >
        <MenuItem key={'job'} value={DisplayType.JOB}>
          {translate('planning.topToolbar.byJob')}
        </MenuItem>
        <MenuItem key={'user'} value={DisplayType.USER}>
          {translate('planning.topToolbar.byUser')}
        </MenuItem>
      </SelectInput>
      <Box sx={{ alignItems: 'center', display: byJob ? 'flex' : 'none' }}>
        <CheckboxInput
          name="nurse"
          control={control}
          label={translate('common.nurseShort')}
          sx={{
            color: jobColors[PlanningJob.NURSE],
            '&.Mui-checked': {
              color: jobColors[PlanningJob.NURSE],
            },
          }}
          size="small"
        />
        <CheckboxInput
          name="midwife"
          control={control}
          label={translate('common.midwifeShort')}
          sx={{
            color: jobColors[PlanningJob.MIDWIFE],
            '&.Mui-checked': {
              color: jobColors[PlanningJob.MIDWIFE],
            },
          }}
          size="small"
        />
        <CheckboxInput
          name="doc"
          control={control}
          label={translate('common.doctorShort')}
          sx={{
            color: jobColors[PlanningJob.DOCTOR],
            '&.Mui-checked': {
              color: jobColors[PlanningJob.DOCTOR],
            },
          }}
          size="small"
        />
      </Box>
      <Box sx={{ alignItems: 'center', display: byJob ? 'none' : 'flex' }}>
        <AutocompleteStaffSelectInput
          name="selectedUser"
          control={control}
          label=""
          isLoadingStaff={isLoadingStaff}
          staffUsers={staffUsers}
          size="small"
          sx={{ mr: 3 }}
        />
      </Box>
    </>
  );
};
