import { Roles } from '@boTypes/user';
import { Box } from '@mui/material';

import { PricingProCustomList } from '../entities/pricingProCustom/list';
import { PricingProGenericList } from '../entities/pricingProGeneric/list';
import { useSelector } from '../store';

export const PricingProList = () => {
  const roles = useSelector((state) => state.user.roles);
  const isAdmin = roles.includes(Roles.ADMIN);

  if (!isAdmin) {
    return null;
  }

  return (
    <Box>
      <PricingProGenericList />
      <PricingProCustomList />
    </Box>
  );
};
