import { useTranslate } from 'react-admin';

import { CameraRoll } from '@mui/icons-material';
import {
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

export const ScenarioCard = () => {
  const translate = useTranslate();

  return (
    <Card sx={{ padding: 2 }}>
      <Typography variant="subtitle1" color="primary" sx={{ paddingBottom: 1 }}>
        {translate('admin.others.title')}
      </Typography>
      <List dense>
        <ListItem disablePadding>
          <ListItemButton disableGutters component="a" href="/#/scenarios">
            <ListItemIcon sx={{ minWidth: '2rem' }}>
              <CameraRoll fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translate('admin.others.scenarios')} />
          </ListItemButton>
        </ListItem>
      </List>
    </Card>
  );
};
