import { useTranslate } from 'react-admin';
import { Controller, Path } from 'react-hook-form';

import { DialogButton } from '@components/DialogButton/DialogButton';
import { Button } from '@components/generic/Button';
import { Add } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  IconButtonProps,
  TextField,
  Typography,
} from '@mui/material';

import { ExposedControllerProps } from './type';
import { errorMessage } from './utils';
import { colorTokens } from '../../themes';

type OptionInputProps<
  TFieldValues extends Record<string, any> & {
    [k: string]: { key: string; value: string }[];
  },
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> & {
  label: string;
  onBlur?: () => void;
  fullWidth?: boolean;
  withDescription?: boolean;
  labelOnly?: boolean;
};

const DeleteButton = (props: IconButtonProps) => (
  <IconButton {...props}>
    <Delete />
  </IconButton>
);

export function OptionInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  name,
  control,
  label,
  rules,
  fullWidth,
  withDescription = false,
  labelOnly = false,
  ...other
}: OptionInputProps<TFieldValues, TName>) {
  const translate = useTranslate();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState, formState }) => {
        const hideLabel = typeof label === 'string' && !label;
        return (
          <FormControl fullWidth={fullWidth}>
            {!hideLabel && !labelOnly && (
              <Typography>{label ?? name}</Typography>
            )}
            {field.value?.map(
              (
                {
                  key,
                  value,
                  description,
                }: { key: string; value: string; description?: string },
                index: number,
              ) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'stretch',
                    alignItems: 'center',
                    gap: 4,
                  }}
                  key={key}
                >
                  {!labelOnly && (
                    <DialogButton
                      title={translate('forms.dialogs.delete.title')}
                      text={translate('forms.dialogs.delete.content')}
                      onClick={() => {
                        field.value.splice(index, 1);
                        field.onChange([...field.value]);
                      }}
                      // @ts-expect-error icon button props does not match perfectly button props
                      component={DeleteButton}
                    />
                  )}
                  <TextField
                    sx={{ display: 'none' }}
                    error={Boolean(fieldState.error)}
                    disabled={formState.isSubmitting}
                    {...field}
                    value={key}
                    onBlur={other.onBlur}
                    onChange={(e) => {
                      field.value[index].key = e.target.value;
                      field.onChange([...field.value]);
                    }}
                    variant="standard"
                  />
                  <TextField
                    sx={{ flex: 1 }}
                    error={Boolean(fieldState.error)}
                    disabled={formState.isSubmitting}
                    helperText={
                      !value ? (
                        <Box
                          component={'span'}
                          sx={{
                            color: colorTokens.content.danger,
                            fontSize: '0.75rem',
                          }}
                        >
                          {translate('field.optionInput.requiredValue')}
                        </Box>
                      ) : null
                    }
                    {...field}
                    value={value ?? ''}
                    onBlur={other.onBlur}
                    onChange={(e) => {
                      field.value[index].value = e.target.value;
                      field.onChange([...field.value]);
                    }}
                    label={
                      translate('field.optionInput.value') + ' ' + (index + 1)
                    }
                    variant="standard"
                    autoFocus={index === field.value.length - 1}
                  />
                  {withDescription && (
                    <TextField
                      sx={{ flex: 3 }}
                      error={Boolean(fieldState.error)}
                      disabled={formState.isSubmitting}
                      {...field}
                      value={description ?? ''}
                      onBlur={other.onBlur}
                      onChange={(e) => {
                        if (e.target.value) {
                          field.value[index].description = e.target.value;
                        } else if (description) {
                          delete field.value[index].description;
                        }
                        field.onChange([...field.value]);
                      }}
                      label={
                        translate('field.optionInput.description') +
                        ' ' +
                        (index + 1)
                      }
                      variant="standard"
                      autoFocus={index === field.value.length - 1}
                    />
                  )}
                </Box>
              ),
            )}
            {!labelOnly && (
              <Button
                startIcon={<Add />}
                onClick={() =>
                  field.onChange([
                    ...field.value,
                    { key: self.crypto.randomUUID(), value: '' },
                  ])
                }
              >
                {translate('field.optionInput.add')}
              </Button>
            )}
            <FormHelperText>
              {errorMessage(fieldState.error, translate)}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}
