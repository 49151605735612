import { useTranslate } from 'react-admin';
import { Control, Path, useWatch } from 'react-hook-form';

import { SelectInput } from '@components/forms/selectInput';
import { TextInput } from '@components/forms/textInput';
import { MenuItem } from '@mui/material';
import { Question, QuestionType } from '@teammay/form-core';

import { useUsedSlugs } from '../../hooks/useUsedSlugs';

const implementedQuestionTypes = [
  QuestionType.RADIO,
  QuestionType.TEXT,
  QuestionType.SELECT,
  QuestionType.SCALE,
  QuestionType.IMAGE,
];
export type CommonQuestion = Pick<
  Question,
  'id' | 'title' | 'description' | 'type' | 'slug'
>;

export function CommonQuestionEdit<T extends CommonQuestion>({
  control,
  onBlur,
  labelOnly = false,
}: {
  control: Control<T, any>;
  onBlur: () => void;
  labelOnly?: boolean;
}) {
  const t = useTranslate();
  const id = useWatch({ control, name: 'id' as Path<T> });
  const usedSlugs = useUsedSlugs(id);
  return (
    <>
      <TextInput
        control={control}
        name={'title' as Path<T>}
        label={t('forms.questions.title')}
        onBlur={onBlur}
      />
      <TextInput
        control={control}
        name={'slug' as Path<T>}
        label={t('forms.questions.slug')}
        onBlur={onBlur}
        rules={{
          validate: (value) => {
            if (value && usedSlugs.includes(value.trim())) {
              return t('forms.errors.unique_slug');
            }
          },
        }}
      />
      <TextInput
        control={control}
        name={'description' as Path<T>}
        label={t('forms.questions.description')}
        onBlur={onBlur}
      />
      {!labelOnly && (
        <SelectInput
          variant="outlined"
          control={control}
          name={'type' as Path<T>}
          rules={{ required: true }}
        >
          {implementedQuestionTypes.map((qType) => (
            <MenuItem key={qType} value={qType}>
              {qType}
            </MenuItem>
          ))}
        </SelectInput>
      )}
    </>
  );
}
