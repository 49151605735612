import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import frenchMessages from 'ra-language-french';
import { useEffect } from 'react';
import {
  Admin,
  Authenticated,
  CustomRoutes,
  resolveBrowserLocale,
  Resource,
} from 'react-admin';
import { Provider } from 'react-redux';
import { Navigate, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

// @ts-ignore
import spanishMessages from '@blackbox-vision/ra-language-spanish';
import { CurrentCountryProvider } from '@hooks/useCurrentCountry';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import './App.css';

import { useTracking } from './analytics';
import { Login } from './auth';
import { Logout } from './auth/Logout';
import { ResetScreen } from './auth/Reset';
import { ResetPassword } from './auth/reset/ResetPassword';
import { FormEdit } from './entities/form/edit';
import { FormList } from './entities/form/list';
import { Library } from './entities/library';
import { PlanningAvailabilitiesEdit } from './entities/planningAvailabilities/edit';
import { PlanningAvailabilitiesList } from './entities/planningAvailabilities/list';
import { Invoices } from './entities/plannings/invoices';
import { PlanningList } from './entities/plannings/list';
import { PlanningResults } from './entities/plannings/results';
import { SlotCreate } from './entities/plannings/slotCreate';
import { StaffUserPlanning } from './entities/plannings/staffUserPlanning';
import { domainMessages } from './i18n';
import MyLayout from './Layout';
import { RedirectAfterLogin, EditProfile, Favorites, Help } from './pages';
import { Admin as AdminArea } from './pages/admin/admin';
import { ContentTranslationAddContent } from './pages/admin/sections/contentTranslation/addContent';
import { ContentTranslationDashboard } from './pages/admin/sections/contentTranslation/dashboard';
import { ContentTranslationExternalSource } from './pages/admin/sections/contentTranslation/externalSource';
import { LeaderBoard } from './pages/admin/sections/contentTranslation/leaderBoard';
import { AdminInvoices } from './pages/adminInvoices';
import { Changelog } from './pages/changelog';
import { CodeAffiliation } from './pages/codeAffiliation';
import { ConsultationPlanning } from './pages/consultationPlanning';
import { ContentTranslation } from './pages/contentTranslation';
import { Dashboard } from './pages/dashboard';
import { MidwifeSubjects } from './pages/midwifeSubjects';
import { NurseSubjects } from './pages/nurseSubjects';
import { PricingProList } from './pages/pricingProList';
import { EditPassword } from './pages/profile/editPassword';
import { SubjectExplorerScreen } from './pages/subjectExplorer/subjectExplorer';
import { authProvider, dataProvider } from './provider';
import { Resources } from './resources';
import { store, persistor } from './store';
import { mayTheme } from './themes';
import { GatewayProvider } from './utils/gateway';
import { setApiClientLogout } from './utils/httpClients';

const messages = {
  fr: { ...frenchMessages, ...domainMessages.fr },
  en: { ...englishMessages, ...domainMessages.en },
  es: { ...spanishMessages, ...domainMessages.es },
};
export const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale] || messages.fr,
  resolveBrowserLocale('fr'),
  [
    { locale: 'en', name: 'English' },
    { locale: 'fr', name: 'Français' },
    { locale: 'es', name: 'Español' },
  ],
);

const queryClient = new QueryClient({
  queryCache: new QueryCache(),
});

const App = () => {
  useTracking();
  useEffect(() => setApiClientLogout(authProvider.logout), []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GatewayProvider>
            <CurrentCountryProvider>
              <Admin
                disableTelemetry
                theme={mayTheme}
                title="May Pro"
                dataProvider={dataProvider}
                authProvider={authProvider}
                loginPage={Login}
                i18nProvider={i18nProvider}
                layout={MyLayout}
                queryClient={queryClient}
                dashboard={RedirectAfterLogin}
                darkTheme={null}
              >
                <CustomRoutes>
                  <Route
                    key="midwife-subjects"
                    path="/midwifesubjects"
                    element={
                      <Authenticated>
                        <MidwifeSubjects />
                      </Authenticated>
                    }
                  >
                    <Route
                      path=":discussionId"
                      element={
                        <Authenticated>
                          <MidwifeSubjects />
                        </Authenticated>
                      }
                    />
                  </Route>
                  <Route
                    key="content-translation"
                    path="/content-translation"
                    element={
                      <Authenticated>
                        <ContentTranslation />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="nurse-subjects"
                    path="/nursesubjects"
                    element={
                      <Authenticated>
                        <NurseSubjects />
                      </Authenticated>
                    }
                  >
                    <Route
                      path=":discussionId"
                      element={
                        <Authenticated>
                          <NurseSubjects />
                        </Authenticated>
                      }
                    />
                  </Route>
                  <Route
                    key="planning"
                    path="/planning"
                    element={
                      <Authenticated>
                        <PlanningList />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="invoices-admin"
                    path="/admin/invoices"
                    element={
                      <Authenticated>
                        <AdminInvoices />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="staffUserPlanning"
                    path="/staffUserPlanning"
                    element={
                      <Authenticated>
                        <StaffUserPlanning />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="staffUserPlanning"
                    path="/planning/:planningId/invoices"
                    element={
                      <Authenticated>
                        <Invoices />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="planningAvailabilities"
                    path="/planning-availabilities"
                    element={
                      <Authenticated>
                        <PlanningAvailabilitiesList />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="planningAvailabilities"
                    path="/planning-availabilities/:planningId"
                    element={
                      <Authenticated>
                        <PlanningAvailabilitiesEdit />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="planningSlots"
                    path="/planning/slots/:planningId"
                    element={
                      <Authenticated>
                        <SlotCreate />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="planningSlots"
                    path="/planning/agenda/:planningId"
                    element={
                      <Authenticated>
                        <PlanningResults />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="profile"
                    path="/profile"
                    element={
                      <Authenticated>
                        <EditProfile />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="favorites"
                    path="/favorites"
                    element={
                      <Authenticated>
                        <Favorites />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="changelog"
                    path="/changelogs/:id"
                    element={
                      <Authenticated>
                        <Changelog />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="help"
                    path="/help"
                    element={
                      <Authenticated>
                        <Help />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="subjects-explorer"
                    path="/subjects-explorer"
                    element={
                      <Authenticated>
                        <SubjectExplorerScreen />
                      </Authenticated>
                    }
                  >
                    <Route
                      path=":subjectId/discussion/:discussionId"
                      element={
                        <Authenticated>
                          <SubjectExplorerScreen />
                        </Authenticated>
                      }
                    />
                  </Route>
                  <Route
                    key="dashboard"
                    path="/my-dashboard"
                    element={
                      <Authenticated>
                        <Dashboard />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="edit-password"
                    path="/profile-password"
                    element={
                      <Authenticated>
                        <EditPassword />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="pricing-pro"
                    path="/pricing-pro"
                    element={
                      <Authenticated>
                        <PricingProList />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="code-affiliations"
                    path="/code-affiliations"
                    element={
                      <Authenticated>
                        <CodeAffiliation />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="calendars"
                    path="/calendars"
                    element={
                      <Authenticated>
                        <StaffUserPlanning />
                      </Authenticated>
                    }
                  />
                  <Route
                    path="/discussions"
                    element={
                      <Authenticated>
                        <Navigate to="/nursesubjects" />
                      </Authenticated>
                    }
                  />
                  <Route
                    path="/discussions-midwife"
                    element={
                      <Authenticated>
                        <Navigate to="/midwifesubjects" />
                      </Authenticated>
                    }
                  />
                  <Route
                    path="/library"
                    element={
                      <Authenticated>
                        <Library />
                      </Authenticated>
                    }
                  />
                  <Route
                    path="/admin"
                    element={
                      <Authenticated>
                        <AdminArea />
                      </Authenticated>
                    }
                  />
                  <Route
                    path="/admin/content-translation/add-content"
                    element={
                      <Authenticated>
                        <ContentTranslationAddContent />
                      </Authenticated>
                    }
                  />
                  <Route
                    path="/admin/content-translation/dashboard"
                    element={
                      <Authenticated>
                        <ContentTranslationDashboard />
                      </Authenticated>
                    }
                  />
                  <Route
                    path="/admin/content-translation/dashboard/leaderboard"
                    element={
                      <Authenticated>
                        <LeaderBoard />
                      </Authenticated>
                    }
                  />
                  <Route
                    path="/admin/content-translation/external-source"
                    element={
                      <Authenticated>
                        <ContentTranslationExternalSource />
                      </Authenticated>
                    }
                  />
                  <Route
                    path="/consultations-planning"
                    element={
                      <Authenticated>
                        <ConsultationPlanning />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="formList"
                    path="/forms"
                    element={
                      <Authenticated>
                        <FormList />
                      </Authenticated>
                    }
                  />
                  <Route
                    key="formEdition"
                    path="/forms/:slug"
                    element={
                      <Authenticated>
                        <FormEdit />
                      </Authenticated>
                    }
                  />
                </CustomRoutes>
                <CustomRoutes noLayout>
                  <Route
                    key="reset-screen"
                    path="/reset-password"
                    element={<ResetScreen />}
                  />
                  <Route
                    key="reset-password"
                    path="/reset-password/:id/:token"
                    element={<ResetPassword />}
                  />
                  <Route key="logout" path="/logout" element={<Logout />} />
                </CustomRoutes>

                {Resources.map(({ name, icon, crud = {}, options }) => (
                  <Resource
                    key={name}
                    name={name}
                    icon={icon}
                    options={options}
                    {...crud}
                  />
                ))}
              </Admin>
            </CurrentCountryProvider>
          </GatewayProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};
export default App;
