import { useTranslate } from 'react-admin';
import { FormProvider } from 'react-hook-form';

import { Box } from '@mui/material';

import { AuthCard } from '../components/authCard';
import { TwoFA } from '../TwoFA';
import { useResetPasswordResetSteps } from './hook';
import { ResetPasswordForm } from './ResetPasswordForm';
import { secondary } from '../../themes';
import Aurora from '../components/aurora';

export const ResetPassword = () => {
  const translate = useTranslate();
  const { form, oneFAOK, handleSubmitOneFA, handleSubmitTwoFA } =
    useResetPasswordResetSteps();

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
      >
        <Aurora
          colorStops={[secondary.lilac, secondary.southSea, secondary.mandarin]}
          speed={0.5}
          amplitude={1}
        />
      </Box>

      {/* Content on top */}
      <Box
        sx={{
          zIndex: 1,
          width: '100%',
          height: '100%',
        }}
      >
        <FormProvider {...form}>
          <AuthCard
            hint={
              oneFAOK
                ? translate('auth.resetPassword.twoFA')
                : translate('auth.resetPassword.resetPassword')
            }
          >
            {oneFAOK ? (
              <TwoFA onSubmit={handleSubmitTwoFA} />
            ) : (
              <ResetPasswordForm onSubmit={handleSubmitOneFA} />
            )}
          </AuthCard>
        </FormProvider>
      </Box>
    </Box>
  );
};
