import { intersection } from 'lodash';
import { useMemo } from 'react';
import { usePermissions, useTranslate } from 'react-admin';
import { useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { countryCode } from '@boTypes/common';
import { Roles } from '@boTypes/user';
import { useCurrentCountry } from '@hooks/useCurrentCountry';
import { Box, Grid, Theme, Typography, useMediaQuery } from '@mui/material';

import { SubjectExplorerDetail } from './subjectExplorer.detail';
import { useSubjects } from './subjectExplorer.hook';
import { SubjectExplorerList } from './subjectExplorerList';
import { SubjectExplorerSearchFilters } from './subjectExplorerSearchFilter';
import superWeasel from '../../assets/super_weasel.webp';

export type SearchExplorerInputs = {
  midwife: boolean;
  keywords: string[];
  categoryId: number[];
  discussionId: number[];
  handoverUserId: number[];
  start: Date;
  end: Date;
  country: countryCode | 'all';
};

export const SubjectExplorerScreen = () => {
  const { permissions } = usePermissions();
  const { subjectId } = useParams();
  const bigScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const translate = useTranslate();
  const defaultCountry = useCurrentCountry();
  const { register, control } = useForm<SearchExplorerInputs>({
    defaultValues: {
      country: defaultCountry,
    },
  });

  const [
    keywords,
    midwife,
    discussionId,
    handoverUserId,
    categoryId,
    start,
    end,
    country,
  ] = useWatch({
    control,
    name: [
      'keywords',
      'midwife',
      'discussionId',
      'handoverUserId',
      'categoryId',
      'start',
      'end',
      'country',
    ],
  });

  const filter = useMemo(
    () => ({
      keywords,
      midwife,
      categoryId,
      discussionId,
      handoverUserId,
      start,
      end,
      country,
    }),
    [
      keywords,
      midwife,
      categoryId,
      discussionId,
      handoverUserId,
      start,
      end,
      country,
    ],
  );

  const {
    data: subjectsData,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useSubjects({
    filter,
  });

  if (
    intersection(permissions, [
      Roles.ADMIN,
      Roles.STAFF_MANAGER,
      Roles.SUPERUSER,
    ]).length === 0
  ) {
    return null;
  }

  return (
    <Box sx={{ height: 'calc(100vh - 49px)' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          sx={(theme) => ({
            flexDirection: 'column',
            height: '100%',
            flex: 2,
            borderRightWidth: '1px',
            borderRightStyle: 'solid',
            borderRightColor: 'primary.main10',
            alignItems: 'stretch',
            display: bigScreen || !subjectId ? 'flex' : 'none',
            width: 'calc(100vw - 50px)',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100vw',
            },
          })}
          xs={12}
          md={3}
        >
          <SubjectExplorerSearchFilters
            register={register}
            midwife={midwife}
            control={control}
          />
          <Box sx={{ height: 'calc(100%)' }}>
            <SubjectExplorerList
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              loading={isLoading}
              subjects={subjectsData}
            />
          </Box>
        </Grid>
        <Grid
          item
          sx={(theme) => ({
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden',
            flex: 8,
            [theme.breakpoints.down('md')]: {
              display: subjectId ? 'flex' : 'none',
            },
            [theme.breakpoints.up('md')]: {
              display: 'flex',
            },
          })}
          xs={12}
          md={9}
        >
          {subjectId ? (
            <SubjectExplorerDetail subjectId={Number(subjectId)} />
          ) : (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={superWeasel}
                alt="Subject explorer logo"
                style={{
                  maxHeight: '300px',
                  marginTop: '-6rem',
                  marginBottom: '1rem',
                }}
              />
              <Typography variant="body2">
                {translate('subject.selectOne')}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
