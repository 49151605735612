import { Fragment } from 'react';
import {
  Datagrid,
  DateField,
  ListActions,
  NumberField,
  TextField,
  useTranslate,
} from 'react-admin';

import { List } from '../../components/generic/List';

export const PricingProCustomList = () => {
  const translate = useTranslate();

  return (
    <List
      title={<Fragment />}
      actions={<ListActions hasCreate />}
      resource={'pricing-pro-custom'}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label={translate('common.name')} source="name" />
        <NumberField
          source="pricePerUnitBase"
          label={translate('pricingProCustom.base')}
        />
        <NumberField
          source="pricePerUnitNight"
          label={translate('pricingProCustom.night')}
        />
        <NumberField
          source="pricePerUnitSundayAndHoliday"
          label={translate('pricingProCustom.sundayHoliday')}
        />
        <NumberField
          source="onCallNonActivatedRate"
          label={translate('pricingProCustom.rateOnCall')}
        />
        <TextField source="currency" label={translate('common.currency')} />
        <DateField source="startAt" label={translate('common.startAt')} />
      </Datagrid>
    </List>
  );
};
