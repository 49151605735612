import { Box, Card, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CoffeeMachine } from './coffeeMachine';
// import { Reindeer } from './reindeer';

const Image = styled('img')({
  width: '250px',
  padding: '0 1rem 1rem 1rem',
  zIndex: 1000,
});

const Root = styled('main')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '100%',
  minHeight: '100vh',
});

const AuthUIWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledCard = styled(Card)({
  minWidth: 300,
  borderRadius: '1rem',
  backdropFilter: 'blur(4px) saturate(120%)',
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  border: '1px solid rgba(209, 213, 219, 0.2)',
  transition: 'width 0.5s ease, height 0.5s ease',
});

const Hint = styled(Typography)({
  marginTop: '2rem',
  paddingLeft: '1rem',
  display: 'flex',
});

export const AuthCard = ({
  children,
  hint,
}: {
  children?: React.ReactNode;
  hint?: string;
}) => {
  const hasLargeScreen = useMediaQuery('(min-width:900px)');

  return (
    <Root>
      <AuthUIWrapper>
        <Image src={'/may_logo_24.svg'} alt="MAY logo" />
        <StyledCard>
          {hint && <Hint color="primary">{hint}</Hint>}
          {children}
        </StyledCard>
      </AuthUIWrapper>

      {hasLargeScreen && <CoffeeMachine />}
      {/* {hasLargeScreen && <Reindeer />} */}
    </Root>
  );
};
