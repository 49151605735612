import { useTranslate } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { OptionInput } from '@components/forms/optionInput';
import { TextInput } from '@components/forms/textInput';
import { Question, QuestionType } from '@teammay/form-core';

export type SelectQuestion = Omit<
  Question,
  'id' | 'createdAt' | 'updatedAt'
> & {
  maxSelection: NonNullable<Question['maxSelection']>;
};

export const SelectQuestionEdit = ({
  onBlur,
  labelOnly = false,
}: {
  onBlur: () => void;
  labelOnly?: boolean;
}) => {
  const { control } = useFormContext<SelectQuestion>();
  const type = useWatch({ control, name: 'type' });
  const t = useTranslate();
  if (type !== QuestionType.SELECT) {
    return null;
  }
  return (
    <>
      <OptionInput
        control={control}
        name="options"
        label={t('forms.questions.options')}
        onBlur={onBlur}
        withDescription
        labelOnly={labelOnly}
      />
      {!labelOnly && (
        <TextInput
          control={control}
          name={'maxSelection'}
          label={t('forms.questions.maxSelection')}
          onBlur={onBlur}
          rules={{
            required: true,
          }}
          type="number"
        />
      )}
      <TextInput
        control={control}
        name={'label'}
        label={t('forms.questions.label')}
        onBlur={onBlur}
        rules={{
          required: true,
        }}
      />
    </>
  );
};
