import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from '@components/generic/Button';
import { DialogTitle } from '@components/generic/Dialog';
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Stack,
} from '@mui/material';
import { Form, FormStep, Question, Template } from '@teammay/form-core';

import { QuestionEdit } from './questions/QuestionEdit';
import { StepEdit } from './steps/StepEdit';
import { ImageTemplateEdit } from './templates/ImageTemplateEdit';
import { colorTokens } from '../../../themes';
import { OnChangeFormFunction } from '../types';

type QorT =
  | {
      question: Question;
      type: 'question';
      order: number;
    }
  | {
      template: Template;
      type: 'template';
      order: number;
    };

const StepLabels = ({
  step,
  questions,
  templates,
  onChange,
}: {
  step: FormStep;
  questions: Question[];
  templates: Template[];
  onChange: OnChangeFormFunction;
}) => {
  const form = useForm({ defaultValues: step });

  const mixedAndOrdered = useMemo<QorT[]>(() => {
    const stepsQuestions: {
      question: Question;
      type: 'question';
      order: number;
    }[] = questions
      .filter((question) => question.stepId === step.id)
      .map((question) => ({
        question,
        type: 'question' as const,
        order: question.order,
      }));
    const stepTemplates: {
      template: Template;
      type: 'template';
      order: number;
    }[] = templates
      .filter((template) => template.stepId === step.id)
      .map((template) => ({
        template,
        type: 'template' as const,
        order: template.order,
      }));

    return sortBy(
      (stepTemplates as QorT[]).concat(stepsQuestions as QorT[]),
      'order',
    );
  }, [questions, templates, step.id]);

  return (
    <>
      <ListItem sx={{ px: 0 }}>
        <Card sx={{ backgroundColor: colorTokens.surface.subtle }}>
          <CardContent>
            <FormProvider {...form}>
              <StepEdit labelOnly onBlur={() => {}} onCancel={() => {}} />
            </FormProvider>
          </CardContent>
        </Card>
      </ListItem>
      {mixedAndOrdered.map((d) =>
        d.type === 'question' ? (
          <ListItem sx={{ px: 0 }}>
            <QuestionLabels
              key={d.question.id}
              question={d.question}
              onChange={onChange}
            />
          </ListItem>
        ) : (
          <ListItem sx={{ px: 0 }}>
            <TemplateLabels
              key={d.template.id}
              template={d.template}
              onChange={onChange}
            />
          </ListItem>
        ),
      )}
    </>
  );
};

const QuestionLabels = ({
  question,
  onChange,
}: {
  question: Question;
  onChange: OnChangeFormFunction;
}) => {
  const form = useForm({ defaultValues: question });
  return (
    <Card sx={{ backgroundColor: colorTokens.surface.secondary }}>
      <CardContent>
        <FormProvider {...form}>
          <QuestionEdit
            labelOnly
            onBlur={() => {
              const values = form.getValues();
              onChange({ updatedQuestion: values });
            }}
            onCancel={() => {}}
          />
        </FormProvider>
      </CardContent>
    </Card>
  );
};

const TemplateLabels = ({
  template,
  onChange,
}: {
  template: Template;
  onChange: OnChangeFormFunction;
}) => {
  const form = useForm({ defaultValues: template });
  return (
    <Card sx={{ backgroundColor: colorTokens.surface.accentSeaSubtle }}>
      <CardContent>
        <FormProvider {...form}>
          <ImageTemplateEdit
            labelOnly
            onBlur={() => {
              const values = form.getValues();
              onChange({ updatedTemplate: values });
            }}
            onCancel={() => {}}
          />
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export const AllLabelsModal = ({
  form,
  questions,
  templates,
  open,
  onClose,
  onChange,
}: {
  form: Form;
  questions: Question[];
  templates: Template[];
  open: boolean;
  onClose: () => void;
  onChange: OnChangeFormFunction;
}) => {
  const translate = useTranslate();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle onClose={onClose}>
        {translate('forms.edit.allLabels')}
      </DialogTitle>
      <DialogContent>
        <List>
          {form.steps.map((step) => (
            <StepLabels
              key={step.id}
              step={step}
              questions={questions}
              templates={templates}
              onChange={onChange}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={4} flex={1} p={2} mx={4}>
          <Button sx={{ flex: 1 }} onClick={onClose} variant="outlined">
            {translate('common.close')}
          </Button>
          <Button sx={{ flex: 1 }} onClick={onClose} variant="contained">
            {translate('common.save')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
