import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useTranslate } from 'react-admin';

import { Button } from '@components/generic/Button';
import { DialogTitle } from '@components/generic/Dialog';
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Form, FormStep, Question, ScoreMode } from '@teammay/form-core';

import { colorTokens } from '../../../themes';
import { OnChangeFormFunction } from '../types';

const StepTags = ({
  step,
  questions,
  onChange,
}: {
  step: FormStep;
  questions: Question[];
  onChange: OnChangeFormFunction;
}) => {
  const orderedQuestions = useMemo(() => {
    const stepsQuestions = questions.filter(
      (question) => question.stepId === step.id,
    );
    return sortBy(stepsQuestions, 'order');
  }, [questions, step.id]);

  return (
    <>
      {orderedQuestions.map((q) => (
        <QuestionTags key={q.id} question={q} onChange={onChange} />
      ))}
    </>
  );
};

const QuestionTags = ({
  question,
  onChange,
}: {
  question: Question;
  onChange: OnChangeFormFunction;
}) => {
  const tags = question.scoringRules
    ?.map((rule, index) => [rule, index] as const)
    .filter(([rule]) => rule.mode === ScoreMode.TAG);
  if (tags.length === 0) {
    return null;
  }
  return (
    <ListItem sx={{ px: 0 }}>
      <Card sx={{ backgroundColor: colorTokens.surface.secondary }}>
        <CardContent>
          <Typography variant="h6">{question.title}</Typography>
          {tags.map(([rule, index]) => (
            <TextField
              key={index}
              label={`Tag ${index + 1}`}
              value={rule.scoringValue}
              onChange={(e) => {
                onChange({
                  updatedQuestion: {
                    ...question,
                    scoringRules: [
                      ...question.scoringRules.map((r, i) =>
                        i === index
                          ? { ...r, scoringValue: e.target.value }
                          : r,
                      ),
                    ],
                  },
                });
              }}
            />
          ))}
        </CardContent>
      </Card>
    </ListItem>
  );
};

export const AllTagsModal = ({
  form,
  questions,
  open,
  onClose,
  onChange,
}: {
  form: Form;
  questions: Question[];
  open: boolean;
  onClose: () => void;
  onChange: OnChangeFormFunction;
}) => {
  const translate = useTranslate();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle onClose={onClose}>
        {translate('forms.edit.allTags')}
      </DialogTitle>
      <DialogContent>
        <List>
          {form.steps.map((step) => (
            <StepTags
              key={step.id}
              step={step}
              questions={questions}
              onChange={onChange}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={4} flex={1} p={2} mx={4}>
          <Button sx={{ flex: 1 }} onClick={onClose} variant="outlined">
            {translate('common.close')}
          </Button>
          <Button sx={{ flex: 1 }} onClick={onClose} variant="contained">
            {translate('common.save')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
