import React, { Dispatch, SetStateAction, useMemo } from 'react';

import { countryCode } from '@boTypes/common';
import { Roles } from '@boTypes/user';

import { useSelector } from '../store';

const CountryContext = React.createContext<
  [
    countryCode | 'all' | undefined,
    Dispatch<SetStateAction<countryCode | 'all' | undefined>>,
  ]
>([null, () => null]);

export const useCurrentCountryContext: () => [
  countryCode | 'all' | undefined,
  Dispatch<SetStateAction<countryCode | 'all' | undefined>>,
] = () => {
  const [country, setCountry] = React.useContext(CountryContext);
  const isAdminOrStaffManager = useSelector(
    (state) =>
      state.user.roles.includes(Roles.ADMIN) ||
      state.user.roles.includes(Roles.STAFF_MANAGER),
  );
  return [isAdminOrStaffManager ? country : undefined, setCountry] as const;
};

export const CurrentCountryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const roles = useSelector((state) => state.user.roles);
  const countryState = React.useState<countryCode | 'all' | null>(
    roles.includes(Roles.ADMIN) || roles.includes(Roles.STAFF_MANAGER)
      ? 'all'
      : null,
  );

  return (
    <CountryContext.Provider value={countryState}>
      {children}
    </CountryContext.Provider>
  );
};

export const useCurrentCountry = () => {
  const [country] = useCurrentCountryContext();
  return useMemo(() => country, [country]);
};
