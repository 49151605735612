import {
  ReferenceField,
  DateField,
  useTranslate,
  useLocale,
} from 'react-admin';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AvatarAndTextField, ShyText } from '../../components/fields';
import { Handover } from '../../types';

const Section = styled('section')(({ theme }) => ({
  borderRadius: theme.spacing(1),
  borderWidth: '1px',
  borderStyle: 'solid',
  // @ts-ignore
  borderColor: theme.palette.primary.main10,
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

const Header = styled('header')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
}));

const HeaderTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > *': {
    marginRight: theme.spacing(0.5),
  },
}));

export const HandoverItem = ({ handover }: { handover: Handover }) => {
  const translate = useTranslate();
  const locale = useLocale();
  return (
    <Section>
      <Header>
        <HeaderTitle>
          <ReferenceField
            source="userId"
            record={handover}
            reference="users"
            link={false}
          >
            <AvatarAndTextField />
          </ReferenceField>
          <span>{'-'}</span>
          <DateField
            record={handover}
            source="createdAt"
            locales={locale}
            showTime
            style={{ lineHeight: '1.5rem' }}
            sx={(theme) => ({ fontSize: theme.spacing(1.5) })}
          />
        </HeaderTitle>
      </Header>
      <main>
        <ShyText
          record={handover}
          inline
          source="problem"
          label={translate('handover.problem') + translate('common.colon')}
        />
        <ShyText
          component="pre"
          style={{ whiteSpace: 'pre-wrap' }}
          record={handover}
          inline
          source="observations"
          label={
            translate('handover.observationsLong') + translate('common.colon')
          }
        />
        <ShyText
          component="pre"
          style={{ whiteSpace: 'pre-wrap' }}
          record={handover}
          inline
          source="advices"
          label={translate('handover.adviceLong') + translate('common.colon')}
        />
        {handover.reviveAt && (
          <div>
            <Typography variant="body2" component="span" color="primary">
              {translate('handover.reviveAt') + translate('common.colon')}{' '}
            </Typography>
            <DateField record={handover} source="reviveAt" locales={locale} />
          </div>
        )}
        <ShyText
          component="pre"
          style={{ whiteSpace: 'pre-wrap' }}
          inline
          record={handover}
          source="plannedText"
          label={translate('revives.plannedText') + translate('common.colon')}
        />
      </main>
    </Section>
  );
};
