import { useMemo, useState } from 'react';
import { useTranslate } from 'react-admin';

import { ConfirmDialog } from '@components/ConfirmDialog/ConfirmDialog';
import { Add, Delete } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { ExtraTimeTrackingDialog } from './extraTimeTrackingDialog';
import { useExtraTaskActions, useExtraTasks } from './hooks';
import { TranslationExtraTask } from '../common/contentTranslation/types';
import { filterPanelProps } from '../common/dataGrid/styles';

export const ExtraTasks = () => {
  const translate = useTranslate();
  const { extraTasks, isPending, refetch } = useExtraTasks();
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedExtraTaskId, setSelectedExtraTaskId] = useState<
    TranslationExtraTask['id'] | null
  >();
  const { deleteExtraTask } = useExtraTaskActions(selectedExtraTaskId);

  const extraTask = useMemo(() => {
    return extraTasks?.length && selectedExtraTaskId
      ? extraTasks.find((task) => task.id === selectedExtraTaskId)
      : undefined;
  }, [selectedExtraTaskId, extraTasks]);

  const columns = useMemo<GridColDef<TranslationExtraTask>[]>(
    () => [
      {
        field: 'date',
        width: 150,
        headerName: translate('common.date'),
      },
      {
        field: 'timeSpent',
        width: 70,
        headerName: translate('contentTranslation.fields.timeSpent'),
      },
      {
        field: 'comments',
        headerName: translate('contentTranslation.fields.comments'),
        flex: 1,
      },
      {
        field: 'actions',
        headerName: translate('common.edit'),
        filterable: false,
        width: 150,
        renderCell: (params) => {
          return (
            <Stack
              flexDirection={'row'}
              gap={1}
              alignContent={'center'}
              justifyContent={'flex-start'}
            >
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  setSelectedExtraTaskId(params.row.id);
                  setOpen(true);
                }}
              >
                {translate('common.edit')}
              </Button>
              <IconButton
                onClick={() => {
                  setSelectedExtraTaskId(params.row.id);
                  setOpenDeleteDialog(true);
                }}
                size="small"
              >
                <Delete />
              </IconButton>
            </Stack>
          );
        },
      },
    ],
    [translate],
  );

  return (
    <>
      {/* TITLE */}
      <Stack
        flexDirection={'row'}
        spacing={2}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography>{translate('contentTranslation.extraTasks')}</Typography>
        <Button onClick={() => setOpen(true)} startIcon={<Add />}>
          {translate('contentTranslation.addExtraTime')}
        </Button>
      </Stack>
      {/* DATAGRID */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DataGrid
          rows={extraTasks}
          columns={columns}
          loading={isPending}
          density="compact"
          keepNonExistentRowsSelected
          ignoreDiacritics
          slotProps={{ filterPanel: filterPanelProps }}
        />
      </Box>

      {/* MODAL */}
      <ConfirmDialog
        title={translate('common.delete')}
        text={translate('common.confirmDeletion')}
        onClick={async () => {
          await deleteExtraTask(selectedExtraTaskId);
          refetch();
          setOpenDeleteDialog(false);
        }}
        open={openDeleteDialog && !!selectedExtraTaskId}
        onClose={() => {
          setOpenDeleteDialog(false);
          setSelectedExtraTaskId(null);
        }}
      />
      {(!isPending && open && selectedExtraTaskId ? !!extraTask : open) && (
        <ExtraTimeTrackingDialog
          open={!isPending && open && selectedExtraTaskId ? !!extraTask : open}
          setOpen={setOpen}
          extraTaskId={selectedExtraTaskId}
          setExtraTaskId={setSelectedExtraTaskId}
          refetch={refetch}
          extraTask={extraTask}
        />
      )}
    </>
  );
};
