import dayjs from 'dayjs';
import { SetStateAction, useCallback } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { DateInput } from '@components/forms/dateInput';
import { TextInput } from '@components/forms/textInput';
import { DialogTitle } from '@components/generic/Dialog';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import { useExtraTaskActions } from './hooks';
import { TranslationExtraTask } from '../common/contentTranslation/types';

type TimeTrackingInput = {
  timeSpent?: number;
  comments?: string;
  date?: string;
};

export const ExtraTimeTrackingDialog = ({
  extraTaskId,
  setExtraTaskId,
  refetch,
  open,
  setOpen,
  extraTask,
}: {
  extraTaskId?: TranslationExtraTask['id'];
  setExtraTaskId: React.Dispatch<
    SetStateAction<TranslationExtraTask['id'] | null>
  >;
  refetch: () => void;
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  extraTask: TranslationExtraTask | undefined;
}) => {
  const translate = useTranslate();
  const notify = useNotify();
  const { control, handleSubmit, reset } = useForm<TimeTrackingInput>({
    defaultValues: {
      timeSpent: extraTask?.timeSpent ?? 0,
      comments: extraTask?.comments ?? '',
      date: extraTask?.date ?? dayjs().format('YYYY-MM-DD'),
    },
  });

  const { createExtraTask, updateExtraTask } = useExtraTaskActions(extraTaskId);

  const onSubmit = useCallback(
    async (data: TimeTrackingInput) => {
      const date = dayjs(data.date).format('YYYY-MM-DD');
      try {
        if (extraTaskId) {
          await updateExtraTask({
            ...data,
            date: data.date ? date : undefined,
            timeSpent: Math.round(Number(data.timeSpent)),
          });
        } else {
          await createExtraTask({
            date: data.date ? date : undefined,
            comments: data.comments!,
            timeSpent: Math.round(Number(data.timeSpent)) ?? 0,
          });
        }
        refetch();
        notify('contentTranslation.timeTrackingSuccess', { type: 'success' });
        setExtraTaskId(null);
        setOpen(false);
        reset();
      } catch {
        notify('contentTranslation.timeTrackingError', { type: 'error' });
      }
    },
    [
      createExtraTask,
      updateExtraTask,
      notify,
      extraTaskId,
      setExtraTaskId,
      refetch,
      reset,
      setOpen,
    ],
  );
  const onClose = useCallback(() => {
    setExtraTaskId(null);
    setOpen(false);
  }, [setExtraTaskId, setOpen]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('contentTranslation.timeTrackingTitle')}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DateInput
            variant="standard"
            name="date"
            label={translate('common.date')}
            control={control}
            rules={{ required: true }}
          />
          <TextInput
            type="number"
            name="timeSpent"
            label={translate('contentTranslation.fields.timeSpent')}
            control={control}
            rules={{ required: true }}
          />
          <TextInput
            name="comments"
            label={translate('contentTranslation.fields.comments')}
            control={control}
            rules={{ required: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {translate('common.validate')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
