import { useTranslate } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { TextInput } from '@components/forms/textInput';
import { Question, QuestionType } from '@teammay/form-core';

export type TextQuestion = Omit<Question, 'id' | 'createdAt' | 'updatedAt'>;

export const TextQuestionEdit = ({
  onBlur,
}: {
  onBlur: () => void;
  labelOnly?: boolean;
}) => {
  const { control } = useFormContext<TextQuestion>();
  const type = useWatch({ control, name: 'type' });
  const t = useTranslate();
  if (type !== QuestionType.TEXT) {
    return null;
  }
  return (
    <>
      <TextInput
        control={control}
        name={'label'}
        label={t('forms.questions.label')}
        onBlur={onBlur}
        rules={{
          required: true,
        }}
      />
    </>
  );
};
