import { Fragment, useState } from 'react';
import {
  DeleteButton,
  Edit,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import {
  Datagrid,
  List,
  TextField,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import { TranslationScope } from '@boTypes/common';
import { StaffUser } from '@boTypes/staffUser';
import { TranslationPricing } from '@boTypes/translationPricing';
import {
  TranslationProfile,
  TranslationProfileUpdate,
} from '@boTypes/translationProfile';
import { Button } from '@components/generic/Button';
import { useMutation, useQuery } from '@hooks/queryWrappers';
import { Save } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';

import { UsersForm } from './form';
import useTransform from '../../hooks/transform';
import { useSelector } from '../../store';
import { Roles } from '../../types/user';

const CreateButton = () => {
  const { permissions } = usePermissions();
  const { id } = useParams<'id'>();
  const [create] = useCreate<{ id: number; userId: number; role: Roles }>(
    'roles',
  );
  const [currentRole, setCurrentRole] = useState<Roles>(Roles.HEALTH_PRO);
  const notify = useNotify();
  const refresh = useRefresh();

  const onCreate = () => {
    create(
      'roles',
      {
        data: {
          userId: Number(id),
          role: currentRole,
        },
      },
      {
        onSuccess: () => {
          notify('roles.created', {
            type: 'success',
            undoable: false,
          });
          refresh();
        },
        onError: (error: Record<string, any>) => {
          notify(error?.response?.data?.message || 'common.error.create', {
            type: 'warning',
          });
        },
      },
    );
  };
  if (
    !permissions?.includes(Roles.STAFF_MANAGER) &&
    !permissions?.includes(Roles.ADMIN)
  ) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Select<Roles>
        value={currentRole}
        onChange={(e) => setCurrentRole(e.target.value as Roles)}
        size="small"
      >
        {Object.values(Roles).map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))}
      </Select>
      <IconButton onClick={onCreate}>
        <Add />
      </IconButton>
    </Box>
  );
};

const Toolbar = () => {
  const translate = useTranslate();

  return (
    <TopToolbar
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
      }}
    >
      <Typography variant="subtitle2">{translate('roles.roles')}</Typography>
      <CreateButton />
    </TopToolbar>
  );
};

const InlineRoleList = () => {
  const { id } = useParams<'id'>();
  const translate = useTranslate();

  return (
    <List
      resource="roles"
      exporter={false}
      pagination={null}
      perPage={50}
      filter={{ userId: Number(id) }}
      actions={<Toolbar />}
      empty={<Toolbar />}
      title={<Fragment />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label={translate('roles.role')} source="role" />
        <DeleteButton redirect={false} mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
};

const TranslationProfileList = () => {
  const translate = useTranslate();
  const isContentTranslationManager = useSelector((state) =>
    state.user.roles?.find(
      (role) => role === Roles.CONTENT_TRANSLATION_MANAGER,
    ),
  );
  const staffUser = useRecordContext<StaffUser>();
  const [scope, setScope] = useState<TranslationScope | ''>(
    staffUser.translationProfile?.scope ?? '',
  );

  const { data, mutate, isPending } = useMutation<
    TranslationProfile,
    any,
    TranslationProfileUpdate
  >(
    ['translationProfiles'],
    {
      url: `/api/admin/content-translation/invoicing/profiles`,
      method: 'PUT',
    },
    {},
  );

  const { data: allPricings } = useQuery<TranslationPricing[]>(
    ['pricing'],
    {
      url: `/api/admin/content-translation/invoicing/pricing`,
    },
    {},
  );

  if (!isContentTranslationManager) {
    return null;
  }

  const optionText = (option: TranslationPricing) => {
    return option.default
      ? translate('admin.contentTranslation.pricingLabels.default', {
          startPricing: option.startPricing,
          country: translate(`common.countries.${option.locale}`),
        })
      : translate('admin.contentTranslation.pricingLabels.specific', {
          startPricing: option.startPricing,
          country: translate(`common.countries.${option.locale}`),
        });
  };
  const translationProfile = data ?? staffUser.translationProfile;
  return (
    <Card sx={{ my: '1rem' }}>
      <CardContent>
        <Typography variant="h6">
          {translate('admin.contentTranslation.title')}
        </Typography>
        <Stack direction="row" spacing={2} py={2}>
          <Typography variant="subtitle1" py={1}>
            {translate('admin.contentTranslation.scope')}
          </Typography>
          <Select
            value={scope}
            onChange={(e) => {
              setScope(e.target.value as TranslationScope);
            }}
          >
            {Object.values(TranslationScope).map((s) => (
              <MenuItem key={s} value={s}>
                {translate(`admin.contentTranslation.scopes.${s}`)}
              </MenuItem>
            ))}
          </Select>
          <Button
            disabled={isPending}
            onClick={() => {
              mutate({
                id: translationProfile?.id,
                scope: scope as TranslationScope,
                staffUserId: staffUser.id,
                pricing:
                  translationProfile?.pricing.map((pricing) => ({
                    id: pricing.id,
                  })) ?? [],
              });
            }}
          >
            {isPending ? (
              <CircularProgress size={20} />
            ) : translationProfile?.id ? (
              <Save />
            ) : (
              <Add />
            )}
            {translate(
              translationProfile?.id ? 'common.update' : 'common.create',
            )}
          </Button>
        </Stack>
        {!translationProfile?.id ? (
          <></>
        ) : allPricings ? (
          <Autocomplete
            options={allPricings}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {optionText(option)}
                </li>
              );
            }}
            autoHighlight
            loading={isPending}
            onChange={(_, newValue: TranslationPricing[]) => {
              mutate({
                id: translationProfile?.id,
                scope: scope as TranslationScope,
                staffUserId: staffUser.id,
                pricing: newValue.map((v) => ({ id: v.id })),
              });
            }}
            defaultValue={[]}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => {
              return (
                <MuiTextField
                  label={translate('admin.contentTranslation.pricing')}
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ margin: 0 }}
                  variant="outlined"
                />
              );
            }}
            value={translationProfile?.pricing}
            renderTags={(value) => {
              return value.map((v) => {
                return (
                  <Card sx={{ mx: 1, p: 1 }}>
                    <CardContent
                      sx={{
                        p: 0,
                        '&:last-child': {
                          paddingBottom: 0,
                        },
                      }}
                    >
                      {optionText(v)}
                    </CardContent>
                  </Card>
                );
              });
            }}
            multiple
          />
        ) : (
          <CircularProgress size={20} />
        )}
      </CardContent>
    </Card>
  );
};

export const UsersEdit = (props) => {
  const { transform } = useTransform('users', 'avatar');

  return (
    <Box
      sx={{
        paddingTop: 0,
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingBottom: '1rem',
        overflow: 'auto',
      }}
    >
      <Edit
        {...props}
        redirect="list"
        resource="users"
        transform={transform}
        mutationMode="pessimistic"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <UsersForm />
          </Grid>
          <Grid item xs={12} md={5}>
            <TranslationProfileList />
            <InlineRoleList />
          </Grid>
        </Grid>
      </Edit>
    </Box>
  );
};
