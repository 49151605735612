import { Fragment } from 'react';
import {
  Datagrid,
  DateField,
  ListActions,
  NumberField,
  TextField,
  useTranslate,
} from 'react-admin';

import { List } from '../../components/generic/List';

export const PricingProGenericList = () => {
  const translate = useTranslate();

  return (
    <List
      actions={<ListActions hasCreate />}
      resource={'pricing-pro-generic'}
      title={<Fragment />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label={translate('common.name')} source="name" />
        <TextField
          source="jobTitle"
          label={translate('pricingProGeneric.job')}
        />
        <NumberField
          source="pricePerUnitBase"
          label={translate('pricingProGeneric.base')}
        />
        <NumberField
          source="pricePerUnitNight"
          label={translate('pricingProGeneric.night')}
        />
        <NumberField
          source="pricePerUnitSundayAndHoliday"
          label={translate('pricingProGeneric.sundayHoliday')}
        />
        <NumberField
          source="onCallNonActivatedRate"
          label={translate('pricingProGeneric.rateOnCall')}
        />
        <TextField source="country" label={translate('common.country')} />
        <TextField source="currency" label={translate('common.currency')} />
        <DateField source="startAt" label={translate('common.startAt')} />
      </Datagrid>
    </List>
  );
};
