import { useTranslate } from 'react-admin';

import WorkIcon from '@mui/icons-material/Work';
import {
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

export const AppBundleCard = () => {
  const translate = useTranslate();

  return (
    <Card sx={{ padding: 2 }}>
      <Typography variant="subtitle1" color="primary" sx={{ paddingBottom: 1 }}>
        {translate('admin.appBundles.title')}
      </Typography>
      <List dense>
        <ListItem disablePadding>
          <ListItemButton disableGutters component="a" href="/#/app-bundles">
            <ListItemIcon sx={{ minWidth: '2rem' }}>
              <WorkIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translate('admin.appBundles.list')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            disableGutters
            component="a"
            href="/#/app-bundle-testers"
          >
            <ListItemIcon sx={{ minWidth: '2rem' }}>
              <Typography variant="h6">👨‍💻</Typography>
            </ListItemIcon>
            <ListItemText primary={translate('admin.appBundles.testers')} />
          </ListItemButton>
        </ListItem>
      </List>
    </Card>
  );
};
