import { useMutation, useQuery } from '@hooks/queryWrappers';

import { useContentFromTasks } from '../common/contentTranslation/hooks';
import {
  TaskStatus,
  TranslationExtraTask,
  TranslationTask,
} from '../common/contentTranslation/types';

export const useTasks = ({ status }: { status?: TaskStatus } = {}) => {
  const {
    data: tasks,
    isPending,
    refetch,
  } = useQuery<TranslationTask[]>(['contentTranslation', 'tasks'], () => ({
    method: 'GET',
    url: '/api/content-translation/tasks',
    params: { filter: { status } },
  }));

  const { multiSearchIdMapping } = useContentFromTasks(tasks);

  return { tasks, isPending, multiSearchIdMapping, refetch };
};

export const useStaffUserSummary = () => {
  const { data: summary, isPending } = useQuery(
    ['contentTranslation', 'summary'],
    () => ({
      method: 'GET',
      url: '/api/content-translation/tasks/summary',
    }),
  );
  return { summary, isPending };
};

export const useExtraTasks = () => {
  const {
    data: extraTasks,
    isPending,
    refetch,
  } = useQuery<TranslationExtraTask[]>(
    ['contentTranslation', 'extraTasks'],
    () => ({
      method: 'GET',
      url: '/api/content-translation/invoicing/extra-tasks',
    }),
  );

  return { extraTasks, isPending, refetch };
};

export const useSubmitTask = (taskId: TranslationTask['id']) => {
  const { mutateAsync: submitTask } = useMutation(
    ['contentTranslation', 'submitTask'],
    (data: any) => ({
      method: 'PUT',
      url: `/api/content-translation/tasks/${taskId}/translator_review`,
      data,
    }),
  );

  return { submitTask };
};

export const useExtraTaskActions = (taskId: TranslationTask['id']) => {
  const { mutateAsync: createExtraTask } = useMutation(
    ['contentTranslation', 'createExtraTask'],
    (data: { comments: string; timeSpent: number; date: string }) => ({
      method: 'POST',
      url: `/api/content-translation/invoicing/extra-tasks`,
      data,
    }),
  );

  const { mutateAsync: updateExtraTask } = useMutation(
    ['contentTranslation', 'updateExtraTask'],
    (data: { comments?: string; timeSpent?: number; date?: string }) => ({
      method: 'PUT',
      url: `/api/content-translation/invoicing/extra-tasks/${taskId}`,
      data,
    }),
  );

  const { mutateAsync: deleteExtraTask } = useMutation(
    ['contentTranslation', 'deleteExtraTask'],
    (deleteTaskId: string) => ({
      method: 'DELETE',
      url: `/api/content-translation/invoicing/extra-tasks/${deleteTaskId}`,
    }),
  );

  return { createExtraTask, updateExtraTask, deleteExtraTask };
};
