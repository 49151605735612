import { useTranslate } from 'react-admin';

import { Roles } from '@boTypes/user';
import { Grid, Paper, Typography } from '@mui/material';

import { AdminCardGrid } from './cardWrapper';
import { AppBundleCard } from './sections/appBundleCard';
import { CategoryCard } from './sections/categoryCard';
import { ContentTranslationCard } from './sections/contentTranslation';
import { FormCard } from './sections/formCard';
import { PlanningCard } from './sections/planningCard';
import { PricingCard } from './sections/pricingCard';
import { ScenarioCard } from './sections/scenarioCard';
import { useSelector } from '../../store';

export const Admin = () => {
  const translate = useTranslate();
  const roles = useSelector((state) => state.user.roles);

  const isAdmin = roles.includes(Roles.ADMIN);
  const isStaffManager = roles.includes(Roles.STAFF_MANAGER);
  const isFormEditor = roles.includes(Roles.FORM_EDITOR);
  const isAffiliationManager = roles.includes(Roles.AFFILIATIONS_MANAGER);
  const isCodePushManager = roles.includes(Roles.CODE_PUSH_MANAGER);
  const isContentTranslationAdmin = roles.includes(
    Roles.CONTENT_TRANSLATION_MANAGER,
  );

  if (
    !isAdmin &&
    !isStaffManager &&
    !isFormEditor &&
    !isAffiliationManager &&
    !isCodePushManager &&
    !isContentTranslationAdmin
  ) {
    return null;
  }

  return (
    <Paper
      sx={{ padding: '1rem', minHeight: '100%', zIndex: 10 }}
      elevation={0}
    >
      <Typography
        variant="h1"
        color="primary"
        sx={{ mb: 1, fontWeight: 'bold' }}
      >
        {translate('title.admin')}
      </Typography>

      <Grid container spacing={2}>
        <AdminCardGrid roles={[Roles.STAFF_MANAGER, Roles.ADMIN]}>
          <PlanningCard />
        </AdminCardGrid>
        <AdminCardGrid roles={[Roles.FORM_EDITOR, Roles.ADMIN]}>
          <FormCard />
        </AdminCardGrid>
        <AdminCardGrid roles={[Roles.ADMIN]}>
          <CategoryCard />
        </AdminCardGrid>
        <AdminCardGrid roles={[Roles.AFFILIATIONS_MANAGER, Roles.ADMIN]}>
          <PricingCard />
        </AdminCardGrid>
        <AdminCardGrid roles={[Roles.CODE_PUSH_MANAGER]}>
          <AppBundleCard />
        </AdminCardGrid>
        <AdminCardGrid roles={[Roles.CONTENT_TRANSLATION_MANAGER, Roles.ADMIN]}>
          <ContentTranslationCard />
        </AdminCardGrid>
        <AdminCardGrid roles={[Roles.ADMIN]}>
          <ScenarioCard />
        </AdminCardGrid>
      </Grid>
    </Paper>
  );
};
