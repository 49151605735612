import React, {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useTranslate } from 'react-admin';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { AppointmentSuggestion } from '@boTypes/appointmentSuggestion';
import { DailyTip } from '@boTypes/dailyTip';
import { FertiTip } from '@boTypes/fertiTip';
import Folder from '@boTypes/folder';
import { Guide } from '@boTypes/guide';
import ItemSuggestion from '@boTypes/ItemSuggestion';
import { Macro } from '@boTypes/macroType';
import { Masterclass } from '@boTypes/masterclass';
import { Post } from '@boTypes/post';
import PregnancyQuestion from '@boTypes/PregnancyQuestion';
import { PregnancyWeekContent } from '@boTypes/pregnancyWeekContent';
import { TodoListSuggestion } from '@boTypes/todoListSuggestion';
import { WeeklyTip } from '@boTypes/weeklyTip';
import { AppointmentSuggestionDialog } from '@components/AppointmentSuggestion/AppointmentSuggestionItem';
import { DailyTipItemDialog } from '@components/DailyTip/DailyTipItem';
import { FertiTipItemDialog } from '@components/FertiTip/FertiTipItem';
import { FolderItemDialog } from '@components/Folder/FolderItem';
import { GuideCarousel } from '@components/Guide/GuideItem';
import { ItemSuggestionDialog } from '@components/ItemSuggestion/ItemSuggestionDialog';
import { Loader } from '@components/Loader';
import { MacrosItemDialog } from '@components/Macro/MacroItem';
import { MasterclassItemDialog } from '@components/Masterclass/MasterclassItem';
import { PostItemDialog } from '@components/Post/PostItem';
import { PregnancyQuestionDialog } from '@components/PregnancyQuestion/PregnancyQuestionDialog';
import { PregnancyWeekContentDialog } from '@components/PregnancyWeekContent/PregnancyWeekContentItem';
import { TodoListSuggestionDialog } from '@components/TodoListSuggestion/TodoListSuggestionItem';
import { WeeklyTipItemDialog } from '@components/WeeklyTip/WeeklyTipItem';
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { stringToPastelColor } from '@utils/color';

import {
  CMSContent,
  ContentType,
} from '../../../../entities/library/contentTextList';
import { colorTokens } from '../../../../themes';

const ItemSize = 36;

export function ContentDetail<T extends ContentType>({
  type,
  open,
  setOpen,
  item,
  translatedItem,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: T;
  item?: CMSContent<T>;
  translatedItem?: CMSContent<T>;
}) {
  const close = useCallback(() => setOpen(false), [setOpen]);
  if (!open) {
    return null;
  }
  if (type === ContentType.GUIDES) {
    return (
      <GuideCarousel
        // slug={(item as Guide)?.slug}
        item={item as Guide}
        translatedItem={translatedItem as Guide}
        open={open}
        setOpen={setOpen}
      />
    );
  } else if (type === ContentType.POSTS) {
    return (
      <PostItemDialog
        open={open}
        setOpen={setOpen}
        item={item as Post}
        translatedItem={translatedItem as Post}
      />
    );
  } else if (type === ContentType.MASTERCLASSES) {
    return (
      // Not translated
      <MasterclassItemDialog
        item={item as Masterclass}
        opened={open}
        close={close}
      />
    );
  } else if (type === ContentType.MACRO) {
    return (
      <MacrosItemDialog
        open={open}
        close={close}
        item={item as Macro}
        translatedItem={translatedItem as Macro}
      />
    );
  } else if (type === ContentType.DAILY_TIPS) {
    return (
      <DailyTipItemDialog
        item={item as DailyTip}
        translatedItem={translatedItem as DailyTip}
        open={open}
        close={close}
      />
    );
  } else if (type === ContentType.FOLDER) {
    // Not translated for now
    return <FolderItemDialog item={item as Folder} open={open} close={close} />;
  } else if (type === ContentType.PREGNANCY_QUESTIONS) {
    return (
      <PregnancyQuestionDialog
        item={item as PregnancyQuestion}
        translatedItem={translatedItem as PregnancyQuestion}
        open={open}
        close={close}
      />
    );
  } else if (type === ContentType.ITEM_SUGGESTIONS) {
    // Not yet translated, support is only partial for double sided viz
    return (
      <ItemSuggestionDialog
        item={item as ItemSuggestion}
        translatedItem={translatedItem as ItemSuggestion}
        open={open}
        close={close}
      />
    );
  } else if (type === ContentType.WEEKLY_TIPS) {
    return (
      <WeeklyTipItemDialog
        item={item as WeeklyTip}
        translatedItem={translatedItem as WeeklyTip}
        open={open}
        close={close}
      />
    );
  } else if (type === ContentType.FERTI_TIPS) {
    return (
      <FertiTipItemDialog
        item={item as FertiTip}
        open={open}
        close={close}
        translatedItem={translatedItem as FertiTip}
      />
    );
  } else if (type === ContentType.PREGNANCY_WEEK_CONTENT) {
    return (
      <PregnancyWeekContentDialog
        item={item as PregnancyWeekContent}
        translatedItem={translatedItem as PregnancyWeekContent}
        open={open}
        close={close}
      />
    );
  } else if (type === ContentType.APPOINTMENT_SUGGESTIONS) {
    return (
      <AppointmentSuggestionDialog
        item={item as AppointmentSuggestion}
        translatedItem={translatedItem as AppointmentSuggestion}
        open={open}
        close={close}
      />
    );
  } else if (type === ContentType.TODO_LIST_SUGGESTIONS) {
    return (
      <TodoListSuggestionDialog
        item={item as TodoListSuggestion}
        translatedItem={translatedItem as TodoListSuggestion}
        open={open}
        close={close}
      />
    );
  }
}

const keyExtractor = (index: number, data: { id: number }[]) =>
  data[index]?.id ?? 'loader';

export const ContentLine = <T extends ContentType>({
  item,
  setItem,
  setTranslatedItem,
  setOpen,
  type,
  setSelectedType,
  translatedDocument,
}: {
  item: CMSContent<T> & { alreadyAdded?: boolean };
  setItem: Dispatch<SetStateAction<CMSContent<T>>>;
  setTranslatedItem?: Dispatch<SetStateAction<CMSContent<T>>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedType?: Dispatch<SetStateAction<T>>;
  type: T;
  translatedDocument?: CMSContent<T>;
}) => {
  const translate = useTranslate();

  if (!item) {
    return null;
  }

  return (
    <ListItemButton
      color="primary"
      disableGutters
      sx={{
        '&:hover': {
          borderRadius: 2,
          backgroundColor: 'primary.main10',

          '.MuiTouchRipple-child': {
            backgroundColor: 'primary.main',
          },
        },
        justifyContent: 'flex-start',
        selfAlign: 'center',
        marginTop: '1px',
        height: '2rem', // cell have a border of 1px
      }}
      onClick={() => {
        setItem(item);
        setTranslatedItem?.(translatedDocument ?? null);
        setOpen(true);
        setSelectedType && setSelectedType(type);
      }}
    >
      {/* @ts-ignore */}
      {(item.cover ||
        // @ts-expect-error
        item.coverThumbnailUrl ||
        // @ts-expect-error
        item.expertImageThumbnailUrl ||
        // @ts-expect-error
        item.weeklyTipIllustrationThumbnailUrl ||
        // @ts-expect-error
        item.fruit) && (
        <ListItemAvatar sx={{ minWidth: 32, marginLeft: 1 }}>
          <Avatar
            src={
              // @ts-expect-error
              item.cover ||
              // @ts-expect-error
              item.coverThumbnailUrl ||
              // @ts-expect-error
              item.expertImageThumbnailUrl ||
              // @ts-expect-error
              item.weeklyTipIllustrationThumbnailUrl ||
              // @ts-expect-error
              item.fruit
            }
            sx={{
              width: 28,
              height: 28,
              borderColor: 'primary.main10',
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
        </ListItemAvatar>
      )}
      <ListItemText
        primaryTypographyProps={{
          style: {
            color:
              // @ts-ignore
              item?.sent !== undefined && item?.sent
                ? 'background.grey'
                : 'inherit',
          },
        }}
        sx={{
          flexGrow: 0,
          paddingLeft: '0.5rem',
        }}
        // @ts-expect-error
        primary={item?.title || item?.name}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '0.5rem',
          marginLeft: '0.5rem',
        }}
      >
        {/* @ts-ignore */}
        {item.categories?.map((category: string) => (
          <Chip
            key={category}
            label={category}
            size="small"
            sx={{ backgroundColor: stringToPastelColor(category) }}
          />
        ))}
        {type === ContentType.PREGNANCY_QUESTIONS && (
          <Chip
            // @ts-expect-error
            key={item.output}
            // @ts-expect-error
            label={item.output}
            size="small"
            sx={{
              // @ts-expect-error
              backgroundColor: stringToPastelColor(item.output ?? ''),
            }}
          />
        )}
        {type === ContentType.DAILY_TIPS && (
          <Chip
            // @ts-expect-error
            key={`${item.weekNumber}-${item.id}`}
            label={translate('admin.contentTranslation.dailyTip.weekNumber', {
              weekNumber:
                (item as DailyTip).subAgeWeek?.[0] !== undefined
                  ? (item as DailyTip).subAgeWeek?.[0] + 1
                  : 'N/A',
            })}
            size="small"
            sx={{
              // @ts-expect-error
              backgroundColor: item?.isPregnancy
                ? colorTokens.surface.accentLilacSubtle
                : colorTokens.surface.accentMandarinSubtle,
            }}
          />
        )}
        {/* @ts-expect-error */}
        {type === ContentType.WEEKLY_TIPS && item?.type && (
          <Chip
            // @ts-expect-error
            key={item.type}
            // @ts-expect-error
            label={item.type}
            size="small"
            sx={{
              backgroundColor: stringToPastelColor(
                // @ts-expect-error
                item?.type,
              ),
            }}
          />
        )}
        {type === ContentType.FERTI_TIPS && (
          <Chip
            // @ts-expect-error
            key={`${item.cycleNumber}-${item.cyclePosition}`}
            label={translate('fertiTip.shortNumberAndPosition', {
              // @ts-expect-error
              cycleNumber: item.cycleNumber,
              // @ts-expect-error
              cyclePosition: item.cyclePosition,
            })}
            size="small"
            sx={{
              backgroundColor: stringToPastelColor(
                // @ts-expect-error
                String(item?.cycleNumber),
              ),
            }}
          />
        )}
        {type === ContentType.APPOINTMENT_SUGGESTIONS && (
          <Chip
            // @ts-expect-error
            key={`${item.id}-${item.start}-${item.end}`}
            // @ts-expect-error
            label={`${item.start}-${item.end}`}
            size="small"
            sx={{
              backgroundColor: colorTokens.surface.accentLilacSubtle,
            }}
          />
        )}
        {item.alreadyAdded && !translatedDocument && (
          <Chip
            label={translate('admin.contentTranslation.alreadyAdded')}
            size="small"
            sx={{ backgroundColor: colorTokens.surface.dangerLight }}
          />
        )}
      </Box>
    </ListItemButton>
  );
};

export function TranslationList<T extends ContentType>({
  type,
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage,

  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  isNextPageLoading,

  // Array of items loaded so far.
  items,

  // Array of items already in the translation pipe
  itemsAlreadyAdded,

  // Callback function responsible for loading the next page of items.
  loadNextPage,
  checked,
  setChecked,
}: {
  type: T;
  items?: (CMSContent<T> & { alreadyAdded: boolean })[];
  itemsAlreadyAdded?: number[];
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  loadNextPage: () => void;
  checked: number[];
  setChecked: React.Dispatch<React.SetStateAction<number[]>>;
}) {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState<CMSContent<T>>();

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = items ? (hasNextPage ? items.length + 1 : items.length) : 0;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) =>
    !hasNextPage || items ? index < items.length : false;

  const ListItemRender = useCallback(
    ({
      data,
      index,
      style,
    }: {
      data: typeof items;
      index: number;
      style: CSSProperties;
    }) => {
      const itm = data[index];
      return (
        <div key={itm?.id ?? 'loader'} style={style}>
          {!itm ? (
            <ListItem
              disableGutters
              disablePadding
              sx={{ px: 1 }}
              key={'loader'}
            >
              <Loader />
            </ListItem>
          ) : (
            <ListItem
              disableGutters
              disablePadding
              key={`${type}-${itm.id}`}
              sx={{ px: 1 }}
            >
              <ListItemIcon sx={{ minWidth: 'unset' }}>
                <Checkbox
                  edge="start"
                  checked={checked?.indexOf(itm.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  onChange={(ev) => {
                    const isChecked = ev.target.checked;
                    if (isChecked) {
                      if (checked?.indexOf(itm.id) === -1) {
                        setChecked([...checked, itm.id]);
                      }
                    } else {
                      if (checked?.indexOf(itm.id) !== -1) {
                        setChecked(checked?.filter((id) => id !== itm.id));
                      }
                    }
                  }}
                />
              </ListItemIcon>
              <ContentLine
                item={itm}
                setItem={setItem}
                setOpen={setOpen}
                type={type}
              />
            </ListItem>
          )}
        </div>
      );
    },
    [type, checked, setChecked],
  );

  items = items.map((itm) => ({
    ...itm,
    alreadyAdded: itemsAlreadyAdded?.indexOf(itm.id) !== -1,
  }));

  if (!items?.length) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100% - 151px)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <List disablePadding dense sx={{ height: '100%' }}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    itemData={items}
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    className="scrollbar"
                    height={height}
                    width={width}
                    ref={ref}
                    itemSize={ItemSize}
                    overscanCount={5}
                    itemKey={keyExtractor}
                  >
                    {ListItemRender}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </List>
        <ContentDetail type={type} open={open} setOpen={setOpen} item={item} />
      </Box>
    </Box>
  );
}
