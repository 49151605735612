import { isAxiosError } from 'axios';
import uniq from 'lodash/uniq';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  DataProvider,
  Identifier,
  RaRecord,
  useDataProvider,
} from 'react-admin';

import { Child } from '@boTypes/child';
import { FamilyPopulated } from '@boTypes/family';
import { PatientListItem } from '@boTypes/patient';
import { User } from '@boTypes/user';
import * as Sentry from '@sentry/browser';

type ResourceAssociationType = 'children' | 'patients' | 'family' | 'users';
interface ResourceAssociation {
  children: Child;
  patients: PatientListItem;
  family: FamilyPopulated;
  users: User;
}

type OneResourceCache<T extends ResourceAssociationType> = Record<
  Identifier,
  {
    isLoading: boolean;
    isError?: boolean;
    data: ResourceAssociation[T] | undefined;
    timestamp: number;
  }
>;

export const getVaryingCache: {
  [K in ResourceAssociationType]: OneResourceCache<K>;
} = {
  children: {} as OneResourceCache<'children'>,
  patients: {} as OneResourceCache<'patients'>,
  family: {} as OneResourceCache<'family'>,
  users: {} as OneResourceCache<'users'>,
};

export interface GetVaryingManyOptions<
  T extends ResourceAssociationType,
  ReturnType = (ResourceAssociation[T] | undefined)[],
> {
  cacheDuration?: number;
  select?: (arg: (ResourceAssociation[T] | undefined)[]) => ReturnType;
  enabled?: boolean;
}

export type DataSourceType<ResourceType> = Record<
  Identifier,
  {
    isLoading: boolean;
    isError?: boolean;
    data: ResourceType | undefined;
    timestamp: number;
    getItemPromise?: Promise<any>;
  }
>;

const maintainCache = <ResourceType extends RaRecord>(
  dataSource: DataSourceType<ResourceType>,
  cacheDuration: number,
) => {
  // maintain cache
  Object.keys(dataSource).forEach((id) => {
    const data = dataSource[id];
    if (
      data &&
      !data.isLoading &&
      !data.isError &&
      data.timestamp + 2 * cacheDuration < Date.now()
    ) {
      delete dataSource[id];
    }
  });
};

export const getVaryingMany = async <ResourceType extends RaRecord>(
  dataProvider: Pick<DataProvider, 'getMany'>,
  dataSource: DataSourceType<ResourceType>,
  resource: ResourceAssociationType,
  ids: Identifier[],
  setIsLoading: (isLoading: boolean) => void,
  cacheDuration: number,
) => {
  const shouldFetchIds = uniq(
    ids.filter(Boolean).filter((id) => {
      const data = dataSource[id];
      return (
        !data ||
        ((data.isError || data.timestamp + cacheDuration < Date.now()) &&
          !data.isLoading)
      );
    }),
  );

  const otherRequestPromises = ids
    .filter((id) => {
      const data = dataSource[id];
      return (
        !(
          !data ||
          ((data.isError || data.timestamp + cacheDuration < Date.now()) &&
            !data.isLoading)
        ) && data.getItemPromise
      );
    })
    .map((id) => dataSource[id].getItemPromise);

  if (shouldFetchIds.length > 0) {
    setIsLoading(true);
    shouldFetchIds.forEach((id) => {
      dataSource[id] = {
        isLoading: true,
        timestamp: Date.now(),
        data: undefined,
      };
    });

    // maintain cache after setting isLoading to true
    maintainCache(dataSource, cacheDuration);

    try {
      const getManyPromise = dataProvider.getMany<ResourceType>(resource, {
        ids: shouldFetchIds,
      });

      shouldFetchIds.forEach((id) => {
        dataSource[id].getItemPromise = getManyPromise;
      });

      const res = await getManyPromise;
      res.data.forEach(({ id }, index) => {
        dataSource[id] = {
          isLoading: false,
          data: res.data[index],
          timestamp: Date.now(),
          getItemPromise: undefined,
        };
      });

      if (!otherRequestPromises.length) {
        setIsLoading(
          ids.reduce((acc, id) => acc || dataSource[id]?.isLoading, false),
        );
      }
    } catch (err: unknown) {
      if (!isAxiosError(err) || err?.response?.status === 500) {
        Sentry.captureException(err);
      }
      shouldFetchIds.forEach((id) => {
        dataSource[id] = {
          isLoading: false,
          isError: true,
          data: dataSource[id]?.data,
          timestamp: Date.now(),
        };
      });
      if (!otherRequestPromises.length) {
        setIsLoading(
          ids.reduce((acc, id) => acc || dataSource[id]?.isLoading, false),
        );
      }
    }
  } else {
    maintainCache(dataSource, cacheDuration);
  }

  // wait for other executing requests to finish to set isLoading to false
  if (otherRequestPromises.length) {
    setIsLoading(true);
    try {
      await Promise.race([
        new Promise((resolve) => setTimeout(resolve, 3000)),
        ...otherRequestPromises,
      ]);
    } catch (_err) {
    } finally {
      setIsLoading(
        ids.reduce((acc, id) => acc || dataSource[id]?.isLoading, false),
      );
    }
  }
};

const identity = (a) => a;

export const useGetVaryingMany = <
  T extends ResourceAssociationType,
  ReturnType = (ResourceAssociation[T] | undefined)[],
>(
  resource: T,
  ids: Identifier[],
  {
    cacheDuration = 60 * 5000,
    enabled = true,
    select = identity,
  } = {} as GetVaryingManyOptions<T, ReturnType>,
): { data: (ResourceAssociation[T] | undefined)[]; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(false);

  const dataProvider = useDataProvider();
  const dataSource = useRef(getVaryingCache[resource]);

  useEffect(() => {
    if (enabled) {
      getVaryingMany(
        dataProvider,
        dataSource.current,
        resource,
        ids,
        setIsLoading,
        cacheDuration,
      );
    }
  }, [cacheDuration, dataProvider, enabled, ids, resource]);

  const data = useMemo(
    () => select(ids.map((id) => dataSource.current[id]?.data)),
    [ids, select],
  );
  return { isLoading: isLoading && enabled, data };
};
