import { useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { CheckboxInput } from '@components/forms/checkBoxInput';
import { SelectInput } from '@components/forms/selectInput';
import { TextInput } from '@components/forms/textInput';
import { Button } from '@components/generic/Button';
import { useMutation } from '@hooks/queryWrappers';
import { MenuItem, Stack, Typography } from '@mui/material';

import {
  ContentType,
  UserLocale,
} from '../../../common/contentTranslation/types';

type FormData = {
  folder: string;
  type: ContentType;
  locale: UserLocale;
  limit: number;
  ignoreExisting: boolean;
};

export const ContentTranslationExternalSource = () => {
  const { control, handleSubmit, formState } = useForm<FormData>({
    defaultValues: {
      ignoreExisting: false,
    },
  });
  const t = useTranslate();

  const { isValid, isSubmitting } = formState;

  const { mutateAsync: createFromExternalSource } = useMutation(
    ['createFromExternalSource'],
    (data: FormData) => ({
      method: 'POST',
      url: '/api/admin/content-translation/tasks/external-source',
      data: {
        ...data,
        limit: data.limit ? Number(data.limit) : undefined,
      },
    }),
  );

  const onSubmit = handleSubmit(async (data: FormData) => {
    await createFromExternalSource(data);
  });

  return (
    <Stack flexDirection={'column'} m={2}>
      <Typography variant="h4">
        Importer depuis un dossier Google Drive
      </Typography>
      <form onSubmit={onSubmit}>
        <Stack flexDirection={'column'} gap={2} maxWidth={'400px'}>
          <TextInput
            label="Id dossier Google Drive"
            name="folder"
            control={control}
            rules={{ required: true }}
          />
          <SelectInput
            label="Type de contenu"
            name="type"
            control={control}
            rules={{ required: true }}
            variant="outlined"
          >
            {Object.values(ContentType).map((type) => (
              <MenuItem key={type} value={type}>
                {t(`admin.contentTranslation.contentType.${type}`)}
              </MenuItem>
            ))}
          </SelectInput>
          <SelectInput
            label="Locale"
            name="locale"
            control={control}
            rules={{ required: true }}
            variant="outlined"
          >
            {Object.values(UserLocale).map((locale) => (
              <MenuItem key={locale} value={locale}>
                {locale}
              </MenuItem>
            ))}
          </SelectInput>
          <TextInput
            label="Nombre de documents à importer"
            name="limit"
            type="number"
            control={control}
            rules={{ required: false, min: 1, max: 1000 }}
          />
          <CheckboxInput
            label="Ignorer les documents déjà importés"
            name="ignoreExisting"
            control={control}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? 'Importation en cours...' : 'Importer'}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};
