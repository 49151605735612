import { sortBy } from 'lodash';
import { Edge, Node } from 'reactflow';

import {
  Form,
  FormStep,
  Question,
  StepLink,
  Template,
} from '@teammay/form-core';

import {
  FillerNodeType,
  OnChangeFormFunction,
  QuestionNodeType,
  StepNodeType,
  TemplateNodeType,
} from '../types';

const stepWidth = 200;
const questionHeight = 72;
const stepElementHeight = 32;
const questionMarginVertical = 4;
const questionMarginHorizontal = 8;
const stepButtonHeight = 20;

export const questionToNode = (
  question: Question,
  onChange: OnChangeFormFunction,
  step: Pick<FormStep, 'id'>,
): QuestionNodeType => ({
  id: `question-${question.id}`,
  data: { question, onChange },
  position: { x: 0, y: 0 },
  parentId: `step-${step.id}`,
  type: 'question',
  deletable: false,
  zIndex: 10010,
});

export const fillerNode = (node: Node): FillerNodeType => ({
  ...node,
  type: 'filler',
  data: {
    forNode: node.id,
  },
  id: `filler-${node.id}`,
});

export const templateToNode = (
  template: Template,
  onChange: OnChangeFormFunction,
  step: Pick<FormStep, 'id'>,
): TemplateNodeType => ({
  id: `template-${template.id}`,
  data: { template, onChange },
  position: { x: 0, y: 0 },
  parentId: `step-${step.id}`,
  type: 'template',
  deletable: false,
  zIndex: 10010,
});

export const stepToNode = (
  step: FormStep,
  onChange: OnChangeFormFunction,
  link?: StepLink,
  allLinks?: StepLink[],
): StepNodeType => ({
  id: `step-${step.id}`,
  data: { step, link, allLinks, onChange },
  position: { x: 0, y: 0 },
  style: { width: stepWidth, height: 100 },
  type: 'step',
  deletable: false,
});

export const stepsEdge = (
  from: FormStep['id'],
  to: FormStep['id'],
  deletable = false,
): Edge => ({
  id: `edge-${from}-to-${to}`,
  source: `step-${from}`,
  target: `step-${to}`,
  deletable,
});

export const positionNodesInStep = (nodes: Node[], stepNode: Node) => {
  nodes.forEach((node, nodeIndex) => {
    node.position = {
      x: questionMarginHorizontal,
      y:
        stepElementHeight +
        questionMarginVertical +
        nodeIndex * (questionHeight + 2 * questionMarginVertical),
    };
    node.style = {
      width: stepWidth - questionMarginHorizontal * 2,
      height: questionHeight,
    };
    node.width = stepWidth - questionMarginHorizontal * 2;
    node.height = questionHeight;
  });
  stepNode.height =
    stepElementHeight +
    6 * questionMarginVertical +
    (questionHeight + questionMarginVertical * 2) * nodes.length +
    stepButtonHeight;
  stepNode.width = stepWidth;
  stepNode.style = {
    width: stepNode.width,
    height: stepNode.height,
  };
};

export const positionSteps = (nodes: Node[]) => {
  const xList = nodes.map((node) => node.position.x).sort();
  nodes.forEach((node, nodeIndex) => {
    node.position = {
      x: xList[nodeIndex],
      y: node.position.y,
    };
  });
};

/**
 * generates nodes and edges from form, questions and templates
 * @param form
 * @param questions
 * @param templates
 * @param onChange
 * @returns
 */
export const apiToNodes = (
  form: Form,
  questions: Question[],
  templates: Template[],
  onChange: OnChangeFormFunction,
) => {
  const stepsNodes: StepNodeType[] = form.steps.map((step) => {
    const allLinks = form.steps
      .flatMap((s) => s.links)
      ?.filter((l) => l.nextStepId === step.id);
    return stepToNode(step, onChange, allLinks[0], allLinks);
  });

  const stepIdToQuestions = questions.reduce(
    (acc, question) => {
      if (!acc[question.stepId]) {
        acc[question.stepId] = [];
      }
      acc[question.stepId].push(question);
      return acc;
    },
    {} as Record<string, Question[]>,
  );

  const stepIdToTemplates = templates.reduce(
    (acc, template) => {
      if (!acc[template.stepId]) {
        acc[template.stepId] = [];
      }
      acc[template.stepId].push(template);
      return acc;
    },
    {} as Record<string, Template[]>,
  );

  const innerNodes = form.steps.map((step) => {
    const stepQuestions = stepIdToQuestions[step.id] || [];
    const stepTemplates = stepIdToTemplates[step.id] || [];

    const questionsNodes = stepQuestions.map((question) =>
      questionToNode(question, onChange, step),
    );

    const templatesNodes = stepTemplates.map((template) =>
      templateToNode(template, onChange, step),
    );

    const all = sortBy([...questionsNodes, ...templatesNodes], (n) =>
      n.type === 'question' ? n.data.question?.order : n.data.template?.order,
    );
    return all;
  }, {});

  // position inner nodes inside step
  innerNodes.forEach((nodes, index) => {
    positionNodesInStep(nodes, stepsNodes[index]);
  });

  const conditionEdges: Edge[] = [];
  stepsNodes.forEach((stepNode) => {
    stepNode.data.allLinks.forEach((link) => {
      conditionEdges.push(
        stepsEdge(
          link.stepId,
          link.nextStepId,
          stepNode.data.allLinks.length > 1,
        ),
      );
    });
  });

  return {
    stepsNodes,
    innerNodes: innerNodes.flat(),
    conditionEdges,
  };
};
