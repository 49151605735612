import { useTranslate } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { CheckboxInput } from '@components/forms/checkBoxInput';
import { TextInput } from '@components/forms/textInput';
import { Question, QuestionType } from '@teammay/form-core';

export type ImageQuestion = Omit<
  Question<QuestionType.IMAGE>,
  'id' | 'createdAt' | 'updatedAt'
>;

export const ImageQuestionEdit = ({
  onBlur,
  labelOnly = false,
}: {
  onBlur: () => void;
  labelOnly?: boolean;
}) => {
  const { control } = useFormContext<ImageQuestion>();
  const type = useWatch({ control, name: 'type' });
  const t = useTranslate();

  if (type !== QuestionType.IMAGE) {
    return null;
  }
  return (
    <>
      {!labelOnly && (
        <TextInput
          control={control}
          name="options.image"
          onBlur={onBlur}
          label={t('common.image')}
        />
      )}
      <TextInput
        control={control}
        name="options.primaryDescription"
        onBlur={onBlur}
        label={t('forms.questions.label')}
      />
      <TextInput
        control={control}
        name="options.secondaryDescription"
        onBlur={onBlur}
        label={t('forms.questions.secondaryLabel')}
      />
      <CheckboxInput
        control={control}
        name="options.hideTopBar"
        onBlur={onBlur}
        label={t('forms.questions.hideTopBar')}
      />
    </>
  );
};
