import {
  BooleanInput,
  SelectInput,
  SimpleForm,
  TextInput,
  TextField,
  usePermissions,
  useTranslate,
  Toolbar,
  SaveButton,
  useRecordContext,
} from 'react-admin';
import { useParams } from 'react-router';

import { PatientDetail } from '@boTypes/patient';
import { Roles } from '@boTypes/user';
import { FamilyHeader } from '@components/subjects/familyHeader';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import { localeChoices, subscriptionChoices } from '../common';
import { DeletionForm } from './inlines/deletionForm';
import { ExportSection } from './inlines/exportForm';
import { EmailUpdateForm } from './inlines/mailUpdate';
import { PhoneForm } from './inlines/phoneForm';

const PatientToolbar = () => {
  const translate = useTranslate();
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton label={translate('common.save')} />
      <DeletionForm />
    </Toolbar>
  );
};

export const PatientsForm = () => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const { id } = useParams();
  const record = useRecordContext<PatientDetail>();
  const globTheme = useTheme();
  const isSmall = useMediaQuery(globTheme.breakpoints.down('sm'));

  return (
    <SimpleForm sx={{ width: '100%' }} toolbar={<PatientToolbar />}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Typography
          variant="h1"
          sx={{ fontWeight: 'bold', mb: 1 }}
          color="primary"
        >
          {translate('appUsers.title')}
        </Typography>
        <ExportSection
          familyId={record?.familyId}
          children={record?.children}
          lang={record?.locale}
        />
      </Stack>
      <TextField
        label={translate('common.name')}
        source="name"
        variant="body1"
        sx={{ fontWeight: 'bold' }}
      />
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <TextField
          label={translate('common.email')}
          source="email"
          variant="body2"
        />
        <EmailUpdateForm id={Number(id)} />
      </Stack>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%', maxWidth: '100%' }}
      >
        <TextInput
          label={translate('common.phone')}
          source="phone"
          variant="standard"
          sx={{ minWidth: '140px', width: '140px !important' }}
        />
        <PhoneForm id={Number(id)} />
      </Stack>
      <Stack
        sx={{ width: '100%' }}
        flexDirection="row"
        justifyContent="space-between"
        gap={2}
      >
        <SelectInput
          disabled
          source="subscriptionStatus"
          choices={subscriptionChoices()}
          variant="standard"
          emptyText={translate('subscription.none')}
          label={translate('common.subscriptionStatus')}
          sx={{
            minWidth: '120px',
            width: isSmall ? '120px !important' : '100%',
          }}
        />
        <SelectInput
          source="locale"
          choices={localeChoices()}
          variant="standard"
          label={translate('common.locale')}
          sx={{
            minWidth: '120px',
            width: isSmall ? '120px !important' : '100%',
          }}
        />
      </Stack>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        sx={{ width: '100%' }}
      >
        <BooleanInput label="VIP" source="vip" variant="standard" />
        {permissions?.includes(Roles.ADMIN) && (
          <BooleanInput
            source="active"
            variant="standard"
            label={translate('common.active')}
          />
        )}
      </Stack>
      <Typography
        variant="h6"
        sx={{ fontWeight: 'bold', mb: 1 }}
        color="primary"
      >
        {translate('appUsers.discussion')}
      </Typography>
      {record && <FamilyHeader familyId={record?.familyId} />}
    </SimpleForm>
  );
};
