import { useMemo } from 'react';

import { useCurrentForm } from './useCurrentForm';

export const useUsedSlugs = (id: string) => {
  const { questions, templates } = useCurrentForm();
  const usedSlugs = useMemo(() => {
    const questionSlugs = questions
      .filter((q) => q.id !== id)
      .map((q) => q.slug);
    const templateSlugs = templates
      .filter((t) => t.id !== id)
      .map((template) => template.slug);
    return [...questionSlugs, ...templateSlugs]
      .filter(Boolean)
      .map((slug) => slug.trim());
  }, [questions, templates, id]);

  return usedSlugs;
};
