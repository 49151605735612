export const userAnswersKeys = [
  'sex',
  'maritalStatus',
  'source',
  'paid',
  'intent',
] as const;

export interface UserAnswers {
  sex: ANSWERS_SEX;
  maritalStatus: ANSWERS_MARITAL_STATUS;
  source?: ANSWERS_SOURCE;
  paid?: ANSWER_PAID_ORIGIN;
  intent?: string;
}

export enum ANSWERS_SEX {
  MASCULINE = 'masculine',
  FEMININE = 'feminine',
  UNKNOWN = 'unknown',
}

export enum ANSWERS_MARITAL_STATUS {
  COUPLE = 'couple',
  ALONE = 'alone',
  UNKNOWN = 'unknown',
}

export enum ANSWERS_SOURCE {
  // previous sources
  //
  // STORE_DISCOVERY = 'store_discovery',
  // FAMILY_AND_FRIENDS = 'family_and_friends',
  // DOCTOR = 'healthcare_pro',
  // SEO = 'SEO',
  // TV = 'TV',
  // PODCAST = 'podcast',
  // UNKNOWN = 'unknown',

  // currently active
  RECOMMENDATION = 'recommendation',
  MATERNITY_BOX = 'maternity_box',
  ONLINE_DISCOVERY = 'online_discovery',
  MEDIA_EVENTS = 'media_events',
  INSTAGRAM = 'follower_instagram',
  PAID = 'paid',
  OTHER = 'other',
}

export enum ANSWER_PAID_ORIGIN {
  // paid
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  GOOGLE = 'google',
  YOUTUBE = 'youtube',
  TIKTOK = 'tiktok',
  // PINTEREST = 'pinterest',
  SNAPCHAT = 'snapchat',
  OTHER = 'other',
  // UNKNOWN = 'unknown',

  // Recommendations
  FRIENDS_AND_FAMILY = 'friends_and_family',
  HEALTHCARE_PROFESSIONNAL = 'healthcare_professionnal',
  MATERNITY = 'maternity',

  // Online discovery
  SEO = 'seo',
  STORE_DISCOVERY = 'store_discovery',

  // Media events
  EVENTS = 'events',
  TV = 'tv',
  PODCAST = 'podcast',
  JOURNAL = 'journal',
}

export const subSources: Record<ANSWERS_SOURCE, ANSWER_PAID_ORIGIN[]> = {
  [ANSWERS_SOURCE.MATERNITY_BOX]: [],
  [ANSWERS_SOURCE.INSTAGRAM]: [],
  [ANSWERS_SOURCE.OTHER]: [],
  [ANSWERS_SOURCE.PAID]: [
    ANSWER_PAID_ORIGIN.FACEBOOK,
    ANSWER_PAID_ORIGIN.INSTAGRAM,
    ANSWER_PAID_ORIGIN.GOOGLE,
    ANSWER_PAID_ORIGIN.YOUTUBE,
    ANSWER_PAID_ORIGIN.TIKTOK,
    ANSWER_PAID_ORIGIN.SNAPCHAT,
    ANSWER_PAID_ORIGIN.OTHER,
  ],
  [ANSWERS_SOURCE.RECOMMENDATION]: [
    ANSWER_PAID_ORIGIN.FRIENDS_AND_FAMILY,
    ANSWER_PAID_ORIGIN.HEALTHCARE_PROFESSIONNAL,
    ANSWER_PAID_ORIGIN.MATERNITY,
    ANSWER_PAID_ORIGIN.OTHER,
  ],
  [ANSWERS_SOURCE.ONLINE_DISCOVERY]: [
    ANSWER_PAID_ORIGIN.SEO,
    ANSWER_PAID_ORIGIN.STORE_DISCOVERY,
    ANSWER_PAID_ORIGIN.OTHER,
  ],
  [ANSWERS_SOURCE.MEDIA_EVENTS]: [
    ANSWER_PAID_ORIGIN.EVENTS,
    ANSWER_PAID_ORIGIN.TV,
    ANSWER_PAID_ORIGIN.PODCAST,
    ANSWER_PAID_ORIGIN.JOURNAL,
    ANSWER_PAID_ORIGIN.OTHER,
  ],
};

export enum FormActions {
  USER_PROFILE = 'userProfile',
  EXTERNAL_DATA = 'externalData',
  NEXT_SCREEN = 'nextScreen',
  PAYWALL = 'paywall',
  PAYMENT_SCREEN = 'paymentScreen',
  NEXT_FORM = 'nextForm',
  END_ONBOARDING = 'endOnboarding',
  SCHEDULE_SUBSCRIPTION_UPDATE = 'scheduleSubscriptionUpdate',
  CHURN_SUBSCRIPTION = 'churnSubscription',
  EVENT_TRACKING = 'eventTracking',
}

export enum OnboardingMobileScreens {
  NOTIFICATION_OPT_IN_NO_PERSONA = 'optIn_NO_PERSONA',
  NOTIFICATION_OPT_IN_PREGNANCY_CONTENT = 'optIn_PREGNANCY_CONTENT',
  NOTIFICATION_OPT_IN_PREGNANCY_TOOLS = 'optIn_PREGNANCY_TOOLS',
  NOTIFICATION_OPT_IN_ALL_CHAT = 'optIn_ALL_CHAT',
  NOTIFICATION_OPT_IN_PARENT_CONTENT = 'optIn_PARENT_CONTENT',
  NOTIFICATION_OPT_IN_PARENT_TOOLS = 'optIn_PARENT_TOOLS',
  NOTIFICATION_OPT_IN_FERTILITY_CONTENT = 'optIn_FERTILITY_CONTENT',
  NOTIFICATION_OPT_IN_FERTILITY_TOOLS = 'optIn_FERTILITY_TOOLS',
  FAMILY_CREATION = 'familyCreation',
  FEATURES_BABY_3D = 'featureToolsBaby3D',
  FEATURES_SLEEP = 'featureToolsSleep',
  FEATURES_DIAPER = 'featureToolsDiaper',
  FEATURES_FEED = 'featureToolsFeed',
  FEATURES_CHILD_GROWTH_WEIGHT = 'featureToolsChildGrowthWeight',
  FEATURES_CHILD_GROWTH_HEIGHT = 'featureToolsChildGrowthHeight',
  FEATURES_CHILD_GROWTH_CRANIAL = 'featureToolsChildGrowthCranial',
  FEATURES_CONTENT_FERTILITY = 'featureContentFertility',
  FEATURES_CONTENT_CHILD = 'featureContentChild',
  FEATURES_CONTENT_PREGNANCY = 'featureContentPregnancy',
  FEATURE_FERTILITY = 'featureFertility',
  ONBOARDING_START = 'onboardingStart',
  ONBOARDING_INTRO_1 = 'onboardingIntro1',
}

export type ParsedNextFormAction = {
  action: FormActions.NEXT_FORM;
  arguments: { slug: string };
};

export type ParsedUserProfileAction = {
  action: FormActions.USER_PROFILE;
  arguments: Partial<UserAnswers>;
};

export type ParsedExternalDataAction = {
  action: FormActions.EXTERNAL_DATA;
  arguments: Record<string, string>;
};

export type ParsedNextScreenAction = {
  action: FormActions.NEXT_SCREEN;
  arguments: { screenName: OnboardingMobileScreens };
};

export type ParsedEndOnboardingAction = {
  action: FormActions.END_ONBOARDING;
  arguments: null;
};

export const PAYWALL_ARGUMENTS = [
  'SubscribeModalName',
  'promoCode',
  'targetTab',
] as const;
export type ParsedPaywallAction = {
  action: FormActions.PAYWALL;
  arguments: { [key in (typeof PAYWALL_ARGUMENTS)[number]]?: string } | null;
};

export type ParsedScheduleSubscriptionUpdateAction = {
  action: FormActions.SCHEDULE_SUBSCRIPTION_UPDATE;
  arguments: { planId: string; promotionCode?: string };
};

export type ParsedPaymentScreenAction = {
  action: FormActions.PAYMENT_SCREEN;
  arguments: { planId: string; promotionCode?: string };
};

export type ParsedChurnSubscriptionAction = {
  action: FormActions.CHURN_SUBSCRIPTION;
  arguments: null;
};

export type ParsedEventTrackingAction = {
  action: FormActions.EVENT_TRACKING;
  arguments: { event: string; [key: string]: string };
};

export type ParsedFormActions =
  | ParsedNextFormAction
  | ParsedUserProfileAction
  | ParsedNextScreenAction
  | ParsedPaywallAction
  | ParsedEndOnboardingAction
  | ParsedExternalDataAction
  | ParsedScheduleSubscriptionUpdateAction
  | ParsedChurnSubscriptionAction
  | ParsedEventTrackingAction;
