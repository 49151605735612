import { useCallback, useMemo, useState } from 'react';
import { views as Views } from 'react-big-calendar/lib/utils/constants';

import { countryCode } from '@boTypes/common';
import {
  PlanningAdditionalHours,
  PlanningAttribution,
  PlanningJob,
} from '@boTypes/planning';
import { EntitySlotType, Slot } from '@boTypes/slot';
import { StaffUser } from '@boTypes/staffUser';
import { User } from '@boTypes/user';
import { CalendarEvent } from '@components/Calendar';
import {
  useIntervalAdditionalHours,
  useIntervalAttributionsAndUpdaters,
  useIntervalSlots,
} from '@hooks/plannings';
import { useByIds } from '@hooks/useByIds';

import { useUnattributedSlots } from './hooks/useUnattributedSlots';
import { additionalHoursAppointment, slotAppointment } from './utils';
import { useSelector } from '../../store';
import { dayjsTz } from '../../utils/date';

export const useCalendarDateConfig = (defaultView?: Views[keyof Views]) => {
  const tz = useSelector((state) => state.timezone);
  let begin: string;
  let end: string;

  // This should be handle via onRangeChange but it's not called on first render
  if (!defaultView) {
    // act as if we were in day view
    begin = dayjsTz(undefined, tz).startOf('day').toISOString();
    end = dayjsTz(undefined, tz).endOf('day').toISOString();
  } else {
    if (defaultView === Views.DAY) {
      begin = dayjsTz(undefined, tz).startOf('day').toISOString();
      end = dayjsTz(undefined, tz).endOf('day').toISOString();
    } else if (defaultView === Views.WEEK) {
      begin = dayjsTz(undefined, tz).startOf('week').toISOString();
      end = dayjsTz(undefined, tz).endOf('week').toISOString();
    } else if (defaultView === Views.MONTH) {
      begin = dayjsTz(undefined, tz).startOf('month').toISOString();
      end = dayjsTz(undefined, tz).endOf('month').toISOString();
    } else if (defaultView === Views.AGENDA) {
      begin = dayjsTz(undefined, tz).startOf('month').toISOString();
      end = dayjsTz(undefined, tz).endOf('month').toISOString();
    }
  }

  const [view, setView] = useState<Views[keyof Views]>(defaultView);
  const [currentRange, setCurrentRange] = useState<[string, string]>([
    begin,
    end,
  ]);

  return { view, setView, currentRange, setCurrentRange };
};

const getJobsAgendaData = (
  slotsById: Record<string, Slot>,
  attributions: (PlanningAttribution & { slot?: Slot })[],
  staffUsersById: Record<string, User>,
  additionalHours: PlanningAdditionalHours[],
  unattributedSlots: Slot[],
  currentUserId?: StaffUser['id'],
) => {
  return (
    (attributions ?? [])
      .filter(({ slotId }) => slotsById[slotId]) // handle case where slots are not yet fetched
      .map(({ slotId, staffUserId, onCallActivated }) =>
        slotAppointment(
          slotsById[slotId],
          staffUserId,
          onCallActivated,
          staffUsersById,
          undefined,
          currentUserId,
        ),
      )
      .concat(additionalHoursAppointment(additionalHours, staffUsersById))
      .concat(
        unattributedSlots.map((slot) =>
          slotAppointment(slot, undefined, false, staffUsersById),
        ),
      ) ?? []
  );
};

const filterUser =
  (userId: number) =>
  ({ staffUserId }: { staffUserId: number }) =>
    !staffUserId || staffUserId === userId;

const getUserSpecificAgendaData = (
  userId: number,
  slotsById: Record<string, Slot>,
  attributions: (PlanningAttribution & { slot?: Slot })[],
  staffUsersById: Record<string, User>,
  additionalHours: PlanningAdditionalHours[],
) => {
  return (
    (attributions ?? [])
      .filter(filterUser(userId)) // keep only searched user
      .filter(({ slotId }) => slotsById[slotId]) // handle case where slots are not yet fetched
      .map(({ slotId, staffUserId, onCallActivated }) =>
        slotAppointment(
          slotsById[slotId],
          staffUserId,
          onCallActivated,
          staffUsersById,
        ),
      )
      .concat(
        additionalHoursAppointment(
          additionalHours?.filter(filterUser(userId)),
          staffUsersById,
        ),
      ) ?? []
  );
};

export const usePlanningData = ({
  selectedUserId,
  selectedJobs,
  staffUsers,
  range,
  displayType,
  countries,
}: {
  selectedUserId?: User['id'];
  selectedJobs: PlanningJob[];
  staffUsers: Record<User['id'], User>;
  range: [string, string];
  displayType: 'job' | 'user';
  countries?: countryCode[] | 'all';
}) => {
  const jobs =
    displayType === 'user' ? Object.values(PlanningJob) : selectedJobs;
  const userId = useSelector((state) => state.user?.userId);
  const { data: slots, isLoading: isLoadingSlots } = useIntervalSlots({
    jobs,
    range,
    countries: countries === 'all' ? undefined : countries,
  });

  const slotsById = useByIds(slots);

  const {
    data: attributions,
    isLoading: isLoadingAttributions,
    onLeaveAttribution,
    onRequestAttribution,
  } = useIntervalAttributionsAndUpdaters({
    jobs,
    userId,
    slotsById,
    range,
  });
  const {
    data: additionalHours,
    isLoading: isLoadingAdditionalHours,
    setCache: setAdditionalHours,
  } = useIntervalAdditionalHours(jobs, range);

  const unattributedSlots = useUnattributedSlots(slots, attributions);

  const agendaData = useMemo(
    () =>
      displayType === 'user' && selectedUserId
        ? getUserSpecificAgendaData(
            selectedUserId,
            slotsById,
            attributions,
            staffUsers,
            additionalHours,
          )
        : getJobsAgendaData(
            slotsById,
            attributions,
            staffUsers,
            additionalHours,
            unattributedSlots,
            userId,
          ),
    [
      additionalHours,
      attributions,
      selectedUserId,
      slotsById,
      staffUsers,
      unattributedSlots,
      displayType,
      userId,
    ],
  );

  return {
    agendaData,
    isLoading:
      isLoadingSlots || isLoadingAttributions || isLoadingAdditionalHours,
    onLeaveAttribution,
    onRequestAttribution,
    setAdditionalHours,
  };
};

export const useSlotActions = () => {
  const userId = useSelector((state) => state.user?.userId);
  const [selectedSlot, setSelectedSlot] = useState<CalendarEvent>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const updateAction = useCallback(() => false, []);
  const deleteAction = useCallback(
    (slot) => {
      if (slot.entityType === EntitySlotType.additionalHour) {
        return slot.staffUserId === userId && !slot.validated;
      }
      return false;
    },
    [userId],
  );
  return {
    displayActions: { delete: deleteAction, update: updateAction },
    callbackActions: {
      remove: (slot) => {
        setSelectedSlot(slot);
        setOpenDeleteModal(true);
      },
      update: () => {},
      create: () => {},
    },
    openDeleteModal,
    setOpenDeleteModal,
    selectedSlot,
  };
};
