export const formatSearchFilters = ({
  category,
  ageLevel,
  pregnancy,
  keys: { category: cKey, ageLevel: aKey } = {
    category: 'categoryIds',
    ageLevel: 'ageLevelIds',
  },
  ids,
}: {
  category?: number | number[];
  ageLevel?: number | number[];
  pregnancy?: boolean | null;
  keys?: { category?: string; ageLevel?: string; excludedAgeLevels?: string };
  ids?: number[];
}) => {
  let filtering: string[] = [];
  let result = '';

  if (category) {
    if (Array.isArray(category)) {
      if (category.length) {
        filtering.push(
          `(${category.map((c) => `${cKey} = '${c}'`).join(' OR ')})`,
        );
      }
    } else {
      filtering.push(`${cKey} = '${category}'`);
    }
  }
  if (ageLevel) {
    if (Array.isArray(ageLevel)) {
      if (ageLevel.length) {
        filtering.push(ageLevel.map((c) => `${aKey} = '${c}'`).join(' OR '));
      }
    } else {
      filtering.push(`${aKey} = '${ageLevel}'`);
    }
  }

  if (pregnancy !== null) {
    if (pregnancy) {
      filtering.push(`isPregnancy =  ${pregnancy}`);
    } else {
      filtering.push(`isChild = ${!pregnancy}`);
    }
  }

  if (ids) {
    filtering.push(`id IN [${ids.join(',')}]`);
  }

  if (filtering.length) {
    result = filtering.join(' AND ');
  }

  return result;
};
