export type BrandedUUID<T> =
  `${string}-${string}-${string}-${string}-${string}` & {
    __brand: T;
  };

export enum PricingType {
  DOCUMENT = 'document',
  HOURLY = 'hourly',
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
}

export enum TranslationScope {
  MIDWIFE = 'midwife',
  TRANSLATOR = 'translator',
  GYNECOLOGIST = 'gynecologist',
  HEALTH_VISITOR = 'health_visitor',
  PEDIATRIC_NURSE = 'pediatric_nurse',
  PEDIATRICIAN = 'pediatrician',
  OTHER = 'other',
}

export enum countryCode {
  FRANCE = 'FR',
  SPAIN = 'ES',
  UNITED_KINGDOM = 'GB',
}

export const CountryCodeToCountryFlag: Record<countryCode, string> = {
  [countryCode.FRANCE]: '🇫🇷',
  [countryCode.SPAIN]: '🇪🇸',
  [countryCode.UNITED_KINGDOM]: '🇬🇧',
};
