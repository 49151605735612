import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { StaffUser } from '@boTypes/staffUser';
import { useOneKindList } from '@hooks/cms';
import { useMutation, useQuery } from '@hooks/queryWrappers';
import { useSearchKey } from '@utils/search';

import { fromTranslationTypeToContentType } from './utils';
import { setTargetLocale } from '../../../../store/contentTranslationFilters';
import { useContentFromTasks } from '../../../common/contentTranslation/hooks';
import {
  ContentType,
  TaskStatus,
  TranslationScope,
  TranslationTask,
  TranslationTaskStatusComments,
  UserLocale,
} from '../../../common/contentTranslation/types';

export type Inputs = {
  referenceLocale: UserLocale;
  targetLocale: UserLocale;
  type: ContentType;
  isPregnancy: boolean;
  isChild: boolean;
  scope: TranslationScope;
};

type SendContentToTranslationPayload = {
  referenceLocale: UserLocale;
  targetLocale: UserLocale;
  type: ContentType;
  documentIds: number[];
  scope: TranslationScope;
};

export const useItemsAdded = (
  type: ContentType,
  fromLocale: UserLocale,
  targetLocale: UserLocale,
) => {
  return useQuery<number[]>(
    ['contentTranslation', 'itemsAdded', type, fromLocale, targetLocale],
    () => ({
      method: 'GET',
      url: `/api/admin/content-translation/documents/${fromLocale}/to/${targetLocale}/${type}`,
    }),
    { enabled: !!type && fromLocale !== targetLocale },
  );
};

export const useSendContentToTranslation = () => {
  const { mutateAsync } = useMutation<
    any,
    any,
    SendContentToTranslationPayload
  >(
    ['add-content-translation-documents'],
    (data: SendContentToTranslationPayload) => ({
      method: 'POST',
      url: '/api/admin/content-translation/documents',
      data,
    }),
  );

  return mutateAsync;
};

export const useLibraryForTranslation = ({
  targetLocale: userTargetLocale,
}: {
  targetLocale: UserLocale;
}) => {
  const [searchedText, setSearchedText] = useState('');
  const [documentIds, setDocumentIds] = useState<number[]>([]);
  const [filterDone, setFilterDone] = useState(false);
  const dispatch = useDispatch();

  const { control, handleSubmit, watch } = useForm<Inputs>({
    defaultValues: {
      referenceLocale: UserLocale.FR,
      targetLocale: userTargetLocale ?? UserLocale.EN,
      type: ContentType.DAILY_TIPS,
      isPregnancy: false,
      isChild: false,
      scope: TranslationScope.OTHER,
    },
  });

  const sendContentToTranslation = useSendContentToTranslation();

  const [selectedContent, isChild, isPregnancy, referenceLocale, targetLocale] =
    watch([
      'type',
      'isChild',
      'isPregnancy',
      'referenceLocale',
      'targetLocale',
    ]);

  useEffect(() => {
    dispatch(setTargetLocale({ targetLocale }));
  }, [targetLocale, dispatch]);

  const pregnancy = useMemo(() => {
    if ((!isPregnancy && !isChild) || (isPregnancy && isChild)) {
      return null;
    } else if (isPregnancy) {
      return true;
    }
    return false;
  }, [isChild, isPregnancy]);

  const selectedContentCMSType =
    fromTranslationTypeToContentType(selectedContent);

  const searchKey = useSearchKey();
  const {
    data: items,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isPending: isOneKindListPending,
  } = useOneKindList(selectedContentCMSType, {
    search: searchedText,
    searchKey,
    pregnancy,
    fetchWholeList: true,
  });

  // OUR SEARCH KEY DOES NOT ALLOW DOCUMENT FETCHING FOR NOW
  // const { data: items, isPending: isOneKindListPending } = useOneKindWholeList(
  //   selectedContentCMSType,
  //   {
  //     searchKey,
  //   },
  // );

  const { data: itemsAlreadyAdded } = useItemsAdded(
    selectedContent,
    referenceLocale,
    targetLocale,
  );

  return {
    control,
    documentIds,
    fetchNextPage,
    filterDone,
    handleSubmit,
    hasNextPage,
    isChild,
    isFetchingNextPage,
    isOneKindListPending,
    isPregnancy,
    items,
    itemsAlreadyAdded: itemsAlreadyAdded || [],
    pregnancy,
    searchedText,
    selectedContent,
    selectedContentCMSType,
    sendContentToTranslation,
    setDocumentIds,
    setFilterDone,
    setSearchedText,
  };
};

export const useAdminDashboard = (
  status?: TaskStatus,
  approverId?: TranslationTask['approverId'],
) => {
  const { data: tasks, isPending } = useQuery<TranslationTask[]>(
    ['admin', 'contentTranslation', 'tasks'],
    () => ({
      method: 'GET',
      url: '/api/admin/content-translation/tasks',
      params: {
        filter: {
          status,
          approverId,
        },
      },
    }),
  );

  return { tasks, isPending };
};

export const useRefreshOwnership = () => {
  const { mutateAsync } = useMutation(
    ['contentTranslation', 'refreshOwnership'],
    (data: { taskIds: TranslationTask['id'][] }) => ({
      method: 'POST',
      url: '/api/admin/content-translation/documents/refresh-ownership',
      data,
    }),
  );

  return { refreshOwnership: mutateAsync };
};

export const useTasksToAttribute = ({
  targetLocale,
}: {
  targetLocale?: UserLocale;
} = {}) => {
  const {
    data: tasksToAttribute,
    isPending,
    refetch,
  } = useQuery<TranslationTask[]>(
    ['contentTranslation', 'tasks', 'toAttribute', targetLocale],
    () => ({
      method: 'GET',
      url: '/api/admin/content-translation/tasks/toAttribute',
      params: { filter: JSON.stringify({ targetLocale }) },
    }),
  );

  const { mutateAsync: updateTask, isPending: isAttributionPending } =
    useMutation(
      ['contentTranslation', 'updateTask'],
      (data: { taskIds: TranslationTask['id'][] }) => ({
        method: 'POST',
        url: '/api/admin/content-translation/tasks/toAttribute',
        data,
      }),
    );

  const { mutateAsync: retryPostCreateActions } = useMutation(
    ['contentTranslation', 'retryPostCreateActions'],
    (data: { taskIds: TranslationTask['id'][] }) => ({
      method: 'POST',
      url: '/api/admin/content-translation/documents/retry-create',
      data,
    }),
  );

  const { multiSearchIdMapping } = useContentFromTasks(tasksToAttribute);

  return {
    tasksToAttribute,
    isAttributionPending,
    isPending,
    multiSearchIdMapping,
    updateTask,
    refetch,
    retryPostCreateActions,
  };
};

export const useTasksToValidate = ({
  targetLocale,
  approverId,
}: {
  targetLocale?: UserLocale;
  approverId?: StaffUser['id'];
} = {}) => {
  const {
    data: tasksToValidate,
    isPending,
    refetch,
  } = useQuery<TranslationTask[]>(
    ['contentTranslation', 'tasks', 'toValidate', targetLocale, approverId],
    () => ({
      method: 'GET',
      url: '/api/admin/content-translation/tasks',
      params: {
        filter: JSON.stringify({
          status: TaskStatus.HUMAN_REVIEWED,
          targetLocale,
        }),
      },
    }),
  );
  const { multiSearchIdMapping } = useContentFromTasks(tasksToValidate);

  return { tasksToValidate, isPending, refetch, multiSearchIdMapping };
};

export const useTasksToUpload = ({
  targetLocale,
  approverId,
}: {
  targetLocale?: UserLocale;
  approverId?: StaffUser['id'];
} = {}) => {
  const {
    data: tasksToUpload,
    isPending,
    refetch,
  } = useQuery<TranslationTask[]>(
    ['contentTranslation', 'tasks', 'toUpload', targetLocale, approverId],
    () => ({
      method: 'GET',
      url: '/api/admin/content-translation/tasks',
      params: {
        filter: JSON.stringify({
          statuses: [TaskStatus.VALIDATED, TaskStatus.CMS_UPLOAD_FAILED],
          targetLocale,
        }),
      },
    }),
  );

  const { mutateAsync: setTaskAsUploaded } = useMutation(
    ['admin', 'contentTranslation', 'setTaskAsUploaded'],
    (taskIds: TranslationTask['id'][]) => ({
      method: 'PUT',
      url: `/api/admin/content-translation/tasks/batch-upload-cms`,
      data: { taskIds },
    }),
  );

  const { multiSearchIdMapping } = useContentFromTasks(tasksToUpload);

  return {
    tasksToUpload,
    isPending,
    refetch,
    multiSearchIdMapping,
    setTaskAsUploaded,
  };
};

export const useTasksToComplete = (
  {
    targetLocale,
    approverId,
    filteredOnPairReviewer = true,
  }: {
    targetLocale?: UserLocale;
    approverId?: StaffUser['id'];
    filteredOnPairReviewer?: boolean;
  } = {},
  { enabled }: { enabled: boolean } = { enabled: true },
) => {
  const {
    data: tasksToComplete,
    isPending,
    refetch,
  } = useQuery<TranslationTask[]>(
    [
      'contentTranslation',
      'tasks',
      'toComplete',
      targetLocale,
      approverId,
      filteredOnPairReviewer,
    ],
    () => ({
      method: 'GET',
      url: '/api/admin/content-translation/tasks',
      params: {
        filter: JSON.stringify({
          statuses: [TaskStatus.CMS_AUTO_UPLOADED],
          targetLocale,
          filteredOnPairReviewer,
        }),
      },
    }),
    { enabled },
  );

  const { mutateAsync: setTaskAsCompleted } = useMutation(
    ['admin', 'contentTranslation', 'setTaskAsCompleted'],
    (taskIds: TranslationTask['id'][]) => ({
      method: 'PUT',
      url: `/api/admin/content-translation/tasks/batch-complete`,
      data: { taskIds },
    }),
  );

  const { multiSearchIdMapping } = useContentFromTasks(tasksToComplete);

  return {
    tasksToComplete,
    isPending,
    refetch,
    multiSearchIdMapping,
    setTaskAsCompleted,
  };
};

export const useDoneTasks = (
  {
    targetLocale,
  }: {
    targetLocale?: UserLocale;
  } = {},
  { enabled }: { enabled: boolean } = { enabled: true },
) => {
  const { data: doneTasks, isPending } = useQuery<TranslationTask[]>(
    ['contentTranslation', 'tasks', 'done', targetLocale],
    () => ({
      method: 'GET',
      url: '/api/admin/content-translation/tasks',
      params: {
        filter: JSON.stringify({
          statuses: [TaskStatus.CMS_UPLOADED],
          targetLocale,
        }),
      },
    }),
    { enabled },
  );

  const { multiSearchIdMapping } = useContentFromTasks(doneTasks);

  return {
    doneTasks,
    isPending,
    multiSearchIdMapping,
  };
};

export const useTasksInProgress = ({
  targetLocale,
  approverId,
}: {
  targetLocale?: UserLocale;
  approverId?: StaffUser['id'];
} = {}) => {
  const {
    data: tasksInProgress,
    isPending,
    refetch,
  } = useQuery<TranslationTask[]>(
    ['contentTranslation', 'tasks', 'inProgress', targetLocale, approverId],
    () => ({
      method: 'GET',
      url: '/api/admin/content-translation/tasks',
      params: {
        filter: JSON.stringify({ targetLocale, approverId }),
      },
    }),
  );
  const { multiSearchIdMapping } = useContentFromTasks(tasksInProgress);

  return {
    multiSearchIdMapping,
    tasksInProgress,
    isPending,
    refetch,
  };
};

export const useTasksSummary = ({
  targetLocale,
}: {
  targetLocale?: UserLocale;
}) => {
  const { data: summary, isPending } = useQuery<any>(
    ['contentTranslation', 'tasks', 'summary', targetLocale],
    () => ({
      method: 'GET',
      url: '/api/admin/content-translation/tasks/summary',
      params: { targetLocale },
    }),
  );

  return { summary, isPending };
};

export const useTaskStatus = (taskId: TranslationTask['id']) => {
  const { mutateAsync: validateTask } = useMutation(
    ['admin', 'contentTranslation', 'validateTask'],
    (data: TranslationTaskStatusComments) => ({
      method: 'PUT',
      url: `/api/admin/content-translation/tasks/${taskId}/validate`,
      data,
    }),
  );

  const { mutateAsync: requestChanges } = useMutation(
    ['admin', 'contentTranslation', 'requestChanges'],
    (data: TranslationTaskStatusComments) => ({
      method: 'PUT',
      url: `/api/admin/content-translation/tasks/${taskId}/request-changes`,
      data,
    }),
  );

  return { validateTask, requestChanges };
};

export const useTranslatorUsers = (targetLocale?: UserLocale) => {
  const { data: users } = useQuery<any[]>(
    ['contentTranslation', 'translatorUsers', targetLocale],
    () => ({
      method: 'GET',
      url: '/api/users/translators',
      params: { locale: targetLocale },
    }),
  );

  return { users };
};
