import { useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { TextInput } from '@components/forms/textInput';
import { Close } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { FormStep } from '@teammay/form-core';

export type FormStepForm = Omit<FormStep, 'id' | 'links'>;

export const StepEdit = ({
  onBlur,
  onCancel,
  labelOnly = false,
}: {
  onBlur: () => void;
  onCancel: () => void;
  labelOnly?: boolean;
}) => {
  const { control } = useFormContext<FormStepForm>();
  const translate = useTranslate();

  return (
    <>
      {!labelOnly && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {translate('forms.step.formTitle')}
          </Typography>
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </Box>
      )}
      <TextInput
        control={control}
        name={'title'}
        label={translate('common.title')}
        onBlur={onBlur}
      />
    </>
  );
};
