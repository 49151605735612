import { ContentType } from './types';
import { ContentType as CMSContentType } from '../../../entities/library/contentTextList';

// Meilisearch UID
export enum IndexUid {
  APPOINTMENT_SUGGESTION = 'appointment-suggestions',
  DAILY_TIPS = 'daily-tips',
  FERTILITY_TIPS = 'ferti-tips',
  GUIDE = 'guides',
  MACRO = 'macros',
  MASTERCLASS = 'masterclasses',
  POST = 'posts',
  PREGNANCY_QUESTIONS = 'pregnancy-questions',
  PREGNANCY_WEEK_CONTENT = 'pregnancy-week-contents',
  PROGRAMS = 'programs',
  TODO_LIST_SUGGESTION = 'todo-list-suggestions',
  WEEKLY_TIPS = 'weekly-tips',
  ITEM_SUGGESTIONS = 'item-suggestions',
}

const mappingContentToIndex = {
  [ContentType.APPOINTMENT_SUGGESTIONS]: IndexUid.APPOINTMENT_SUGGESTION,
  [ContentType.DAILY_TIPS]: IndexUid.DAILY_TIPS,
  [ContentType.FERTILITY_TIPS]: IndexUid.FERTILITY_TIPS,
  [ContentType.GUIDE]: IndexUid.GUIDE,
  [ContentType.MACRO]: IndexUid.MACRO,
  [ContentType.MASTERCLASS]: IndexUid.MASTERCLASS,
  [ContentType.POST]: IndexUid.POST,
  [ContentType.PREGNANCY_QUESTIONS]: IndexUid.PREGNANCY_QUESTIONS,
  [ContentType.PREGNANCY_WEEK_CONTENT]: IndexUid.PREGNANCY_WEEK_CONTENT,
  [ContentType.TODO_LIST_SUGGESTIONS]: IndexUid.TODO_LIST_SUGGESTION,
  [ContentType.WEEKLY_TIPS]: IndexUid.WEEKLY_TIPS,
};

const mappingTranslationToContent = {
  [ContentType.APPOINTMENT_SUGGESTIONS]: CMSContentType.APPOINTMENT_SUGGESTIONS,
  [ContentType.DAILY_TIPS]: CMSContentType.DAILY_TIPS,
  [ContentType.FERTILITY_TIPS]: CMSContentType.FERTI_TIPS,
  [ContentType.GUIDE]: CMSContentType.GUIDES,
  [ContentType.MACRO]: CMSContentType.MACRO,
  [ContentType.MASTERCLASS]: CMSContentType.MASTERCLASSES,
  [ContentType.POST]: CMSContentType.POSTS,
  [ContentType.PREGNANCY_QUESTIONS]: CMSContentType.PREGNANCY_QUESTIONS,
  [ContentType.PREGNANCY_WEEK_CONTENT]: CMSContentType.PREGNANCY_WEEK_CONTENT,
  [ContentType.TODO_LIST_SUGGESTIONS]: CMSContentType.TODO_LIST_SUGGESTIONS,
  [ContentType.WEEKLY_TIPS]: CMSContentType.WEEKLY_TIPS,
};

export const translationTypeToCMSContentType = (contentType: ContentType) =>
  mappingTranslationToContent[contentType];

const mappingIndexToContent = {
  [IndexUid.APPOINTMENT_SUGGESTION]: CMSContentType.APPOINTMENT_SUGGESTIONS,
  [IndexUid.DAILY_TIPS]: CMSContentType.DAILY_TIPS,
  [IndexUid.FERTILITY_TIPS]: CMSContentType.FERTI_TIPS,
  [IndexUid.GUIDE]: CMSContentType.GUIDES,
  [IndexUid.MACRO]: CMSContentType.MACRO,
  [IndexUid.MASTERCLASS]: CMSContentType.MASTERCLASSES,
  [IndexUid.POST]: CMSContentType.POSTS,
  [IndexUid.PREGNANCY_QUESTIONS]: CMSContentType.PREGNANCY_QUESTIONS,
  [IndexUid.PREGNANCY_WEEK_CONTENT]: CMSContentType.PREGNANCY_WEEK_CONTENT,
  [IndexUid.TODO_LIST_SUGGESTION]: CMSContentType.TODO_LIST_SUGGESTIONS,
  [IndexUid.WEEKLY_TIPS]: CMSContentType.WEEKLY_TIPS,
};

export const contentTypeToIndexUid = (contentType: ContentType) =>
  mappingContentToIndex[contentType] || '';

export const indexUidToContentType = (indexUid: IndexUid) =>
  mappingIndexToContent[indexUid];

// CMS UID for Urls
export enum ContentUid {
  APPOINTMENT_SUGGESTION = 'appointment-suggestion',
  DAILY_TIPS = 'daily-tip',
  FERTILITY_TIPS = 'ferti-tip',
  GUIDE = 'guide',
  MACRO = 'macro',
  MASTERCLASS = 'masterclass',
  POST = 'post',
  PREGNANCY_QUESTION = 'pregnancy-question',
  PREGNANCY_WEEK_CONTENT = 'pregnancy-week-content',
  TODO_LIST_SUGGESTION = 'todo-list-suggestion',
  WEEKLY_TIPS = 'weekly-tip',
}

const mappingContentToUid = {
  [ContentType.APPOINTMENT_SUGGESTIONS]: ContentUid.APPOINTMENT_SUGGESTION,
  [ContentType.DAILY_TIPS]: ContentUid.DAILY_TIPS,
  [ContentType.FERTILITY_TIPS]: ContentUid.FERTILITY_TIPS,
  [ContentType.GUIDE]: ContentUid.GUIDE,
  [ContentType.MACRO]: ContentUid.MACRO,
  [ContentType.MASTERCLASS]: ContentUid.MASTERCLASS,
  [ContentType.POST]: ContentUid.POST,
  [ContentType.PREGNANCY_QUESTIONS]: ContentUid.PREGNANCY_QUESTION,
  [ContentType.PREGNANCY_WEEK_CONTENT]: ContentUid.PREGNANCY_WEEK_CONTENT,
  [ContentType.TODO_LIST_SUGGESTIONS]: ContentUid.TODO_LIST_SUGGESTION,
  [ContentType.WEEKLY_TIPS]: ContentUid.WEEKLY_TIPS,
};

export const contentTypeToCMSIndexUid = (contentType: ContentType) =>
  mappingContentToUid[contentType] || '';

export const cmsUidFromType = (contentType: ContentType) =>
  `api::${contentTypeToCMSIndexUid(contentType)}.${contentTypeToCMSIndexUid(contentType)}/`;
