import dayjs from 'dayjs';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useGetList, useTranslate } from 'react-admin';

import { ATTRIBUTION_OPTIONS_ENUM } from '@boTypes/subject';
import { useActiveTab } from '@hooks/subjects';
import { useGetVaryingMany } from '@hooks/useGetVaryingMany';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
  Button,
  List,
  Typography,
  SxProps,
  Slide,
  Paper,
  IconButton,
  Accordion,
  AccordionDetails,
} from '@mui/material';
import { Box } from '@mui/system';

import { AccordionSummaryWrapper } from './handover.utils';
import { HandoverCard } from './handoverCard';
import { HandoverEditForm, HandoverCreateForm } from './handoverForm';
import { SubjectContent } from './handoverSubject';
import { logHandoverCreateButton } from '../../analytics/events';
import { DiscussionContext } from '../../common';
import { useDiscussionDetail } from '../../hooks/discussion';
import { usePatientHistory } from '../../hooks/history';
import { DiscussionContextProvider } from '../../hooks/useDiscussionContext';
import { usePatientDetail } from '../../hooks/usePatientDetail';
import { colorTokens } from '../../themes';
import { Category, Handover } from '../../types';

export const HandoverList = ({ sx = {} }: { sx?: SxProps }) => {
  const t = useTranslate();
  const contentRef = useRef<HTMLElement>(null);
  const discussion = useDiscussionDetail();
  const { history, fetchNextPage, hasNextPage, revives } =
    usePatientHistory(discussion);
  const [editedHandover, setEditedHandover] = useState<Handover | null>(null);
  const [create, setCreate] = useState<boolean>(false);

  const discussionContext = discussion?.kidId
    ? DiscussionContext.NURSE
    : DiscussionContext.MIDWIFE;

  const onClose = useCallback(() => {
    setEditedHandover(null);
    setCreate(false);
  }, []);

  const patientDetail = usePatientDetail(discussion);

  const referenceDate = useMemo(() => {
    if (patientDetail.type === 'adult') {
      if (!patientDetail.data?.pregnancy?.expectedEnd) {
        return;
      }
      const endDate = dayjs(patientDetail.data?.pregnancy?.expectedEnd);
      return endDate.subtract(41, 'weeks');
    }

    return dayjs(patientDetail.data?.birthDay);
  }, [patientDetail.data, patientDetail.type]);

  const { data: categoryList } = useGetList<Category>(
    'categories',
    {
      pagination: { page: 1, perPage: 1000 },
    },
    {
      gcTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  );

  // fetch all staff users at once
  const handoverStaffUsers = useMemo(
    () =>
      history?.flatMap((subject) =>
        subject.type !== 'Consultation'
          ? (subject?.handovers
              ?.map((h) => h.userId)
              .concat(
                subject?.handovers?.map(
                  (h) => h?.revive?.attributedStaffUserId,
                ),
              )
              .filter(Boolean) ?? [])
          : [],
      ),
    [history],
  );
  useGetVaryingMany('users', handoverStaffUsers);
  const activeTab = useActiveTab();

  const defaultReviveExpand = activeTab === ATTRIBUTION_OPTIONS_ENUM.REVIVE;
  const [expandRevive, setExpandRevive] = useState(defaultReviveExpand);

  return (
    <DiscussionContextProvider discussionContext={discussionContext}>
      <Box
        ref={contentRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'hidden',
          boxShadow: `0 -3px 5px -4px ${colorTokens.content.subtleBackground}, 0 -5px 5px -5px ${colorTokens.content.subtleBackground}`,
          ...sx,
        }}
      >
        <Box
          sx={{
            padding: '0.5rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            boxShadow: `0 3px 5px -4px ${colorTokens.content.subtleBackground}, 0 -8px 8px -8px ${colorTokens.content.subtleBackground}`,
            zIndex: '2',
          }}
        >
          <Typography
            sx={{ flex: 0, fontSize: '1rem', fontWeight: 'bold' }}
            variant="body1"
          >
            {t('history.title')}
          </Typography>
          <IconButton
            size="small"
            onClick={() => {
              logHandoverCreateButton(discussion?.lastSubject?.id);
              setCreate(true);
            }}
            disabled={!discussion?.lastSubject?.id}
            sx={{
              fontSize: '0.75rem',
              marginLeft: '0.5rem',
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
        <List
          className="scrollbar"
          sx={{
            flex: 1,
            overflowY: 'auto',
            padding: '0 0.25rem 0 0.5rem',
          }}
        >
          {Boolean(revives?.length) && (
            <Accordion
              disableGutters
              sx={{
                flex: 1,
                border: `1px solid transparent`,
              }}
              elevation={0}
              defaultExpanded={defaultReviveExpand}
              expanded={expandRevive}
              onChange={(_, expanded) => setExpandRevive(expanded)}
            >
              <AccordionSummaryWrapper
                expandIcon={
                  <ArrowForwardIosSharpIcon sx={{ fontSize: '0.875rem' }} />
                }
              >
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 500,
                  }}
                >
                  {t('history.revives', { smart_count: revives?.length })}
                </Typography>
              </AccordionSummaryWrapper>
              <AccordionDetails sx={{ padding: 0 }}>
                {revives?.map((handoverWithRevive) => (
                  <HandoverCard
                    key={'revive-' + handoverWithRevive.id}
                    handover={handoverWithRevive}
                    canEdit={true}
                    onEditClick={setEditedHandover}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )}
          {history?.map((subject) => (
            <SubjectContent
              key={`${subject.id}-${subject.type}`}
              subject={subject}
              canEdit={subject.id === discussion?.lastSubject?.id}
              onEditClick={setEditedHandover}
              referenceDate={referenceDate}
              categoryList={categoryList}
            />
          ))}
          {hasNextPage && (
            <Button size="small" onClick={() => fetchNextPage()}>
              {t('common.loadMore')}
            </Button>
          )}
        </List>
        <Slide
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 3,
          }}
          direction="left"
          in={Boolean(editedHandover || create)}
          mountOnEnter
          unmountOnExit
          container={contentRef.current}
        >
          <Paper sx={{ height: '100%', width: '100%' }} elevation={4}>
            {create && (
              <HandoverCreateForm
                subjectId={
                  discussion.lastSubject?.empty
                    ? history?.[0]?.id
                    : discussion.lastSubject?.id
                }
                onClose={onClose}
                activeRevives={revives?.length ?? 0}
              />
            )}
            {editedHandover && (
              <HandoverEditForm
                handover={editedHandover}
                onClose={onClose}
                subjectId={discussion.lastSubject?.id}
              />
            )}
          </Paper>
        </Slide>
      </Box>
    </DiscussionContextProvider>
  );
};
