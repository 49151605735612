import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useNotify, usePermissions, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { Child } from '@boTypes/child';
import { Family } from '@boTypes/family';
import { SelectInput } from '@components/forms/selectInput';
import { TextInput } from '@components/forms/textInput';
import { DialogTitle } from '@components/generic/Dialog';
import { useMutation } from '@hooks/queryWrappers';
import { Upload } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  MenuItem,
} from '@mui/material';

import { Roles } from '../../../types/user';

export enum ExportableTools {
  SLEEP = 'SLEEP',
  FEEDING = 'FEEDING',
  DIAPER = 'DIAPER',
  HEIGHT = 'HEIGHT',
  WEIGHT = 'WEIGHT',
  CRANIAL_PERIMETER = 'CRANIAL_PERIMETER',
}

interface ExportFormData {
  childIds: number[];
  tools: ExportableTools[];
  lang: string;
  familyId: Family['id'];
}

const ExportForm = ({
  familyId,
  children,
  lang = 'fr',
  isOpen = false,
  setOpen,
}: {
  familyId: Family['id'];
  children: Child[];
  lang?: string;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const translate = useTranslate();
  const notify = useNotify();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      childIds: children.map((child) => child.id),
      tools: Object.values(ExportableTools),
      lang,
      familyId,
    },
  });

  const { mutate: exportData } = useMutation(
    ['export', 'tools'],
    (data: ExportFormData) => ({
      method: 'post',
      url: `/api/tools/export`,
      data,
      responseType: 'blob',
    }),
    {
      onSuccess(res: BlobPart) {
        setOpen(false);
        notify(`tools.exportSuccess`, { type: 'success' });
        // Download file
        const blob = new Blob([res], { type: 'application/zip' });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = `tools-export-${Date.now()}.zip`;
        link.click();
        window.URL.revokeObjectURL(url);
        setIsLoading(false);
      },
      onError() {
        setIsLoading(false);
        notify('tools.exportError', { type: 'error' });
      },
    },
  );

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: ExportFormData) => {
    setIsLoading(true);
    exportData(data);
  };

  const toolsOptions = useMemo(
    () => [
      {
        value: ExportableTools.SLEEP,
        label: translate('tools.sleep'),
      },
      {
        value: ExportableTools.FEEDING,
        label: translate('tools.feeding'),
      },
      {
        value: ExportableTools.DIAPER,
        label: translate('tools.diapers'),
      },
      {
        value: ExportableTools.HEIGHT,
        label: translate('tools.height'),
      },
      {
        value: ExportableTools.WEIGHT,
        label: translate('tools.weight.title'),
      },
      {
        value: ExportableTools.CRANIAL_PERIMETER,
        label: translate('tools.cranial'),
      },
    ],
    [translate],
  );

  const childrenOptions = useMemo(
    () =>
      children?.map((child) => ({
        value: child.id,
        label: `${child.firstName} ${child.lastName}`,
      })) ?? [],
    [children],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={() => setOpen(false)}
    >
      <DialogTitle onClose={() => setOpen(false)}>
        {translate('common.export')}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectInput
            variant="standard"
            control={control}
            name="tools"
            multiple
            label={translate('tools.title')}
            fullWidth
            required
          >
            {toolsOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </SelectInput>
          <SelectInput
            variant="standard"
            control={control}
            name="childIds"
            multiple
            fullWidth
            required
            label={translate('tools.choseChildren')}
          >
            {childrenOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
            {translate('tools.choseChildren')}
          </SelectInput>
          <TextInput
            sx={{ display: 'none' }}
            name="lang"
            control={control}
            hidden
          />
          <TextInput
            sx={{ display: 'none' }}
            name="familyId"
            control={control}
            hidden
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={() => setOpen(false)}>
              {translate('common.cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ ml: 2 }}
              disabled={isLoading}
            >
              {isLoading && <CircularProgress size={14} sx={{ mr: 1 }} />}
              {translate('common.export')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export const ExportSection = ({
  familyId,
  children,
  lang = 'fr',
}: {
  familyId: Family['id'];
  children: Child[];
  lang?: string;
}) => {
  const translate = useTranslate();
  const [openModal, setModal] = useState(false);
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(Roles.ADMIN) ?? false;

  if (!isAdmin) {
    return null;
  }
  return (
    <>
      <Button
        startIcon={<Upload />}
        onClick={() => setModal(true)}
        variant="text"
        size="small"
      >
        {translate('common.export')}
      </Button>
      {open && familyId && (
        <ExportForm
          familyId={familyId}
          children={children}
          lang={lang}
          isOpen={openModal}
          setOpen={setModal}
        />
      )}
    </>
  );
};
