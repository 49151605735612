import { useTranslate } from 'react-admin';

import { Child } from '@boTypes/child';
import { Close } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import { useFeedingAndSleep } from './hooks';
import { legend } from './legend';
import { Statistics } from './statistics';
import WeekCalendar from '../WeekCalendar';

export const FeedingAndSleep = ({
  child,
  onClose,
}: {
  child: Child;
  onClose: () => void;
}) => {
  const { selectedWeek, setSelectedWeek, calendarData, aggregatedData } =
    useFeedingAndSleep(child);
  const translate = useTranslate();
  if (!child) {
    return null;
  }

  return (
    <Box
      sx={{
        m: 1,
        height: 'calc(100% - 1rem)',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontFamily: 'Lora',
          }}
          variant="h6"
          color="textPrimary"
        >
          {translate('tools.feedingAndSleep.title')}
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <WeekCalendar
        data={calendarData}
        selectedChild={child}
        selectedWeek={selectedWeek}
        setSelectedWeek={setSelectedWeek}
        legend={legend()}
      />
      <Statistics data={aggregatedData} />
    </Box>
  );
};
