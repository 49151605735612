import React from 'react';
import { useTranslate } from 'react-admin';
import ReactMarkdown from 'react-markdown';

import {
  CMSCard,
  CMSCardActionArea,
  CMSCardContent,
  CMSCardTitle,
} from '@components/cmsCard';
import { SuggestionContentType } from '@components/discussions/chat/contentSuggestion/types';
import { Dialog, DialogContent, Divider, Grid, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import {
  logContentSuggestionView,
  logContentView,
} from '../../analytics/events';
import { colorTokens } from '../../themes';
import { Macro } from '../../types';
import { SendIcon } from '../discussions/SendIcon';
import { DialogTitle } from '../generic/Dialog';

export const WIDTH = 148;
const TITLE_LINE_NB = 4;
const TITLE_HEIGHT = TITLE_LINE_NB * 24;
export const HEIGHT = TITLE_HEIGHT;

const InnerMacro = ({
  item,
  close,
  onCopy,
}: {
  item: Macro;
  close: () => void;
  onCopy?: (text: string, id?: number) => void;
}) => {
  const translate = useTranslate();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={(item.suggestions?.length ?? 0) === 0 ? 12 : 6}>
        <Typography
          variant="h1"
          color="primary"
          sx={{ fontSize: '1rem', fontWeight: '500' }}
        >
          {translate('macro.behavior')}
        </Typography>
        {item.protocol ? (
          <ReactMarkdown>{item.protocol}</ReactMarkdown>
        ) : (
          <Typography variant="body2">
            {translate('macro.noProtocol')}
          </Typography>
        )}
      </Grid>
      {(item.suggestions?.length ?? 0) > 0 && (
        <Grid item xs={12} sm={6}>
          {item.suggestions?.map((suggestion) => (
            <Card
              elevation={0}
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'primary.main10',
                marginBottom: '0.5rem',
                position: 'relative',
                overflow: 'unset',
              }}
              key={suggestion.id}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, padding: '0.75rem' }}
              >
                {suggestion.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{ padding: '0.75rem', wordWrap: 'break-word' }}
              >
                {suggestion.content}
              </Typography>
              {onCopy ? (
                <SendIcon
                  onClick={() => {
                    onCopy(suggestion.content, suggestion.id);
                    close();
                  }}
                  sx={{
                    position: 'absolute',
                    right: '0',
                    bottom: '-0.25rem',
                    zIndex: 20,
                  }}
                />
              ) : null}
            </Card>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export const MacrosItemDialog = ({
  item,
  translatedItem,
  open,
  close,
  onCopy,
}: {
  item: Macro;
  translatedItem?: Macro;
  open: boolean;
  close: () => void;
  onCopy?: (text: string, id?: number) => void;
}) => (
  <Dialog
    open={open}
    onClose={close}
    aria-labelledby={item.name}
    aria-describedby={item.name}
    maxWidth="xl"
    fullWidth
  >
    <DialogTitle onClose={close}>
      {translatedItem ? (
        <Stack direction="row" gap={6} sx={{ flex: 1 }}>
          <Stack direction="column" sx={{ flex: 1 }}>
            {item.name}
          </Stack>
          <Stack direction="column" sx={{ flex: 1 }}>
            {translatedItem.name}
          </Stack>
        </Stack>
      ) : (
        item.name
      )}
    </DialogTitle>
    <DialogContent>
      {translatedItem ? (
        <Stack
          direction="row"
          justifyContent={'space-between'}
          gap={1}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack direction="column" sx={{ flex: 1 }}>
            <InnerMacro item={item} onCopy={onCopy} close={close} />
          </Stack>
          <Stack direction="column" sx={{ flex: 1 }}>
            <InnerMacro item={translatedItem} onCopy={onCopy} close={close} />
          </Stack>
        </Stack>
      ) : (
        <InnerMacro item={item} onCopy={onCopy} close={close} />
      )}
    </DialogContent>
  </Dialog>
);

export const MacroItem = ({
  item,
  onCopy,
  subjectId,
  fromSuggestion = false,
}: {
  item: Macro;
  onCopy?: (
    val: string,
    macroId: number,
    suggestionId: number,
    fromSuggestion: boolean,
  ) => void;
  subjectId?: number;
  fromSuggestion?: boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  const onClick = () => {
    fromSuggestion
      ? logContentSuggestionView(
          subjectId,
          SuggestionContentType.MACRO,
          item.id,
        )
      : logContentView('macro', item.id);
    setOpen(true);
  };
  const close = () => setOpen(false);

  return (
    <CMSCard
      width={WIDTH}
      elevation={0}
      height={HEIGHT}
      style={{
        background: `linear-gradient(to bottom, ${colorTokens.surface.accentSeaSubtle} 0%, ${colorTokens.surface.accentSea} 100%)`,
      }}
    >
      {open ? (
        <MacrosItemDialog
          onCopy={
            onCopy
              ? (text, suggestionId) =>
                  onCopy(text, item.id, suggestionId, fromSuggestion)
              : undefined
          }
          item={item}
          open={open}
          close={close}
        />
      ) : null}
      <CMSCardActionArea
        onClick={onClick}
        height={HEIGHT}
        sx={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <CMSCardContent paddingVertical={0}>
          <CMSCardTitle
            variant="subtitle2"
            component="h2"
            clamp={TITLE_LINE_NB}
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontFamily: 'Lora, serif',
              fontWeight: 600,
            })}
          >
            {item.name}
          </CMSCardTitle>
        </CMSCardContent>
      </CMSCardActionArea>
    </CMSCard>
  );
};
