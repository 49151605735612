import {
  BooleanInput,
  ImageField,
  ImageInput,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import { formatImage } from '../../utils/image';

interface CategoriesFormProps {
  transform?: Function;
}

export const CategoriesForm = ({
  transform,
  ...props
}: CategoriesFormProps) => (
  <SimpleForm {...props}>
    <TextInput
      label="Nom"
      source="name"
      validate={required()}
      variant="standard"
    />
    <TextInput
      label="Slug"
      source="slug"
      validate={required()}
      helperText="Identifiant unique. Ne pas mettre à jour."
      variant="standard"
    />
    <TextInput
      label="Description"
      source="description"
      validate={required()}
      variant="standard"
    />
    <TextInput
      label="Prix public (display)"
      source="publicPrice"
      validate={required()}
      variant="standard"
    />
    <TextInput
      label="Prix abonné (display)"
      source="subscribedPrice"
      validate={required()}
      variant="standard"
    />
    <NumberInput
      label="Prix public (chiffre)"
      source="publicPriceAmount"
      validate={required()}
      variant="standard"
    />
    <NumberInput
      label="Prix abonné (chiffre)"
      source="subscribedPriceAmount"
      validate={required()}
      variant="standard"
    />
    <ImageInput
      format={formatImage}
      source="image"
      label="Image"
      labelSingle="field.image.placeholder"
      validate={required()}
      variant="standard"
    >
      <ImageField source="src" />
    </ImageInput>
    <BooleanInput label="Actif" source="active" variant="standard" />
  </SimpleForm>
);
