import { useMemo, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Control, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { ScrollView, StyleSheet } from 'react-native';

import { TextInput } from '@components/forms/textInput';
import { DialogTitle } from '@components/generic/Dialog';
import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import {
  Form,
  getScoringNumber,
  getScoringTags,
  Question,
  StepScreen,
  Template,
} from '@teammay/form-core';
import {
  FormMobileProvider,
  FormRunner,
  StepScreenRenderer,
} from '@teammay/form-mobile';

import { PrimaryButton } from './mobile-components/buttons';
import { BackButton } from './mobile-components/buttons/BackButton';
import { PressableNative } from './mobile-components/buttons/PressableNative';
import { colorTokens } from '../../../themes';

const rnStyles = StyleSheet.create({
  mobileContainer: {
    width: 300,
    height: 600,
    borderRadius: 50,
    borderColor: 'black',
    borderWidth: 10,
    borderStyle: 'solid',
    overflow: 'hidden',
    alignSelf: 'center',
  },
  mobileInnerContainer: {
    flexGrow: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});

const styles = {
  buttonGroup: {
    color: colorTokens.content.secondary,
    borderColor: colorTokens.surface.subtle,
  },
  active: {
    color: colorTokens.content.primary,
    backgroundColor: colorTokens.surface.subtle,
  },
};

const FallBackComponent = (props: FallbackProps) => {
  const translate = useTranslate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" color="error">
        {translate('forms.viewer.runError')}
      </Typography>
      <Typography color="error">{`${props.error}`}</Typography>
      <Button onClick={props.resetErrorBoundary} variant="contained">
        {translate('forms.viewer.retry')}
      </Button>
    </Box>
  );
};

export const ExternalDataModal = ({
  open,
  onClose,
  control,
}: {
  open: boolean;
  onClose: () => void;
  control: Control<{ externalData: { key: string; value: string }[] }>;
}) => {
  const {
    fields: externalDataArray,
    append: appendExternalData,
    remove: removeExternalData,
  } = useFieldArray({
    control: control,
    name: 'externalData',
  });
  const translate = useTranslate();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle onClose={onClose}>External Data</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          {externalDataArray.map((data, index) => (
            <Stack direction="row" spacing={2}>
              <TextInput
                key={`key-${index}`}
                name={`externalData.${index}.key`}
                label={translate('field.optionInput.key')}
                control={control}
              />
              <TextInput
                key={`value-${index}`}
                name={`externalData.${index}.value`}
                label={translate('field.optionInput.value')}
                control={control}
              />
              <IconButton onClick={() => removeExternalData(index)}>
                <Delete />
              </IconButton>
            </Stack>
          ))}
          <Button onClick={() => appendExternalData({ key: '', value: '' })}>
            {translate('forms.runner.addExternalData')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export const MobileView = ({
  sx,
  selected,
  form,
  templates,
  questions,
}: {
  sx: SxProps<Theme>;
  selected: StepScreen | null;
  form: Form;
  templates: Template[];
  questions: Question[];
}) => {
  const [selectedMode, setSelectedMode] = useState<'viewer' | 'runner'>(
    'viewer',
  );
  const notify = useNotify();
  const translate = useTranslate();
  const [hideBack, setHideBack] = useState(false);
  const [openExternalData, setOpenExternalData] = useState(false);
  const extDataForm = useForm<{
    externalData: { key: string; value: string }[];
  }>({
    defaultValues: {
      externalData: [],
    },
  });

  const externalDataArray = useWatch({
    control: extDataForm.control,
    name: 'externalData',
  });

  const externalData = useMemo(() => {
    return externalDataArray.reduce((acc, curr) => {
      if (curr.key && curr.value) {
        acc[curr.key] = curr.value;
      }
      return acc;
    }, {});
  }, [externalDataArray]);

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ErrorBoundary FallbackComponent={FallBackComponent}>
          <FormMobileProvider
            theme={{
              title: {
                fontSize: 17,
                fontFamily: 'Manrope',
                fontWeight: '600',
                color: colorTokens.content.primary,
                textAlign: 'center',
                marginTop: 12,
                marginBottom: 12,
              },
              text: {
                fontSize: 14,
                fontFamily: 'Manrope',
                fontWeight: '400',
                color: colorTokens.content.primary,
                textAlign: 'center',
              },
            }}
            components={{
              BackButton,
              PrimaryButton,
              Button: PressableNative,
            }}
          >
            <ScrollView
              style={rnStyles.mobileContainer}
              contentContainerStyle={rnStyles.mobileInnerContainer}
            >
              {selectedMode === 'viewer' && (
                <StepScreenRenderer
                  stepScreen={selected}
                  submit={(answer) => {
                    notify(
                      `${translate('forms.runner.submitClicked')}: ${answer}`,
                      { type: 'success' },
                    );
                  }}
                />
              )}
              {selectedMode === 'runner' && (
                <FormRunner
                  form={form}
                  templates={templates}
                  questions={questions}
                  totalQuestions={form.questionCount ?? 0}
                  onFormFinished={(answers) => {
                    const score = getScoringNumber(answers, questions) ?? ' - ';
                    const tags = getScoringTags(answers, questions) ?? ' - ';
                    notify('forms.runner.completedForm', {
                      type: 'success',
                      messageArgs: { score, tags },
                    });
                  }}
                  onCancelForm={() => {
                    notify('forms.runner.canceledForm', {
                      type: 'warning',
                    });
                  }}
                  hideBack={hideBack}
                  externalData={externalData}
                />
              )}
            </ScrollView>
          </FormMobileProvider>
        </ErrorBoundary>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          py: 1,
          px: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextField
            value={form?.questionCount ?? 0}
            type="number"
            label={translate('forms.runner.questionCount')}
            variant="standard"
            disabled={true}
            sx={{ minWidth: 0 }}
          />
          <FormControlLabel
            control={<Switch onClick={() => setHideBack((prev) => !prev)} />}
            label={translate('forms.runner.canBack')}
            checked={!hideBack}
          />
        </Box>

        <Stack direction="row" spacing={2}>
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button
              style={{
                ...styles.buttonGroup,
                ...(selectedMode === 'viewer' ? styles.active : {}),
              }}
              onClick={() => setSelectedMode('viewer')}
            >
              Viewer
            </Button>
            <Button
              style={{
                ...styles.buttonGroup,
                ...(selectedMode === 'runner' ? styles.active : {}),
              }}
              onClick={() => setSelectedMode('runner')}
            >
              Runner
            </Button>
          </ButtonGroup>
          <Button onClick={() => setOpenExternalData(true)}>
            {translate('forms.runner.externalData')}
          </Button>
        </Stack>
      </Box>
      <ExternalDataModal
        open={openExternalData}
        onClose={() => setOpenExternalData(false)}
        control={extDataForm.control}
      />
    </Box>
  );
};
