import { useTranslate } from 'react-admin';
import { Controller, Path } from 'react-hook-form';

import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
  TextField,
} from '@mui/material';

import { ExposedControllerProps } from './type';
import { errorMessage } from './utils';
import { slugify } from '../../utils/slugify';

type SelectInputProps<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> &
  Omit<SelectProps, 'onChange' | 'value'> & {
    onChangeCallback?: Pick<SelectProps, 'onChange'>['onChange'];
  } & { variant: 'standard' | 'outlined' };

export type AutocompleteSelectInputProps<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
  Multiple extends boolean = false,
> = ExposedControllerProps<TFieldValues, TName> &
  Omit<
    AutocompleteProps<
      { label: string; id: number | string },
      Multiple,
      false,
      false
    >,
    'onChange' | 'value' | 'renderInput' | 'onChange'
  > & {
    label: string;
  };

export function SelectInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  name,
  control,
  label,
  rules,
  fullWidth = false,
  onChangeCallback,
  ...other
}: SelectInputProps<TFieldValues, TName>) {
  const translate = useTranslate();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState, formState }) => {
        const hideLabel = typeof label === 'string' && !label;
        return (
          <FormControl
            fullWidth={fullWidth}
            variant={other.variant}
            size={other.size}
          >
            {!hideLabel && (
              // @ts-ignore
              <InputLabel id={slugify(name || label)} sx={other.sx}>
                {label ?? name}
              </InputLabel>
            )}
            <Select
              error={Boolean(fieldState.error)}
              disabled={formState.isSubmitting}
              // @ts-ignore
              labelId={slugify(name || label)}
              {...field}
              label={label ?? (name || undefined)}
              value={field.value ?? ''}
              onChange={(e, child) => {
                field.onChange(e.target.value);
                onChangeCallback?.(e, child);
              }}
              {...other}
            />
            <FormHelperText>
              {errorMessage(fieldState.error, translate)}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}

export function AutocompleteSelectInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
  Multiple extends boolean = false,
>({
  name,
  control,
  rules,
  label,
  fullWidth = false,
  options,
  multiple,
  ...other
}: AutocompleteSelectInputProps<TFieldValues, TName, Multiple>) {
  const translate = useTranslate();
  return (
    <Controller
      control={control}
      name={name}
      render={({ fieldState, field }) => {
        return (
          <FormControl fullWidth={fullWidth}>
            <Autocomplete
              options={options}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                );
              }}
              autoHighlight
              loading={other.loading}
              onChange={(_, newValue) => {
                field.onChange(
                  Array.isArray(newValue)
                    ? newValue.map(({ id }) => id)
                    : newValue?.id,
                );
              }}
              defaultValue={other.defaultValue}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  label={label}
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ margin: 0 }}
                  variant="outlined"
                />
              )}
              // good types but it is not differentiating between multiple and single
              // @ts-ignore
              value={
                multiple
                  ? options?.filter(({ id }) => field.value?.includes(id))
                  : options?.find(({ id }) => field.value === id)
              }
              multiple={multiple}
              {...other}
            />
            <FormHelperText>
              {errorMessage(fieldState.error, translate)}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}
