export const baseURL = window.env?.BACKEND_URL ?? 'http://localhost:3010';
export const cmsURL = window.env?.CMS_URL ?? 'http://localhost:1337/api';
export const cmsAdminURL = cmsURL.replace(
  '/api',
  '/admin/content-manager/collection-types/',
);
export const s3URL = window.env?.S3_ENDPOINT_URL ?? 'http://localhost:3030';
export const nestSocketURL =
  window.env?.NEST_SOCKET_URL ?? 'http://localhost:3020';
export const searchURL = window.env?.SEARCH_URL ?? 'http://localhost:7700';
