import { useLocale, useTranslate } from 'react-admin';

import { Paper, Typography, Link, List, ListItem, Alert } from '@mui/material';
import { Box } from '@mui/system';

export const Help = () => {
  const translate = useTranslate();
  const locale = useLocale();

  return (
    <Paper sx={{ padding: '1rem', minHeight: '100%' }} elevation={0}>
      <Typography variant="h1" color="primary" sx={{ fontWeight: 'bold' }}>
        {translate('title.help')}
      </Typography>

      <List>
        <ListItem>
          <Link
            href="https://drive.google.com/drive/u/0/folders/1IcuHpTpj5sqNsC5OKcXVVuxrR0zvmnbb"
            rel="noopener noreferrer"
            target="_blank"
            underline="hover"
          >
            🔗 {translate('help.help')}
          </Link>
        </ListItem>
        {locale === 'fr' && (
          <ListItem>
            <Link
              href="https://maysante.typeform.com/kit-pro"
              rel="noopener noreferrer"
              target="_blank"
              underline="hover"
            >
              📦 {translate('help.proKit')}
            </Link>
          </ListItem>
        )}
        <ListItem>
          <Typography variant="subtitle1" color="primary">
            🔁 {translate('help.refresh')}
          </Typography>
        </ListItem>
        <Alert severity="info" sx={{ ml: 2 }}>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle2">
              {translate('help.refreshBrowser1')}
            </Typography>
            <Box sx={{ ml: 1 }}>
              <Typography>
                {translate('help.pressSimultaneously')}
                <kbd>Ctrl</kbd> +<kbd>shift</kbd> + <kbd>R</kbd>{' '}
                {translate('help.or')} <kbd>Ctrl</kbd> + <kbd>F5</kbd>
              </Typography>
              <Typography variant="body2">
                {translate('help.shiftExplanation')}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle2">
              {translate('help.refreshBrowser2')}
            </Typography>
            <Box sx={{ ml: 1 }}>
              <Typography>
                {translate('help.pressSimultaneously')}
                <kbd>command</kbd> ({translate('help.or')} <kbd>cmd</kbd>) +{' '}
                <kbd>shift</kbd> + <kbd>R</kbd>
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle2">
              {translate('help.refreshBrowser3')}
            </Typography>
            <Box sx={{ ml: 1 }}>
              <Typography>
                {translate('help.pressSimultaneously')}
                <kbd>command</kbd> ({translate('help.or')} <kbd>cmd</kbd>) +{' '}
                <kbd>option</kbd> + <kbd>E</kbd>
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle2">
              {translate('help.refreshBrowser4')}
            </Typography>
            <Box sx={{ ml: 1 }}>
              <Typography>{translate('help.changeBrowser')} </Typography>
              <Typography>
                {translate('help.pressOn')} <kbd>Ctrl</kbd> + <kbd>F5</kbd>
              </Typography>
            </Box>
          </Box>
        </Alert>
        <ListItem sx={{ mt: '1.5rem' }}>
          <Typography variant="body2">
            ℹ️ {translate('help.contact')}
          </Typography>
        </ListItem>
      </List>
    </Paper>
  );
};
