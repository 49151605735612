import { useTranslate } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { TextInput } from '@components/forms/textInput';
import { Row } from '@components/generic/Row';
import { Close } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { Template } from '@teammay/form-core';

import { useUsedSlugs } from '../../hooks/useUsedSlugs';

export type ImageTemplate = Omit<Template, 'createdAt' | 'updatedAt'>;

export const ImageTemplateEdit = ({
  onBlur,
  onCancel,
  labelOnly = false,
}: {
  onBlur: () => void;
  onCancel: () => void;
  labelOnly?: boolean;
}) => {
  const translate = useTranslate();
  const { control } = useFormContext<ImageTemplate>();
  const id = useWatch({ control, name: 'id' });
  const usedSlugs = useUsedSlugs(id);

  return (
    <>
      {!labelOnly && (
        <Row>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {translate('forms.template.formTitle')}
          </Typography>
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </Row>
      )}
      <TextInput
        control={control}
        name="title"
        onBlur={onBlur}
        label={translate('common.title')}
      />
      <TextInput
        control={control}
        name="slug"
        label={translate('forms.template.slug')}
        onBlur={onBlur}
        rules={{
          validate: (value) => {
            if (usedSlugs.includes(value)) {
              return translate('forms.errors.unique_slug');
            }
          },
        }}
      />
      <TextInput
        control={control}
        name="description"
        onBlur={onBlur}
        label={translate('common.description')}
      />
      {!labelOnly && (
        <TextInput
          control={control}
          name="image"
          onBlur={onBlur}
          label={translate('common.image')}
        />
      )}
      <TextInput
        control={control}
        name={'customCTALabel'}
        label={translate('forms.questions.label')}
        onBlur={onBlur}
      />
    </>
  );
};
