export const filterPanelProps = {
  sx: {
    '& .MuiDataGrid-filterFormValueInput': {
      minWidth: '150px',
      width: '150px',
      marginTop: 0,
      '& .MuiTextField-root': {
        width: '150px',
        minWidth: '150px',
      },
    },
    '& .MuiDataGrid-filterFormDeleteIcon': {
      width: '30px',
      alignSelf: 'center',
    },
  },
};
