import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserLocale } from '../pages/common/contentTranslation/types';

const contentTranslationSlice = createSlice({
  name: 'contentTranslationFilters',
  initialState: {
    targetLocale: undefined as UserLocale | undefined,
  },
  reducers: {
    setTargetLocale(
      state,
      {
        payload: { targetLocale },
      }: PayloadAction<{ targetLocale: UserLocale }>,
    ) {
      return {
        ...state,
        targetLocale,
      };
    },
  },
});

export const { setTargetLocale } = contentTranslationSlice.actions;

export default contentTranslationSlice.reducer;
