import React, {
  forwardRef,
  ForwardedRef,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Identifier, useTranslate } from 'react-admin';
import {
  ActivityIndicator,
  ColorValue,
  FlatList,
  LayoutChangeEvent,
  StyleSheet,
  Text,
  View,
  ViewToken,
} from 'react-native';
import { useDispatch } from 'react-redux';

import { ChatMessage } from '@boTypes/messages';
import { Subject } from '@boTypes/subject';
import { Button } from '@components/generic/Button';
import {
  DefaultRawMessage,
  Enrich,
  ForwardChatRef,
  IMediaMessage,
  Media,
} from '@teammay/chat-core';
import {
  defaultTheme,
  IEnrichedMessage,
  IFormMessage,
  MayParticipant,
  Mayssenger,
} from '@teammay/mayssenger';
import {
  BOMessageCollection,
  BOMessageTypes,
} from '@teammay/mayssenger/lib/typescript/src/components/messageCollection';

import { MediaViewer } from './mediaViewer';
import { AppIcon } from './messages/appIcons';
import { ContentMessage } from './messages/cmsMessage';
import FallbackImage from '../../../assets/doctor-consultation.webp';
import { useSelector } from '../../../store';
import { setTargetEventId } from '../../../store/discussion';
import { colorTokens } from '../../../themes';

const styles = StyleSheet.create({
  contentContainer: {
    paddingBottom: 10,
    paddingTop: 10,
  },
  loadMoreContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreText: {
    borderRadius: 8,
    backgroundColor: colorTokens.content.subtleBackground,
    padding: 8,
  },
  containerView: {
    flex: 1,
    position: 'relative',
    paddingHorizontal: 8,
  },
  seenIndicator: {
    color: colorTokens.content.subtleBackground,
    fontFamily: 'Manrope',
    fontWeight: '400',
    fontSize: 12,
  },
});

export type ContextMenuType = React.ComponentType<{
  anchorEl: Element;
  open: boolean;
  onClose: () => void;
  message: BOMessageTypes | DefaultRawMessage;
}>;

const AfterTimeIndicator = ({
  textColor,
  message,
}: {
  textColor: ColorValue;
  message: IEnrichedMessage<string>;
}) => {
  return message.seenBy?.length > 0 ? (
    <Text style={[styles.seenIndicator, { color: textColor }]}>✓</Text>
  ) : null;
};

interface MessageListProps {
  messages: Array<ChatMessage>;
  participants: Record<string, MayParticipant>;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  hasMore?: boolean;
  onEndReached?: () => void;
  scrollToBottomOffset?: number;
  ScrollToBottom?: React.ComponentType<{
    unreadMessagesCount?: number;
    onPress: () => void;
  }>;
  ContextMenu?: ContextMenuType;
  discussionId?: Identifier;
  ref: ForwardedRef<FlatList>;
  onFormPress?: (message: Pick<IFormMessage, 'form' | 'formAnswers'>) => void;
}

const viewabilityConfig = {
  viewAreaCoveragePercentThreshold: 95,
};

const mobileToWebFont = ({ fontFamily }: { fontFamily?: string }) => {
  if (!fontFamily) {
    return {};
  }
  switch (fontFamily) {
    case 'Poppins-Light':
      return { fontFamily: 'Manrope', fontWeight: 300 };
    case 'Poppins-Regular':
      return { fontFamily: 'Manrope', fontWeight: 400 };
    case 'Poppins-Medium':
      return { fontFamily: 'Manrope', fontWeight: 500 };
    case 'Poppins-SemiBold':
      return { fontFamily: 'Manrope', fontWeight: 600 };
    case 'Poppins-Bold':
      return { fontFamily: 'Manrope', fontWeight: 700 };
    default:
      return { fontFamily };
  }
};

const MessageListInner = (
  {
    messages,
    participants,
    hasMore = false,
    isLoading = false,
    isLoadingMore = false,
    onEndReached = () => null,
    ContextMenu,
    discussionId,
    ScrollToBottom,
    onFormPress,
  }: Omit<MessageListProps, 'ref'>,
  ref: ForwardChatRef<
    Enrich<DefaultRawMessage> | IEnrichedMessage<keyof BOMessageCollection>
  >,
) => {
  const innerRef = useRef<React.ElementRef<typeof Mayssenger>>(null);
  useImperativeHandle(ref, () => innerRef.current!);
  const userId = useSelector((state) => state.user.email);
  const earlierMessageSeen = useRef<number>(0);
  const [earlierSeen, setEarlierSeen] = useState<number>(0);
  const [unseenCount, setUnseenCount] = useState<number>(0);
  const flatListHeight = useRef<number>(0);
  const onLayoutFlatList = useCallback((event: LayoutChangeEvent) => {
    flatListHeight.current = event.nativeEvent.layout.height;
  }, []);
  const [openMediaViewer, setOpenMediaViewer] = useState(false);
  const [currentMediaInViewer, setCurrentMedia] = useState<
    (Media & { subjectId?: Subject['id'] }) | null
  >(null);
  const closeMediaViewer = useCallback(() => {
    setOpenMediaViewer(false);
    setCurrentMedia(null);
  }, []);

  const dispatch = useDispatch();
  const targetMessageId = useSelector(
    (state) => state.discussion.targetEventId,
  );
  const highlightedEventIds = useSelector(
    (state) => state.discussion.highlightedEventIds,
  );
  const [anchorEl, setAnchorEl] = useState<
    | { anchorEl: null; selectedMessage: null }
    | {
        anchorEl: Element;
        selectedMessage: BOMessageTypes | DefaultRawMessage;
      }
  >({ anchorEl: null, selectedMessage: null });

  const translate = useTranslate();

  const context = useMemo<React.ComponentProps<typeof Mayssenger>['context']>(
    () => ({
      locale: {
        days: {
          0: translate('locale.days.short.sunday'),
          1: translate('locale.days.short.monday'),
          2: translate('locale.days.short.tuesday'),
          3: translate('locale.days.short.wednesday'),
          4: translate('locale.days.short.thursday'),
          5: translate('locale.days.short.friday'),
          6: translate('locale.days.short.saturday'),
        },
        months: {
          0: translate('locale.months.short.january'),
          1: translate('locale.months.short.february'),
          2: translate('locale.months.short.march'),
          3: translate('locale.months.short.april'),
          4: translate('locale.months.short.may'),
          5: translate('locale.months.short.june'),
          6: translate('locale.months.short.july'),
          7: translate('locale.months.short.august'),
          8: translate('locale.months.short.september'),
          9: translate('locale.months.short.october'),
          10: translate('locale.months.short.november'),
          11: translate('locale.months.short.december'),
        },
      },
      components: {
        ContentCard: ContentMessage as React.ComponentProps<
          typeof Mayssenger
        >['context']['components']['ContentCard'],
        AppIcon: AppIcon as React.ComponentProps<
          typeof Mayssenger
        >['context']['components']['AppIcon'],
        AfterTimeIndicator,
      },
      images: { FormMessageFallbackImage: FallbackImage },
      texts: {
        formMessageLocked: translate('forms.chat.formMessageLocked'),
        formMessage: translate('forms.chat.formMessage'),
      },
    }),
    [translate],
  );
  // reinitialize the target event id when the discussion changes
  useEffect(() => {
    if (discussionId) {
      return () => {
        dispatch(
          setTargetEventId({ targetEventId: null, highlightedEventIds: [] }),
        );
      };
    }
  }, [discussionId, dispatch]);

  useEffect(() => {
    if (targetMessageId) {
      innerRef.current?.highlightMessage([targetMessageId.toString()]);
      innerRef.current?.scrollToMessage(targetMessageId.toString(), () => {
        dispatch(
          setTargetEventId({ targetEventId: null, highlightedEventIds: [] }),
        );
      });
    }
  }, [dispatch, targetMessageId]);

  const onViewableItemsChanged = useRef(
    ({
      viewableItems,
    }: {
      changed: ViewToken<IEnrichedMessage<keyof BOMessageCollection>>[];
      viewableItems: ViewToken<IEnrichedMessage<keyof BOMessageCollection>>[];
    }) => {
      const viewing = viewableItems.filter((c) => c.isViewable);
      if (viewing.length === 0) {
        return;
      }
      const nextEarlier = Math.max(
        earlierMessageSeen.current,
        ...viewing.map((v) => new Date(v.item.date).getTime()),
      );
      if (nextEarlier !== earlierMessageSeen.current) {
        earlierMessageSeen.current = nextEarlier;
        setEarlierSeen(earlierMessageSeen.current);
      }
    },
  ).current;

  // set the earlier seen message to the first message if it's not set - ie : handle the fact that onViewableItemsChanged on first message render
  const onStartReached = useRef(() => {
    if (!messages?.[0]?.date) {
      return;
    }
    const nextEarlier = new Date(messages[0].date).getTime();
    if (nextEarlier !== earlierMessageSeen.current) {
      earlierMessageSeen.current = nextEarlier;
      setEarlierSeen(earlierMessageSeen.current);
    }
  }).current;

  useEffect(() => {
    const unseen = messages.filter(
      (m) =>
        new Date(m.date).getTime() > earlierSeen && m.participantId !== userId,
    );
    setUnseenCount(unseen.length);
  }, [messages, earlierSeen, userId]);

  useEffect(() => {
    innerRef.current?.highlightMessage(highlightedEventIds);
  }, [highlightedEventIds]);

  const handleEndReached = useCallback(() => {
    if (isLoadingMore || !hasMore) {
      return;
    }
    onEndReached();
  }, [isLoadingMore, onEndReached, hasMore]);

  const handleMessageLongPress = useCallback(
    (
      message: BOMessageTypes | DefaultRawMessage,
      selectedIndex?: number,
      messageRef?: RefObject<HTMLAnchorElement>,
    ) => {
      setAnchorEl({
        anchorEl: messageRef.current,
        selectedMessage: message,
      });
    },
    [],
  );

  const handleMessagePress = useCallback(
    (message: BOMessageTypes | DefaultRawMessage, selectedIndex?: number) => {
      if (message.type === 'media') {
        const mediaMessage = message as IMediaMessage & {
          subjectId?: Subject['id'];
        };
        if (mediaMessage.media[selectedIndex]) {
          setOpenMediaViewer(true);
          setCurrentMedia({
            ...mediaMessage.media[selectedIndex],
            subjectId: mediaMessage.subjectId,
          });
        }
      } else if (message.type === 'form') {
        onFormPress?.(message);
      }
    },
    [onFormPress],
  );

  const [layout, setLayout] = useState({ width: 500, height: 500 });

  const theme = useMemo(() => {
    return {
      componentStyles: {
        contentMessage: {
          padding: 0,
        },
      },
      margins: {
        baseMessageSpacing: 8,
        baseMessageMinSpacing: 2,
      },
      dimensions: { maxMessageWidth: layout.width * 0.8 },
      texts: Object.entries(defaultTheme.texts).reduce(
        (acc, [key, { fontFamily, ...style }]) => ({
          ...acc,
          [key]: {
            ...style,
            ...mobileToWebFont({ fontFamily }),
          },
        }),
        {},
      ),
    };
  }, [layout]);

  return (
    <View
      style={styles.containerView}
      onLayout={(event) => setLayout(event.nativeEvent.layout)}
    >
      <MediaViewer
        open={openMediaViewer}
        onClose={closeMediaViewer}
        media={currentMediaInViewer}
      />
      <Mayssenger
        ref={innerRef}
        dataSet={{ className: 'scrollbar' }}
        inverted={true}
        messages={messages}
        onEndReached={handleEndReached}
        onEndReachedThreshold={0.3}
        onStartReached={onStartReached}
        contentContainerStyle={styles.contentContainer}
        disableVirtualization={false}
        viewabilityConfig={viewabilityConfig}
        onLayout={onLayoutFlatList}
        ScrollToBottomButton={ScrollToBottom ?? (() => null)}
        isFetchingPreviousMessages={isLoadingMore}
        isLoading={isLoading}
        context={context}
        participantsCollection={participants}
        userId={userId}
        onViewableItemsChanged={onViewableItemsChanged}
        unreadMessagesCount={unseenCount}
        style={{ height: '100%' }}
        // @ts-expect-error converting IEnrichedMessage to BOMessageTypes
        onMessageLongPress={handleMessageLongPress}
        // @ts-expect-error converting IEnrichedMessage to BOMessageTypes
        onMessagePress={handleMessagePress}
        theme={theme}
        ListFooterComponent={
          isLoadingMore ? (
            <ActivityIndicator color={'primary.main50'} />
          ) : hasMore ? (
            <Button
              onClick={() => handleEndReached()}
              style={{ color: colorTokens.content.accentMandarin }}
            >
              {translate('common.loadMore')}
            </Button>
          ) : null
        }
      />
      {!!anchorEl.anchorEl && (
        <ContextMenu
          anchorEl={anchorEl.anchorEl}
          open={!!anchorEl.anchorEl}
          onClose={() => setAnchorEl({ anchorEl: null, selectedMessage: null })}
          message={anchorEl.selectedMessage}
        />
      )}
    </View>
  );
};

export const MessageList = forwardRef(MessageListInner);
