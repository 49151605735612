import { ReactNode } from 'react';

import { Roles } from '@boTypes/user';
import { Grid } from '@mui/material';

import { useSelector } from '../../store';

export const AdminCardGrid = ({
  children,
  roles,
}: {
  roles: Roles[];
  children: ReactNode;
}) => {
  const userRoles = useSelector((state) => state.user.roles);

  const hasAtLeastOneRequiredRole = roles.some((role) =>
    userRoles.includes(role),
  );
  if (!hasAtLeastOneRequiredRole) {
    return null;
  }

  return (
    <Grid item xs={12} md={4}>
      {children}
    </Grid>
  );
};
