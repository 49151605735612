import { useGetList, useTranslate } from 'react-admin';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

import { Category } from '@boTypes/category';
import { MinimalSubject } from '@boTypes/discussion';
import { Subject } from '@boTypes/subject';
import { CategoryChip } from '@components/fields/CategoriesChipField';
import { CategoryChipSelect } from '@components/subjects/CategoryChipSelect';
import { useUpdateSubjectCategory } from '@hooks/category';
import { useMutation, useQueryClient } from '@hooks/queryWrappers';
import { SUBJECT_WAITING_ATTRIBUTION_KEY } from '@hooks/subjectAttribution';
import { Button, Typography } from '@mui/material';
import { removeSubjectFromWaitingList } from '@utils/subjectAttributions';

import { inputContainerHeight } from './chatInput';
import {
  logCategoryChose,
  logCategoryUseSuggestion,
} from '../../../analytics/events';
import { colorTokens } from '../../../themes';

const styles = StyleSheet.create({
  inputContainer: {
    width: '100%',
    flexDirection: 'column',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: colorTokens.content.subtleBackground,
    borderBottomColor: colorTokens.content.subtleBackground,
    height: inputContainerHeight,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fafafb',
  },
  choiceContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '0.5rem',
    gap: 30,
    marginHorizontal: 30,
  },
  attributionFirstLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const UnCategorizedChatInput = ({
  subject,
  onCategoryChanged,
}: {
  subject: MinimalSubject;
  onCategoryChanged?: (subject: Subject) => void;
}) => {
  const translate = useTranslate();
  const { data: categoryList } = useGetList<Category>(
    'categories',
    {
      pagination: { page: 1, perPage: 1000 },
    },
    {
      gcTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  );

  const { mutate: changeCategory, isPending } =
    useUpdateSubjectCategory(subject);

  const suggestedCategory = categoryList?.find(
    (c) => c.id === subject?.suggestedCategoryId,
  );
  return (
    <View style={styles.inputContainer}>
      {Boolean(suggestedCategory) && (
        <Button
          variant="text"
          onClick={() => {
            logCategoryUseSuggestion({
              subjectId: subject.id,
              categoryId: suggestedCategory.id,
              categorySlug: suggestedCategory.slug,
            });
            return changeCategory(
              { category: suggestedCategory },
              { onSuccess: onCategoryChanged },
            );
          }}
          disabled={isPending}
          startIcon={isPending && <ActivityIndicator />}
        >
          <Typography sx={{ textTransform: 'none' }}>
            {translate('category.useSuggestion')}:
          </Typography>
          <CategoryChip
            label={translate('category.suggested')}
            category={suggestedCategory}
            size="small"
            sx={{
              marginLeft: '0.5rem',
              fontSize: '0.75rem',
              textTransform: 'none',
            }}
          />
        </Button>
      )}
      <View style={styles.choiceContainer}>
        <Typography>{translate('category.chose')}</Typography>
        <CategoryChipSelect
          subject={subject}
          sx={{ marginLeft: '0.5rem', fontSize: '0.75rem' }}
          onCategoryChanged={(_subject) => {
            logCategoryChose({
              subjectId: subject.id,
              categoryId: _subject.category.id,
              categorySlug: _subject.category.slug,
            });
            return onCategoryChanged(_subject);
          }}
        />
      </View>
    </View>
  );
};

export const UnAttributedChatInput = ({
  subject,
}: {
  subject: MinimalSubject;
}) => {
  const translate = useTranslate();
  const queryClient = useQueryClient();

  const { mutate: selfAttribute, isPending: isSelfLoading } = useMutation<
    {},
    any,
    { subjectId: number }
  >(
    ['selfAttribute'],
    {
      method: 'PUT',
      url: `/api/chatAttribution/selfAttribute`,
    },
    {
      onSuccess: (_, { subjectId }) => {
        queryClient.setQueryData<number[]>(
          SUBJECT_WAITING_ATTRIBUTION_KEY,
          (previousData) =>
            removeSubjectFromWaitingList(previousData, subjectId),
        );
      },
    },
  );

  const { mutate: autoAttribute, isPending: isAutoLoading } = useMutation<
    {},
    any,
    { subjectId: number }
  >(
    ['selfAttribute'],
    {
      method: 'PUT',
      url: `/api/chatAttribution/autoAttribute`,
    },
    {
      onSuccess: (_, { subjectId }) => {
        queryClient.setQueryData<number[]>(
          SUBJECT_WAITING_ATTRIBUTION_KEY,
          (previousData) =>
            removeSubjectFromWaitingList(previousData, subjectId),
        );
      },
    },
  );

  const isLoading = isSelfLoading || isAutoLoading;

  return (
    <View style={styles.inputContainer}>
      <View style={styles.attributionFirstLine}>
        <Typography sx={{ mr: 1 }}>
          {translate('attributions.choiceTitle')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          startIcon={isLoading && <ActivityIndicator />}
          onClick={() => autoAttribute({ subjectId: subject.id })}
          size="small"
        >
          {translate('attributions.automatic')}
        </Button>
      </View>
      <Button
        variant="text"
        onClick={() => selfAttribute({ subjectId: subject.id })}
      >
        {translate('attributions.toMe')}
      </Button>
    </View>
  );
};
