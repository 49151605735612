import Calendar from '@components/Calendar';
import { Box } from '@mui/material';

import { useAdminPlanningHook } from './adminPlanning.hook';
import { Loader } from '../../../components/Loader';

export enum displayType {
  ATTRIBUTION = 'attributions',
  AVAILABILITY = 'availabilities',
}

export const AdminPlanning = ({
  display,
  date,
}: {
  display: displayType;
  date: Date;
}) => {
  const {
    country,
    agendaData,
    callbackActions,
    callbackExtraActions,
    staffUsersById,
    displayActions,
    participatingUsers,
    job,
    allLoading,
  } = useAdminPlanningHook({
    display,
    date,
  });

  if (allLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ flex: 1, height: 'calc(100% - 3rem)' }}>
      <Calendar
        events={agendaData}
        dayLayoutAlgorithm="no-overlap"
        staffUsers={staffUsersById}
        displayActions={displayActions}
        // @ts-ignore
        drawerCallback={callbackActions}
        selectable={display === displayType.ATTRIBUTION}
        // extra actions
        displayExtraActions={true}
        callbackExtraActions={callbackExtraActions}
        displayOtherAvailable={true}
        displaySelector={true}
        defaultDate={date}
        participantIds={participatingUsers}
        isAdmin={true}
        job={job}
        country={country}
      />
    </Box>
  );
};
