import { Identifier, useGetOne } from 'react-admin';

import { Child } from '@boTypes/child';
import { PatientDetail } from '@boTypes/patient';
import { UseQueryResult } from '@hooks/queryWrappers';

import { Discussion } from '../types';

const useChildPatientDetail = (childId: Identifier, enabled = true) => {
  return useGetOne<Child>('children', { id: Number(childId) }, { enabled });
};
const useAdultPatientDetail = (patientId: Identifier, enabled = true) => {
  return useGetOne<PatientDetail>(
    'patients',
    {
      id: Number(patientId),
    },
    {
      enabled,
    },
  );
};

type ChildResult = {
  type: 'child';
} & UseQueryResult<Child>;
type AdultResult = {
  type: 'adult';
} & UseQueryResult<PatientDetail>;

export type PatientDetailQueryResult = ChildResult | AdultResult;

export const usePatientDetail = (
  discussion: Discussion,
): PatientDetailQueryResult => {
  // AppUser
  const appUserId =
    typeof discussion?.appUser === 'number'
      ? discussion?.appUser
      : discussion?.appUser?.id;
  const adultDetail = useAdultPatientDetail(
    discussion && appUserId,
    Boolean(discussion && appUserId),
  );

  // Child
  const ChildDetail = useChildPatientDetail(
    discussion && discussion.kidId,
    Boolean(discussion && discussion.kidId),
  );

  return discussion?.kidId
    ? { ...ChildDetail, type: 'child' }
    : { ...adultDetail, type: 'adult' };
};
