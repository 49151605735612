import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import uniq from 'lodash/uniq';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Identifier, useTranslate } from 'react-admin';
import { useParams } from 'react-router';

import { ConfirmDialog } from '@components/ConfirmDialog/ConfirmDialog';
import { usePrompt } from '@hooks/usePrompt';
import Close from '@mui/icons-material/Close';
import Save from '@mui/icons-material/Save';
import Settings from '@mui/icons-material/Settings';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import { CheckButton } from './components/CheckButton';
import { PreferencesInput } from './components/preferencesInput';
import {
  logAvailabilityAutoSave,
  logAvailabilitySave,
  logAvailabilitySlotChoiceSelected,
} from '../../analytics/events';
import { Loader } from '../../components/Loader';
import {
  useCondensedAvailabilities,
  usePlanningDetail,
  usePlanningPreferences,
  useSetAvailabilities,
} from '../../hooks/plannings';
import { useSelector } from '../../store';
import {
  PlanningAvailValue,
  PlanningCondensedAvailabilities,
} from '../../types/planning';
import { titleToPlanningJob } from '../../types/user';

dayjs.extend(customParseFormat);

const getAvailabilitiesCreatePayload = (
  avails: Record<Identifier, PlanningAvailValue>,
) => {
  const vals = Object.keys(avails).filter(
    (id) => typeof avails[id] !== 'undefined',
  );
  return {
    slots: vals,
    values: vals.map((id) => avails[id]) as (0 | 1)[],
  };
};

export const AvailabilitiesEditTable = ({
  slotsDay,
  slotsHours,
  slotsByDay,
  slotsAvailabilities,
  setSlotsAvailabilities,
  condensedAvailabilities,
  displayExplanations = false,
}: {
  slotsDay: { dow: string; ddmm: string }[];
  slotsHours: string[];
  slotsByDay: Record<string, Record<string, Identifier[]>>;
  slotsAvailabilities: Record<string, PlanningAvailValue>;
  setSlotsAvailabilities: Dispatch<
    SetStateAction<Record<Identifier, PlanningAvailValue>>
  >;
  condensedAvailabilities: Record<Identifier, PlanningCondensedAvailabilities>;
  displayExplanations?: boolean;
}) => (
  <TableContainer
    // 55 = 50 (header) + 5 (padding when horizontal scrollbar)
    // 280 = 50 (header) + 215 (explanations) + 5 padding (horizontal scrollbar)
    sx={{ maxHeight: `calc(100% - ${displayExplanations ? 280 : 55}px)` }}
  >
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              width: '150px',
              position: 'sticky',
              left: 0,
              backgroundColor: 'primary.main10',
              zIndex: 4,
            }}
          />
          {slotsDay.map(({ dow, ddmm }) => (
            <TableCell
              key={ddmm}
              sx={{
                textAlign: 'center',
                backgroundColor: 'primary.main10',
                zIndex: 3,
              }}
            >
              {dow}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              width: '150px',
              position: 'sticky',
              left: 0,
              // We need this to avoid sticky cells to collapse behind the last row
              top: '57px',
              backgroundColor: 'primary.main10',
              zIndex: 4,
            }}
          />
          {slotsDay.map(({ ddmm }) => (
            <TableCell
              key={ddmm}
              sx={{
                textAlign: 'center',
                backgroundColor: 'primary.main10',
                // We need this to avoid sticky cells to collapse behind the last row
                top: '57px',
                zIndex: 3,
              }}
            >
              {ddmm}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {slotsHours.map((hour) => (
          <TableRow key={hour}>
            <TableCell
              sx={{
                width: '150px',
                position: 'sticky',
                left: 0,
                backgroundColor: 'white',
                zIndex: 2,
              }}
            >
              <Typography noWrap>{hour}</Typography>
            </TableCell>
            {slotsDay.map(({ ddmm }) => {
              if (typeof slotsByDay?.[ddmm]?.[hour] === 'undefined') {
                return (
                  <TableCell
                    sx={{ backgroundColor: 'background.grey' }}
                    key={`${ddmm}-${hour}`}
                  />
                );
              }
              return (
                <TableCell key={`${ddmm}-${hour}`} sx={{ p: 0 }}>
                  {
                    <CheckButton
                      value={slotsAvailabilities?.[slotsByDay[ddmm][hour][0]]}
                      onPress={(val: PlanningAvailValue) => {
                        logAvailabilitySlotChoiceSelected();
                        setSlotsAvailabilities((prev) => ({
                          ...prev,
                          ...slotsByDay[ddmm][hour].reduce(
                            (acc, slotId) => ({
                              ...acc,
                              [slotId]: val,
                            }),
                            {},
                          ),
                        }));
                      }}
                      yesCount={
                        condensedAvailabilities?.[
                          slotsByDay?.[ddmm]?.[hour]?.[0]
                        ]?.yesCount
                      }
                    />
                  }
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export const PlanningAvailabilitiesEdit = () => {
  const { planningId } = useParams();
  const { data: planning, isLoading } = usePlanningDetail(planningId);

  const [slotsAvailModifications, setSlotsAvailModifications] =
    useState<number>(0);
  const { mutate: setAvailabilities, isPending: isPosting } =
    useSetAvailabilities(planningId, {
      onSuccess: () => setSlotsAvailModifications(0),
    });
  const { data: condensedAvailabilities } =
    useCondensedAvailabilities(planningId);
  const [openPreferences, setOpenPreferences] = useState(false);
  const closePreferences = useCallback(
    () => setOpenPreferences(false),
    [setOpenPreferences],
  );
  const { data: preferences, isLoading: isLoadingPreferences } =
    usePlanningPreferences(planningId);

  const [saveConfirm, setSaveConfirm] = useState<React.ReactNode | null>(null);
  const translate = useTranslate();

  const slotsDay = useMemo<{ dow: string; ddmm: string }[]>(() => {
    if (planning) {
      return Object.keys(
        planning.slots.reduce(
          (acc, slot) => {
            const day = dayjs(slot.start).format('YYYY-MM-DD-dd');
            acc[day] = true;
            return acc;
          },
          {} as Record<string, boolean>,
        ),
      )
        .sort()
        .map((day) => {
          const splits = day.split('-');
          return { dow: splits[3], ddmm: `${splits[2]}/${splits[1]}` };
        });
    }
    return [];
  }, [planning]);

  const slotsHours = useMemo<string[]>(() => {
    if (planning) {
      return Object.keys(
        planning.slots.reduce(
          (acc, slot) => {
            const slotString =
              dayjs(slot.start).format('HH:mm') +
              ' - ' +
              dayjs(slot.end).format('HH:mm');
            acc[slotString] = true;
            return acc;
          },
          {} as Record<string, boolean>,
        ),
      ).sort();
    }
    return [];
  }, [planning]);

  const slotsByDay = useMemo<
    Record<string, Record<string, Identifier[]>>
  >(() => {
    if (planning) {
      return planning.slots.reduce(
        (acc, slot) => {
          const day = dayjs(slot.start).format('DD/MM');
          const slotString =
            dayjs(slot.start).format('HH:mm') +
            ' - ' +
            dayjs(slot.end).format('HH:mm');
          if (!acc[day]) {
            acc[day] = {};
          }
          const slots = acc[day][slotString] || [];
          slots.push(slot.id);
          acc[day][slotString] = slots;
          return acc;
        },
        {} as Record<string, Record<string, Identifier[]>>,
      );
    }
    return {};
  }, [planning]);

  const daysBySlot = useMemo<Record<Identifier, string>>(
    () =>
      planning
        ? planning.slots.reduce(
            (acc, slot) => {
              const day = dayjs(slot.start).format('DD/MM/YYYY');
              acc[slot.id] = day;
              return acc;
            },
            {} as Record<Identifier, string>,
          )
        : {},
    [planning],
  );

  const [slotsAvailabilities, _setSlotsAvailabilities] = useState<
    Record<Identifier, PlanningAvailValue>
  >({});
  const [openPrefReminder, setOpenPrefReminder] = useState(false);

  const hasPreferences = preferences && Object.keys(preferences).length > 0;
  const setSlotsAvailabilities = useCallback(
    (input: Parameters<typeof _setSlotsAvailabilities>[0]) => {
      _setSlotsAvailabilities(input);
      setSlotsAvailModifications((prev) => prev + 1);
    },
    [],
  );

  useEffect(() => {
    if (slotsAvailModifications && slotsAvailModifications % 10 === 0) {
      if (hasPreferences) {
        setAvailabilities(getAvailabilitiesCreatePayload(slotsAvailabilities));
        logAvailabilityAutoSave();
      } else {
        if (slotsAvailModifications >= 10 && slotsAvailModifications < 20) {
          setOpenPrefReminder(true);
        }
      }
    }
  }, [
    hasPreferences,
    setAvailabilities,
    slotsAvailModifications,
    slotsAvailabilities,
  ]);

  usePrompt(
    translate('planning.availabilities.notSaved'),
    slotsAvailModifications > 0,
  );

  const jobTitle = useSelector((state) => state.user.jobTitle);
  const canSubmit = planning?.job === titleToPlanningJob[jobTitle];

  const [displayExplanations, setDisplayExplanations] = useState(true);
  useEffect(() => {
    if (planning) {
      _setSlotsAvailabilities(
        planning.availabilities.slots.reduce(
          (acc, slotId, index) => {
            acc[slotId] = planning.availabilities.values[index];
            return acc;
          },
          {} as Record<Identifier, PlanningAvailValue>,
        ),
      );
    }
  }, [planning, _setSlotsAvailabilities]);

  const handleSubmitPressed = useCallback(() => {
    // this first check should not be useful but just in case
    if (hasPreferences) {
      const chosenDays = uniq(
        Object.keys(slotsAvailabilities).map((id) => daysBySlot[id]),
      );
      const weekendDays = chosenDays.filter((day) => {
        const dayNumber = dayjs(day, 'DD/MM/YYYY').day();
        return dayNumber === 0 || dayNumber === 6;
      });
      const errors = [];
      if (chosenDays.length < preferences.minimumSlotsWanted) {
        errors.push(translate('planning.preferences.tooMuchSlotsWanted'));
      }
      if (!weekendDays.length) {
        errors.push(translate('planning.preferences.noWeekEndAvailable'));
      }

      if (errors.length) {
        setSaveConfirm(
          <ul>
            {errors.map((v, index) => (
              <li key={index}>{v}</li>
            ))}
          </ul>,
        );
      } else {
        setAvailabilities(getAvailabilitiesCreatePayload(slotsAvailabilities));
      }
      logAvailabilitySave();
    }
  }, [
    daysBySlot,
    hasPreferences,
    preferences?.minimumSlotsWanted,
    setAvailabilities,
    slotsAvailabilities,
    translate,
  ]);

  if (isLoading || !planning) {
    return <Loader />;
  }
  if (!canSubmit) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <Typography variant="h1" sx={{ fontWeight: 'bold' }} color="primary">
          {planning?.title}
        </Typography>
        <Typography variant="h6">
          {translate('planning.availabilities.cantUpdate')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ height: 'calc(100vh - 49px)' }}>
      <ConfirmDialog
        title={translate('planning.preferences.reminderTitle')}
        text={translate('planning.preferences.reminderText')}
        open={Boolean(openPrefReminder)}
        loading={isPosting}
        onClose={() => setOpenPrefReminder(false)}
        onClick={() => {
          setOpenPreferences(true);
        }}
        confirmText={translate('common.OK')}
        cancelText={translate('common.cancel')}
      />
      <ConfirmDialog
        title={translate('common.confirmSave')}
        text={saveConfirm}
        open={Boolean(saveConfirm)}
        loading={isPosting}
        onClose={() => setSaveConfirm(null)}
        onClick={() => {
          setAvailabilities(
            getAvailabilitiesCreatePayload(slotsAvailabilities),
          );
          setSaveConfirm(null);
        }}
        confirmText={translate('common.saveAnyway')}
        cancelText={translate('common.modifyEntry')}
      />
      <Drawer
        PaperProps={{
          sx: {
            minWidth: '50%',
          },
        }}
        open={openPreferences}
        ModalProps={{
          keepMounted: false,
        }}
        anchor="right"
        onClose={closePreferences}
      >
        <PreferencesInput
          planningId={planningId}
          preferences={hasPreferences ? preferences : undefined}
          onClose={closePreferences}
        />
      </Drawer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          margin: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h1" color="primary" sx={{ fontWeight: 'bold' }}>
          {planning?.title}
        </Typography>

        <Box>
          {hasPreferences ? (
            <Button
              sx={{ marginLeft: 1 }}
              onClick={() => {
                handleSubmitPressed();
              }}
              variant="contained"
              disabled={isPosting}
              startIcon={
                isPosting ? <CircularProgress size={'1rem'} /> : <Save />
              }
            >
              {translate('common.submit')}
            </Button>
          ) : (
            <Tooltip title={translate('planning.preferences.setFirst')}>
              <Button
                sx={{
                  marginLeft: 1,
                  '&.Mui-disabled': {
                    pointerEvents: 'auto',
                  },
                }}
                color="inherit"
                onClick={() => {}}
                variant="contained"
                disabled={true}
                component="div"
                startIcon={<Close />}
              >
                {translate('common.submit')}
              </Button>
            </Tooltip>
          )}
          <Button
            sx={{ marginLeft: 1 }}
            onClick={() => setOpenPreferences(true)}
            variant="outlined"
            startIcon={
              isLoadingPreferences ? (
                <CircularProgress size={'1rem'} />
              ) : (
                <Settings />
              )
            }
            disabled={isLoadingPreferences || isPosting}
          >
            {translate('common.preferences')}
          </Button>
        </Box>
      </Box>
      {displayExplanations && (
        <Alert
          severity="warning"
          sx={{ m: 2, maxHeight: '210px', overflow: 'auto' }}
          action={
            <IconButton onClick={() => setDisplayExplanations(false)}>
              <Close />
            </IconButton>
          }
        >
          <AlertTitle>
            {translate('planning.availabilities.explanationTitle')}
          </AlertTitle>
          <List dense>
            <ListItem>
              {translate('planning.availabilities.explanationItem1')}
            </ListItem>
            <ListItem>
              {translate('planning.availabilities.explanationItem2')}
            </ListItem>
            <ListItem>
              {translate('planning.availabilities.explanationItem3')}
            </ListItem>
            <ListItem>
              {translate('planning.availabilities.explanationItem4')}
            </ListItem>
          </List>
        </Alert>
      )}
      <AvailabilitiesEditTable
        slotsDay={slotsDay}
        slotsHours={slotsHours}
        slotsByDay={slotsByDay}
        slotsAvailabilities={slotsAvailabilities}
        setSlotsAvailabilities={setSlotsAvailabilities}
        condensedAvailabilities={condensedAvailabilities}
        displayExplanations={displayExplanations}
      />
    </Box>
  );
};
