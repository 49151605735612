import { sortBy } from 'lodash';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';

import { Avatar } from '@components/fields/AvatarField';
import { Comment, Timer } from '@mui/icons-material';
import { Box, Card, Paper, Popper, Stack, Typography } from '@mui/material';
import { formatTZ } from '@utils/date';

import { TranslationTask, TranslationTaskStatus } from './types';
import { secondary } from '../../../themes';

const StatusCard = ({ status }: { status: TranslationTaskStatus }) => {
  const translate = useTranslate();

  return (
    <Card sx={{ p: 1 }}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        gap={1}
        alignItems={'center'}
      >
        <Stack
          flexDirection="row"
          justifyContent="flex-start"
          gap={1}
          alignItems={'center'}
        >
          <Avatar size="tiny" user={status.owner} />
          <Typography>
            {translate(`contentTranslation.status.${status.status}`)}
          </Typography>
        </Stack>
        <Typography fontSize={'0.75rem'}>
          {formatTZ(status.createdAt, 'DD/MM/YY HH:mm')}
        </Typography>
      </Stack>
      {status.comments && (
        <Stack flexDirection="row" gap={1} alignItems={'center'}>
          <Comment color="info" fontSize="small" />
          <Typography>{status.comments}</Typography>
        </Stack>
      )}
      {(status.timeSpent ?? 0) > 0 && (
        <Stack flexDirection="row" gap={1} alignItems={'center'}>
          <Timer color="info" fontSize="small" />
          <Typography>{status.timeSpent} min</Typography>
        </Stack>
      )}
    </Card>
  );
};

interface GridCellStatusExpandProps {
  value: TranslationTaskStatus[];
  width: number;
  // Task owner (!= taskStatus owner)
  ownerId?: TranslationTask['ownerId'];
}

export const GridCellStatusExpand = memo(function GridCellStatusExpand(
  props: GridCellStatusExpandProps,
) {
  const { width, value, ownerId } = props;
  const totalTime = value?.reduce((acc, itm) => {
    return acc + (itm.timeSpent || 0);
  }, 0);
  const hasUserComment = value?.some(
    (itm) => itm.ownerId !== ownerId && itm.comments,
  );
  const wrapper = useRef<HTMLDivElement | null>(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    setShowPopper(true);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      if (nativeEvent.key === 'Escape') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Stack flexDirection="row" gap={1} alignItems={'center'}>
          <Timer sx={{ fontSize: '1rem' }} color="info" />
          {hasUserComment && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                '&::after': {
                  width: '0.5rem',
                  height: '0.5rem',
                  content: '""',
                  display: 'inline-block',
                  backgroundColor: secondary.apricot,
                  borderRadius: '1rem',
                  position: 'relative',
                },
              }}
            />
          )}
          <Typography fontSize="inherit">{totalTime} min</Typography>
        </Stack>
      </Box>
      {showPopper && value.length > 0 && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          sx={{ marginLeft: -17, zIndex: 2 }}
        >
          <Paper
            elevation={1}
            sx={{
              minHeight: wrapper.current!.offsetHeight - 3,
              p: 1,
            }}
          >
            <Stack flexDirection="column" gap={1} spacing={1}>
              {sortBy(value, 'createdAt')?.map((status) => (
                <StatusCard status={status} key={status.id} />
              ))}
            </Stack>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});
