import { ChangeEventHandler } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router';

import { Roles } from '@boTypes/user';
import { CheckboxInput } from '@components/forms/checkBoxInput';
import { SelectInput } from '@components/forms/selectInput';
import { Close, FilterAlt, OpenInBrowser, Search } from '@mui/icons-material';
import {
  Button,
  Checkbox as MuiCheckbox,
  IconButton,
  Input,
  MenuItem,
  Paper,
  Stack,
  Typography,
  FormControlLabel,
} from '@mui/material';

import { useLibraryForTranslation } from './hooks';
import { type Inputs } from './hooks';
import { TranslationList } from './translationList';
import { useSelector } from '../../../../store';
import {
  ContentType,
  TranslationScope,
  UserLocale,
} from '../../../common/contentTranslation/types';

export const ContentTranslationAddContent = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const notify = useNotify();
  const targetLocale = useSelector(
    (state) => state.contentTranslation.targetLocale,
  );
  const roles = useSelector((state) => state.user.roles);
  const isAdmin = roles.includes(Roles.ADMIN);
  const isContentTranslationManager = roles.includes(
    Roles.CONTENT_TRANSLATION_MANAGER,
  );

  const {
    control,
    documentIds,
    fetchNextPage,
    filterDone,
    handleSubmit,
    hasNextPage,
    isChild,
    isFetchingNextPage,
    isPregnancy,
    items,
    itemsAlreadyAdded,
    searchedText,
    selectedContentCMSType,
    sendContentToTranslation,
    setDocumentIds,
    setFilterDone,
    setSearchedText,
  } = useLibraryForTranslation({ targetLocale });

  const onSubmit = async (data: Inputs) => {
    await sendContentToTranslation({ ...data, documentIds });
    setDocumentIds([]);
    notify('admin.contentTranslation.documentsSent', { type: 'success' });
  };

  const handleSearchChanges: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (evt) => {
    setSearchedText(evt.target.value);
  };
  const resetSearch = () => {
    setSearchedText('');
  };

  const handleFilterDoneChange = () => {
    setFilterDone((state) => !state);
  };

  let filteredItems = [...(items || [])];
  if (filterDone) {
    filteredItems = filteredItems.filter(
      (item) => !itemsAlreadyAdded.includes(item.id),
    );
  }

  if (!isAdmin && !isContentTranslationManager) {
    return null;
  }

  return (
    <Paper sx={{ padding: '1rem', minHeight: '100%' }} elevation={0}>
      <Stack
        flexDirection="row"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="h1" color="primary" sx={{ fontWeight: 'bold' }}>
          {translate('admin.contentTranslation.addContent')}
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            navigate('/admin/content-translation/dashboard');
          }}
          startIcon={<OpenInBrowser />}
        >
          Dashboard
        </Button>
      </Stack>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ marginBottom: '0.5rem' }}
      >
        <Stack gap={2} flexDirection="row">
          <SelectInput
            variant="standard"
            control={control}
            name="referenceLocale"
            label={translate('admin.contentTranslation.referenceLocale')}
            required
            sx={{ minWidth: '140px' }}
          >
            {Object.values(UserLocale).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectInput>
          <SelectInput
            variant="standard"
            control={control}
            name="targetLocale"
            label={translate('admin.contentTranslation.targetLocale')}
            required
            sx={{ minWidth: '140px' }}
          >
            {Object.values(UserLocale).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectInput>
          <SelectInput
            variant="standard"
            control={control}
            name="type"
            label={translate('admin.contentTranslation.type')}
            required
            sx={{ minWidth: '140px' }}
          >
            {Object.values(ContentType).map((value) => (
              <MenuItem key={value} value={value}>
                {translate(`admin.contentTranslation.contentType.${value}`)}
              </MenuItem>
            ))}
          </SelectInput>
          <CheckboxInput
            control={control}
            name="isChild"
            label={translate('admin.contentTranslation.isChild')}
            checked={isChild}
          />
          <CheckboxInput
            control={control}
            name="isPregnancy"
            label={translate('admin.contentTranslation.isPregnancy')}
            checked={isPregnancy}
          />
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent={'space-between'}
          gap={2}
          sx={{ width: '100%' }}
          alignItems={'center'}
        >
          <FormControlLabel
            sx={{ minWidth: '190px', paddingLeft: '8px' }}
            control={
              <MuiCheckbox
                name="selectAll"
                onChange={(ev) => {
                  const isChecked = ev.target.checked;
                  if (isChecked) {
                    setDocumentIds(filteredItems.map((item) => item.id));
                  } else {
                    setDocumentIds([]);
                  }
                }}
              />
            }
            label={translate('admin.contentTranslation.selectAll')}
          />
          <IconButton
            size="small"
            sx={{ width: '2.5rem' }}
            onClick={handleFilterDoneChange}
          >
            <FilterAlt
              sx={{ color: filterDone ? 'primary.main' : 'inherit' }}
            />
          </IconButton>
          <Input
            name="search"
            sx={{ flexGrow: 1 }}
            placeholder={translate('common.search')}
            value={searchedText}
            onChange={handleSearchChanges}
            startAdornment={<Search sx={{ color: 'background.grey' }} />}
            endAdornment={
              <IconButton onClick={resetSearch}>
                <Close />
              </IconButton>
            }
            autoFocus
          />
          <SelectInput
            variant="standard"
            control={control}
            name="scope"
            label={translate('admin.contentTranslation.scope')}
            required
            sx={{ minWidth: '100px' }}
          >
            {Object.values(TranslationScope).map((value) => (
              <MenuItem key={value} value={value}>
                {translate(`admin.contentTranslation.scopes.${value}`)}
              </MenuItem>
            ))}
          </SelectInput>
          <Button
            type="submit"
            variant="contained"
            color={documentIds.length === 1000 ? 'error' : 'primary'}
            disabled={documentIds.length === 0}
            sx={{ minWidth: '130px' }}
          >
            {translate('admin.contentTranslation.startTranslation')}
            {documentIds.length > 0 && ` (${documentIds.length})`}
          </Button>
        </Stack>
      </form>
      <TranslationList
        checked={documentIds}
        setChecked={setDocumentIds}
        type={selectedContentCMSType}
        items={filteredItems}
        hasNextPage={hasNextPage}
        isNextPageLoading={isFetchingNextPage}
        loadNextPage={fetchNextPage}
        itemsAlreadyAdded={itemsAlreadyAdded}
      />
    </Paper>
  );
};
