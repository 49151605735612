import { Edit, usePermissions } from 'react-admin';

import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { PatientsForm } from './form';
import { InlineAffiliationsList } from './inlines/affiliations';
import { InlineChildrenList } from './inlines/children';
import { InlinePregnanciesList } from './inlines/pregnancies';
import { InlineSubscriptionsList } from './inlines/subscriptions';
import { Roles } from '../../types/user';

export const PatientsEdit = () => {
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(Roles.ADMIN);
  const globTheme = useTheme();
  const isSmall = useMediaQuery(globTheme.breakpoints.down('sm'));

  if (!isAdmin) {
    return null;
  }

  return (
    <Box
      sx={{
        paddingLeft: '1rem',
        paddingTop: 0,
        paddingBottom: isSmall ? '6rem' : undefined,
        paddingRight: '1rem',
        overflow: 'auto',
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Edit resource="patients" mutationMode="pessimistic">
            <PatientsForm />
          </Edit>
        </Grid>
        <Grid item md={6}>
          <InlineChildrenList />
          <InlinePregnanciesList />
        </Grid>
        <Grid item xs={12}>
          <InlineSubscriptionsList />
          <InlineAffiliationsList />
        </Grid>
      </Grid>
    </Box>
  );
};
