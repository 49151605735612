import { useTranslate } from 'ra-core';
import { Controller, Path } from 'react-hook-form';

import { TextField, TextFieldProps } from '@mui/material';

import { ExposedControllerProps } from './type';
import { errorMessage } from './utils';

type TextInputProps<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
> = ExposedControllerProps<TFieldValues, TName> &
  Omit<TextFieldProps, 'onChange' | 'value'> & {
    reportRequired?: boolean;
    transform?: (value: string) => string;
    variant?: 'standard' | 'outlined' | 'filled';
  };

export function TextInput<
  TFieldValues extends Record<string, any>,
  TName extends Path<TFieldValues>,
>({
  name,
  control,
  label,
  rules,
  reportRequired = true,
  transform = (value) => value,
  ...other
}: TextInputProps<TFieldValues, TName>) {
  const translate = useTranslate();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState, formState }) => (
        <TextField
          error={Boolean(fieldState.error)}
          disabled={formState.isSubmitting}
          helperText={errorMessage(
            fieldState.error,
            translate,
            reportRequired,
            rules,
          )}
          {...field}
          value={field.value ? field.value.toString() : ''}
          onChange={(e) => field.onChange(transform(e.target.value))}
          label={label ?? name}
          variant="standard"
          {...other}
          hiddenLabel={other.hidden}
        />
      )}
    />
  );
}
