import sortBy from 'lodash/sortBy';
import { useCallback, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router';
import ReactFlow, {
  Background,
  Controls,
  Edge,
  Instance,
  Node,
  NodeAddChange,
  NodeChange,
  NodeDimensionChange,
  NodePositionChange,
  NodeRemoveChange,
  Panel,
  useEdgesState,
  useNodesState,
  useOnSelectionChange,
  useReactFlow,
} from 'reactflow';
import './Flow.css';

import 'reactflow/dist/style.css';
import { Button } from '@components/generic/Button';
import { ClampTypography } from '@components/generic/ClampTypography';
import Dagre from '@dagrejs/dagre';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import Label from '@mui/icons-material/Label';
import SaveIcon from '@mui/icons-material/Save';
import Settings from '@mui/icons-material/Settings';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { Form, Question, StepLink, Template } from '@teammay/form-core';

import { FormSettingsModal } from './FormSettingsForm';
import {
  OnChangeFormArgs,
  OnChangeFormFunction,
  QuestionNodeType,
  StepNodeType,
  TemplateNodeType,
} from '../types';
import { AllLabelsModal } from './AllLabelsModal';
import { AllTagsModal } from './AlltagsModal';
import { FillerNode } from './nodes/FillerNode';
import { QuestionNode } from './nodes/QuestionNode';
import { StepNode } from './nodes/StepNode';
import { TemplateNode } from './nodes/TemplateNode';
import {
  apiToNodes,
  fillerNode,
  positionNodesInStep,
  positionSteps,
  questionToNode,
  stepToNode,
  stepsEdge,
  templateToNode,
} from '../utils/apiToNodes';

const repositionSteps = (
  nodes: StepNodeType[],
  g: Dagre.graphlib.Graph<{}>,
) => {
  const baseX = nodes.map((node) => g.node(node.id).x).sort();
  const orderedNodes = sortBy(nodes, (node) => node.data.link?.order ?? 0);
  orderedNodes.forEach((node, index) => {
    const deltaX = baseX[index] - node.position.x;
    if (node.position) {
      node.position.x = baseX[index];
    }
    if (node.positionAbsolute) {
      node.positionAbsolute.x += deltaX;
    }
    // @ts-expect-error
    if (node.x) {
      // @ts-expect-error don't know why x isn't declared in node type while it is actually used by reactflow
      node.x = baseX[index];
    }
  });
  return orderedNodes;
};

const getChildren = (node: StepNodeType, nodes: StepNodeType[]) => {
  return [
    node.data.step.links
      .filter((link) => link.nextStepId)
      .map((link) => link.nextStepId)
      .flatMap<StepNodeType>(
        (id) => nodes.find((n) => n?.data?.step?.id === id) ?? [],
      ),
  ];
};

const getLayoutedElements = (
  nodes: StepNodeType[],
  edges: Edge[],
  options: { direction: 'TB' | 'LR' },
) => {
  const g = new Dagre.graphlib.Graph({ directed: true }).setDefaultEdgeLabel(
    () => ({}),
  );
  g.setGraph({ rankdir: options.direction });

  nodes.forEach((node) => g.setNode(node.id, node));
  edges.forEach((edge) => g.setEdge(edge.source, edge.target));

  Dagre.layout(g);

  const firstStep = nodes.find((node) => !node.data.link);
  let children = getChildren(firstStep, nodes);

  do {
    let nextChildren = [];
    children.forEach((childGroup) => {
      repositionSteps(childGroup, g);
      childGroup.forEach((child) => {
        nextChildren.push(...getChildren(child, nodes));
      });
    });
    children = nextChildren;
  } while (children.length > 0 && children.some((child) => child.length > 0));

  return {
    nodes: nodes.map((node) => {
      const { x, y } = g.node(node.id);
      return {
        ...node,
        position: { x: x - node.width / 2, y: y - node.height / 2 },
      };
    }),
    edges,
  };
};

const nodeTypes = {
  question: QuestionNode,
  step: StepNode,
  template: TemplateNode,
  filler: FillerNode,
};

const getLayout = (nodes: Node[], edges: Edge[]) => {
  let layoutNodes = nodes.filter(
    (node) => node.type === 'step',
  ) as StepNodeType[];
  layoutNodes = sortBy(layoutNodes, (node) => node.data.link?.order ?? 0);
  const layoutEdges = edges.filter(
    (edge) =>
      layoutNodes.find((node) => node.id === edge.source) &&
      layoutNodes.find((node) => node.id === edge.target),
  );
  const { nodes: layoutedStepNodes } = getLayoutedElements(
    layoutNodes,
    layoutEdges,
    {
      direction: 'TB',
    },
  );

  const otherNodes = nodes.filter(
    (node) => node.type !== 'link' && node.type !== 'step',
  );
  const otherEdges = edges.filter(
    (edge) =>
      !layoutNodes.find((node) => node.id === edge.source) ||
      !layoutNodes.find((node) => node.id === edge.target),
  );

  return {
    nodes: [...layoutedStepNodes, ...otherNodes],
    edges: [...layoutEdges, ...otherEdges],
  };
};

const updateQuestionAndTemplate = ({
  addedNode,
  deletedNode,
  getNode,
  getNodes,
  onNodesChange,
  onChangeProp,
}: {
  addedNode?: Node;
  deletedNode?: Node;
  getNode: Instance.GetNode<any>;
  getNodes: Instance.GetNodes<any>;
  onNodesChange: (changes: NodeChange[]) => void;
  onChangeProp: OnChangeFormFunction;
}) => {
  const impactedNode = addedNode ?? deletedNode;
  const otherNodes = getNodes()
    .filter(
      (node) =>
        node.parentId === impactedNode.parentId && node.id !== impactedNode.id,
    )
    .sort((a, b) => a.position.y - b.position.y);

  const stepNode = getNode(impactedNode.parentId);
  const positionedNode = [...otherNodes, addedNode].filter(Boolean);
  positionNodesInStep(positionedNode, stepNode);

  // update order of questions
  positionedNode.filter(Boolean).forEach((node, index) => {
    if (!node) {
      return;
    }
    const content =
      node.type === 'question' ? node.data.question : node.data.template;
    if (content.order !== index + 1) {
      if (node.type === 'question') {
        onChangeProp({
          updatedQuestion: {
            ...content,
            order: index + 1,
          } as Question,
        });
      } else {
        onChangeProp({
          updatedTemplate: {
            ...content,
            order: index + 1,
          } as Template,
        });
      }
    }
  });

  const addDeleteChange = addedNode
    ? ({ type: 'add', item: addedNode } as NodeAddChange)
    : ({ type: 'remove', id: deletedNode?.id } as NodeRemoveChange);

  const stepDimensionChanges: NodeDimensionChange = {
    id: stepNode.id,
    type: 'dimensions',
    dimensions: { height: stepNode.height, width: stepNode.width },
    updateStyle: true,
    resizing: true,
  };

  const positionChanges: NodePositionChange[] = positionedNode.map((node) => ({
    type: 'position',
    id: node.id,
    position: node.position,
    positionAbsolute: node.positionAbsolute,
  }));

  onNodesChange([addDeleteChange, stepDimensionChanges, ...positionChanges]);
};

export const Flow = ({
  form,
  questions,
  templates,
  onChange: onChangeProp,
  onSelectionChange,
  onSave,
  isLoading,
}: {
  form: Form;
  questions: Question[];
  templates: Template[];
  onChange: OnChangeFormFunction;
  onSelectionChange: (type: 'question' | 'template', id: string) => void;
  onSave: (arg: {
    form: Form;
    questions: Question[];
    templates: Template[];
  }) => void;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { fitView, getNode, getNodes, getEdges, getEdge } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [editingForm, setEditingForm] = useState(false);
  const [editingLabels, setEditingLabels] = useState(false);
  const [editingTags, setEditingTags] = useState(false);
  useOnSelectionChange({
    onChange: ({ nodes: selected }) => {
      const selectedNode = selected.find(
        (node) => node.type === 'question' || node.type === 'template',
      );
      if (!selectedNode) {
        return;
      }
      onSelectionChange(
        selectedNode.type as 'question' | 'template',
        selectedNode.data.question?.id ?? selectedNode.data.template?.id,
      );
    },
  });

  const onChange = useCallback(
    (args: OnChangeFormArgs) => {
      onChangeProp(args);
      if (args.addQuestion || args.addTemplate) {
        const addedNode = args.addQuestion
          ? questionToNode(args.addQuestion, onChange, {
              id: args.addQuestion.stepId,
            })
          : templateToNode(args.addTemplate, onChange, {
              id: args.addTemplate.stepId,
            });

        updateQuestionAndTemplate({
          addedNode,
          getNode,
          getNodes,
          onNodesChange,
          onChangeProp,
        });
      }

      if (args.deletedQuestion || args.deletedTemplates) {
        const deletedNode = getNodes().find(
          (node) =>
            (args.deletedQuestion &&
              node.data?.question?.id === args.deletedQuestion) ||
            (args.deletedTemplates &&
              node.data?.template?.id === args.deletedTemplates),
        );
        if (!deletedNode) {
          return;
        }
        updateQuestionAndTemplate({
          deletedNode,
          getNode,
          getNodes,
          onNodesChange,
          onChangeProp,
        });
        setEdges((prevEdges) =>
          prevEdges.filter((e) => e.source !== deletedNode.id),
        );
      }

      if (args.addStep) {
        const { to, link } = args.addStep;
        const toNode = stepToNode(to, onChange, link);
        const newEdge = stepsEdge(link.stepId, link.nextStepId);
        onNodesChange([{ type: 'add', item: toNode }]);
        onEdgesChange([{ type: 'add', item: newEdge }]);
      }

      if (args.addStepLink) {
        const newEdge = stepsEdge(
          args.addStepLink.stepId,
          args.addStepLink.nextStepId,
        );
        const stepNode = getNode(`step-${args.addStepLink.nextStepId}`);
        stepNode.data.allLinks = [
          ...(stepNode.data.allLinks ?? []),
          args.addStepLink,
        ];
        if (stepNode.data.allLinks.length > 1) {
          newEdge.deletable = true;
        }
        onEdgesChange([{ type: 'add', item: newEdge }]);
      }

      if (args.deleteStep) {
        const stepNode = getNodes().find(
          (node) =>
            node.type === 'step' && node.data.step.id === args.deleteStep,
        );
        const stepEdges = getEdges().filter(
          (e) => e.target === `step-${args.deleteStep}`,
        );
        const innerStepNodes = getNodes().filter(
          (node) => node.parentId === stepNode.id,
        );
        const innerStepEdges = getEdges().filter((edge) =>
          innerStepNodes.find((node) => node.id === edge.source),
        );
        onNodesChange([
          { type: 'remove', id: stepNode.id },
          ...innerStepNodes.map(
            (node) => ({ type: 'remove', id: node.id }) as const,
          ),
        ]);
        onEdgesChange(
          stepEdges
            .map((edge) => ({ type: 'remove', id: edge.id }) as const)
            .concat(
              innerStepEdges.map(
                (edge) => ({ type: 'remove', id: edge.id }) as const,
              ),
            ),
        );
      }

      setTimeout(() => {
        window.requestAnimationFrame(() => {
          const layout = getLayout(getNodes(), getEdges());
          setNodes(layout.nodes);
          setEdges(layout.edges);

          // window.requestAnimationFrame(() => {
          // fitView();
          // });
        });
      }, 10);
    },
    [
      getEdges,
      getNode,
      getNodes,
      onChangeProp,
      onEdgesChange,
      onNodesChange,
      setEdges,
      setNodes,
      // fitView,
    ],
  );

  useEffect(() => {
    const { stepsNodes, innerNodes, conditionEdges } = apiToNodes(
      form,
      questions,
      templates,
      onChange,
    );

    const layout = getLayout(
      [...stepsNodes, ...innerNodes],
      [...conditionEdges],
    );
    setNodes(layout.nodes);
    setEdges(layout.edges);

    window.requestAnimationFrame(() => {
      fitView();
    });
    // Only make the effect run once on mount. User is free to change layout after that.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fitView, setNodes, setEdges]);

  const handleChanges = useCallback(
    (changes: NodeChange[]) => {
      const movingChange = changes.find(
        (change) =>
          change.type === 'position' &&
          (change.id.startsWith('question') ||
            change.id.startsWith('template')),
      ) as NodePositionChange | undefined;

      const movingStepChange = changes.find((change) => {
        return (
          change.type === 'position' &&
          (change.id.startsWith('step') ||
            change.id.startsWith('condition-link'))
        );
      }) as NodePositionChange | undefined;

      if (movingChange) {
        const movingNode = getNode(movingChange.id);

        if (!movingChange.dragging) {
          const fillerNodes = getNodes().filter(
            (node) => node.type === 'filler',
          );

          const removeChanges: NodeRemoveChange[] = fillerNodes.map((node) => ({
            type: 'remove',
            id: node.id,
          }));
          const innerNodes = getNodes().filter(
            (node) =>
              node.parentId === movingNode.parentId && node.type !== 'filler',
          ) as (QuestionNodeType | TemplateNodeType)[];
          const stepNode = getNode(movingNode.parentId);

          const layoutedInnerNodes = sortBy(innerNodes, 'position.y');
          positionNodesInStep(layoutedInnerNodes, stepNode);

          const nodeChanges: NodePositionChange[] = layoutedInnerNodes.map(
            (node) => ({
              type: 'position',
              id: node.id,
              position: node.position,
              positionAbsolute: node.positionAbsolute,
            }),
          );

          layoutedInnerNodes.forEach((node, index) => {
            const content =
              node.type === 'question'
                ? node.data.question
                : node.data.template;
            if (content.order !== index + 1) {
              if (node.type === 'question') {
                onChangeProp({
                  updatedQuestion: {
                    ...content,
                    order: index + 1,
                  } as Question,
                });
              } else {
                onChangeProp({
                  updatedTemplate: {
                    ...content,
                    order: index + 1,
                  } as Template,
                });
              }
            }
          });

          onNodesChange([...removeChanges, ...nodeChanges, ...changes]);
        } else {
          const innerNodes = getNodes().filter(
            (node) =>
              node.parentId === movingNode.parentId &&
              node.id !== movingNode.id,
          );
          const stepNode = getNode(movingNode.parentId);

          const addChanges = [];
          let filler = innerNodes.find((node) => node.type === 'filler');

          if (!filler) {
            filler = fillerNode(movingNode);
            const addChange: NodeAddChange = { type: 'add', item: filler };
            changes.push(addChange);
            addChanges.push(filler);
          }

          filler.position = movingChange.position;

          const layoutedInnerNodes = sortBy(innerNodes, 'position.y');
          positionNodesInStep(layoutedInnerNodes, stepNode);

          const nodeChanges: NodePositionChange[] = layoutedInnerNodes.map(
            (node) => ({
              type: 'position',
              id: node.id,
              position: node.position,
              positionAbsolute: node.positionAbsolute,
            }),
          );

          onNodesChange([...addChanges, ...nodeChanges, ...changes]);
        }
        return;
      }

      if (movingStepChange) {
        if (!movingStepChange.dragging) {
          const fillerNodes = getNodes().filter(
            (node) => node.type === 'filler',
          );

          const removeChanges: NodeRemoveChange[] = fillerNodes.map((node) => ({
            type: 'remove',
            id: node.id,
          }));

          const finalPosition = fillerNodes?.[0]?.position;
          if (finalPosition !== undefined) {
            const parentEdge = getEdges().find(
              (edge) => edge.target === movingStepChange.id,
            );
            const otherStepIds = getEdges()
              .filter(
                (e) => e.source === parentEdge.source && e.id !== parentEdge.id,
              )
              .map((e) => e.target);
            const stepNodes = [
              getNode(movingStepChange.id),
              ...getNodes().filter((node) => otherStepIds.includes(node.id)),
            ];
            stepNodes[0].position = finalPosition;

            const orderedNodes = sortBy(
              stepNodes,
              'position.x',
            ) as StepNodeType[];

            orderedNodes.forEach((node, index) => {
              const link = node.data.link;
              if (link && link.order !== index + 1) {
                link.order = index + 1;
                onChangeProp({ updatedLink: link as StepLink });
              }
            });

            onNodesChange([
              ...removeChanges,
              {
                type: 'position',
                id: movingStepChange.id,
                position: finalPosition,
                positionAbsolute: finalPosition,
              },
            ]);
          }
        } else {
          const movingNode = getNode(movingStepChange.id);
          const addChanges = [];
          let filler = getNodes().find((node) => node.type === 'filler');

          if (!filler) {
            filler = fillerNode(movingNode);
            const addChange: NodeAddChange = { type: 'add', item: filler };
            changes.push(addChange);
            addChanges.push(filler);
          }

          const fillerPosition = filler.position;
          filler.position = movingStepChange.position;

          const parentEdge = getEdges().find(
            (edge) => edge.target === movingNode.id,
          );
          const otherIds = getEdges()
            .filter(
              (e) => e?.source === parentEdge.source && e.id !== parentEdge.id,
            )
            .map((e) => e.target);

          const otherNodes = getNodes().filter((node) =>
            otherIds.includes(node.id),
          );

          const layoutedNodes = sortBy([...otherNodes, filler], 'position.x');
          filler.position = fillerPosition;
          positionSteps(layoutedNodes);

          const nodeChanges: NodePositionChange[] = layoutedNodes.map(
            (node) => ({
              type: 'position',
              id: node.id,
              position: node.position,
              positionAbsolute: node.positionAbsolute,
            }),
          );

          onNodesChange([...addChanges, ...nodeChanges, ...changes]);
        }
      }
      onNodesChange(
        changes
          .filter((change) => change.type !== 'remove') // in case
          .filter(
            (change) =>
              !(change.type === 'position' && change.id.startsWith('question')),
          ),
      );
    },
    [getEdges, getNode, getNodes, onChangeProp, onNodesChange],
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={handleChanges}
      onEdgesChange={(changes) => {
        const additionalChanges = [];
        const removalChanges = changes.filter(
          (change) => change.type === 'remove',
        );
        removalChanges.forEach((change) => {
          const edge = getEdge(change.id);
          if (edge) {
            const sourceNode = getNode(edge.source) as StepNodeType | undefined;
            const targetNode = getNode(edge.target) as StepNodeType | undefined;
            if (targetNode && targetNode.type === 'step' && sourceNode) {
              targetNode.data.allLinks = targetNode.data.allLinks.filter(
                (link) => link.stepId !== sourceNode.data.step.id,
              );
              sourceNode.data.step.links = sourceNode.data.step.links?.filter(
                (link) => link.nextStepId !== targetNode.data.step.id,
              );

              // if there is only one link left, remove the possibility to delete the edge
              if (targetNode.data.allLinks.length === 1) {
                const edgeToReset = stepsEdge(
                  targetNode.data.allLinks[0].stepId,
                  targetNode.data.allLinks[0].nextStepId,
                  false,
                );
                additionalChanges.push({ type: 'remove', id: edge.id });
                additionalChanges.push({ type: 'add', item: edgeToReset });
              }
            }
          }
        });
        onEdgesChange([...changes, ...additionalChanges]);
      }}
      nodeTypes={nodeTypes}
      fitView
    >
      <Panel position="top-left">
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={() => {
              navigate('/forms');
            }}
          >
            <ArrowBackIos />
          </IconButton>
          <ClampTypography
            variant="h1"
            sx={{
              fontWeight: 'bold',
              maxWidth: '400px', // @ts-ignore
            }}
            color="primary"
          >
            {form.title}
          </ClampTypography>
        </Box>
      </Panel>
      <Panel position="top-right">
        <FormSettingsModal
          form={editingForm ? form : null}
          onClose={() => {
            setEditingForm(false);
          }}
        />
        <AllLabelsModal
          form={form}
          questions={questions}
          templates={templates}
          open={editingLabels}
          onClose={() => {
            setEditingLabels(false);
          }}
          onChange={onChange}
        />
        <AllTagsModal
          form={form}
          questions={questions}
          open={editingTags}
          onClose={() => {
            setEditingTags(false);
          }}
          onChange={onChange}
        />

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            onClick={() => {
              setEditingLabels(true);
            }}
            variant="contained"
            startIcon={<Label />}
          >
            {translate('forms.edit.labels')}
          </Button>
          <Button
            onClick={() => {
              setEditingTags(true);
            }}
            variant="contained"
            startIcon={<Label />}
          >
            {translate('forms.edit.tags')}
          </Button>
          <Button
            onClick={() => {
              setEditingForm(true);
            }}
            variant="contained"
            startIcon={<Settings />}
          >
            {translate('common.settings')}
          </Button>
          <Button
            onClick={() => {
              onSave({ form, questions, templates });
              return false;
            }}
            variant="contained"
            startIcon={
              isLoading ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <SaveIcon />
              )
            }
            disabled={isLoading}
          >
            {translate('generic.save')}
          </Button>
        </Box>
      </Panel>
      <Background />
      <Controls />
    </ReactFlow>
  );
};
