import { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router';

import { useCurrentCountryContext } from '@hooks/useCurrentCountry';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, List, ListItem, Paper, Typography } from '@mui/material';

import { Loader } from '../../components/Loader';
import { usePlannings } from '../../hooks/plannings';
import { useSelector } from '../../store';
import { PlanningStatus } from '../../types/planning';
import { titleToPlanningJob } from '../../types/user';

export const PlanningAvailabilitiesList = () => {
  const jobTitle = useSelector((state) => state.user.jobTitle);
  const [country] = useCurrentCountryContext();
  const { data: plannings, isLoading } = usePlannings({
    status: PlanningStatus.ADMIN_SLOTS_ASSIGNED,
    job: titleToPlanningJob[jobTitle],
    countries: !country || country === 'all' ? 'all' : [country],
  });
  const navigate = useNavigate();
  const translate = useTranslate();

  useEffect(() => {
    if (plannings?.length && plannings.length === 1) {
      navigate(`/planning-availabilities/${plannings[0].id}`);
    }
  }, [navigate, plannings]);

  if (isLoading) {
    return <Loader />;
  }

  if (plannings?.length === 0) {
    return (
      <Paper sx={{ padding: '1rem', height: '100%' }}>
        <Typography>{translate('planning.notYetOpen')}</Typography>
      </Paper>
    );
  }
  return (
    <Paper
      elevation={0}
      sx={{
        padding: 0,
        paddingX: 2,
        margin: 0,
        marginTop: '1rem',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <Typography color="primary" variant="h1" sx={{ fontWeight: 'bold' }}>
        {translate('planning.mine')}
      </Typography>
      <List dense>
        {plannings?.map((planning) => (
          <ListItem
            key={planning.id}
            disableGutters
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ marginRight: 1 }}>{planning.title}</Typography>
            <IconButton
              onClick={() => {
                navigate(`/planning-availabilities/${planning.id}`);
              }}
            >
              <EditIcon color="primary" />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};
