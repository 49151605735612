import { countryCode, CountryCodeToCountryFlag } from '@boTypes/common';

interface NamedObject {
  firstName: string;
  lastName?: string;
  chatCountries?: countryCode[];
}

export const getFullName = ({
  firstName,
  lastName,
  chatCountries,
}: NamedObject) =>
  [
    firstName,
    lastName,
    ...(chatCountries?.flatMap((c) => CountryCodeToCountryFlag[c]) ?? []),
  ]
    .filter(Boolean)
    .join(' ')
    .trim();
