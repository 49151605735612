import { useTranslate } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { TextInput } from '@components/forms/textInput';
import { Question, QuestionType } from '@teammay/form-core';

export type ScaleQuestion = Omit<
  Question<QuestionType.SCALE>,
  'id' | 'createdAt' | 'updatedAt'
> & {
  min: number;
  max: number;
  step: number;
};

export const ScaleQuestionEdit = ({
  onBlur,
  labelOnly = false,
}: {
  onBlur: () => void;
  labelOnly?: boolean;
}) => {
  const { control } = useFormContext<ScaleQuestion>();
  const type = useWatch({ control, name: 'type' });
  const t = useTranslate();
  if (type !== QuestionType.SCALE) {
    return null;
  }
  return (
    <>
      {!labelOnly && (
        <>
          <TextInput
            control={control}
            name={'options.sliderType'}
            label={t('forms.questions.scale.type')}
            onBlur={onBlur}
            rules={{
              required: false,
            }}
          />
          <TextInput
            control={control}
            name={'options.min'}
            label={t('generic.minValue')}
            onBlur={onBlur}
            rules={{
              required: true,
            }}
            defaultValue={1}
          />
          <TextInput
            control={control}
            name={'options.max'}
            label={t('generic.maxValue')}
            onBlur={onBlur}
            rules={{
              required: true,
            }}
            defaultValue={5}
          />
        </>
      )}
      <TextInput
        control={control}
        name={'options.minimumLabel'}
        label={t('forms.questions.scale.minimumLabel')}
        onBlur={onBlur}
        rules={{
          required: false,
        }}
      />
      <TextInput
        control={control}
        name={'options.maximumLabel'}
        label={t('forms.questions.scale.maximumLabel')}
        onBlur={onBlur}
        rules={{
          required: false,
        }}
      />
      {!labelOnly && (
        <TextInput
          control={control}
          name={'options.step'}
          label={t('generic.step')}
          onBlur={onBlur}
          rules={{
            required: true,
          }}
          defaultValue={1}
        />
      )}
      <TextInput
        control={control}
        name={'label'}
        label={t('forms.questions.label')}
        onBlur={onBlur}
        rules={{
          required: true,
        }}
      />
    </>
  );
};
