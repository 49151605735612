import dayjs from 'dayjs';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { View } from 'react-native';
import {
  createContainer,
  Flyout,
  Point,
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryStack,
  VictoryTooltip,
} from 'victory';
import { VictoryThemeDefinition, PointProps, VictoryLabel } from 'victory-core';

import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
} from '@mui/material';

import { OMSCurve, OMSDataTypes, orderedOMSCurve } from './types';
import {
  getDataOnScale,
  getOMSReferenceData,
  getVerticalLineData,
  getYDomain,
} from './utils';
import { colorTokens, opacity } from '../../themes';
import { getAge } from '../../utils/getAge';

const mapping = ['-3', '-2', '-1', 'M', '+1', '+2', '+3'];

const OMSTable = ({ values }: { values: number[] }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        {mapping.map((key) => (
          <TableCell
            sx={{ fontSize: 12, padding: 0, margin: 0, textAlign: 'center' }}
          >
            {`${key}`}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        {values.map((_value) => (
          <TableCell sx={{ fontSize: 12, padding: 0 }}>{_value}</TableCell>
        ))}
      </TableRow>
    </TableBody>
  </Table>
);

const theme: VictoryThemeDefinition = {
  // VictoryAxis
  axis: {
    style: {
      axis: {
        stroke: colorTokens.content.subtleBackground,
        strokeWidth: 1,
      },
      axisLabel: {
        fill: colorTokens.content.primary,
        padding: 8,
      },
      grid: { stroke: 'none' },
      ticks: { stroke: 'none' },
      tickLabels: {
        fill: 'none',
        padding: 8,
      },
    },
  },
  // VictoryLine
  line: {
    style: {
      data: { stroke: colorTokens.content.primary, strokeWidth: 2 },
      labels: {
        fill: colorTokens.content.primary,
        verticalAnchor: 'middle',
      },
    },
  },
  chart: {
    padding: {
      top: 30,
      bottom: 50,
      left: 60,
      right: 40,
    },
  },
  // VictoryScatter
  scatter: {
    style: {
      data: {
        fill: colorTokens.content.primary,
      },
      labels: {
        fill: colorTokens.content.primary,
        verticalAnchor: 'middle',
      },
    },
  },
};

const areaLineColor = colorTokens.content.secondary;

const VictoryZoomVoronoiContainer = createContainer(
  'zoom',
  'voronoi',
) as React.ComponentType<any>;

const ignoredVoronoi = [
  ...Object.values(OMSCurve),
  'plottedLine',
  'periodLine1m',
  'periodLine6m',
  'periodLine1a',
];

const referenceColors = [
  'transparent',
  colorTokens.content.subtleBackground + opacity[80],
  colorTokens.content.subtle + opacity[80],
  colorTokens.content.secondary + opacity[80],
  colorTokens.content.secondary + opacity[80],
  colorTokens.content.subtle + opacity[80],
  colorTokens.content.subtleBackground + opacity[80],
  'transparent',
];

const LabelComponent = (props) => (
  <VictoryLabel
    backgroundStyle={{ fill: colorTokens.surface.primary }}
    backgroundPadding={{ top: 4, bottom: 4 }}
    {...props}
  />
);

const TooltipComponent = (props) => {
  const [value, age, date, ...omsValues] = props.text;
  const { x, y } = props;
  return (
    <foreignObject height={100} width={200} x={x - 100} y={y - 50}>
      <Box
        sx={{
          backgroundColor: 'primary.main10',
          padding: '0.5rem',
          borderRadius: '0.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
            {value}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {[age, date].map((el) => (
              <Typography key={el} sx={{ fontSize: 12 }}>
                {el}
              </Typography>
            ))}
          </Box>
        </Box>
        <OMSTable values={omsValues} />
      </Box>
    </foreignObject>
  );
};

const VictoryLineStyle = {
  data: {
    strokeDasharray: '5,5',
    stroke: colorTokens.content.primary,
    strokeWidth: 1,
  },
};

const getDateAndAge = (initialDate: Date, date: Date, locale = 'fr-FR') => {
  const ageToDisplay = getAge(initialDate, date);
  return [
    ageToDisplay,
    new Date(date).toLocaleDateString(locale, {
      day: 'numeric',
      month: 'short',
    }),
  ];
};

const MyPoint = (props: PointProps & { selected: number | undefined }) =>
  props.index === props.selected ? (
    <Point
      {...props}
      size={6}
      // svg style does not work with stylesheet
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        fill: colorTokens.surface.primary,
        stroke: colorTokens.content.primary,
        strokeWidth: 2,
      }}
    />
  ) : (
    <Point {...props} size={5} />
  );

interface NormalizedChartsProps {
  data?: { date: Date | string; value: number }[];
  initialDate?: Date;
  dataType?: OMSDataTypes;
  maximumMonths?: number;
  onChangeSelected: (index: number) => void;
  selected: number | undefined;
  height: number;
  width?: number;
}

const defaultInitialDate = new Date();

const NormalizedCharts = ({
  data = [],
  dataType = OMSDataTypes.BOY_WEIGHT,
  initialDate = defaultInitialDate,
  maximumMonths = 6,
  onChangeSelected,
  selected,
  height,
  width,
}: NormalizedChartsProps) => {
  const translate = useTranslate();
  const locale = useLocale();
  const usedScale = maximumMonths <= 6 ? 'week' : 'month';
  const maximumScale = maximumMonths <= 6 ? maximumMonths * 5 : maximumMonths;

  const [zoomedDomain, setZoomedDomain] = useState<[number, number]>([
    0,
    maximumScale,
  ]);
  const [actualWidth, setActualWidth] = useState(400);

  const userData = useMemo(() => {
    return getDataOnScale(data, initialDate, usedScale);
  }, [data, initialDate, usedScale]);

  const plottedData = useMemo(
    () =>
      userData.filter((d) => d.x >= zoomedDomain[0] && d.x <= zoomedDomain[1]),
    [userData, zoomedDomain],
  );

  const firstIndex = useMemo(() => {
    return plottedData.findIndex((d) => d.x >= zoomedDomain[0]);
  }, [plottedData, zoomedDomain]);

  const referenceData = useMemo(() => {
    return getOMSReferenceData(dataType, initialDate, maximumScale, usedScale);
  }, [dataType, initialDate, maximumScale, usedScale]);
  const useDomain = useMemo(
    () => ({
      x: [0, maximumScale] as [number, number],
      y: getYDomain(zoomedDomain, plottedData, referenceData),
    }),
    [maximumScale, plottedData, referenceData, zoomedDomain],
  );

  const DataLabel = useCallback(({ unit, viewWidth, ...props }) => {
    return (
      <VictoryTooltip
        {...props}
        dx={({ x }) => {
          if (x + 120 > viewWidth) {
            return viewWidth - x - 120;
          }
          return Math.max(0, 100 - x);
        }}
        dy={({ y }) => {
          return y > 120 ? -12 : 12;
        }}
        style={{ padding: 6, fontSize: 14 }}
        flyoutStyle={{ strokeWidth: 0, fill: colorTokens.surface.subtle }}
        width={200}
        height={100}
        flyoutHeight={100}
        flyoutWidth={200}
        flyoutComponent={<Flyout dx={0} />}
        labelComponent={<TooltipComponent />}
      />
    );
  }, []);

  const unit =
    dataType === OMSDataTypes.BOY_HEIGHT ||
    dataType === OMSDataTypes.GIRL_HEIGHT
      ? 'cm'
      : 'kg';

  return (
    <View onLayout={(e) => setActualWidth(e.nativeEvent.layout.width)}>
      <VictoryChart
        height={height}
        width={width}
        theme={theme}
        containerComponent={
          <VictoryZoomVoronoiContainer
            zoomDimension="x"
            voronoiBlacklist={ignoredVoronoi}
            onActivated={(points) => {
              if (!points.length) {
                return;
              }
              onChangeSelected(points[0].eventKey);
            }}
            onZoomDomainChange={(dom) => {
              if (dom.x && dom.x.length === 2) {
                // with the context of the chart, we can safely ignore the error
                // @ts-ignore
                setZoomedDomain(dom.x);
              }
            }}
          />
        }
        // The four following props are required to make the chart responsive
        domain={useDomain}
        categories={{ x: [], y: [] }}
        scale={{ x: 'linear', y: 'linear' }}
        defaultAxes={undefined}
      >
        <VictoryStack
          domain={useDomain}
          categories={{ x: [], y: [] }}
          scale={{ x: 'linear', y: 'linear' }}
          // forgotten by the library
          // @ts-ignore
          datasets={orderedOMSCurve.map((key) => referenceData[key])}
        >
          {orderedOMSCurve.map((key, index) => (
            <VictoryArea
              key={key}
              name={key}
              style={{
                data: {
                  stroke: areaLineColor,
                  fill: referenceColors[index],
                },
              }}
              data={referenceData[key]}
            />
          ))}
        </VictoryStack>
        <VictoryAxis
          dependentAxis
          tickFormat={(tick) => `${tick}`}
          style={{
            tickLabels: { fill: colorTokens.content.primary },
            axisLabel: { padding: 30 },
            grid: { stroke: colorTokens.content.subtle, strokeWidth: 0.5 },
          }}
          label={unit}
        />
        {/* Adds horizontal lines for labels */}
        <VictoryLine
          name={'horizontalLine'}
          data={[
            { x: 0, y: useDomain.y[0] },
            { x: maximumScale, y: useDomain.y[0] },
          ]}
          style={VictoryLineStyle}
          labels={['', '0']}
          labelComponent={<LabelComponent />}
        />
        {/* Vertical lines at 1month, 6months and one year */}
        <VictoryLine
          name={'periodLine1m'}
          data={getVerticalLineData(
            initialDate,
            useDomain.y,
            height,
            dayjs(initialDate).add(1, 'month').toDate(),
            usedScale,
          )}
          style={VictoryLineStyle}
          labels={['', '1m']}
          labelComponent={<LabelComponent />}
        />
        <VictoryLine
          name={'periodLine6m'}
          data={getVerticalLineData(
            initialDate,
            useDomain.y,
            height,
            dayjs(initialDate).add(6, 'month').toDate(),
            usedScale,
          )}
          style={VictoryLineStyle}
          labels={['', '6m']}
          labelComponent={<LabelComponent />}
        />
        <VictoryLine
          name={'periodLine1a'}
          data={getVerticalLineData(
            initialDate,
            useDomain.y,
            height,
            dayjs(initialDate).add(1, 'year').toDate(),
            usedScale,
          )}
          style={VictoryLineStyle}
          labels={['', translate('tools.common.oneYearShort')]}
          labelComponent={<LabelComponent />}
        />
        <VictoryAxis
          style={{
            tickLabels: { fill: colorTokens.content.primary },
            axisLabel: { padding: 25 },
          }}
          label={
            usedScale === 'month'
              ? translate('common.month')
              : translate('common.weeks')
          }
        />
        <VictoryScatter
          dataComponent={<MyPoint selected={firstIndex + selected} />}
          data={plottedData}
          labels={({ datum }) => [
            `${datum.y.toFixed(1)} ${unit}`,
            ...getDateAndAge(initialDate, datum.date, locale),
            ...orderedOMSCurve.map((key) => {
              const ref = referenceData[key].find(
                (refPoint) => refPoint.x + 0.5 > datum.x,
              );
              return `${ref?._y1.toFixed(1)}`;
            }),
          ]}
          labelComponent={<DataLabel unit={unit} viewWidth={actualWidth} />}
        />
        <VictoryLine name={'plottedLine'} data={plottedData} />
      </VictoryChart>
    </View>
  );
};

export default memo<NormalizedChartsProps>(NormalizedCharts);
