import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@hooks/queryWrappers';

import { Discussion } from '../../types';
import { FamilyActiveMembers } from '../../types/family';
import { stringToColor } from '../../utils/color';
import { preciseAmenorrheaWeeks } from '../../utils/date';
import { getAge } from '../../utils/getAge';
import { AvatarFamilyList } from '../AvatarList';

const useFamily = ({ family }: { family: FamilyActiveMembers }) => {
  if (!family.appUsers) {
    return;
  }
  // children
  const children = (family.children ?? [])
    .sort((a, b) => dayjs(b.birthDay).diff(dayjs(a.birthDay)))
    .map((child) => ({
      firstName: child.firstName,
      color: stringToColor(child.firstName.trim()),
      extra: getAge(child.birthDay),
      id: child.id,
      avatar: child.image,
      birthTermWeeks: child.birthTermWeeks,
    }));

  // appUsers
  const pregnancy = family.appUsers.find((user) => user.pregnancies.length > 0)
    ?.pregnancies?.[0];
  const appUserIdOwningPregnancy = pregnancy?.appUserId;
  const appUsers = family.appUsers.map((user) => ({
    firstName: `${user.firstName}${
      !user.lastName && appUserIdOwningPregnancy === user.id ? ' 🤰' : ''
    }`,
    lastName:
      `${user.lastName ?? ''}${
        appUserIdOwningPregnancy === user.id ? ' 🤰' : ''
      }` || '',
    color: stringToColor(`${user.firstName} ${user.lastName ?? ''}`.trim()),
    extra: `${
      appUserIdOwningPregnancy === user.id
        ? preciseAmenorrheaWeeks(pregnancy.expectedEnd)
        : ''
    }`,
    id: user.id,
  }));

  return { children, appUsers };
};

export const FamilyAvatars = ({
  family,
  size,
  callback,
}: {
  family: FamilyActiveMembers;
  size?: 'default' | 'dense' | 'medium';
  callback?: () => void;
}) => {
  const navigate = useNavigate();

  const { mutate: getChildDiscussion } = useMutation<Discussion, any, number>(
    ['childDiscussion'],
    (id) => ({ url: `/api/discussions/fromKid/${id}` }),
    {
      onSuccess: (discussion) => {
        navigate(`/nursesubjects/${discussion.id}`);
        callback && callback();
      },
    },
  );

  const { mutate: getPregnancyDiscussion } = useMutation<
    Discussion,
    any,
    number
  >(
    ['appUserDiscussion'],
    (id) => ({ url: `/api/discussions-midwife/fromAppUser/${id}` }),
    {
      onSuccess: (discussion) => {
        navigate(`/midwifesubjects/${discussion.id}`);
        callback && callback();
      },
    },
  );

  const { children, appUsers } = useFamily({ family });

  return (
    <AvatarFamilyList
      appUsers={appUsers}
      children={children}
      handleChildClick={getChildDiscussion}
      handlePregnancyClick={getPregnancyDiscussion}
      size={size}
    />
  );
};
