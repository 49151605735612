import React, { useCallback, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import {
  CMSCard,
  CMSCardActionArea,
  CMSCardSentOverlay,
  CMSCardTitle,
} from '@components/cmsCard';
import { SuggestionContentType } from '@components/discussions/chat/contentSuggestion/types';
import { useHandleLongPress } from '@hooks/useHandleLongPress';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import {
  Box,
  CardActions,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import {
  logContentSuggestionView,
  logContentView,
} from '../../analytics/events';
import { Post } from '../../types';
import { SendIcon } from '../discussions/SendIcon';
import { DialogTitle } from '../generic/Dialog';

export const WIDTH = 208;
const IMAGE_HEIGHT = 80;
const TITLE_LINE_NB = 2;
const TITLE_HEIGHT = TITLE_LINE_NB * 24;
const TITLE_PADDING = 2 * 8;
export const HEIGHT = IMAGE_HEIGHT + TITLE_HEIGHT + TITLE_PADDING;

const StyledImage = styled('img')({
  marginLeft: '-24px',
  marginRight: '-24px',
  width: 'calc(100% + 48px)',
  height: '250px',
  objectFit: 'cover',
});

const StyledImageNoCover = styled('img')({
  width: '100%',
  height: '250px',
  objectFit: 'cover',
});

const InnerPost = ({
  item,
  double = false,
}: {
  item: Post;
  double?: boolean;
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      {double ? (
        <StyledImageNoCover
          src={item?.cover?.url ?? item?.coverThumbnailUrl}
          alt={item.title}
        />
      ) : (
        <StyledImage
          src={item?.cover?.url ?? item?.coverThumbnailUrl}
          alt={item.title}
        />
      )}
      {item?.authorNames?.length > 0 && (
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          {item?.authorNames?.join(', ')}
        </Typography>
      )}
      <Box
        sx={{
          maxWidth: '100%',
          '& p > img': { width: '100%' },
          '& p': { wordWrap: 'break-word' },
        }}
      >
        <ReactMarkdown>{item.content}</ReactMarkdown>
      </Box>
    </Box>
  );
};

export const PostItemDialog = ({
  item,
  translatedItem,
  open,
  setOpen,
}: {
  item: Post;
  translatedItem?: Post;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const close = useCallback(() => setOpen(false), [setOpen]);
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby={item.title}
      aria-describedby={item.title}
      maxWidth={translatedItem ? 'xl' : 'lg'}
    >
      <DialogTitle
        onClose={close}
        sx={{
          fontFamily: 'Lora, serif',
          fontWeight: 600,
          color: 'text.primary',
        }}
      >
        {translatedItem ? (
          <Stack direction="row" gap={6} sx={{ width: '100%' }}>
            <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
              {item.title}
            </Stack>
            <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
              {translatedItem.title}
            </Stack>
          </Stack>
        ) : (
          item.title
        )}
      </DialogTitle>
      <DialogContent>
        {translatedItem ? (
          <Stack
            direction="row"
            justifyContent={'space-between'}
            gap={1}
            divider={<Divider orientation="vertical" flexItem />}
            sx={{ width: '100%' }}
          >
            <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
              <InnerPost item={item} double={true} />
            </Stack>
            <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
              <InnerPost item={translatedItem} double={true} />
            </Stack>
          </Stack>
        ) : (
          <InnerPost item={item} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export const PostItem = ({
  item: post,
  onSend,
  onLongPress,
  fromSuggestion,
  subjectId,
}: {
  item: Post;
  onSend?: (
    {
      type,
      content,
    }: {
      type: DiscussionEventType;
      content: string;
    },
    contentType: string,
    contentId: number,
    fromSuggestion?: boolean,
    subjectId?: number,
  ) => void;
  onLongPress?: () => void;
  fromSuggestion?: boolean;
  subjectId?: number;
}) => {
  const { cover, title } = post;
  const { formats, url } = cover || { url: post?.coverThumbnailUrl };
  const [open, setOpen] = useState(false);
  const onClick = useCallback(() => {
    if (fromSuggestion) {
      logContentSuggestionView(subjectId, SuggestionContentType.POSTS, post.id);
    } else {
      logContentView('post', post.id);
    }
    setOpen(true);
  }, [setOpen, post.id, fromSuggestion, subjectId]);
  const postInfo = {
    type: DiscussionEventType.POST,
    content: post.slug,
  };
  const [handlePress, startClick] = useHandleLongPress(onClick, onLongPress);
  return (
    <CMSCard width={WIDTH} height={HEIGHT} elevation={0}>
      {open ? (
        <PostItemDialog open={open} setOpen={setOpen} item={post} />
      ) : null}
      <CMSCardActionArea
        height={HEIGHT}
        onClick={handlePress}
        onMouseDown={startClick}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        {post.sent && <CMSCardSentOverlay />}
        <CardMedia
          component="img"
          alt={title}
          height={String(IMAGE_HEIGHT)}
          image={formats?.thumbnail?.path ?? url}
          title={title}
        />
        <CardContent sx={{ height: TITLE_HEIGHT + TITLE_PADDING, padding: 1 }}>
          <CMSCardTitle
            variant="subtitle2"
            component="h2"
            sx={{
              fontFamily: 'Lora, serif',
              fontWeight: 600,
            }}
            clamp={TITLE_LINE_NB}
          >
            {title}
          </CMSCardTitle>
        </CardContent>
        {post.sent && (
          <DownloadDoneIcon
            sx={(theme) => ({
              position: 'absolute',
              left: theme.spacing(1),
              bottom: theme.spacing(0),
            })}
          />
        )}
      </CMSCardActionArea>
      {onSend ? (
        <CardActions sx={{ padding: 0 }}>
          <SendIcon
            onClick={() =>
              onSend(
                postInfo,
                DiscussionEventType.POST,
                post.id,
                fromSuggestion,
                subjectId,
              )
            }
          />
        </CardActions>
      ) : null}
    </CMSCard>
  );
};
