import {
  Datagrid,
  DateField,
  TextField,
  useLocale,
  useTranslate,
} from 'react-admin';

import { List } from '../../components/generic/List';

export const ScenariosList = (props) => {
  const translate = useTranslate();
  const locale = useLocale();

  return (
    <List {...props}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" label={translate('common.name')} />
        <DateField
          locales={locale}
          source="created"
          label={translate('scenario.created')}
        />
      </Datagrid>
    </List>
  );
};
