import { useTranslate } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { Row } from '@components/generic/Row';
import { Close } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { Question, QuestionType } from '@teammay/form-core';

import { CommonQuestionEdit } from './CommonQuestionEdit';
import { ImageQuestionEdit } from './ImageQuestionEdit';
import { RadioQuestionEdit } from './RadioQuestionEdit';
import { ScaleQuestionEdit } from './ScaleQuestionEdit';
import { SelectQuestionEdit } from './SelectQuestionEdit';
import { TextQuestionEdit } from './TextQuestionEdit';

export type QuestionForm = Omit<Question, 'createdAt' | 'updatedAt'>;

export const QuestionEdit = ({
  labelOnly = false,
  onBlur,
  onCancel,
}: {
  labelOnly?: boolean;
  onBlur: () => void;
  onCancel: () => void;
}) => {
  const translate = useTranslate();
  const { control } = useFormContext<QuestionForm>();
  const type = useWatch({ control, name: 'type' });
  return (
    <>
      {!labelOnly && (
        <Row>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {translate('forms.questions.formTitle')}
          </Typography>
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </Row>
      )}
      <CommonQuestionEdit
        control={control}
        onBlur={onBlur}
        labelOnly={labelOnly}
      />
      {type === QuestionType.RADIO && (
        <RadioQuestionEdit onBlur={onBlur} labelOnly={labelOnly} />
      )}
      {type === QuestionType.SELECT && (
        <SelectQuestionEdit onBlur={onBlur} labelOnly={labelOnly} />
      )}
      {type === QuestionType.TEXT && (
        <TextQuestionEdit onBlur={onBlur} labelOnly={labelOnly} />
      )}
      {type === QuestionType.SCALE && (
        <ScaleQuestionEdit onBlur={onBlur} labelOnly={labelOnly} />
      )}
      {type === QuestionType.IMAGE && (
        <ImageQuestionEdit onBlur={onBlur} labelOnly={labelOnly} />
      )}
    </>
  );
};
