import { createContext, useContext } from 'react';

import { Form, Template } from '@teammay/form-core';
import { Question } from '@teammay/form-core';

const CurrentFormContext = createContext<{
  form: Form;
  questions: Question[];
  templates: Template[];
}>({
  form: null,
  questions: [],
  templates: [],
});

export const CurrentFormProvider = CurrentFormContext.Provider;
export const useCurrentForm = () => {
  return useContext(CurrentFormContext);
};
