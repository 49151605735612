// For anyone who might need it, here is a median point « · »

export const domainMessages = {
  fr: {
    activityPopup: {
      cancelText: 'Terminer ma vacation',
      confirmText: 'Continuer ma vacation',
      stillThere: '%{firstName}, es-tu toujours là ?',
    },
    additionalHours: {
      add: 'Heure de renfort',
      cancelSelection: 'Annuler la sélection',
      comment: 'Commentaire',
      deleteConfirmText:
        'Voulez-vous vraiment supprimer cette heure de renfort ?',
      deleteHours: 'Supprimer',
      duration: 'Durée (min)',
      emptySelection: 'Aucune heure de renfort sélectionnée',
      error:
        'La date de fin doit être après le début, le même jour. La durée entre 15min et 2h.',
      errorCreateShort: 'Erreur lors de la création. (15min minimum, 2h max)',
      errorGeneric: 'Erreur lors de la création',
      errorNoPlanning: 'Aucun planning trouvé',
      hoursDeleted: 'Heures supprimées',
      hoursInvalidated: 'Heures invalidées',
      hoursValidated: 'Heures validées',
      invalidateHours: '❌ Invalider',
      selectedRows:
        '1 heure de renfort sélectionnée |||| %{smart_count} heures de renfort sélectionnées',
      startHour: 'Heure de début',
      validateHours: '✅ Valider',
      validated: 'Validée',
    },
    admin: {
      appBundles: {
        list: 'Liste des bundles',
        testers: 'Liste des testeurs',
        title: 'Bundles',
        platform: 'Plateforme',
        runtimeVersion: 'Version',
        status: 'Statut',
        active: 'Actif',
        inactive: 'Inactif',
        testing: 'En test',
        s3Key: 'Clé S3',
      },
      categories: {
        consultations: 'Catégories de consultation',
        questions: 'Catégories de question',
        title: 'Catégories',
      },
      contentTranslation: {
        addContent: 'Ajouter des contenus à traduire',
        alreadyAdded: 'Déjà ajouté',
        changeApprover: "Modifier l'approver",
        contentType: {
          appointment_suggestion: 'Calendrier',
          dailytips: 'Daily tips',
          post: 'Articles',
          masterclass: 'Masterclass',
          weeklytips: 'Weekly tip',
          fertitips: 'Fertitip',
          pregnancy_week_content: 'Contenu grossesse hebdo',
          child_month_content: 'Contenu mensuel enfant',
          todo_list_suggestion: 'Todo suggestion',
          pregnancy_question: 'Enceinte, est-ce que je peux ?',
          macro: 'Macro',
          guide: 'Fiches pratiques',
        },
        dailyTip: {
          weekNumber: 'N° semaine : %{weekNumber}',
        },
        dashboard: 'Dashboard',
        dashboardTitle: 'Suivi de la traduction',
        documentsSent:
          'Documents envoyés. Ils apparaîtront bientôt dans votre dashboard.',
        externalSource: 'Importer depuis un dossier Google Drive',
        extra: 'Extra',
        isChild: 'Enfants',
        isPregnancy: 'Grossesse',
        uploadCMS: 'Uploader sur CMS',
        complete: 'Finaliser',
        pricing: 'Pricing',
        referenceLocale: 'Langue de référence',
        refreshOwnership: "Rafraîchir les droits d'accès",
        refreshOwnershipSuccess:
          'Propriétaire en cours de mise à jour, merci de patienter',
        retryPostCreateActions:
          'Relancer les actions post création (AI & gdoc)',
        retryPostCreateActionsSuccess:
          'Les documents sont en cours de traitement (traduction IA + Ajout sur gdrive). Patience !',
        scope: 'Scope',
        scopes: {
          midwife: 'Sage-femme',
          translator: 'Traducteur',
          gynecologist: 'Gynéco',
          health_visitor: 'Health visitor',
          pediatric_nurse: 'Infirmière puéricultrice',
          pediatrician: 'Pédiatre',
          other: 'Autre',
        },
        selectAll: 'Tout sélectionner',
        selectApprover: 'Sélectionner un approver',
        startTranslation: 'Start',
        targetLocale: 'Langue cible',
        taskAttributed: 'Tâches attribuées',
        type: 'Type de contenu',
        title: 'Traduction de contenu',
        pricingTitle: 'Pricing',
        pricingLabels: {
          default: '%{startPricing} - Default %{country}',
          specific: '%{startPricing} %{country} %{basePrice} - %{vatRate}%',
        },
      },
      forms: {
        create: 'Créer un formulaire',
        list: 'Liste des formulaires',
        title: 'Formulaires',
      },
      others: {
        title: 'Autres',
        scenarios: 'Scénarios',
      },
      planning: {
        admin: 'Admin',
      },
      pricing: {
        affiliations: 'Code promo affiliations',
        slots: 'Prix des vacations',
        title: 'Pricing et factures',
      },
      title: 'Admin',
    },
    affiliations: {
      consultationsNumber: 'Nb consultations offertes',
      create: 'Ajouter une affiliation',
      createdAt: 'Date de création',
      empty: 'Aucune affiliation',
      expirationDate: "Date d'expiration",
      exportProCommunity: 'Export communauté de pros',
      managePartners: 'Gérer les partenaires',
      managePlans: 'Gérer les offres génériques',
      title: 'Affiliations',
      titleCode: 'Codes promo affiliation',
      usage: 'Usage / mois',
      usagePerMonth: 'Activation du code / mois',
    },
    appUsers: {
      addToPhoneWhitelist: 'Whitelister',
      currentEmail: 'Email actuel',
      deletionConfirmation: 'Êtes vous sûr de vouloir supprimer ce compte ?',
      deletionCompletedAt: 'Suppression effectuée le ',
      deletionDate: 'Suppression planifiée le ',
      discussion: 'Discussions',
      membershipStatus: {
        bonus: 'Abonné·e bonus',
        active: 'Abonné·e',
        affiliated: 'Affilié·e',
        trialing: 'Abonné·e (période essai)',
        notSubscribed: 'Non abonné·e',
      },
      moreInformation: 'Informations détaillées',
      newMail: 'Nouvel email',
      removeFromWhitelist: 'Retirer de la whitelist',
      requestDeletion: 'Demander la suppression ',
      title: 'Patient',
      updateMailAlertItem1: 'Le mail est valide',
      updateMailAlertItem2: "Le mail est différent de l'ancien",
      updateMailAlertItem3: "Aucun compte n'existe avec ce nouveau mail",
      updateMailAlertItem4: 'Stripe & Customer.io sont mis à jour',
      updateMailAlertTitle: 'Etapes intégrées',
      updateMailError: 'Erreur - Email non mis à jour',
      updateMailSuccess: 'Email mis à jour',
      updateMailTitle: "Modification de l'email",
    },
    attributions: {
      automatic: 'Automatique',
      choiceTitle: "Mode d'attribution :",
      myAttributions: 'Mes attributions',
      noAttribution: 'Sans attribution ou attribution non connectée',
      start: 'Commencer ma vacation',
      startNoAttrib: 'Prêter main forte',
      stop: 'Terminer ma vacation',
      toMe: "Je m'attribue cette question et réponds",
    },
    auth: {
      backToLogin: "Revenir à l'écran de connexion",
      changePassword: 'Changer mon mot de passe',
      confirmationDialog: {
        title: 'Êtes vous sûr·e de vouloir vous déconnecter?',
        disconnect: 'Me déconnecter',
      },
      email: 'Email',
      forgottenPassword: 'Mot de passe oublié',
      forms: {
        errors: {
          generic_error:
            'Une erreur est survenue... Vérifiez les règles de mot de passe',
          invalid_credentials: 'Identifiants invalides',
          invalid_2FA: 'Code invalide',
          timeout: 'Envoi trop long, veuillez réessayer',
        },
        success: {
          email_sent: 'Email envoyé',
          password_updated: 'Mot de passe mis à jour',
          password_reset: 'Mot de passe réinitialisé',
        },
      },
      login: {
        '2FA': 'Veuillez entrer le code reçu par sms',
        TOTP: 'Veuillez entrer le code affiché par authenticator',
        connect: 'Connexion',
        forceSms: 'Utiliser le SMS',
        forceTOTP: "Utiliser l'authenticator",
      },
      logout: 'Déconnexion',
      totp: {
        title: 'Authenticator',
        qrcode: 'QR Code',
        description: 'Scannez le QR Code avec votre authenticator',
        manual: 'Saisissez le code manuellement: %{code}',
        generate: 'Utiliser un authenticator',
        validate: 'Valider en saisissant le code',
      },
      passwordConstraints:
        'Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule & un chiffre ou caractère spécial.',
      resetPassword: {
        newPassword: 'Nouveau mot de passe',
        resetPassword: 'Réinitialisez votre mot de passe',
        sendEmail: 'Envoyer le mail de réinitialisation',
        twoFA: 'Veuillez entrer le code reçu par SMS',
        validateNew: 'Valider mon nouveau mot de passe',
      },
      trustDevice: 'Faire confiance à cet appareil',
    },
    availabilities: {
      created: 'Créneau créé',
      deleted: 'Créneau supprimé',
      my_consultations: 'Mes consultations',
      other_consultations: 'Autres consultations',
      owner_error:
        'Vous ne pouvez pas modifier/supprimer un créneau qui ne vous appartient pas',
      updated: 'Créneau mis à jour',
      none: 'Pas de dispo',
    },
    button: {
      add: 'Ajouter',
    },
    calendars: {
      allDay: 'Toute la journée',
      daily: 'Quotidien',
      date: 'Date',
      hour: 'Heure',
      list: 'Liste',
      monthly: 'Mensuel',
      next: '>',
      noEvents: 'Aucun créneau',
      previous: '<',
      slot: 'Créneau',
      title: 'Calendriers',
      today: "Aujourd'hui",
      tomorrow: 'Demain',
      total: '1 autre |||| %{smart_count} autres',
      weekly: 'Hebdo',
      yesterday: 'Hier',
    },
    category: {
      name: 'Catégorie',
      save: 'Catégorie sauvée',
      useSuggestion: 'Utiliser la suggestion',
      chose: 'Choisir une catégorie :',
      suggested: 'Catégorie suggérée',
    },
    categories: {
      active: 'Active',
      add: 'Ajouter une catégorie',
      excludeFromTriage: 'Pas pour les IAO',
      meta: 'Méta',
      name: 'Nom',
      pregnancy: 'Grossesse',
      priority: 'Priorité',
      slug: 'Slug',
      slugDoNotModify: 'Identifiant unique. Ne pas mettre à jour.',
      title: 'Catégories',
    },
    changelog: {
      contents: 'Contenus',
      displayNotifications: 'Afficher les notifications',
      empty: 'Pas de news pour le moment :(',
      title: 'Nouveautés',
    },
    changes_saved: 'Changements sauvés',
    chat: {
      contentTitle: {
        folder: 'Dossier',
        masterclass: 'Masterclass',
        article: 'Article',
        guide: 'Fiche pratique',
        dailyTip: 'Clé du jour',
        weeklyTip: 'Activité de la semaine',
        fertiTip: 'Conseil fertilité',
        video: 'Vidéo',
      },
      endIntroductionMessage: "chez May et je vous accompagne jusqu'à",
      greetings: 'Bonjour',
      greetingsNight: 'Bonsoir',
      iAm: 'je suis',
      loadPrevious: 'Charger les messages précédents',
      placeholder: 'Tapez votre message ici...',
      sentError: "Erreur lors de l'envoi du message %{error}",
    },
    children: {
      deactivated: 'désactivé',
      empty: 'Aucun enfant',
      title: 'Enfants',
    },
    common: {
      active: 'Actif',
      add: 'Ajouter',
      admin: 'Admin',
      age: 'Age',
      alias: 'Alias',
      all: 'Tous',
      already: 'déjà',
      avatar: 'Avatar',
      averageLast7Days: 'Moyenne quotidienne sur les derniers jours',
      birth: 'Naissance',
      birthDay: 'Date de naissance',
      born: 'Né·e',
      bottleShort: 'Bib.',
      cancel: 'Annuler',
      charts: 'Courbes',
      child: 'Enfant',
      children: 'Enfants',
      close: 'Fermer',
      colon: ' : ',
      confirm: 'Confirmer',
      confirmClose: 'Confirmer la fermeture',
      confirmCloseText:
        'Vos modifications ne seront pas enregistrées, voulez vous continuer ?',
      confirmDeletion: 'Confirmer la suppression',
      confirmSave: 'Confirmer la sauvegarde',
      countries: {
        fr: 'France',
        FR: 'France',
        en: 'UK',
        GB: 'UK',
        es: 'Espagne',
        ES: 'Espagne',
      },
      country: 'Pays',
      copy: 'Copier',
      create: 'Créer',
      createdAt: 'Création',
      creationDate: 'Date ajout',
      currency: 'Devise',
      dashboard: 'Tableau de bord',
      date: 'Date',
      day: 'Jour',
      dayCount: '1 jour |||| %{smart_count} jours',
      dayShortCount: '%{days}j',
      deactivate: 'Désactiver',
      delete: 'Supprimer',
      description: 'Description',
      discussion: 'Discussion',
      doctorShort: 'Doc',
      dollar: '$',
      edit: 'Editer',
      email: 'email',
      emptyList: 'Aucune donnée disponible',
      enclose: 'Clôturer',
      english: 'Anglais',
      error: {
        create: 'Erreur lors de la création',
        delete: 'Erreur lors de la suppression',
        validate: 'Erreur lors de la validation',
        withArgs: 'Une erreur est survenue : %{error}',
        generic: 'Une erreur est survenue...',
      },
      errorBoundary:
        'Une erreur est survenue, merci de réessayer ultérieurement',
      euro: '€',
      expectedEnd: 'Date de terme',
      export: 'Export',
      failToFetch: 'Immpossible de récupérer',
      feedback: 'Votre retour',
      feedbackHelpText:
        'Votre retour nous aide à améliorer notre service. Quel contenus attendiez vous ?',
      feeding: 'Alimentation',
      filterByName: 'Filtrer par prénom',
      firstName: 'Prénom',
      french: 'Français',
      genericError: 'Une erreur est survenue...',
      goToDiscussion: 'Voir la discussion',
      height: 'Taille',
      image: 'Image',
      in: 'dans',
      international: 'International',
      job: 'Job',
      legacy: 'Legacy',
      loadMore: 'Charger plus',
      locale: 'Langue',
      logWith: 'Log with',
      male: 'Masculin',
      female: 'Féminin',
      me: 'Moi',
      media: 'Média',
      midwifeShort: 'SF',
      model: 'Modèle',
      modifyEntry: 'Modifier ma saisie',
      month: 'mois',
      monthCount: '1 mois |||| %{smart_count} mois',
      monthShortCount: '%{months}m',
      more: 'Voir plus',
      multiple: 'Multiple',
      name: 'Nom',
      negativeFeedbackSubmitted: 'Entendu. On fera mieux la prochaine fois !',
      number: 'Nombre',
      next: 'Suivant',
      noData: 'Aucune donnée',
      noDate: 'Pas de date',
      noResults: 'Aucun résultat',
      notValidated: 'non validé',
      nurseShort: 'IPDE',
      OK: 'OK',
      onCall: 'Astreinte',
      page: 'Page',
      parent: 'Parent',
      parents: 'Parents',
      partners: 'Partenaires',
      partnersSendLivi: 'Envoyer un lien vers Livi ?',
      phone: 'Téléphone',
      positiveFeedbackSubmitted: '🤗 Merci pour votre retour !',
      pound: '£',
      preferences: 'Préférences',
      pregnancy: 'Grossesse',
      previous: 'Précédent',
      priority: 'Prio',
      pro: 'Pro',
      save: 'Enregistrer',
      saveAnyway: 'Sauvegarder quand même',
      search: 'Recherche...',
      see: 'Voir',
      seeMore: 'Voir plus...',
      seen: 'Vu',
      selectAll: 'Tout sélectionner',
      settings: 'Paramètres',
      sex: 'Sexe',
      sleep: 'Sommeil',
      slot: 'Créneau',
      slots: 'Créneaux',
      socketError:
        'Erreur de connexion au serveur, rechargez la page si ce message ne disparait pas',
      spanish: 'Espagnol',
      startAt: 'Début',
      status: 'Statut',
      submit: 'Soumettre',
      subscriptionStatus: "Status d'abonnement",
      successfulUpdate: 'Mise à jour réussie',
      title: 'Titre',
      today: "Aujourd'hui",
      total: 'Total',
      totalPerDay: 'Total par jour',
      triageNurse: 'IAO',
      unknown: 'Inconnu',
      unitPrice: 'Prix unitaire',
      unselectAll: 'Tout déselectionner',
      update: 'Modifier',
      updatedAt: 'Modification',
      usePrompt:
        'Vos modifications ne seront pas enregistrées, voulez-vous continuer ?',
      validate: 'Valider',
      waitingTime: "Tps d'attente global",
      waitingTimeMine: "Tps d'attente perso",
      weeks: 'semaines',
      weight: 'Poids',
      yearCount: '1 an |||| %{smart_count} ans',
    },
    company: {
      activePlans: 'Plans actifs',
      add: 'Ajouter une entreprise',
      codes: 'Codes',
      create: 'Créer une entreprise',
      maxUsage: 'Max utilisateurs',
      title: 'Entreprises',
    },
    companyPlan: {
      code: 'Code',
      alias: 'Alias',
      companyPlan: 'Plan entreprise',
      create: "Ajouter un plan d'entreprise",
      maxUsage: 'Max utilisations (-1 pour infini)',
      renewable: 'Renouvelable',
    },
    companyPlanCode: {
      code: 'Code',
      create: 'Ajouter un code',
      expiresAt: "Date d'expiration",
      maxUsage: 'Max utilisations (-1 pour infini)',
    },
    connectedUsers: {
      title: 'Utilisateurs connectés',
    },
    consultationCategory: {
      title: 'Consultation catégories',
    },
    consultations: {
      actionPlan: "Plan d'action pour le patient",
      attribution: 'Changer de pro',
      attributionWarningTitle: 'Attention',
      attributionWarningText:
        'Avant de changer de professionnel, assurez-vous que toutes les parties prenantes sont au courant, une notification slack sera envoyée.',
      attributionSuccess:
        "Consultation mise à jour : changement de l'attribution",
      availabilities: 'Créneau de disponibilité',
      cancel: 'Annuler la consultation',
      category: 'Thématique',
      date: 'Date',
      displayOnboardingForm: 'Résultats questionnaire',
      displayTherapy: '🤓 Voir le contenu du programme',
      empty: 'Aucune consultation prévue',
      goToConsultations: 'Accéder aux consultations',
      hangUp: 'Raccrocher',
      otherActionsTitle: 'Autres actions',
      patientEmailContent: "Contenu de l'email destiné au parent",
      phoneOnlyWarning:
        'Pensez bien à masquer votre numéro en le préfixant avec "#31#" sur votre portable.',
      planning: {
        title: 'Planning des consultations',
      },
      proGuidelines: "Consignes pour la rédaction du plan d'action",
      reason: 'Motif',
      reopen: 'Réouvrir',
      report: 'Compte-rendu de CS',
      reportShort: 'Compte-rendu',
      reportShortPro: 'Compte-rendu à usage interne',
      staffUser: 'Pro',
      startConsultation: 'Démarrer la visio',
      status: 'Statut',
      tabs: {
        todo: 'A traiter',
        closed: 'Clôturé',
        canceled: 'Annulé',
        mine: 'Mes consultations',
      },
      therapyTitle: 'Programme : ',
      title: 'Consultations',
      titleOne: 'Consultation',
      toComeTitle: 'Consultations à venir',
      warning: 'Attention',
    },
    contentSuggestion: {
      assessment: 'Évaluer les suggestions',
      empty: 'Aucun contenu pertinent trouvé.',
      guide: 'Fiches',
      macro: 'Macro',
      masterclass: 'Masterclass',
      post: 'Article',
      subtitle: 'Contenus suggérés en fonction des messages patient',
      title: 'Suggestions de contenu',
    },
    contentTranslation: {
      addExtraTime: 'Ajouter du temps extra',
      approver: 'Approuveur',
      commentsError: "Erreur lors de l'enregistrement du commentaire",
      commentsSuccess: 'Commentaire enregistré avec succès',
      commentsTitle: 'Commentaires',
      dashboardTitle: 'Traduction de contenu',
      extraTasks: 'Taches additionnelles',
      fields: {
        allStatus: 'Tous les status',
        cmsURL: 'CMS',
        comments: 'Commentaires',
        count: 'Nombre',
        documentTitle: 'Titre',
        documentUrl: 'Gdoc',
        meanTimeSpent: 'Temps moyen',
        scope: 'Scope',
        state: 'État',
        status: 'Statut',
        timeSpent: 'Temps passé (min)',
        type: 'Type',
      },
      requestChanges: 'Demander des modifications',
      status: {
        created: 'Créé',
        ai_translated: 'Traduction IA',
        human_reviewed: 'Relecture pro',
        pair_reviewed: 'Pair reviewed',
        changes_requested: 'Changements attendus',
        validated: 'Validé',
        cms_auto_uploaded: 'Uploadé à vérifier sur le CMS',
        cms_upload_failed: '⚠ Upload failed',
        cms_uploaded: 'Uploadé sur le CMS',
      },
      tab: {
        done: 'Terminés',
        extra: 'Extras (hors traduction)',
        inProgress: 'En cours',
        toAttribute: 'À attribuer',
        toApprove: 'À approuver',
        toUpload: 'À uploader',
        toComplete: 'À finaliser',
        summary: 'Résumé',
      },
      timeTrackingTitle: 'Suivi du temps et commentaires',
      timeTrackingSuccess: 'Temps enregistré avec succès',
      timeTrackingError: "Erreur lors de l'enregistrement du temps",
      uploader: 'Uploader',
    },
    discussion: {
      dailyTip: 'Daily tip',
      deletedMessage: '[Message supprimé]',
      folder: 'Dossier',
      form: 'Questionnaire',
      guide: 'Fiche',
      image: 'Image',
      masterclass: 'Masterclass',
      noMessage: 'Aucun message',
      numberCopied: 'Numéro de discussion copié',
      partner: 'Livi',
      post: 'Article',
      video: 'Vidéo',
      vipTooltip: 'Famille VIP ⭐ à chouchouter sur le BO 😊',
      weeklyTip: 'Weekly tip',
    },
    favorites: {
      add: 'Ajouter un favori',
      addDescription: 'Ajouter une discussion aux favoris avec un commentaire',
      addLabel: 'Ajouter une discussion aux favoris',
      commentLabel: 'Ajouter un commentaire',
      comments: 'Commentaires',
      dashboard: 'Mes discussions en favoris',
      lastMessage: 'Dernier msg',
      none: "Vous n'avez pas encore de favoris",
      save: 'Enregistrer le favori',
      title: 'Favoris',
    },
    fertiTip: {
      cyclePosition: 'Semaine dans le cycle : %{cyclePosition}',
      cycleNumber: 'Cycle n°%{cycleNumber}',
      shortNumberAndPosition: 'Cycle %{cycleNumber} - Semaine %{cyclePosition}',
    },
    field: {
      image: {
        placeholder: 'Glissez votre fichier ou sélectionnez une image',
      },
      optionInput: {
        add: 'Ajouter une option',
        key: 'Clé',
        requiredKey: 'Clé requise',
        requiredValue: 'Valeur requise',
        title: 'Options',
        value: 'Valeur',
        description: 'Description',
      },
    },
    followUp: {
      cancel: 'Annuler',
      confirm: 'Confirmer',
      create: 'Suivi',
      createTitle: 'Ajouter un suivi',
      edit: 'Modifier un suivi',
      empty: " Aucun suivi médical n'a été enregistré pour le moment",
      observations: 'Observations',
      suppressText: 'Êtes vous sur de vouloir supprimer ce suivi',
      suppressTitle: 'Supprimer un suivi',
      title: 'Suivi',
    },
    forms: {
      chat: {
        formMessage: 'En cours. Le patient doit terminer le questionaire.',
        formMessageLocked: 'Formulaire complété',
      },
      conditions: {
        booleanOperator: 'Opérateur logique',
        hideTitle: 'Editer les conditions de masquage',
        displayTitle: "Editer les conditions d'affichage",
        questionRules: {
          add: 'Ajouter une règle',
          expectedValue: 'Valeur cible',
          operator: 'Opérateur',
          question: 'Question',
          title: 'Règles sur les questions',
        },
        dataRules: {
          add: 'Ajouter une donnée',
          dataSlug: 'Donnée',
          expectedValue: 'Valeur cible',
          operator: 'Opérateur',
          title: 'Règles sur les données utilisateurs',
        },
        externalRules: {
          add: 'Ajouter une donnée externe (remote config)',
          dataSlug: 'Donnée',
          expectedValue: 'Valeur cible',
          operator: 'Opérateur',
          title: 'Règles sur les données externes',
        },
      },
      dialogs: {
        delete: {
          content: 'Voulez vous vraiment supprimer cet élément ?',
          title: 'Confirmer la suppression',
        },
      },
      display: {
        title: 'Affichage',
      },
      edit: {
        modified:
          'Des modifications sont en cours. Voulez-vous vraiment quitter ?',
        saved: 'Formulaire sauvegardé',
        title: 'Paramètres du formulaire',
        labels: 'Labels',
        allLabels: 'Tous les labels',
        tags: 'Tags',
        allTags: 'Tous les tags',
      },
      duplicate: {
        title: 'Paramètres du formulaire dupliqué',
      },
      errors: {
        generic_error: 'Une erreur est survenue...',
        invalid_fields: 'Champs invalides ou manquants: %{invalidFields}',
        invalid_pattern: 'Format invalide',
        required_field: 'Champ Requis',
        min_length: 'Le champ doit contenir au moins %{minLength} caractères',
        max_length: 'Le champ doit contenir au plus %{maxLength} caractères',
        unique_slug: 'Le slug doit être unique',
      },
      list: {
        columns: {
          createdAt: 'Création',
          description: 'Description',
          slug: 'slug',
          title: 'Titre',
        },
        title: 'Formulaires',
        empty: 'Aucun formulaire',
      },
      operator: {
        EQUAL: 'Égal',
        NOT_EQUAL: 'Différent',
      },
      patientFile: {
        empty: " Aucun formulaire n'a été rempli pour le moment",
      },
      questions: {
        description: 'Description',
        formTitle: 'Editer la question',
        label: 'CTA Label',
        secondaryLabel: 'CTA secondaire',
        options: 'Options',
        hideTopBar: 'Masquer la barre de progression',
        slug: 'Slug',
        title: 'Titre',
        type: 'Type',
        copy: {
          title: 'Copier une question',
          question: 'Question à copier',
          copy: 'Copie',
        },
        requiredCTALabel:
          'Le label du CTA est obligatoire si des descriptions sont fournies',
        maxSelection: 'Nombre maximum de choix',
        scale: {
          minimumLabel: 'Minimum label',
          maximumLabel: 'Maximum label',
          type: 'Type de slider',
        },
      },
      runner: {
        backClicked: 'Retour',
        canBack: 'Retour possible',
        canceledForm: 'Formulaire annulé',
        completedForm: 'Formulaire complété. Score : %{score}, Tags : %{tags}',
        noAnswers: 'Aucune réponse',
        proAnalysis: 'Analyse des réponses',
        questionCount: 'Nombre de questions',
        score: 'Score',
        submitClicked: 'Valider',
        tags: 'Tags',
        unlockedForm: 'Formulaire non finalisé',
        addExternalData: 'Ajouter des données externes',
        externalData: 'Données externes',
      },
      scoreMode: {
        NUMBER: 'Nombre',
        TAG: 'Tag',
      },
      scoringRule: {
        add: 'Ajouter une règle de scoring',
        expectedValue: 'Valeur cible',
        formTitle: 'Règle de scoring "%{question}"',
        operator: 'Opérateur',
        scoringValue: 'Valeur si vrai (%{number_or_string})',
        onboardingTag: 'Tag onboarding',
        actions: {
          NEXT_SCREEN: 'Afficher un écran',
          USER_PROFILE: 'Ajouter au profil utilisateur',
          EXTERNAL_DATA: 'Ajouter des données pour les formulaires suivants',
          PAYWALL: 'Paywall',
          NEXT_FORM: 'Formulaire suivant',
          END_ONBOARDING: "Fin de l'onboarding",
          SCHEDULE_SUBSCRIPTION_UPDATE: 'Promo abonnement',
          CHURN_SUBSCRIPTION: 'Churn',
          EVENT_TRACKING: 'Event tracking',
          PAYMENT_SCREEN: 'Écran de paiement',
        },
        remoteConfig: 'Clef remote config',
        screenType: "Type d'écran",
        screenName: 'Ecran',
        externalDataKey: 'Clef',
        externalDataValue: 'Valeur',
        planId: 'Identifiant produit Stripe',
        promotionCode: 'Code promo',
        event: 'Évènement à logguer',
        trackedData: 'Données à logguer (format: key1:value1,key2:value2)',
        screenNames: {
          NOTIFICATION_OPT_IN_NO_PERSONA: 'opt-in notifications (sans persona)',
          NOTIFICATION_OPT_IN_PREGNANCY_CONTENT:
            'opt-in notifications (grossesse-content)',
          NOTIFICATION_OPT_IN_PREGNANCY_TOOLS:
            'opt-in notifications (grossesse-tools)',
          NOTIFICATION_OPT_IN_ALL_CHAT: 'opt-in notifications (chat)',
          NOTIFICATION_OPT_IN_PARENT_CONTENT:
            'opt-in notifications (parent-content)',
          NOTIFICATION_OPT_IN_PARENT_TOOLS:
            'opt-in notifications (parent-tools)',
          NOTIFICATION_OPT_IN_FERTILITY_CONTENT:
            'opt-in notifications (fertility-content)',
          NOTIFICATION_OPT_IN_FERTILITY_TOOLS:
            'opt-in notifications (fertility-tools)',
          FAMILY_CREATION: 'Création de famille',
          FEATURES_BABY_3D: 'Baby 3D',
          FEATURES_SLEEP: 'Dodo',
          FEATURES_DIAPER: 'Couches',
          FEATURES_FEED: 'Biberon/tété',
          FEATURES_CHILD_GROWTH_WEIGHT: 'Courbe de poids',
          FEATURES_CHILD_GROWTH_HEIGHT: 'Courbe de taille',
          FEATURES_CHILD_GROWTH_CRANIAL: 'Courbe de périmètre crânien',
          FEATURES_CONTENT_FERTILITY: 'Content fertility',
          FEATURES_CONTENT_CHILD: 'Content child',
          FEATURES_CONTENT_PREGNANCY: 'Content pregnancy',
          FEATURE_FERTILITY: 'Ajout symptômes fertilité',
          ONBOARDING_START: 'onboarding bienvenue',
          ONBOARDING_INTRO_1: 'Vous êtes en sécurité',
          REMOTE_CONFIG: 'remote config',
        },
        profile: 'Clef de profil',
        profileKeys: {
          sex: 'sexe',
          maritalStatus: 'statut marital',
          source: 'source',
          paid: 'Source (détail)',
          intent: 'Intention',
        },
        sexOptions: {
          MASCULINE: 'Masculin',
          FEMININE: 'Féminin',
          UNKNOWN: 'Inconnu',
        },
        maritalStatusOptions: {
          COUPLE: 'En couple',
          ALONE: 'Seul',
          UNKNOWN: 'Inconnu',
        },
        sourceOptions: {
          PAID: 'Paiement',
          STORE_DISCOVERY: 'Découverte en magasin',
          FAMILY_AND_FRIENDS: 'Famille et amis',
          DOCTOR: 'Médecin',
          SEO: 'SEO',
          TV: 'TV',
          PODCAST: 'Podcast',
          OTHER: 'Autre',
          UNKNOWN: 'Inconnu',
          RECOMMENDATION: 'Recommandation',
          MATERNITY_BOX: 'Maternité (box)',
          ONLINE_DISCOVERY: 'Découverte en ligne',
          MEDIA_EVENTS: 'Évènements médias',
          INSTAGRAM: 'Instagram',
        },
        paidOriginOptions: {
          FACEBOOK: 'Facebook',
          INSTAGRAM: 'Instagram',
          GOOGLE: 'Google',
          YOUTUBE: 'Youtube',
          TIKTOK: 'Tiktok',
          PINTEREST: 'Pinterest',
          OTHER: 'Autre',
          UNKNOWN: 'Inconnu',
          SNAPCHAT: 'Snapchat',
          FRIENDS_AND_FAMILY: 'Famille et amis',
          HEALTHCARE_PROFESSIONNAL: 'Médecin',
          MATERNITY: 'Maternité (box)',
          SEO: 'SEO',
          STORE_DISCOVERY: 'Découverte en magasin',
          EVENTS: 'Évènements médias',
          TV: 'TV',
          PODCAST: 'Podcast',
          JOURNAL: 'Journal',
        },
        subscribeModalName: 'Numéro de la modale',
        promoCode: 'Code promo',
        targetTab: 'Tab cible',
        slugType: 'Type de slug',
        formSlug: 'Slug du formulaire',
      },
      settings: {
        deleteTitle: 'Êtes-vous sûr de vouloir supprimer ce formulaire ?',
        deleteText:
          'Cette action est irréversible. Si des réponses ont déjà été fournies, la suppression échouera. Vous pouvez toujours archiver le formulaire.',
        description: 'Description',
        illustration: 'Illustration',
        questionCount: 'Nombre de questions',
        questionCountHelper:
          "Laisser vide si l'affichage d'une barre de progression n'est pas souhaité",
        sentInChat: 'Envoyé au sein de la messagerie',
        slug: 'Slug',
        status: 'Statut',
        title: 'Paramètres',
        titleField: 'Titre',
      },
      send: 'Envoyer un formulaire',
      status: {
        draft: 'Brouillons',
        published: 'Publiés',
        archived: 'Archivés',
      },
      step: {
        createQuestion: 'Ajouter une question',
        copyQuestion: 'Copier une question',
        createTemplate: 'Ajouter un template',
        formTitle: 'Editer l’étape',
      },
      template: {
        formTitle: 'Editer le template',
        slug: 'Slug',
      },
      viewer: {
        retry: 'Essayer à nouveau',
        runError:
          'Impossible de lancer le formulaire, une incohérence persiste',
      },
    },
    generic: {
      createSuccess: 'Création réalisée',
      filtered: 'Filtré',
      maxValue: 'Valeur maximale',
      minValue: 'Valeur minimale',
      notSaved:
        'Vos modifications ne seront pas enregistrées, voulez vous continuer ?',
      reset: 'Réinitialiser',
      save: 'Enregistrer',
      step: 'Pas',
      updateSuccess: 'Modification réalisée',
    },
    handover: {
      AISuggestion: '🎉 Suggestion IA : ',
      acceptAllGeneration: '🎉 Accepter toutes les suggestions',
      activeRevives: 'relances actives',
      add: 'Ajouter une transmission',
      adviceLong: 'Conseils/Actions proposés',
      advice: 'Conseils :',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      create: 'Créer une transmission',
      createWithRevive: 'Créer avec relance',
      deleteConfirmContent:
        'Voulez-vous vraiment supprimer cette transmission ?',
      deleteConfirmTitle: 'Supprimer cette transmission ?',
      edit: 'Editer une transmission',
      editTooltip: 'Modifier la transmission',
      error: 'Erreur lors de la création de la relance: %{error}',
      noData: 'Aucune transmission disponible',
      noneAvailable: 'Pas de transmission disponible !',
      observations: 'Obs :',
      observationsLong: 'Observations faites',
      preventEarlyLeave:
        "Attention, votre transmission/relance n'est pas sauvegardée",
      problem: 'Problème identifié',
      reviveAt: 'Date relance souhaitée',
      title: 'Transmissions',
      titleForOne: 'Transmission',
    },
    help: {
      changeBrowser: "Changez de navigateur. Dans l'incapacité de le faire…",
      contact:
        'Pour toute question technique ou remontée de bug, vous pouvez nous contacter directement sur le channel slack #help-technique.',
      help: 'Aide May',
      or: 'ou',
      pressOn: 'Appuyez sur',
      pressSimultaneously: 'Appuyez simultanément sur ',
      proKit: 'Commande de kit pro (France)',
      refresh: 'Rafraichir le BO',
      refreshBrowser1: 'Chrome/Firefox et Windows/Linux',
      refreshBrowser2: 'Chrome et Mac',
      refreshBrowser3: 'Safari et Mac',
      refreshBrowser4: 'Internet Explorer',
      shiftExplanation:
        '(shift étant la touche près de Ctrl avec une flèche vers le haut, la touche pour faire des majuscules !)',
    },
    history: {
      revives:
        '%{smart_count} relance active |||| %{smart_count} relances actives',
      title: 'Transmissions',
    },
    invoice: {
      admin: {
        list: 'Liste',
        summary: 'Résumé',
        title: 'Factures - Admin',
      },
      adminList: {
        amountValidated: 'Montant validé',
        amountValidatedShort: '€ ?',
        comments: '💬',
        createdAt: 'Ajout',
        downloadButton:
          'Télécharger une facture |||| Télécharger les %{smart_count} factures',
        downloadError: 'Erreur lors du téléchargement',
        downloadSuccess: 'Téléchargement réussi',
        filters: 'Filtres',
        invoice: 'Facture',
        job: {
          doctor: 'Médecin',
          midwife: 'SF',
          nurse: 'IPDE',
          title: 'Métier',
        },
        notValidated: 'Non validé',
        planning: 'Planning',
        resetFilters: 'Réinitialiser',
        staffUser: 'Pro',
        validated: 'Validé',
        validateAndSepa: 'Valider + SEPA',
        validatedShort: '✔️',
      },
      bankTransfer: 'Virement',
      comments: 'Commentaires',
      deleteShort: 'Suppr.',
      detailPerSlot: 'Détail par créneau HT',
      dialog: {
        amountErrorTitle: 'Erreur de montant',
        amountSuccessTitle: 'Montant détecté dans la facture',
        amountError:
          "Nous n'avons pas détecté de montant correspondant à votre bilan (%{amount} €) dans votre facture. Corrigez-la si besoin ou laissez un commentaire ci-dessous pour justifier l'écart.",
        amountSuccess:
          'Nous avons bien détecté le montant correspondant à votre bilan (%{amount} €) dans votre facture.',
        comments: 'Commentaires',
        commentsHelpTextWhenError:
          "Commentaire obligatoire pour justifier l'écart",
        error: 'Erreur lors de création de la facture',
        errorRetrievingSummary: 'Erreur lors de la récupération du bilan',

        instructions: 'Consignes',
        instructions1: 'Votre document doit être au format PDF',
        instructions2:
          'La date qui y figure doit correspondre au mois de la prestation',
        instructions3:
          'Le total doit correspondre au montant dans le bilan (sauf erreur de notre part : faites-le nous savoir)',
        success: 'Facture ajoutée avec succès',
        title: 'Ajouter une facture',
        upload: 'Ajouter une facture',
      },
      downloadXLS: 'Télécharger XLSX',
      downloadAccountantSummary: 'Télécharger le récap fichier compta',
      empty: 'Aucune facture pour le moment.',
      errorGeneratingSepa:
        'Erreur lors de la génération du fichier SEPA - vérifiez les IBAN/BIC',
      feesNurse: 'Honoraires puers',
      feesDoctor: 'Honoraires médicaux',
      format: 'format',
      generateSepa: 'Générer document virement SEPA',
      invoiceFrom: 'Facture du',
      invoiceTo: 'au',
      lineChat: 'Décaissement honoraires praticiens tchat',
      mailSent: 'Mail envoyé',
      mailSentPlural: 'Mails envoyés',
      numberSelected: '1 sélectionné |||| %{smart_count} sélectionnés',
      noActivity: 'Aucune activité sur ce planning',
      pendingInvoice: 'En attente de validation',
      see: 'Voir',
      sendSummaryMail: 'Envoyer le bilan par mail',
      sendSummaryToAll: 'Envoyer le récap à tous par email',
      sepaGenerated: 'Fichier SEPA généré',
      summary: 'Bilan',
      title: 'Factures',
      total: 'Total calculé: %{total} €',
      totalTaxFree: 'Total HT',
      vat20: 'TVA 20%',
      totalWithTax: 'Total TTC',
      viewerTitle: 'Facture',
      upload: 'Ajouter une facture',
      validatedInvoice: 'Facture validée',
    },
    latestContent: {
      empty: "Aucun contenu n'a été créé dans les 7 derniers jours. 😴",
      goToLibrary: 'Consulter la bibliothèque',
      loadError: 'Impossible de charger les derniers contenus',
      subtitle: 'Sortis il y a moins de 7 jours',
      title: 'Derniers contenus',
    },
    library: {
      contentType: 'Type de contenu',
      dailyTips: 'Daily tips',
      empty: 'Aucun résultat',
      guide: {
        carousel: 'Détail des fiches',
        title: 'Fiche',
      },
      explorer: 'Tous contenus',
      fertiTips: 'Conseils fertilité',
      folders: 'Dossiers',
      form: 'Formulaires',
      guides: 'Fiches',
      itemSuggestions: "Puériculture : Guide d'achats",
      loading: 'Chargement...',
      macros: 'Protocoles',
      masterclasses: 'Masterclasses',
      mode: 'Mode',
      post: 'Article',
      posts: 'Articles',
      pregnancyQuestions: 'Grossesse: Est-ce que je peux...',
      results: 'Résultat de la recherche',
      search: 'Contenus à envoyer',
      searchField: 'Recherche',
      shareContent: 'Partager du contenu',
      steps: 'Étapes',
      summary: 'Résumé',
      title: 'Bibliothèque',
      tracks: 'Episodes',
      types: 'Types de contenus',
      weeklyTips: 'Weekly tips',
    },
    locale: {
      days: {
        long: {
          friday: 'Vendredi',
          monday: 'Lundi',
          saturday: 'Samedi',
          sunday: 'Dimanche',
          thursday: 'Jeudi',
          tuesday: 'Mardi',
          wednesday: 'Mercredi',
        },
        short: {
          friday: 'Ven',
          monday: 'Lun',
          saturday: 'Sam',
          sunday: 'Dim',
          thursday: 'Jeu',
          tuesday: 'Mar',
          wednesday: 'Mer',
        },
      },
      months: {
        long: {
          april: 'Avril',
          august: 'Août',
          december: 'Décembre',
          february: 'Février',
          january: 'Janvier',
          july: 'Juillet',
          june: 'Juin',
          march: 'Mars',
          may: 'Mai',
          november: 'Novembre',
          october: 'Octobre',
          september: 'Septembre',
        },
        number: {
          '1': 'Janvier',
          '2': 'Février',
          '3': 'Mars',
          '4': 'Avril',
          '5': 'Mai',
          '6': 'Juin',
          '7': 'Juillet',
          '8': 'Août',
          '9': 'Septembre',
          '10': 'Octobre',
          '11': 'Novembre',
          '12': 'Décembre',
        },
        short: {
          april: 'Avr',
          august: 'Aou',
          december: 'Dec',
          february: 'Fev',
          january: 'Jan',
          july: 'Jul',
          june: 'Jun',
          march: 'Mar',
          may: 'Mai',
          november: 'Nov',
          october: 'Oct',
          september: 'Sep',
        },
      },
    },
    macro: {
      behavior: 'Conduite à tenir',
      noProtocol: "Aucune CAT n'a été défini pour le moment",
      protocol: 'CAT',
    },
    medicalFollowUp: {
      add: 'Ajouter un suivi',
      deleteConfirmTitle: 'Supprimer cette observation ?',
      deleteConfirmContent:
        'Voulez-vous vraiment supprimer cette observation ?',
      fields: {
        content: 'Observations',
      },
      noData: 'Aucune observation disponible',
      preventEarlyLeave: "Attention, votre suivi n'est pas sauvegardé",
      title: 'Suivi global',
    },
    midwifeSubjects: {
      title: 'Chat SF',
    },
    nurseSubjects: {
      title: 'Chat IPDE',
    },
    patients: {
      deactivated: 'Patient désactivé',
      childrenNumber: '1 enfant. |||| %{smart_count} enfants.',
      lastBornChildBirthday: 'Dernier né le %{date}',
      title: 'Parents',
      unknown: 'Inconnu',
    },
    patientFile: {
      title: 'Dossier patient',
    },
    partner: {
      livi: {
        description: '7j/7 de 7h à minuit',
        title: 'Téléconsultez en ligne avec un médecin',
      },
    },
    plan: {
      accessLevel: "Niveau d'accès",
      add: 'Ajouter un plan',
      consultationsNumber: 'Nombre de consultations offertes',
      monthsNumber: 'Nombre de mois valide',
      name: 'Plan',
    },
    planning: {
      availabilities: {
        title: 'Disponibilités',
        cantUpdate: 'Vous ne pouvez modifier les disponibilités de ce planning',
        explanationTitle:
          'Quelques rappels pour améliorer le remplissage du planning :',
        explanationItem1:
          'Donne des disponibilités sur un maximum de JOURS possibles dans le mois',
        explanationItem2:
          'Donne des disponibilités sur un maximum de CRENEAUX possibles dans une journée',
        explanationItem3:
          'Remplis tes préférences de manière à venir "encadrer" ce que l\'algo t\'attribue parmi toutes ces disponibilités',
        explanationItem4:
          'Coche ✅ si tu es disponible sur le créneau, sinon ne coche rien. Si tu as coché ✅ par erreur, tu peux recliquer dessus pour que la case redevienne vide (= pas dispo)',
        notSaved:
          'Vos modifications ne sont pas sauvegardées. Quitter quand même?',
      },
      bias: 'Biais planning',
      chosePlanningHours: 'Choisissez les horaires du planning',
      copyPlanning: 'Copier le planning',
      create: 'Créer un nouveau planning',
      deadlineAvailabilities: 'Deadline remplissage des disponibilités',
      dialog: {
        deletion: 'Suppression',
        deletionText: 'Voulez-vous vraiment supprimer ce planning ?',
        editSlots: 'Modifier les créneaux',
        endRegistration: 'Finaliser les inscriptions',
        endRegistrationCTA: 'Clôturer la saisie',
        openToPro: "Permettre aux professionnels de s'inscrire ?",
        openToProCTA: 'Ouvrir la saisie pro',
        seeAgenda: 'Voir le planning',
        summary: 'Récap/Factures',
        validate: 'Valider le planning',
      },
      displayedUsers: 'Utilisateurs affichés',
      displaySelector: {
        displayUnattributed: 'Afficher les créneaux non attribués',
      },
      edit: 'Modifier le planning',
      errorLoading: 'Erreur lors du chargement des plannings',
      evaluation: {
        BORING: 'Un peu trop calme 😴',
        CALM: 'Plutôt calme 😌',
        feedback: "Comment s'est passée ta vacation ?",
        INTENSE: 'Intense mais ça restait gérable 💪',
        PERFECT: 'Juste parfait 👌',
        summary:
          'Pendant cette vacation, tu as participé à %{participatedSubjects} questions et en a clôturé %{closedSubjects}.\n\nMerci 👍 !',
        summaryNoCounts: 'Merci pour ton retour !',
        title: 'Retour sur ta vacation',
        TOO_INTENSE: 'Trop intense donc inconfortable 🥵',
        error: 'Une erreur est survenue, peux-tu réessayer ?',
      },
      event: {
        additionalHour: 'Heure de renfort',
        attribution: 'Attribution',
        cancelAttribution: 'Libérer le créneau',
        cancelAttributionConfirmText:
          'Êtes-vous sûr·e de vouloir libérer ce créneau ?',
        create: 'Ajouter un créneau',
        created: 'Créneau créé',
        delete: 'Supprimer ce créneau',
        deleteError: 'Erreur lors de la suppression du créneau',
        deleted: 'Créneau supprimé',
        edit: 'Modifier un créneau',
        end: 'Fin',
        error: 'Erreur lors de la création/modification du créneau',
        getAttribution: "S'attribuer le créneau",
        getAttributionConfirmText:
          'Êtes-vous sûr·e de vouloir vous attribuer ce créneau ?',
        isTriage: 'IAO',
        noOtherAvailable: 'Aucune disponibilité',
        onCall: 'Astreinte',
        onCallActivated: 'Astreinte activée',
        otherAvailable:
          '%{smart_count} disponibilité |||| %{smart_count} disponibilités',
        slot: 'Créneau',
        start: 'Début',
        type: 'Type de créneau',
        updated: 'Créneau mis à jour',
      },
      fields: {
        title: 'Planning',
        dates: 'Dates',
        totalInvoices: 'Factures',
        totalValidated: 'Validés',
        totalInvalidAmount: 'Erreurs',
        totalExpectedInvoices: 'Pros',
      },
      goToPlanning: 'Voir le planning',
      hoursAlreadyValidated: 'Impossible : les horaires ont déjà été validés',
      job: { nurse: 'IPDE', midwife: 'Sage-femme', doctor: 'Médecin/Pédiatre' },
      mine: 'Mes plannings',
      modelGlobal: 'Modèle global',
      modelPerWeek: 'Modèle par semaine',
      noMatch: 'Aucun planning ne correspond',
      notAttributed: 'Non attribué',
      notify: {
        errorSubmittingPlanning: 'Ereur lors de la soumission du planning',
        create: 'Planning créé',
        edit: 'Planning modifié',
      },
      notYetOpen:
        '🤷 Les plannings ne sont pas encore ouverts pour la saisie des disponibilités futures.',
      preferences: {
        unsaved:
          'Vos modifications ne seront pas enregistrées, voulez vous continuer ?',
        confirmClose: 'Confirmer la fermeture',
        confirmCloseText:
          'Vos modifications ne seront pas enregistrées, voulez vous continuer ?',
        title: 'Préférences',
        maximumPerPlanning: 'Maximum de vacations sur tout le planning',
        maximumSlotsOnWeekends:
          'Maximum de WE travaillés dans le mois (samedi ou dimanche)',
        maximumPerWeek: 'Maximum de vacations par semaine (hors WE)',
        maximumNightsPerWeek:
          'Maximum de vacations le soir par semaine (hors WE)',
        minimumSlotsWanted: 'Minimum de vacations souhaitées au total',
        minimumSlotsWantedError: 'Le minimum doit être inférieur au maximum !',
        noWeekEndAvailable:
          "Tu n'as donné aucune disponibilité le week-end ce mois-ci, est-ce un oubli ?",
        save: 'Mettre à jour mes préférences',
        setFirst: "Saisir les préférences d'abord",
        reminderTitle: 'Rappel',
        reminderText:
          "Mieux vaut remplir tes préférences d'abord pour bénéficier de la sauvegarde automatique",
        tooMuchSlotsWanted: `Tu demandes plus de vacations que tu n'as de jours avec des disponibilités : nous ne pourrons pas satisfaire ta demande.`,
        workWholeWeekend:
          'Acceptez-vous de travailler deux jours différents dans le même weekend ?',
      },
      priorityBias: 'Biais pour créneaux prioritaires',
      results: {
        additionalHours: 'Renfort',
        attributions: 'Attributions',
        attributionCount: 'Attributions %{count}/%{total}',
        availabilities: 'Disponibilités',
        summary: 'Résumé',
      },
      status: {
        adminSlotsAssigned: 'Saisie dispos',
        calculationPerformed: 'A valider',
        done: 'Validé',
        draft: 'Brouillon',
        healthProAssigned: 'Saisie clôturée',
        slotsCreated: 'Template créé',
      },
      summary: {
        maxNightPerWeek: '# max soir/semaine',
        nbMaxPerWeek: '# max/semaine',
        nbMin: '# min',
        nbNight: '# soir',
        nbOnCalls: '# astreintes',
        nbSlots: '# vacations',
        pro: 'Pro',
        weekend: 'Week-end',
      },
      title: 'Planning',
      titleAdmin: 'Planning admin',
      topToolbar: {
        byJob: 'Par métier',
        byUser: 'Par personne',
      },
    },
    pregnancy: {
      born: 'Né·e',
      empty: 'Aucune grossesse en cours',
      expectedEnd: 'Terme prévu',
      interrupted: 'Interrompue',
      multiple: 'Grossesse multiple',
      multipleVeryShort: 'Mult.',
      plural: 'Grossesses',
    },
    pregnancyWeekContent: {
      amenorrheaWeek: 'SA',
      height: 'Taille',
      weight: 'Poids',
    },
    pricingProCustom: {
      add: 'Ajouter un pricing',
      base: 'Base',
      night: 'Nuit',
      rateOnCall: 'Coeff astreinte',
      sundayHoliday: 'Férié',
      vat: 'TVA',
    },
    pricingProGeneric: {
      add: 'Ajouter un pricing',
      base: 'Base',
      job: 'Profession',
      night: 'Nuit',
      rateOnCall: 'Coeff astreinte',
      sundayHoliday: 'Férié',
    },
    profile: {
      changePassword: 'Changer mon mot de passe',
      commercialName: 'Nom commercial (factures)',
      confirmPassword: 'Confirmer le nouveau mot de passe',
      jobTitle: 'Spécialité',
      newPassword: 'Nouveau mot de passe',
      passwordsDoNotMatch: 'Les mots de passe ne sont pas identiques',
      phone: 'Téléphone (format +336...)',
      title: 'Mon profil',
      update: 'Mettre à jour mes informations',
    },
    revives: {
      attributed: 'Attribuée',
      attributedStaffUserId: 'Attribuée à',
      by: 'par : ',
      close: 'Fermer la relance',
      closeQuestion: 'Fermer la relance ?',
      createOne: 'Créer une relance',
      edit: 'Modifier la relance',
      errorCreate: 'Erreur lors de la création de la relance',
      errorClosing: 'Erreur lors de la clôture de la relance',
      errorSendingMessage: "Erreur lors de l'envoi du message",
      loading: 'Chargement...',
      noRevives: 'Aucune relance',
      nonAttributed: 'Non attribuée',
      pendingRevivesBeforeSlotCancel:
        '1 relance était attribuée sur ce créneau. Elle a été désattribuée. |||| %{smart_count} relances étaient attribuées sur ce créneau. Elles ont été désattribuées.',
      performAt: 'Relancer le : ',
      performedAt: 'Relance faite le : ',
      planned:
        'Vous avez %{smart_count} relance planifiée pour cette vacation : |||| Vous avez %{smart_count} relances planifiées pour cette vacation :',
      plannedText: 'Texte de la relance',
      reviveAt: 'Date souhaitée de relance',
      revives: '1 relance |||| %{smart_count} relances',
      sendRevive: 'Envoyer la relance ?',
      sendText: 'Envoyer le texte de la relance',
      slot: 'Créneau',
      successCreate: 'Relance créée avec succès',
      title: 'Relance',
      todo: 'Relances à faire',
      unattributed: 'non attribuée |||| non attribuées',
    },
    roles: {
      cannotCreateAdminRoleDueToPermission:
        "Vous n'avez pas les droits suffisants pour créer un rôle administrateur",
      cannotCreateRoleWithSameRole: 'Cet utilisateur possède déjà ce rôle',
      created: 'Rôle créé',
      deleted: 'Rôle supprimé',
      role: 'Rôle',
      roles: 'Rôles',
    },
    scenario: {
      add: 'Ajouter un scénario',
      created: 'Créé le',
    },
    similarQuestion: {
      button: 'Afficher des questions similaires',
      noMatch: 'Aucune question similaire trouvée.',
      noMatchNB:
        'ℹ️ Pour les relances, aucune question similaire ne remonte. Ainsi que toutes les questions où les pros ont initié une conversation',
      title: 'Questions similaires',
    },
    slots: {
      atLeastOneRequired: 'Au moins un slot requis',
      empty: 'Aucun créneau prévu pour le moment.',
      startBeforeEnd:
        'Les créneaux doivent avoir une date de début antérieure à la date de fin',
      toComeTitle: 'Créneaux à venir',
      toComeSubtitle: 'Seuls 10 résultats sont affichés au maximum',
    },
    staffUser: {
      avatar: 'Avatar',
      bic: 'BIC',
      comfortArea: "A l'aise avec",
      commercialName: 'Nom commercial (facture)',
      consultationPrice: 'Tarif de la consultation',
      email: 'Email',
      expertiseArea: "Domaine d'expertise",
      firstName: 'Prénom',
      iban: 'IBAN',
      job: {
        admin: 'Admin',
        childPsychiatrist: 'Pedo-psychiatre',
        generalPractitioner: 'Médecin généraliste',
        midwife: 'Sage-femme',
        nurseryNurse: 'Infirmière puéricultrice',
        nutritionistDoctor: 'Médecin-nutritionniste',
        pediatrician: 'Pédiatre',
        psychologist: 'Psychologue',
      },
      jobTitle: 'Métier',
      language: 'Langue',
      lastName: 'Nom',
      password: 'Mot de passe',
      passwordHelperText:
        'Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule et un chiffre ou caractère spécial.',
      phone: 'Téléphone',
      phoneWithFormat: 'Téléphone (format +336...)',
      timezone: 'Fuseau horaire',
      title: 'Team may',
    },
    subject: {
      actives: 'En cours',
      category: 'Catégorie',
      inactives: 'Terminés',
      medicalIntervention: 'Avis médicaux',
      midwifeQuestionsTooltip: "Questions SF en attente d'une réponse IPDE",
      medicalQuestions: 'Questions avec avis médical',
      myQuestions: 'Mes questions',
      myQuestionsTooltip: 'Nombre de questions attribuées : perso (total)',
      noCategory: 'Questions sans catégorie',
      notAttributed: 'Non attribuées',
      notAttributedTooltip: 'Nombre de questions non attribuées',
      notPrio: 'Non Prio',
      noQuestionFilter: 'Aucune question ne correspond à ce(s) filtre(s)',
      ongoingQuestions: 'Questions en cours',
      prio: 'Prio',
      priority: 'Prioritaire ?',
      priorityQuestions: 'Questions prioritaires',
      revives: 'Relances',
      selectOne: 'Sélectionnez une question dans la liste de gauche',
      uncategorized: 'Non catégorisées',
      withMedicalIntervention: '👩🏻‍⚕️ Avis médical demandé ?',
      withMedicalInterventionShort: 'Med requis',
      withoutMedicalInterventionShort: 'Med',
      withNurseIntervention: 'Intervention IPDE ?',
      withNurseInterventionShort: 'IPDE requis',
      withoutNurseInterventionShort: 'IPDE',
      zenTooltip: 'Questions en cours\n(attendant une réponse de notre part)',
    },
    subjectExplorer: {
      category: 'Catégorie',
      discussion: '# de discussion',
      end: 'Fin',
      goToDiscussion: 'Aller sur la discussion',
      keywords: 'Mots clés',
      noMessage: 'Aucun message...',
      noSubjectWithFilters: 'Aucune question ne correspond à ce(s) filtre(s)',
      pro: 'Pro',
      start: 'Début',
      title: 'Super fouine',
    },
    subscription: {
      activateBonus: 'Créer bonus',
      affiliated: 'Affilié',
      bonus: 'Compte gratuit',
      deactivateBonus: 'Désactiver bonus',
      empty: 'Aucun abonnement pour le moment',
      none: 'Nada',
      paid: 'Souscription payante',
      refresh: 'Rafraîchir',
      title: 'Abonnements',
      trial: 'Période essai',
    },
    timezoneSelector: {
      label: 'Fuseau horaire',
      title: 'Fuseau horaire',
    },
    title: {
      admin: "Interface d'administration",
      calendars: 'Calendriers',
      dashboard: 'Tableau de bord',
      help: 'Aide',
      moreInfo: "Plus d'infos",
      patient: 'Parents',
    },
    tools: {
      appert: {
        babyBottles: 'Nombre biberons',
        childWeight: "Poids de l'enfant (en g)",
        description: 'Calculateur méthode Appert',
        formula: 'Formule utilisée (Appert): 250 mL + (poids en gramme) / 10',
        quantityInBottles: 'Soit : %{bottleQuantity} mL par biberon',
        quantityRecommended: 'Quantité de lait recommandé : %{milk} mL / 24h',
        title: 'Méthode Appert',
      },
      common: {
        oneYearShort: '1a',
      },
      choseChildren: 'Choisissez un/des enfants',
      cranial: 'Périmètre crânien',
      diapers: 'Couche',
      exportSuccess: "L'export s'est déroulé avec succès",
      exportError: "L'export à échoué.",
      feeding: 'Repas',
      feedingAndSleep: {
        legend: {
          babyBottle: 'Biberon',
          breastfeed: 'Allaitement',
          sleep: 'Sommeil',
        },
        title: 'Suivi sommeil/tétées',
        tooltip: {
          duration: 'Durée: %{duration} min',
          quantity: 'Quantité: %{quantity} mL',
        },
        statistics: 'Statistiques',
      },
      height: 'Taille',
      title: 'Outils',
      sleep: 'Sommeil',
      weight: {
        dueDate: 'Terme',
        start: 'Début',
        title: 'Poids',
      },
    },
    therapy: {
      audio: '🎧 Audio',
      guide: '📄 Fiche',
      post: '🗞️ Article',
      unknown: 'Inconnu',
      video: '🎞️ Vidéo',
      withConsultation: 'avec cs',
      sessionCount: 'Séance %{count}',
      chapterCount: 'Chapitre %{count}',
    },
    todoListSuggestion: {
      todoTasks: 'To-do tasks',
    },
    validation: {
      integer: 'Un chiffre entier est attendu',
    },
  },
  en: {
    activityPopup: {
      cancelText: 'End my shift',
      confirmText: 'Continue my shift',
      stillThere: '%{firstName}, are you still there?',
    },
    additionalHours: {
      add: 'Backup hour',
      cancelSelection: 'Cancel selection',
      comment: 'Comment',
      deleteConfirmText: 'Are you sure you want to delete this backup hour?',
      deleteHours: 'Delete',
      duration: 'Duration (min)',
      emptySelection: 'No backup hour selected',
      error:
        'The end time must be after the start time on the same day. Duration must be between 15 minutes and 2 hours.',
      errorCreateShort: 'Error creating. (Minimum 15 minutes, maximum 2 hours)',
      errorGeneric: 'Error during creation',
      errorNoPlanning: 'No planning found',
      hoursDeleted: 'Hours deleted',
      hoursInvalidated: 'Hours invalidated',
      hoursValidated: 'Hours validated',
      invalidateHours: '❌ Invalidate',
      selectedRows:
        '1 backup hour selected |||| %{smart_count} backup hours selected',
      startHour: 'Start time',
      validateHours: '✅ Validate',
      validated: 'Validated',
    },
    admin: {
      appBundles: {
        list: 'Bundle list',
        testers: 'Tester list',
        title: 'Bundles',
        platform: 'Platform',
        runtimeVersion: 'Version',
        status: 'Status',
        active: 'Active',
        inactive: 'Inactive',
        testing: 'Testing',
        s3Key: 'S3 Key',
      },
      categories: {
        consultations: 'Consultation categories',
        questions: 'Question categories',
        title: 'Categories',
      },
      contentTranslation: {
        addContent: 'Add content to translate',
        alreadyAdded: 'Already added',
        changeApprover: 'Update approver',
        contentType: {
          appointment_suggestion: 'Appointment suggestion',
          dailytips: 'Daily tips',
          post: 'Post',
          masterclass: 'Masterclass',
          weeklytips: 'Weekly tip',
          fertitips: 'Fertility tip',
          pregnancy_week_content: 'Pregnancy week content',
          child_month_content: 'Child month content',
          todo_list_suggestion: 'Todo suggestion',
          pregnancy_question: 'Prengnant can I...?',
          macro: 'Macro',
          guide: 'Guide',
        },
        dailyTip: {
          weekNumber: 'Week number: %{weekNumber}',
        },
        dashboard: 'Dashboard',
        dashboardTitle: 'Translation dashboard',
        documentsSent: 'Documents sent, it will appear soon on your dashboard.',
        externalSource: 'Import from a Google Drive folder',
        extra: 'Extra',
        isChild: 'Children',
        isPregnancy: 'Pregnancy',
        uploadCMS: 'Upload to CMS',
        complete: 'Complete',
        pricing: 'Pricing',
        referenceLocale: 'Reference language',
        refreshOwnership: 'Refresh ownership',
        refreshOwnershipSuccess:
          'Ownership refresh in progress. Please wait a few minutes.',
        retryPostCreateActions: 'Retry AI & gdoc creation',
        retryPostCreateActionsSuccess:
          'Documents are being processed (AI translation + added on gdrive). Please wait!',
        scope: 'Alcance',
        scopes: {
          midwife: 'Midwife',
          translator: 'Translator',
          gynecologist: 'Gynecologist',
          health_visitor: 'Health visitor',
          pediatric_nurse: 'Nurse',
          pediatrician: 'Pediatrician',
          other: 'Other',
        },
        selectAll: 'Select all',
        selectApprover: 'Select approver',
        startTranslation: 'Start',
        taskAttributed: 'Tasks attributed',
        targetLocale: 'Target language',
        type: 'Type',
        title: 'Content translation',
        pricingTitle: 'Pricing',
        pricingLabels: {
          default: '%{startPricing} - Default %{country}',
          specific: '%{startPricing} %{country} %{basePrice} - %{vatRate}%',
        },
      },
      forms: {
        create: 'Create a form',
        list: 'Form list',
        title: 'Forms',
      },
      others: {
        title: 'Others',
        scenarios: 'Scenarios',
      },
      planning: {
        admin: 'Admin',
      },
      pricing: {
        affiliations: 'Promo code affiliations',
        slots: 'Shift pricing',
        title: 'Pricing and invoices',
      },
      title: 'Admin',
    },
    affiliations: {
      consultationsNumber: 'Number of consultations offered',
      create: 'Add an affiliation',
      createdAt: 'Creation date',
      empty: 'No affiliation',
      expirationDate: 'Expiration date',
      exportProCommunity: 'Export pro community',
      managePartners: 'Manage partners',
      managePlans: 'Manage generic offers',
      title: 'Affiliations',
      titleCode: 'Affiliation promo codes',
      usage: 'Usage / month',
      usagePerMonth: 'Code activation / month',
    },
    appUsers: {
      addToPhoneWhitelist: 'whitelist',
      currentEmail: 'Current email',
      deletionConfirmation: 'Are you sure you want to delete this account?',
      deletionCompletedAt: 'Deletion completed on ',
      deletionDate: 'Deletion scheduled on ',
      discussion: 'Discussions',
      membershipStatus: {
        bonus: 'Bonus subscriber',
        active: 'Subscriber',
        affiliated: 'Affiliated',
        trialing: 'Subscriber (trial period)',
        notSubscribed: 'Not subscribed',
      },
      moreInformation: 'Detailed information',
      newMail: 'New mail',
      removeFromWhitelist: 'Remove from whitelist',
      requestDeletion: 'Request deletion',
      title: 'Patient',
      updateMailAlertItem1: 'The email is valid',
      updateMailAlertItem2: 'The email is different from the old one',
      updateMailAlertItem3: 'No account exists with this new email',
      updateMailAlertItem4: 'Stripe & Customer.io are updated',
      updateMailAlertTitle: 'Integrated steps',
      updateMailError: 'Error - Email not updated',
      updateMailSuccess: 'Email updated',
      updateMailTitle: 'Update user email',
    },
    attributions: {
      automatic: 'Automatic',
      choiceTitle: 'Assignment mode:',
      myAttributions: 'My assignments',
      noAttribution: 'No assignment or unconnected assignment',
      start: 'Start my shift',
      startNoAttrib: 'Lend a hand',
      stop: 'End my shift',
      toMe: 'I assign this question to myself and respond',
    },
    auth: {
      backToLogin: 'Return to login screen',
      changePassword: 'Change my password',
      confirmationDialog: {
        title: 'Are you sure you want to log out?',
        disconnect: 'Log me out',
      },
      email: 'Email',
      forgottenPassword: 'Forgot password',
      forms: {
        errors: {
          generic_error: 'An error occurred... Check the password rules',
          invalid_credentials: 'Invalid credentials',
          invalid_2FA: 'Invalid code',
          timeout: 'Request timed out, please try again',
        },
        success: {
          email_sent: 'Email sent',
          password_updated: 'Password updated',
          password_reset: 'Password reset',
        },
      },
      login: {
        '2FA': 'Please enter the code received via SMS',
        TOTP: 'Please enter the code displayed by the authenticator',
        connect: 'Login',
        forceSms: 'Use SMS',
        forceTOTP: 'Use authenticator',
      },
      logout: 'Logout',
      totp: {
        title: 'Authenticator',
        qrcode: 'QR Code',
        description: 'Scan the QR Code with your authenticator',
        manual: 'Enter the code manually: %{code}',
        generate: 'Use an authenticator',
        validate: 'Validate by entering the code',
      },
      passwordConstraints:
        'The password must contain at least 8 characters, including an uppercase letter, a lowercase letter, and a number or special character.',
      resetPassword: {
        newPassword: 'New password',
        resetPassword: 'Reset your password',
        sendEmail: 'Send password reset email',
        twoFA: 'Please enter the code received via SMS',
        validateNew: 'Validate my new password',
      },
      trustDevice: 'Trust this device',
    },
    availabilities: {
      created: 'Slot created',
      deleted: 'Slot deleted',
      my_consultations: 'My consultations',
      other_consultations: 'Other consultations',
      owner_error: 'You cannot edit/delete a slot that does not belong to you',
      updated: 'Slot updated',
      none: 'No availability',
    },
    button: {
      add: 'Add',
    },
    calendars: {
      allDay: 'All day',
      daily: 'Daily',
      date: 'Date',
      hour: 'Hour',
      list: 'List',
      monthly: 'Monthly',
      next: '>',
      noEvents: 'No slots',
      previous: '<',
      slot: 'Slot',
      title: 'Calendars',
      today: 'Today',
      tomorrow: 'Tomorrow',
      total: '1 other |||| %{smart_count} others',
      weekly: 'Weekly',
      yesterday: 'Yesterday',
    },
    category: {
      name: 'Category',
      save: 'Category saved',
      useSuggestion: 'Use suggestion',
      chose: 'Choose a category:',
      suggested: 'Suggested category',
    },
    categories: {
      active: 'Active',
      add: 'Add a category',
      excludeFromTriage: 'Not for AI triage',
      meta: 'Meta',
      name: 'Name',
      pregnancy: 'Pregnancy',
      priority: 'Priority',
      slug: 'Slug',
      slugDoNotModify: 'Unique identifier. Do not update.',
      title: 'Categories',
    },
    changelog: {
      contents: 'Contents',
      displayNotifications: 'Display notifications',
      empty: 'No news at the moment :(',
      title: "What's new",
    },
    changes_saved: 'Changes saved',
    chat: {
      contentTitle: {
        folder: 'Folder',
        masterclass: 'Masterclass',
        article: 'Article',
        guide: 'Practical guide',
        dailyTip: 'Tip of the day',
        weeklyTip: 'Weekly activity',
        fertiTip: 'Fertility tip',
        video: 'Video',
      },
      endIntroductionMessage: 'at May, and I will assist you until',
      greetings: 'Good morning',
      greetingsNight: 'Good evening',
      iAm: 'I am',
      loadPrevious: 'Load previous messages',
      placeholder: 'Type your message here...',
      sentError: 'Error sending message %{error}',
    },
    children: {
      deactivated: 'Deactivated',
      empty: 'No child',
      title: 'Children',
    },
    common: {
      active: 'Active',
      add: 'Add',
      admin: 'Admin',
      age: 'Age',
      alias: 'Alias',
      all: 'All',
      already: 'already',
      avatar: 'Avatar',
      averageLast7Days: 'Daily average over the last days',
      birth: 'Birth',
      birthDay: 'Date of birth',
      born: 'Born',
      bottleShort: 'Bottle',
      cancel: 'Cancel',
      charts: 'Charts',
      child: 'Child',
      children: 'Children',
      close: 'Close',
      colon: ' : ',
      confirm: 'Confirm',
      confirmClose: 'Confirm close',
      confirmCloseText:
        'Your changes will not be saved, do you want to continue?',
      confirmDeletion: 'Confirm deletion',
      confirmSave: 'Confirm save',
      copy: 'Copy',
      countries: {
        fr: 'France',
        FR: 'France',
        en: 'United Kingdom',
        GB: 'United Kingdom',
        es: 'Spain',
        ES: 'Spain',
      },
      country: 'Country',
      create: 'Créer',
      createdAt: 'Création',
      creationDate: 'Date ajout',
      currency: 'Currency',
      dashboard: 'Dashboard',
      date: 'Date',
      day: 'Day',
      dayCount: '1 day |||| %{smart_count} days',
      dayShortCount: '%{days}d',
      deactivate: 'Deactivate',
      delete: 'Delete',
      description: 'Description',
      discussion: 'Discussion',
      doctorShort: 'Doc',
      dollar: '$',
      edit: 'Edit',
      email: 'Email',
      emptyList: 'No data available',
      enclose: 'Close',
      english: 'English',
      error: {
        create: 'Error during creation',
        delete: 'Error during deletion',
        validate: 'Error during validation',
        withArgs: 'An error occurred: %{error}',
        generic: 'An error occurred...',
      },
      errorBoundary: 'An error occurred, please try again later',
      euro: '€',
      expectedEnd: 'Due date',
      export: 'Export',
      failToFetch: 'Unable to retrieve',
      feedback: 'Your feedback',
      feedbackHelpText:
        'Your feedback helps us improve our service. What content were you expecting?',
      feeding: 'Feeding',
      filterByName: 'Filter by first name',
      firstName: 'First name',
      french: 'French',
      genericError: 'An error occurred...',
      goToDiscussion: 'Go to discussion',
      height: 'Height',
      image: 'Image',
      in: 'in',
      international: 'International',
      job: 'Job',
      legacy: 'Legacy',
      loadMore: 'Load more',
      locale: 'Language',
      logWith: 'Log with',
      male: 'Male',
      female: 'Female',
      me: 'Me',
      media: 'Media',
      midwifeShort: 'MW',
      model: 'Model',
      modifyEntry: 'Edit my entry',
      month: 'month',
      monthCount: '1 month |||| %{smart_count} months',
      monthShortCount: '%{months}m',
      more: 'See more',
      multiple: 'Multiple',
      name: 'Name',
      negativeFeedbackSubmitted: "Understood. We'll do better next time!",
      number: 'Number',
      next: 'Next',
      noData: 'No data',
      noDate: 'No date',
      noResults: 'No results',
      notValidated: 'Not validated',
      nurseShort: 'Nurse',
      OK: 'OK',
      onCall: 'On-call',
      page: 'Page',
      parent: 'Parent',
      parents: 'Parents',
      partners: 'Partners',
      partnersSendLivi: 'Send a link to Livi?',
      phone: 'Phone',
      positiveFeedbackSubmitted: '🤗 Thank you for your feedback!',
      pound: '£',
      preferences: 'Preferences',
      pregnancy: 'Pregnancy',
      previous: 'Previous',
      priority: 'Priority',
      pro: 'Pro',
      save: 'Save',
      saveAnyway: 'Save anyway',
      search: 'Search...',
      see: 'See',
      seeMore: 'See more...',
      seen: 'Seen',
      selectAll: 'Select all',
      settings: 'Settings',
      sex: 'Sex',
      sleep: 'Sleep',
      slot: 'Slot',
      slots: 'Slots',
      socketError:
        'Connection error to the server, reload the page if this message does not disappear',
      spanish: 'Spanish',
      startAt: 'Start at',
      status: 'Status',
      submit: 'Submit',
      subscriptionStatus: 'Subscription status',
      successfulUpdate: 'Update successful',
      title: 'Title',
      today: 'Today',
      total: 'Total',
      totalPerDay: 'Total per day',
      triageNurse: 'Triage nurse',
      unknown: 'Unknown',
      unitPrice: 'Unit price',
      unselectAll: 'Deselect all',
      update: 'Update',
      updatedAt: 'Updated at',
      usePrompt: 'Your changes will not be saved, do you want to continue?',
      validate: 'Validate',
      waitingTime: 'Overall waiting time',
      waitingTimeMine: 'Personal waiting time',
      weeks: 'weeks',
      weight: 'Weight',
      yearCount: '1 year |||| %{smart_count} years',
    },
    company: {
      activePlans: 'Active plans',
      add: 'Add a company',
      codes: 'Codes',
      create: 'Create a company',
      maxUsage: 'Max users',
      title: 'Companies',
    },
    companyPlan: {
      alias: 'Alias',
      code: 'Code',
      companyPlan: 'Company plan',
      create: 'Add a company plan',
      maxUsage: 'Max usages (-1 for infinite)',
      renewable: 'Renewable',
    },
    companyPlanCode: {
      code: 'Code',
      create: 'Add a code',
      expiresAt: 'Expiration date',
      maxUsage: 'Max usages (-1 for infinite)',
    },
    connectedUsers: {
      title: 'Connected users',
    },
    consultationCategory: {
      title: 'Consultation categories',
    },
    consultations: {
      actionPlan: 'Patient action plan',
      attribution: 'Change professional',
      attributionWarningTitle: 'Warning',
      attributionWarningText:
        'Before changing the professional, make sure all stakeholders are informed; a Slack notification will be sent.',
      attributionSuccess:
        'Consultation updated: professional assignment changed',
      availabilities: 'Availability slot',
      cancel: 'Cancel consultation',
      category: 'Topic',
      date: 'Date',
      displayOnboardingForm: 'Questionnaire results',
      displayTherapy: '🤓 View the program content',
      empty: 'No consultation scheduled',
      goToConsultations: 'Access consultations',
      hangUp: 'Hang up',
      otherActionsTitle: 'Other actions',
      patientEmailContent: 'Email content intended for the parent',
      phoneOnlyWarning:
        "Remember to hide your number by prefixing it with '#31#' on your mobile phone.",
      planning: {
        title: 'Consultation schedule',
      },
      proGuidelines: 'Guidelines for writing the action plan',
      reason: 'Reason',
      reopen: 'Reopen',
      report: 'Consultation report',
      reportShort: 'Report',
      reportShortPro: 'Internal use report',
      staffUser: 'Professional',
      startConsultation: 'Start video call',
      status: 'Status',
      tabs: {
        todo: 'To process',
        closed: 'Closed',
        canceled: 'Canceled',
        mine: 'My consultations',
      },
      therapyTitle: 'Program: ',
      title: 'Consultations',
      titleOne: 'Consultation',
      toComeTitle: 'Upcoming consultations',
      warning: 'Warning',
    },
    contentSuggestion: {
      assessment: 'Evaluate suggestions',
      empty: 'No relevant content found.',
      guide: 'Guides',
      macro: 'Macro',
      masterclass: 'Masterclass',
      post: 'Article',
      subtitle: 'Content suggested based on patient messages',
      title: 'Content suggestions',
    },
    contentTranslation: {
      addExtraTime: 'Add extra time',
      approver: 'Approver',
      commentsError: 'Error saving comments',
      commentsSuccess: 'Comments saved',
      commentsTitle: 'Comments',
      dashboardTitle: 'Content translation',
      extraTasks: 'Extra tasks',
      fields: {
        allStatus: 'All status',
        cmsURL: 'CMS',
        comments: 'Comments',
        count: 'Count',
        documentTitle: 'Title',
        documentUrl: 'Gdoc',
        meanTimeSpent: 'Mean time spent',
        scope: 'Scope',
        state: 'State',
        status: 'Status',
        timeSpent: 'Time spent (min)',
        type: 'type',
      },
      requestChanges: 'Request changes',
      status: {
        created: 'Created',
        ai_translated: 'AI translated',
        human_reviewed: 'Pro reviewed',
        pair_reviewed: 'Pair reviewed',
        changes_requested: 'Changes requested',
        validated: 'Validated',
        cms_auto_uploaded: 'CMS uploaded to check',
        cms_upload_failed: '⚠ Upload failed',
        cms_uploaded: 'CMS uploaded',
      },
      tab: {
        done: 'Done',
        extra: 'Extras (excluding translations)',
        inProgress: 'In progress',
        toAttribute: 'Attribute',
        toApprove: 'Approve',
        toUpload: 'Upload',
        toComplete: 'Complete',
        summary: 'Summary',
      },
      timeTrackingTitle: 'Time tracking and comments',
      timeTrackingSuccess: 'Time spent successfully saved',
      timeTrackingError: 'Error while saving time spent',
      uploader: 'Uploader',
    },
    discussion: {
      dailyTip: 'Daily tip',
      deletedMessage: '[Message deleted]',
      folder: 'Folder',
      form: 'Questionnaire',
      guide: 'Guide',
      image: 'Image',
      masterclass: 'Masterclass',
      noMessage: 'No message',
      numberCopied: 'Discussion number copied',
      partner: 'Livi',
      post: 'Article',
      video: 'Video',
      vipTooltip: 'VIP family ⭐ to pamper on the BO 😊',
      weeklyTip: 'Weekly tip',
    },
    favorites: {
      add: 'Add to favorites',
      addDescription: 'Add a discussion to favorites with a comment',
      addLabel: 'Add a discussion to favorites',
      commentLabel: 'Add a comment',
      comments: 'Comments',
      dashboard: 'My favorite discussions',
      lastMessage: 'Last msg',
      none: "You don't have any favorites yet",
      save: 'Save favorite',
      title: 'Favorites',
    },
    fertiTip: {
      cyclePosition: 'Week in cycle: %{cyclePosition}',
      cycleNumber: 'Cycle n°%{cycleNumber}',
      shortNumberAndPosition: 'Cycle %{cycleNumber} - Week %{cyclePosition}',
    },
    field: {
      image: {
        placeholder: 'Drag your file or select an image',
      },
      optionInput: {
        add: 'Add an option',
        description: 'Description',
        key: 'Key',
        requiredKey: 'Key required',
        requiredValue: 'Value required',
        title: 'Options',
        value: 'Value',
      },
    },
    followUp: {
      cancel: 'Cancel',
      confirm: 'Confirm',
      create: 'Follow-up',
      createTitle: 'Add a follow-up',
      edit: 'Edit a follow-up',
      empty: 'No medical follow-up has been recorded so far',
      observations: 'Observations',
      suppressText: 'Are you sure you want to delete this follow-up',
      suppressTitle: 'Delete a follow-up',
      title: 'Follow-up',
    },
    forms: {
      chat: {
        formMessage:
          'In progress. The patient must complete the questionnaire.',
        formMessageLocked: 'Form completed',
      },
      conditions: {
        booleanOperator: 'Logical operator',
        hideTitle: 'Edit masking conditions',
        displayTitle: 'Edit display conditions',
        questionRules: {
          add: 'Add a rule',
          expectedValue: 'Target value',
          operator: 'Operator',
          question: 'Question',
          title: 'Question rules',
        },
        dataRules: {
          add: 'Add data',
          dataSlug: 'Data',
          expectedValue: 'Target value',
          operator: 'Operator',
          title: 'User data rules',
        },
        externalRules: {
          add: 'Add external data',
          dataSlug: 'Data',
          expectedValue: 'Target value',
          operator: 'Operator',
          title: 'External data rules',
        },
      },
      dialogs: {
        delete: {
          content: 'Do you really want to delete this item?',
          title: 'Confirm deletion',
        },
      },
      display: {
        title: 'Display',
      },
      edit: {
        modified: 'Changes are in progress. Do you really want to leave?',
        saved: 'Form saved',
        title: 'Form settings',
        allLabels: 'All labels',
        labels: 'Labels',
        tags: 'Tags',
        allTags: 'All tags',
      },
      duplicate: {
        title: 'Duplicate form settings',
      },
      errors: {
        generic_error: 'An error occurred...',
        invalid_fields: 'Invalid or missing fields: %{invalidFields}',
        invalid_pattern: 'Invalid format',
        required_field: 'Required field',
        min_length: 'The field must contain at least %{minLength} characters',
        max_length:
          'The field must contain no more than %{maxLength} characters',
        unique_slug: 'The slug must be unique',
      },
      list: {
        columns: {
          createdAt: 'Creation',
          description: 'Description',
          slug: 'Slug',
          title: 'Title',
        },
        title: 'Forms',
        empty: 'No forms',
      },
      operator: {
        EQUAL: 'Equal',
        NOT_EQUAL: 'Not equal',
      },
      patientFile: {
        empty: 'No form has been completed so far',
      },
      questions: {
        description: 'Description',
        formTitle: 'Edit the question',
        label: 'CTA Label',
        secondaryLabel: 'CTA secondary label',
        hideTopBar: 'Hide top bar',
        options: 'Options',
        slug: 'Slug',
        title: 'Title',
        type: 'Type',
        copy: {
          title: 'Copy a question',
          question: 'Question to copy',
          copy: 'Copy',
        },
        requiredCTALabel: 'CTA Label is required if descriptions are provided',
        maxSelection: 'Maximum number of choices',
        scale: {
          type: 'Type of slider',
          minimumLabel: 'Minimum label',
          maximumLabel: 'Maximum label',
        },
      },
      runner: {
        backClicked: 'Back',
        canBack: 'Back possible',
        canceledForm: 'Form canceled',
        completedForm: 'Form completed. Score: %{score}, Tags: %{tags}',
        noAnswers: 'No answers',
        proAnalysis: 'Answer analysis',
        questionCount: 'Number of questions',
        score: 'Score',
        submitClicked: 'Submit',
        tags: 'Tags',
        unlockedForm: 'Form not finalized',
        addExternalData: 'Add external data',
        externalData: 'External data',
      },
      scoreMode: {
        NUMBER: 'Number',
        TAG: 'Tag',
      },
      scoringRule: {
        add: 'Add a scoring rule',
        expectedValue: 'Target value',
        formTitle: 'Scoring rule "%{question}"',
        operator: 'Operator',
        scoringValue: 'Value if true (%{number_or_string})',
        onboardingTag: 'Tag onboarding',
        actions: {
          NEXT_SCREEN: 'Display a screen',
          USER_PROFILE: 'Add to user profile',
          PAYWALL: 'Paywall',
          NEXT_FORM: 'Next form',
          END_ONBOARDING: 'End onboarding',
          SCHEDULE_SUBSCRIPTION_UPDATE: 'Schedule subscription update',
          EVENT_TRACKING: 'Event tracking',
          CHURN_SUBSCRIPTION: 'Churn ',
          PAYMENT_SCREEN: 'Payment screen',
        },
        remoteConfig: 'Remote config key',
        screenType: 'Screen type',
        screenName: 'Screen',
        planId: 'Stripe product ID',
        promotionCode: 'Promo code',
        event: 'Event to log',
        trackedData: 'Tracked data (format: key1:value1,key2:value2)',
        screenNames: {
          NOTIFICATION_OPT_IN_NO_PERSONA: 'opt-in notifications (sans persona)',
          NOTIFICATION_OPT_IN_PREGNANCY_CONTENT:
            'opt-in notifications (pregnancy-content)',
          NOTIFICATION_OPT_IN_PREGNANCY_TOOLS:
            'opt-in notifications (pregnancy-tools)',
          NOTIFICATION_OPT_IN_ALL_CHAT: 'opt-in notifications (chat)',
          NOTIFICATION_OPT_IN_PARENT_CONTENT:
            'opt-in notifications (parent-content)',
          NOTIFICATION_OPT_IN_PARENT_TOOLS:
            'opt-in notifications (parent-tools)',
          NOTIFICATION_OPT_IN_FERTILITY_CONTENT:
            'opt-in notifications (fertility-content)',
          NOTIFICATION_OPT_IN_FERTILITY_TOOLS:
            'opt-in notifications (fertility-tools)',
          FAMILY_CREATION: 'Family creation',
          FEATURES_BABY_3D: 'Baby 3D',
          FEATURES_SLEEP: 'Sleep',
          FEATURES_DIAPER: 'Diaper',
          FEATURES_FEED: 'Feed',
          FEATURES_CHILD_GROWTH_WEIGHT: 'Weight curve',
          FEATURES_CHILD_GROWTH_HEIGHT: 'Height curve',
          FEATURES_CHILD_GROWTH_CRANIAL: 'Cranial perimeter curve',
          FEATURES_CONTENT_FERTILITY: 'Content fertility',
          FEATURES_CONTENT_CHILD: 'Content child',
          FEATURES_CONTENT_PREGNANCY: 'Content pregnancy',
          FEATURE_FERTILITY: 'Add fertility symptoms',
          ONBOARDING_START: 'Welcome onboarding',
          ONBOARDING_INTRO_1: 'You are safe',
          REMOTE_CONFIG: 'remote config',
        },
        profile: 'Profile key',
        profileKeys: {
          sex: 'sex',
          maritalStatus: 'marital status',
          source: 'source',
          paid: 'Source (detail)',
          intent: 'Intent',
        },
        sexOptions: {
          MASCULINE: 'Masculine',
          FEMININE: 'Feminine',
          UNKNOWN: 'Unknown',
        },
        maritalStatusOptions: {
          COUPLE: 'In couple',
          ALONE: 'Alone',
          UNKNOWN: 'Unknown',
        },
        sourceOptions: {
          PAID: 'Payment',
          STORE_DISCOVERY: 'Store discovery',
          FAMILY_AND_FRIENDS: 'Family and friends',
          DOCTOR: 'Doctor',
          SEO: 'SEO',
          TV: 'TV',
          PODCAST: 'Podcast',
          OTHER: 'Other',
          UNKNOWN: 'Unknown',
          RECOMMENDATION: 'Recommandation',
          MATERNITY_BOX: 'Maternity (box)',
          ONLINE_DISCOVERY: 'online discovery',
          MEDIA_EVENTS: 'media events',
          INSTAGRAM: 'Instagram',
        },
        paidOriginOptions: {
          FACEBOOK: 'Facebook',
          INSTAGRAM: 'Instagram',
          GOOGLE: 'Google',
          YOUTUBE: 'Youtube',
          TIKTOK: 'Tiktok',
          PINTEREST: 'Pinterest',
          OTHER: 'Other',
          UNKNOWN: 'Unknown',
          SNAPCHAT: 'Snapchat',
          FRIENDS_AND_FAMILY: 'friends and family',
          HEALTHCARE_PROFESSIONNAL: 'healthcare professional',
          MATERNITY: 'maternity (box)',
          SEO: 'SEO',
          STORE_DISCOVERY: 'store discovery',
          EVENTS: 'media events',
          TV: 'TV',
          PODCAST: 'Podcast',
          JOURNAL: 'Journal',
        },
        subscribeModalName: 'Modal number',
        promoCode: 'Promo code',
        targetTab: 'Target tab',
        slugType: 'Slug type',
        formSlug: 'Form slug',
      },
      settings: {
        deleteTitle: 'Are you sure you want to delete this form?',
        deleteText:
          'This action is irreversible. If responses have already been provided, deletion will fail. You can always archive the form.',
        description: 'Description',
        illustration: 'Illustration',
        questionCount: 'Number of questions',
        questionCountHelper:
          'Leave blank if displaying a progress bar is not desired',
        sentInChat: 'Sent within messaging',
        slug: 'Slug',
        status: 'Status',
        title: 'Settings',
        titleField: 'Title',
      },
      send: 'Send a form',
      status: {
        draft: 'Drafts',
        published: 'Published',
        archived: 'Archived',
      },
      step: {
        createQuestion: 'Add a question',
        copyQuestion: 'Copy a question',
        createTemplate: 'Add a template',
        formTitle: 'Edit the step',
      },
      template: {
        formTitle: 'Edit the template',
        slug: 'Slug',
      },
      viewer: {
        retry: 'Try again',
        runError: 'Unable to run the form, an inconsistency persists',
      },
    },
    generic: {
      createSuccess: 'Creation successful',
      filtered: 'Filtered',
      maxValue: 'Maximum value',
      minValue: 'Minimum value',
      notSaved: 'Your changes will not be saved, do you want to continue?',
      reset: 'Reset',
      save: 'Save',
      step: 'Step',
      updateSuccess: 'Update successful',
    },
    handover: {
      AISuggestion: '🎉 AI Suggestion: ',
      acceptAllGeneration: '🎉 Accept all suggestions',
      activeRevives: 'active follow-ups',
      add: 'Add a handover',
      adviceLong: 'Proposed advice/actions',
      advice: 'Advice:',
      cancel: 'Cancel',
      confirm: 'Confirm',
      create: 'Create a handover',
      createWithRevive: 'Create with follow-up',
      deleteConfirmContent: 'Do you really want to delete this handover?',
      deleteConfirmTitle: 'Delete this handover?',
      edit: 'Edit a handover',
      editTooltip: 'Edit the handover',
      error: 'Error creating the follow-up: %{error}',
      noData: 'No handover available',
      noneAvailable: 'No handover available!',
      observations: 'Obs:',
      observationsLong: 'Observations made',
      preventEarlyLeave: 'Warning, your handover/follow-up is not saved',
      problem: 'Identified problem',
      reviveAt: 'Desired follow-up date',
      title: 'Handovers',
      titleForOne: 'Handover',
    },
    help: {
      changeBrowser: 'Switch to another browser. If unable to do so...',
      contact:
        'For any technical questions or bug reports, you can contact us directly on the Slack channel #helpdesk-uk.',
      help: 'May Help',
      or: 'or',
      pressOn: 'Press',
      pressSimultaneously: 'Press simultaneously',
      proKit: 'Pro kit shipping (France only)',
      refresh: 'Refresh BO',
      refreshBrowser1: 'Chrome/Firefox and Windows/Linux',
      refreshBrowser2: 'Chrome and Mac',
      refreshBrowser3: 'Safari and Mac',
      refreshBrowser4: 'Internet Explorer',
      shiftExplanation:
        '(Shift is the key near Ctrl with an upward arrow, the key used for capital letters!)',
    },
    history: {
      revives:
        '%{smart_count} active follow-up |||| %{smart_count} active follow-ups',
      title: 'Handovers',
    },
    invoice: {
      admin: {
        list: 'List',
        summary: 'Summary',
        title: 'Invoices - Admin',
      },
      adminList: {
        amountValidated: 'Validated amount',
        amountValidatedShort: '€ ?',
        comments: '💬',
        createdAt: 'Added',
        downloadButton:
          'Download an invoice |||| Download %{smart_count} invoices',
        downloadError: 'Error during download',
        downloadSuccess: 'Download successful',
        filters: 'Filters',
        invoice: 'Invoice',
        job: {
          doctor: 'Doctor',
          midwife: 'Midwife',
          nurse: 'Nurse',
          title: 'Profession',
        },
        notValidated: 'Not validated',
        planning: 'Planning',
        resetFilters: 'Reset',
        staffUser: 'Professional',
        validated: 'Validated',
        validateAndSepa: 'Validate + SEPA',
        validatedShort: '✔️',
      },
      bankTransfer: 'Bank transfer',
      comments: 'Comments',
      deleteShort: 'Del.',
      detailPerSlot: 'Detail per slot excl. VAT',
      dialog: {
        amountErrorTitle: 'Amount error',
        amountSuccessTitle: 'Amount detected in the invoice',
        amountError:
          'We did not detect an amount matching your summary (%{amount} €) in your invoice. Correct it if necessary or leave a comment below to justify the discrepancy.',
        amountSuccess:
          'We successfully detected the amount matching your summary (%{amount} €) in your invoice.',
        comments: 'Comments',
        commentsHelpTextWhenError:
          'Comment is required to justify the discrepancy',
        error: 'Error creating the invoice',
        errorRetrievingSummary: 'Error retrieving the summary',
        instructions: 'Instructions',
        instructions1: 'Your document must be in PDF format',
        instructions2: 'The date on it must match the month of the service',
        instructions3:
          'The total must match the amount in the summary (unless there is an error on our part: let us know)',
        success: 'Invoice successfully added',
        title: 'Add an invoice',
        upload: 'Add an invoice',
      },
      downloadXLS: 'Download XLSX',
      downloadAccountantSummary: 'Download accounting summary file',
      empty: 'No invoices at the moment.',
      errorGeneratingSepa: 'Error generating SEPA file - check the IBAN/BIC',
      feesNurse: 'Nursing fees',
      feesDoctor: 'Medical fees',
      format: 'Format',
      generateSepa: 'Generate SEPA transfer document',
      invoiceFrom: 'Invoice from',
      invoiceTo: 'to',
      lineChat: 'Practitioner chat fees disbursement',
      mailSent: 'Mail sent',
      mailSentPlural: 'Mails sent',
      numberSelected: '1 selected |||| %{smart_count} selected',
      noActivity: 'No activity on this schedule',
      pendingInvoice: 'Pending validation',
      see: 'See',
      sendSummaryMail: 'Send summary by email',
      sendSummaryToAll: 'Send summary to everyone by email',
      sepaGenerated: 'SEPA file generated',
      summary: 'Summary',
      title: 'Invoices',
      total: 'Calculated total: %{total} €',
      totalTaxFree: 'Total excl. VAT',
      vat20: 'VAT 20%',
      totalWithTax: 'Total incl. VAT',
      viewerTitle: 'Invoice',
      upload: 'Add an invoice',
      validatedInvoice: 'Validated invoice',
    },
    latestContent: {
      empty: 'No content has been created in the last 7 days. 😴',
      goToLibrary: 'View the library',
      loadError: 'Unable to load the latest content',
      subtitle: 'Released less than 7 days ago',
      title: 'Latest content',
    },
    library: {
      contentType: 'Content type',
      dailyTips: 'Daily tips',
      empty: 'No results',
      guide: {
        carousel: 'Guide details',
        title: 'Guide',
      },
      explorer: 'All content',
      fertiTips: 'Fertility tips',
      folders: 'Folders',
      form: 'Forms',
      guides: 'Guides',
      itemSuggestions: 'Baby care: Shopping guide',
      loading: 'Loading...',
      macros: 'Protocols',
      masterclasses: 'Masterclasses',
      mode: 'Mode',
      post: 'Article',
      posts: 'Articles',
      pregnancyQuestions: 'Pregnancy: Can I...',
      results: 'Search results',
      search: 'Content to send',
      searchField: 'Search',
      shareContent: 'Share content',
      steps: 'Steps',
      summary: 'Summary',
      title: 'Library',
      tracks: 'Episodes',
      types: 'Content types',
      weeklyTips: 'Weekly tips',
    },
    locale: {
      days: {
        long: {
          friday: 'Friday',
          monday: 'Monday',
          saturday: 'Saturday',
          sunday: 'Sunday',
          thursday: 'Thursday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
        },
        short: {
          friday: 'Fri',
          monday: 'Mon',
          saturday: 'Sat',
          sunday: 'Sun',
          thursday: 'Thu',
          tuesday: 'Tue',
          wednesday: 'Wed',
        },
      },
      months: {
        long: {
          april: 'April',
          august: 'August',
          december: 'December',
          february: 'February',
          january: 'January',
          july: 'July',
          june: 'June',
          march: 'March',
          may: 'May',
          november: 'November',
          october: 'October',
          september: 'September',
        },
        number: {
          '1': 'January',
          '2': 'February',
          '3': 'March',
          '4': 'April',
          '5': 'May',
          '6': 'June',
          '7': 'July',
          '8': 'August',
          '9': 'September',
          '10': 'October',
          '11': 'November',
          '12': 'December',
        },
        short: {
          april: 'Apr',
          august: 'Aug',
          december: 'Dec',
          february: 'Feb',
          january: 'Jan',
          july: 'Jul',
          june: 'Jun',
          march: 'Mar',
          may: 'May',
          november: 'Nov',
          october: 'Oct',
          september: 'Sep',
        },
      },
    },
    macro: {
      behavior: 'Behavior',
      noProtocol: 'No protocol has been defined yet',
      protocol: 'Protocol',
    },
    medicalFollowUp: {
      add: 'Add a follow-up',
      deleteConfirmTitle: 'Delete this observation?',
      deleteConfirmContent: 'Do you really want to delete this observation?',
      fields: {
        content: 'Observations',
      },
      noData: 'No observations available',
      preventEarlyLeave: 'Warning, your follow-up is not saved',
      title: 'Global follow-up',
    },
    midwifeSubjects: {
      title: 'Midwife chat',
    },
    nurseSubjects: {
      title: 'Nurse chat',
    },
    patients: {
      deactivated: 'Deactivated patient',
      childrenNumber: '1 child. |||| %{smart_count} children.',
      lastBornChildBirthday: 'Last born on %{date}',
      title: 'Parents',
      unknown: 'Unknown',
    },
    patientFile: {
      title: 'Patient file',
    },
    partner: {
      livi: {
        description: '7 days a week from 7 AM to midnight',
        title: 'Consult online with a doctor',
      },
    },
    plan: {
      accessLevel: 'Access level',
      add: 'Add a plan',
      consultationsNumber: 'Number of consultations offered',
      monthsNumber: 'Number of valid months',
      name: 'Plan',
    },
    planning: {
      availabilities: {
        title: 'Availabilities',
        cantUpdate: 'You cannot modify the availabilities of this schedule',
        explanationTitle: 'Some reminders to improve schedule filling:',
        explanationItem1:
          'Provide availabilities for as many DAYS as possible in the month',
        explanationItem2:
          'Provide availabilities for as many TIME SLOTS as possible in a day',
        explanationItem3:
          "Fill in your preferences to 'frame' what the algorithm assigns you among all these availabilities",
        explanationItem4:
          'Check ✅ if you are available for the slot; otherwise, leave it unchecked. If you checked ✅ by mistake, click again to make the box empty (= not available)',
        notSaved: 'Your changes are not saved. Leave anyway?',
      },
      bias: 'Schedule bias',
      chosePlanningHours: 'Choose schedule hours',
      copyPlanning: 'Copy the schedule',
      create: 'Create a new schedule',
      deadlineAvailabilities: 'Availability submission deadline',
      dialog: {
        deletion: 'Deletion',
        deletionText: 'Do you really want to delete this schedule?',
        editSlots: 'Edit slots',
        endRegistration: 'Finalize registrations',
        endRegistrationCTA: 'Close submissions',
        openToPro: 'Allow professionals to register?',
        openToProCTA: 'Open pro submissions',
        seeAgenda: 'View the schedule',
        summary: 'Summary/Invoices',
        validate: 'Validate the schedule',
      },
      displayedUsers: 'Displayed users',
      displaySelector: {
        displayUnattributed: 'Show unattributed slots',
      },
      edit: 'Edit the schedule',
      errorLoading: 'Error loading schedules',
      evaluation: {
        BORING: 'A bit too quiet 😴',
        CALM: 'Rather calm 😌',
        feedback: 'How was your shift?',
        INTENSE: 'Intense but manageable 💪',
        PERFECT: 'Just perfect 👌',
        summary:
          'During this shift, you participated in %{participatedSubjects} questions and closed %{closedSubjects}.\n\nThank you 👍!',
        summaryNoCounts: 'Thanks for your feedback!',
        title: 'Shift feedback',
        TOO_INTENSE: 'Too intense, therefore uncomfortable 🥵',
        error: 'An error occurred, can you try again?',
      },
      event: {
        additionalHour: 'Backup hour',
        attribution: 'Attribution',
        cancelAttribution: 'Release the slot',
        cancelAttributionConfirmText:
          'Are you sure you want to release this slot?',
        create: 'Add a slot',
        created: 'Slot created',
        delete: 'Delete this slot',
        deleteError: 'Error deleting the slot',
        deleted: 'Slot deleted',
        edit: 'Edit a slot',
        end: 'End',
        error: 'Error creating/updating the slot',
        getAttribution: 'Assign the slot to yourself',
        getAttributionConfirmText:
          'Are you sure you want to assign this slot to yourself?',
        isTriage: 'Triage',
        noOtherAvailable: 'No other availability',
        onCall: 'On-call',
        onCallActivated: 'On-call activated',
        otherAvailable:
          '%{smart_count} availability |||| %{smart_count} availabilities',
        slot: 'Slot',
        start: 'Start',
        type: 'Slot type',
        updated: 'Slot updated',
      },
      fields: {
        title: 'Schedule',
        dates: 'Dates',
        totalInvoices: 'Invoices',
        totalValidated: 'Validated',
        totalInvalidAmount: 'Errors',
        totalExpectedInvoices: 'Professionals',
      },
      goToPlanning: 'View the schedule',
      hoursAlreadyValidated:
        'Impossible: the hours have already been validated',
      job: {
        nurse: 'Nurse',
        midwife: 'Midwife',
        doctor: 'Doctor/Pediatrician',
      },
      mine: 'My schedules',
      modelGlobal: 'Global model',
      modelPerWeek: 'Weekly model',
      noMatch: 'No schedule matches',
      notAttributed: 'Not assigned',
      notify: {
        errorSubmittingPlanning: 'Error submitting the schedule',
        create: 'Schedule created',
        edit: 'Schedule updated',
      },
      notYetOpen:
        '🤷 Schedules are not yet open for future availability submissions.',
      preferences: {
        unsaved: 'Your changes will not be saved, do you want to continue?',
        confirmClose: 'Confirm close',
        confirmCloseText:
          'Your changes will not be saved, do you want to continue?',
        title: 'Preferences',
        maximumPerPlanning: 'Maximum shifts for the entire schedule',
        maximumSlotsOnWeekends:
          'Maximum weekends worked in the month (Saturday or Sunday)',
        maximumPerWeek: 'Maximum shifts per week (excluding weekends)',
        maximumNightsPerWeek:
          'Maximum evening shifts per week (excluding weekends)',
        minimumSlotsWanted: 'Minimum desired shifts in total',
        minimumSlotsWantedError: 'The minimum must be less than the maximum!',
        noWeekEndAvailable:
          'You have not provided any weekend availability this month, is that an oversight?',
        save: 'Update my preferences',
        setFirst: 'Set preferences first',
        reminderTitle: 'Reminder',
        reminderText:
          "It's better to set your preferences first to benefit from automatic saving",
        tooMuchSlotsWanted:
          "You are requesting more shifts than you have available days: we won't be able to meet your request.",
        workWholeWeekend:
          'Do you agree to work two different days in the same weekend?',
      },
      priorityBias: 'Bias for priority slots',
      results: {
        additionalHours: 'Backup',
        attributions: 'Assignments',
        attributionCount: 'Assignments %{count}/%{total}',
        availabilities: 'Availabilities',
        summary: 'Summary',
      },
      status: {
        adminSlotsAssigned: 'Availabilities entered',
        calculationPerformed: 'To validate',
        done: 'Validated',
        draft: 'Draft',
        healthProAssigned: 'Entry closed',
        slotsCreated: 'Template created',
      },
      summary: {
        maxNightPerWeek: '# max evenings/week',
        nbMaxPerWeek: '# max/week',
        nbMin: '# min',
        nbNight: '# evenings',
        nbOnCalls: '# on-calls',
        nbSlots: '# shifts',
        pro: 'Professional',
        weekend: 'Weekend',
      },
      title: 'Schedule',
      titleAdmin: 'Admin schedule',
      topToolbar: {
        byJob: 'By profession',
        byUser: 'By person',
      },
    },
    pregnancy: {
      born: 'Born',
      empty: 'No ongoing pregnancy',
      expectedEnd: 'Expected term',
      interrupted: 'Interrupted',
      multiple: 'Multiple pregnancy',
      multipleVeryShort: 'Mult.',
      plural: 'Pregnancies',
    },
    pregnancyWeekContent: {
      amenorrheaWeek: 'AW',
      height: 'Height',
      weight: 'Weight',
    },
    pricingProCustom: {
      add: 'Add a pricing',
      base: 'Base',
      night: 'Night',
      rateOnCall: 'On-call coefficient',
      sundayHoliday: 'Holiday',
      vat: 'VAT',
    },
    pricingProGeneric: {
      add: 'Add a pricing',
      base: 'Base',
      job: 'Profession',
      night: 'Night',
      rateOnCall: 'On-call coefficient',
      sundayHoliday: 'Holiday',
    },
    profile: {
      changePassword: 'Change my password',
      commercialName: 'Trade name (invoices)',
      confirmPassword: 'Confirm new password',
      jobTitle: 'Specialty',
      newPassword: 'New password',
      passwordsDoNotMatch: 'Passwords do not match',
      phone: 'Phone (format +336...)',
      title: 'My profile',
      update: 'Update my information',
    },
    revives: {
      attributed: 'Attributed',
      attributedStaffUserId: 'Attributed to',
      by: 'by: ',
      close: 'Close the follow-up',
      closeQuestion: 'Close the follow-up?',
      createOne: 'Create a follow-up',
      edit: 'Edit the follow-up',
      errorCreate: 'Error creating the follow-up',
      errorClosing: 'Error closing the follow-up',
      errorSendingMessage: 'Error sending the message',
      loading: 'Loading...',
      noRevives: 'No follow-ups',
      nonAttributed: 'Not attributed',
      pendingRevivesBeforeSlotCancel:
        '1 follow-up was attributed to this slot. It has been unassigned. |||| %{smart_count} follow-ups were attributed to this slot. They have been unassigned.',
      performAt: 'Follow-up on: ',
      performedAt: 'Follow-up done on: ',
      planned:
        'You have %{smart_count} planned follow-up for this shift: |||| You have %{smart_count} planned follow-ups for this shift:',
      plannedText: 'Follow-up text',
      reviveAt: 'Desired follow-up date',
      revives: '1 follow-up |||| %{smart_count} follow-ups',
      sendRevive: 'Send the follow-up?',
      sendText: 'Send the follow-up text',
      slot: 'Slot',
      successCreate: 'Follow-up successfully created',
      title: 'Follow-up',
      todo: 'Follow-ups to do',
      unattributed: 'unassigned |||| unassigned',
    },
    roles: {
      cannotCreateAdminRoleDueToPermission:
        'You do not have sufficient rights to create an administrator role',
      cannotCreateRoleWithSameRole: 'This user already has this role',
      created: 'Role created',
      deleted: 'Role deleted',
      role: 'Role',
      roles: 'Roles',
    },
    scenario: {
      add: 'Add a scenario',
      created: 'Created on',
    },
    similarQuestion: {
      button: 'Show similar questions',
      noMatch: 'No similar questions found.',
      noMatchNB:
        'ℹ️ For follow-ups, no similar questions are returned, as well as all questions where professionals initiated a conversation.',
      title: 'Similar questions',
    },
    slots: {
      atLeastOneRequired: 'At least one slot required',
      empty: 'No slots planned at the moment.',
      startBeforeEnd: 'Slots must have a start date earlier than the end date',
      toComeTitle: 'Upcoming slots',
      toComeSubtitle: 'Only a maximum of 10 results are displayed',
    },
    staffUser: {
      avatar: 'Avatar',
      bic: 'BIC',
      comfortArea: 'Comfortable with',
      commercialName: 'Trade name (invoice)',
      consultationPrice: 'Consultation price',
      email: 'Email',
      expertiseArea: 'Expertise area',
      firstName: 'First name',
      iban: 'IBAN',
      job: {
        admin: 'Admin',
        childPsychiatrist: 'Child psychiatrist',
        generalPractitioner: 'General practitioner',
        midwife: 'Midwife',
        nurseryNurse: 'Pediatric nurse',
        nutritionistDoctor: 'Nutritionist doctor',
        pediatrician: 'Pediatrician',
        psychologist: 'Psychologist',
      },
      jobTitle: 'Profession',
      language: 'Language',
      lastName: 'Last name',
      password: 'Password',
      passwordHelperText:
        'The password must contain at least 8 characters, including an uppercase letter, a lowercase letter, and a number or special character.',
      phone: 'Phone',
      phoneWithFormat: 'Phone (format +336...)',
      timezone: 'Timezone',
      title: 'Team May',
    },
    subject: {
      actives: 'Ongoing',
      category: 'Category',
      inactives: 'Completed',
      medicalIntervention: 'Medical opinions',
      midwifeQuestionsTooltip: 'Midwife questions awaiting a nurse response',
      medicalQuestions: 'Questions requiring medical opinions',
      myQuestions: 'My questions',
      myQuestionsTooltip: 'Number of assigned questions: personal (total)',
      noCategory: 'Questions without category',
      notAttributed: 'Unassigned',
      notAttributedTooltip: 'Number of unassigned questions',
      notPrio: 'Not Priority',
      noQuestionFilter: 'No questions match this filter',
      ongoingQuestions: 'Ongoing questions',
      prio: 'Priority',
      priority: 'Priority?',
      priorityQuestions: 'Priority questions',
      revives: 'Follow-ups',
      selectOne: 'Select a question from the list on the left',
      uncategorized: 'Uncategorized',
      withMedicalIntervention: '👩🏻‍⚕️ Medical opinion requested?',
      withMedicalInterventionShort: 'Med required',
      withoutMedicalInterventionShort: 'Med',
      withNurseIntervention: 'Nurse intervention?',
      withNurseInterventionShort: 'Nurse required',
      withoutNurseInterventionShort: 'Nurse',
      zenTooltip: 'Ongoing questions\n(waiting for a response from us)',
    },
    subjectExplorer: {
      category: 'Category',
      discussion: 'Discussion #',
      end: 'End',
      goToDiscussion: 'Go to discussion',
      keywords: 'Keywords',
      noMessage: 'No message...',
      noSubjectWithFilters: 'No questions match this filter',
      pro: 'Professional',
      start: 'Start',
      title: 'Super detective',
    },
    subscription: {
      activateBonus: 'Create bonus',
      affiliated: 'Affiliated',
      bonus: 'Free account',
      deactivateBonus: 'Deactivate bonus',
      empty: 'No subscriptions at the moment',
      none: 'None',
      paid: 'Paid subscription',
      refresh: 'Refresh',
      title: 'Subscriptions',
      trial: 'Trial period',
    },
    timezoneSelector: {
      label: 'Timezone',
      title: 'Timezone',
    },
    title: {
      admin: 'Administration interface',
      calendars: 'Calendars',
      dashboard: 'Dashboard',
      help: 'Help',
      moreInfo: 'More info',
      patient: 'Parents',
    },
    tools: {
      appert: {
        babyBottles: 'Number of bottles',
        childWeight: "Child's weight (in g)",
        description: 'Appert method calculator',
        formula: 'Formula used (Appert): 250 mL + (weight in grams) / 10',
        quantityInBottles: 'That is: %{bottleQuantity} mL per bottle',
        quantityRecommended: 'Recommended milk quantity: %{milk} mL / 24h',
        title: 'Appert Method',
      },
      choseChildren: 'Pick child(ren)',
      common: {
        oneYearShort: '1y',
      },
      cranial: 'Périmètre crânien',
      diapers: 'Couches',
      exportSuccess: 'The export was successful',
      exportError: 'The export failed.',
      feeding: 'Alimentation',
      feedingAndSleep: {
        legend: {
          babyBottle: 'Bottle',
          breastfeed: 'Breastfeeding',
          sleep: 'Sleep',
        },
        title: 'Feeding/Sleep tracker',
        tooltip: {
          duration: 'Duration: %{duration} min',
          quantity: 'Quantity: %{quantity} mL',
        },
        statistics: 'Statistics',
      },
      height: 'Taille',
      sleep: 'Sommeil',
      title: 'Tools',
      weight: {
        dueDate: 'Due date',
        start: 'Start',
        title: 'Weight',
      },
    },
    therapy: {
      audio: '🎧 Audio',
      guide: '📄 Guide',
      post: '🗞️ Article',
      unknown: 'Unknown',
      video: '🎞️ Video',
      withConsultation: 'with consultation',
      sessionCount: 'Session %{count}',
      chapterCount: 'Chapter %{count}',
    },
    todoListSuggestion: {
      todoTasks: 'To-do tasks',
    },
    validation: {
      integer: 'An integer is expected',
    },
  },
  es: {
    activityPopup: {
      cancelText: 'Terminar mi turno',
      confirmText: 'Continuar mi turno',
      stillThere: '¿%{firstName}, sigues ahí?',
    },
    additionalHours: {
      add: 'Hora de refuerzo',
      cancelSelection: 'Cancelar la selección',
      comment: 'Comentario',
      deleteConfirmText:
        '¿Estás seguro de que deseas eliminar esta hora de refuerzo?',
      deleteHours: 'Eliminar',
      duration: 'Duración (min)',
      emptySelection: 'No se seleccionó ninguna hora de refuerzo',
      error:
        'La hora de finalización debe ser posterior a la de inicio, el mismo día. La duración debe estar entre 15 minutos y 2 horas.',
      errorCreateShort: 'Error al crear. (Mínimo 15 minutos, máximo 2 horas)',
      errorGeneric: 'Error al crear',
      errorNoPlanning: 'No se encontró planificación',
      hoursDeleted: 'Horas eliminadas',
      hoursInvalidated: 'Horas invalidadas',
      hoursValidated: 'Horas validadas',
      invalidateHours: '❌ Invalidar',
      selectedRows:
        '1 hora de refuerzo seleccionada |||| %{smart_count} horas de refuerzo seleccionadas',
      startHour: 'Hora de inicio',
      validateHours: '✅ Validar',
      validated: 'Validada',
    },
    admin: {
      appBundles: {
        list: 'Lista de bundles',
        testers: 'Lista de testers',
        title: 'Bundles',
        platform: 'Plataforma',
        runtimeVersion: 'Versión',
        status: 'Estado',
        active: 'Activo',
        inactive: 'Inactivo',
        testing: 'En prueba',
        s3Key: 'Clave S3',
      },
      categories: {
        consultations: 'Categorías de consulta',
        questions: 'Categorías de preguntas',
        title: 'Categorías',
      },
      contentTranslation: {
        addContent: 'Añadir contenido',
        alreadyAdded: 'Ya añadido',
        changeApprover: 'Cambiar el aprobador',
        contentType: {
          appointment_suggestion: 'Calendario',
          dailytips: 'Consejos diaros',
          post: 'Artículo',
          masterclass: 'Masterclass',
          weeklytips: 'Consejos semanales',
          fertitips: 'Consejos de fertilidad',
          pregnancy_week_content: 'Contenido de la semana de embarazo',
          child_month_content: 'Contenido del mes del niño',
          todo_list_suggestion: 'Sugerencia de hacer',
          pregnancy_question: 'Embarazada: ¿Puedo...?',
          macro: 'Macro',
          guide: 'Guía',
        },
        dailyTip: {
          weekNumber: 'Semana %{weekNumber}',
        },
        dashboard: 'Dashboard',
        dashboardTitle: 'Seguimiento de la traducción',
        documentsSent:
          'Documentos enviados. Pronto aparecerán en tu panel de control.',
        externalSource: 'Importar desde una Google Drive carpeta',
        extra: 'Extra',
        isChild: 'Niños',
        isPregnancy: 'Embarazo',
        uploadCMS: 'Subir a CMS',
        complete: 'Finalizar',
        pricing: 'Pricing',
        referenceLocale: 'Idioma de referencia',
        useRefreshOwnership: 'Actualizar propietarios',
        refreshOwnershipSuccess:
          'Propietarios de documentos en actualización. ¡Por favor, espere!',
        retryPostCreateActions: 'Reintentar acciones de creación (AI & gdoc)',
        retryPostCreateActionsSuccess:
          'Los documentos se están procesando con inteligencia artificial y se agregarán a la unidad. ¡Por favor, espere!',
        scope: 'Alcance',
        scopes: {
          midwife: 'Matrona',
          translator: 'Traductor',
          gynecologist: 'Ginecólogo',
          health_visitor: 'Visitador de salud',
          pediatric_nurse: 'Infermera pediátrica',
          pediatrician: 'Pediatra',
          other: 'Otro',
        },
        selectAll: 'Seleccionar todo',
        selectApprover: 'Seleccionar aprobador',
        startTranslation: 'Iniciar',
        taskAttributed: 'Tarea atribuida',
        targetLocale: 'Idioma de destino',
        title: 'Traducción de contenido',
        pricingLabels: {
          default: '%{startPricing} - Default %{country}',
          specific: '%{startPricing} %{country} %{basePrice} - %{vatRate}%',
        },
        type: 'Tipo',
      },
      forms: {
        create: 'Crear un formulario',
        list: 'Lista de formularios',
        title: 'Formularios',
      },
      others: {
        title: 'Otros',
        scenarios: 'Escenarios',
      },
      planning: {
        admin: 'Admin',
      },
      pricing: {
        affiliations: 'Códigos promocionales de afiliaciones',
        slots: 'Precios de turnos',
        title: 'Precios y facturas',
      },
      title: 'Admin',
    },
    affiliations: {
      consultationsNumber: 'Número de consultas ofrecidas',
      create: 'Agregar una afiliación',
      createdAt: 'Fecha de creación',
      empty: 'Ninguna afiliación',
      expirationDate: 'Fecha de expiración',
      exportProCommunity: 'Exportar comunidad de profesionales',
      managePartners: 'Gestionar socios',
      managePlans: 'Gestionar ofertas genéricas',
      title: 'Afiliaciones',
      titleCode: 'Códigos promocionales de afiliación',
      usage: 'Uso / mes',
      usagePerMonth: 'Activación del código / mes',
    },
    appUsers: {
      addToPhoneWhitelist: 'Whitelist',
      currentEmail: 'Email actual',
      deletionConfirmation: '¿Estás seguro de que deseas eliminar esta cuenta?',
      deletionCompletedAt: 'Eliminación completada el ',
      deletionDate: 'Eliminación programada el ',
      discussion: 'Discusiones',
      membershipStatus: {
        bonus: 'Suscriptor bonus',
        active: 'Suscriptor activo',
        affiliated: 'Afiliado',
        trialing: 'Suscriptor (período de prueba)',
        notSubscribed: 'No suscrito',
      },
      moreInformation: 'Información detallada',
      newMail: 'Nuevo Email',
      removeFromWhitelist: 'Eliminar de la whitelist',
      requestDeletion: 'Solicitar eliminación',
      title: 'Paciente',
      updateMailAlertItem1: 'El correo es válido',
      updateMailAlertItem2: 'El correo es diferente al anterior',
      updateMailAlertItem3: 'No existe ninguna cuenta con este nuevo correo',
      updateMailAlertItem4: 'Stripe y Customer.io han sido actualizados',
      updateMailAlertTitle: 'Pasos integrados',
      updateMailError: 'Error - Correo no actualizado',
      updateMailSuccess: 'Correo actualizado',
      updateMailTitle: 'Actualizar correo del usuario',
    },
    attributions: {
      automatic: 'Automático',
      choiceTitle: 'Modo de asignación:',
      myAttributions: 'Mis asignaciones',
      noAttribution: 'Sin asignación o asignación no conectada',
      start: 'Comenzar mi turno',
      startNoAttrib: 'Echar una mano',
      stop: 'Terminar mi turno',
      toMe: 'Me asigno esta pregunta y respondo',
    },
    auth: {
      backToLogin: 'Volver a la pantalla de inicio de sesión',
      changePassword: 'Cambiar mi contraseña',
      confirmationDialog: {
        title: '¿Estás seguro de que deseas desconectarte?',
        disconnect: 'Desconectarme',
      },
      email: 'Email',
      forgottenPassword: '¿Olvidaste tu contraseña?',
      forms: {
        errors: {
          generic_error:
            'Ocurrió un error... Verifica las reglas de la contraseña',
          invalid_credentials: 'Credenciales inválidas',
          invalid_2FA: 'Código inválido',
          timeout: 'El envío tardó demasiado, por favor intenta nuevamente',
        },
        success: {
          email_sent: 'Correo enviado',
          password_updated: 'Contraseña actualizada',
          password_reset: 'Contraseña restablecida',
        },
      },
      login: {
        '2FA': 'Por favor, ingresa el código recibido por SMS',
        TOTP: 'Por favor, ingresa el código mostrado por el autenticador',
        connect: 'Iniciar sesión',
        forceSms: 'Usar SMS',
        forceTOTP: 'Usar autenticador',
      },
      logout: 'Cerrar sesión',
      totp: {
        title: 'Autenticador',
        qrcode: 'Código QR',
        description: 'Escanea el código QR con tu autenticador',
        manual: 'Ingresa el código manualmente: %{code}',
        generate: 'Usar un autenticador',
        validate: 'Validar ingresando el código',
      },
      passwordConstraints:
        'La contraseña debe contener al menos 8 caracteres, una mayúscula, una minúscula y un número o carácter especial.',
      resetPassword: {
        newPassword: 'Nueva contraseña',
        resetPassword: 'Restablecer tu contraseña',
        sendEmail: 'Enviar correo de restablecimiento',
        twoFA: 'Por favor, ingresa el código recibido por SMS',
        validateNew: 'Validar mi nueva contraseña',
      },
      trustDevice: 'Confiar en este dispositivo',
    },
    availabilities: {
      created: 'Franja horaria creada',
      deleted: 'Franja horaria eliminada',
      my_consultations: 'Mis consultas',
      other_consultations: 'Otras consultas',
      owner_error:
        'No puedes modificar/eliminar una franja horaria que no te pertenece',
      updated: 'Franja horaria actualizada',
      none: 'Sin disponibilidad',
    },
    button: {
      add: 'Agregar',
    },
    calendars: {
      allDay: 'Todo el día',
      daily: 'Diario',
      date: 'Fecha',
      hour: 'Hora',
      list: 'Lista',
      monthly: 'Mensual',
      next: '>',
      noEvents: 'Sin franjas horarias',
      previous: '<',
      slot: 'Franja horaria',
      title: 'Calendarios',
      today: 'Hoy',
      tomorrow: 'Mañana',
      total: '1 otro |||| %{smart_count} otros',
      weekly: 'Semanal',
      yesterday: 'Ayer',
    },
    category: {
      name: 'Categoría',
      save: 'Categoría guardada',
      useSuggestion: 'Usar la sugerencia',
      chose: 'Elegir una categoría:',
      suggested: 'Categoría sugerida',
    },
    categories: {
      active: 'Activa',
      add: 'Agregar una categoría',
      excludeFromTriage: 'No para triaje AI',
      meta: 'Meta',
      name: 'Nombre',
      pregnancy: 'Embarazo',
      priority: 'Prioridad',
      slug: 'Slug',
      slugDoNotModify: 'Identificador único. No actualizar.',
      title: 'Categorías',
    },
    changelog: {
      contents: 'Contenidos',
      displayNotifications: 'Mostrar notificaciones',
      empty: 'Sin novedades por el momento :(',
      title: 'Novedades',
    },
    changes_saved: 'Cambios guardados',
    chat: {
      contentTitle: {
        folder: 'Carpeta',
        masterclass: 'Masterclass',
        article: 'Artículo',
        guide: 'Guía práctica',
        dailyTip: 'Consejo del día',
        weeklyTip: 'Actividad de la semana',
        fertiTip: 'Consejo de fertilidad',
        video: 'Video',
      },
      endIntroductionMessage: 'en May y te acompaño hasta',
      greetings: 'Buenos días',
      greetingsNight: 'Buenas noches',
      iAm: 'soy',
      loadPrevious: 'Cargar mensajes anteriores',
      placeholder: 'Escribe tu mensaje aquí...',
      sentError: 'Error al enviar el mensaje %{error}',
    },
    children: {
      deactivated: 'desactivado',
      empty: 'Ningún niño',
      title: 'Niños',
    },
    common: {
      active: 'Activo',
      add: 'Agregar',
      admin: 'Admin',
      age: 'Edad',
      alias: 'Alias',
      all: 'Todos',
      already: 'ya',
      avatar: 'Avatar',
      averageLast7Days: 'Promedio diario de los últimos días',
      birth: 'Nacimiento',
      birthDay: 'Fecha de nacimiento',
      born: 'Nacido/a',
      bottleShort: 'Bib.',
      cancel: 'Cancelar',
      charts: 'Gráficas',
      child: 'Niño/a',
      children: 'Niños/as',
      close: 'Cerrar',
      colon: ' : ',
      confirm: 'Confirmar',
      confirmClose: 'Confirmar cierre',
      confirmCloseText: 'Tus cambios no se guardarán, ¿quieres continuar?',
      confirmDeletion: 'Confirmar eliminación',
      confirmSave: 'Confirmar guardado',
      copy: 'Copiar',
      countries: {
        fr: 'Francia',
        FR: 'Francia',
        en: 'Reino Unido',
        GB: 'Reino Unido',
        es: 'España',
        ES: 'España',
      },
      country: 'País',
      create: 'Crear',
      createdAt: 'Creación',
      creationDate: 'Fecha de añadido',
      currency: 'Moneda',
      dashboard: 'Panel de control',
      date: 'Fecha',
      day: 'Día',
      dayCount: '1 día |||| %{smart_count} días',
      dayShortCount: '%{days}d',
      deactivate: 'Desactivar',
      delete: 'Eliminar',
      description: 'Descripción',
      discussion: 'Discusión',
      doctorShort: 'Doc',
      dollar: 'Dólar',
      edit: 'Editar',
      email: 'correo electrónico',
      emptyList: 'No hay datos disponibles',
      enclose: 'Cerrar',
      english: 'Inglés',
      error: {
        create: 'Error al crear',
        delete: 'Error al eliminar',
        validate: 'Error al validar',
        withArgs: 'Ocurrió un error: %{error}',
        generic: 'Ocurrió un error...',
      },
      errorBoundary: 'Ocurrió un error, por favor inténtalo más tarde',
      euro: 'Euro',
      expectedEnd: 'Fecha prevista de término',
      export: 'Export',
      failToFetch: 'Imposible recuperar',
      feedback: 'Tu opinión',
      feedbackHelpText:
        'Tu opinión nos ayuda a mejorar nuestro servicio. ¿Qué contenido esperabas?',
      feeding: 'Alimentación',
      filterByName: 'Filtrar por nombre',
      firstName: 'Nombre',
      french: 'Francés',
      genericError: 'Ocurrió un error...',
      goToDiscussion: 'Ver la discusión',
      height: 'Altura',
      image: 'Imagen',
      in: 'en',
      international: 'Internacional',
      job: 'Trabajo',
      legacy: 'Legacy',
      loadMore: 'Cargar más',
      locale: 'Idioma',
      logWith: 'Iniciar sesión con',
      male: 'Masculino',
      female: 'Femenino',
      me: 'Yo',
      media: 'Medios',
      midwifeShort: 'SF',
      model: 'Modelo',
      modifyEntry: 'Modificar mi entrada',
      month: 'mes',
      monthCount: '1 mes |||| %{smart_count} meses',
      monthShortCount: '%{months}m',
      more: 'Ver más',
      multiple: 'Múltiple',
      name: 'Nombre',
      negativeFeedbackSubmitted:
        'Entendido. ¡Haremos un mejor trabajo la próxima vez!',
      number: 'Número',
      next: 'Siguiente',
      noData: 'Sin datos',
      noDate: 'Sin fecha',
      noResults: 'Sin resultados',
      notValidated: 'no validado',
      nurseShort: 'EP',
      OK: 'OK',
      onCall: 'De guardia',
      page: 'Página',
      parent: 'Padre',
      parents: 'Padres',
      partners: 'Socios',
      partnersSendLivi: '¿Enviar un enlace a Livi?',
      phone: 'Teléfono',
      positiveFeedbackSubmitted: '🤗 ¡Gracias por tu opinión!',
      pound: 'Libra',
      preferences: 'Preferencias',
      pregnancy: 'Embarazo',
      previous: 'Anterior',
      priority: 'Prioridad',
      pro: 'Pro',
      save: 'Guardar',
      saveAnyway: 'Guardar de todos modos',
      search: 'Buscar...',
      see: 'Ver',
      seeMore: 'Ver más...',
      seen: 'Visto',
      selectAll: 'Seleccionar todo',
      settings: 'Configuraciones',
      sex: 'Sexo',
      sleep: 'Sueño',
      slot: 'Franja horaria',
      slots: 'Franjas horarias',
      socketError:
        'Error de conexión al servidor, recarga la página si este mensaje no desaparece',
      spanish: 'Español',
      startAt: 'Inicio',
      status: 'Estado',
      submit: 'Enviar',
      subscriptionStatus: 'Estado de suscripción',
      successfulUpdate: 'Actualización exitosa',
      title: 'Título',
      today: 'Hoy',
      total: 'Total',
      totalPerDay: 'Total por día',
      triageNurse: 'Triage AI',
      unknown: 'Desconocido',
      unitPrice: 'Precio unitario',
      unselectAll: 'Deseleccionar todo',
      update: 'Actualizar',
      updatedAt: 'Actualizado en',
      usePrompt: 'Tus cambios no se guardarán, ¿quieres continuar?',
      validate: 'Validar',
      waitingTime: 'Tiempo de espera total',
      waitingTimeMine: 'Mi tiempo de espera',
      weeks: 'semanas',
      weight: 'Peso',
      yearCount: '1 año |||| %{smart_count} años',
    },
    company: {
      activePlans: 'Planes activos',
      add: 'Agregar una empresa',
      codes: 'Códigos',
      create: 'Crear una empresa',
      maxUsage: 'Usuarios máximos',
      title: 'Empresas',
    },
    companyPlan: {
      alias: 'Alias',
      code: 'Código',
      companyPlan: 'Plan empresarial',
      create: 'Agregar un plan empresarial',
      maxUsage: 'Máximo de usos (-1 para infinito)',
      renewable: 'Renovable',
    },
    companyPlanCode: {
      code: 'Código',
      create: 'Agregar un código',
      expiresAt: 'Fecha de expiración',
      maxUsage: 'Máximo de usos (-1 para infinito)',
    },
    connectedUsers: {
      title: 'Usuarios conectados',
    },
    consultationCategory: {
      title: 'Categorías de consulta',
    },
    consultations: {
      actionPlan: 'Plan de acción para el paciente',
      attribution: 'Cambiar de profesional',
      attributionWarningTitle: 'Atención',
      attributionWarningText:
        'Antes de cambiar de profesional, asegúrate de que todas las partes interesadas estén informadas; se enviará una notificación por Slack.',
      attributionSuccess: 'Consulta actualizada: cambio de asignación',
      availabilities: 'Franja de disponibilidad',
      cancel: 'Cancelar la consulta',
      category: 'Temática',
      date: 'Fecha',
      displayOnboardingForm: 'Resultados del cuestionario',
      displayTherapy: '🤓 Ver el contenido del programa',
      empty: 'No hay consultas programadas',
      goToConsultations: 'Acceder a las consultas',
      hangUp: 'Colgar',
      otherActionsTitle: 'Otras acciones',
      patientEmailContent:
        'Contenido del correo electrónico destinado al padre/madre',
      phoneOnlyWarning:
        'Recuerda ocultar tu número prefijándolo con "#31#" en tu teléfono móvil.',
      planning: {
        title: 'Planificación de consultas',
      },
      proGuidelines: 'Instrucciones para redactar el plan de acción',
      reason: 'Motivo',
      reopen: 'Reabrir',
      report: 'Informe de consulta',
      reportShort: 'Informe',
      reportShortPro: 'Informe para uso interno',
      staffUser: 'Profesional',
      startConsultation: 'Iniciar videollamada',
      status: 'Estado',
      tabs: {
        todo: 'Por tratar',
        closed: 'Cerrado',
        canceled: 'Cancelado',
        mine: 'Mis consultas',
      },
      therapyTitle: 'Programa: ',
      title: 'Consultas',
      titleOne: 'Consulta',
      toComeTitle: 'Próximas consultas',
      warning: 'Atención',
    },
    contentSuggestion: {
      assessment: 'Evaluar las sugerencias',
      empty: 'No se encontró contenido relevante.',
      guide: 'Guías',
      macro: 'Macro',
      masterclass: 'Masterclass',
      post: 'Artículo',
      subtitle: 'Contenidos sugeridos en función de los mensajes del paciente',
      title: 'Sugerencias de contenido',
    },
    contentTranslation: {
      addExtraTime: 'Agregar extra tiempo',
      approver: 'Aprobador',
      commentsError: 'Error al guardar los comentarios',
      commentsSuccess: 'Comentarios guardados',
      commentsTitle: 'Comentarios',
      dashboardTitle: 'Traducción de contenido',
      extraTasks: 'Tareas adicionales',
      fields: {
        allStatus: 'Todos los estados',
        cmsURL: 'CMS',
        comments: 'Comentarios',
        count: 'Conteo',
        documentTitle: 'Título',
        documentUrl: 'Gdoc',
        meanTimeSpent: 'Tiempo promedio dedicado',
        scope: 'Scope',
        state: 'Estado',
        status: 'Status',
        timeSpent: 'Tiempo dedicado (min)',
        type: 'Tipo',
      },
      requestChanges: 'Solicitar cambios',
      status: {
        created: 'Creado',
        ai_translated: 'Traducción AI',
        human_reviewed: 'Revisión pro',
        pair_reviewed: 'Revisión en pareja',
        changes_requested: 'Solicitud de cambios',
        validated: 'Validado',
        cms_auto_uploaded: 'Subido a CMS para revisión',
        cms_uploaded: 'Subido al CMS',
        cms_upload_failed: '⚠ Upload failed',
      },
      tab: {
        done: 'Finalizado',
        extra: 'Extras (excluyendo traducción)',
        inProgress: 'En curso',
        toAttribute: 'Por asignar',
        toApprove: 'Por aprobar',
        toUpload: 'Por subir',
        toComplete: 'Por finalizar',
        summary: 'Resumen',
      },
      timeTrackingTitle: 'Tiempo dedicado y comentarios',
      timeTrackingSuccess: 'Tiempo dedicado guardado',
      timeTrackingError: 'Error al guardar el tiempo dedicado',
      uploader: 'Subidor',
    },
    discussion: {
      dailyTip: 'Consejo diario',
      deletedMessage: '[Mensaje eliminado]',
      folder: 'Carpeta',
      form: 'Cuestionario',
      guide: 'Guía',
      image: 'Imagen',
      masterclass: 'Masterclass',
      noMessage: 'Sin mensajes',
      numberCopied: 'Número de discusión copiado',
      partner: 'Livi',
      post: 'Artículo',
      video: 'Video',
      vipTooltip: 'Familia VIP ⭐ para mimar en el BO 😊',
      weeklyTip: 'Consejo semanal',
    },
    favorites: {
      add: 'Agregar a favoritos',
      addDescription: 'Agregar una discusión a favoritos con un comentario',
      addLabel: 'Agregar una discusión a favoritos',
      commentLabel: 'Agregar un comentario',
      comments: 'Comentarios',
      dashboard: 'Mis discusiones en favoritos',
      lastMessage: 'Último mensaje',
      none: 'Aún no tienes favoritos',
      save: 'Guardar como favorito',
      title: 'Favoritos',
    },
    fertiTip: {
      cyclePosition: 'Semana en el ciclo: %{cyclePosition}',
      cycleNumber: 'Ciclo n°%{cycleNumber}',
      shortNumberAndPosition: 'Ciclo %{cycleNumber} - Semana %{cyclePosition}',
    },
    field: {
      image: {
        placeholder: 'Arrastra tu archivo o selecciona una imagen',
      },
      optionInput: {
        add: 'Agregar una opción',
        description: 'Descripción',
        key: 'Clave',
        requiredKey: 'Clave requerida',
        requiredValue: 'Valor requerido',
        title: 'Opciones',
        value: 'Valor',
      },
    },
    followUp: {
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      create: 'Seguimiento',
      createTitle: 'Agregar un seguimiento',
      edit: 'Modificar un seguimiento',
      empty: 'Aún no se ha registrado ningún seguimiento médico',
      observations: 'Observaciones',
      suppressText: '¿Estás seguro de que deseas eliminar este seguimiento?',
      suppressTitle: 'Eliminar un seguimiento',
      title: 'Seguimiento',
    },
    forms: {
      chat: {
        formMessage: 'En curso. El paciente debe completar el cuestionario.',
        formMessageLocked: 'Formulario completado',
      },
      conditions: {
        booleanOperator: 'Operador lógico',
        hideTitle: 'Editar las condiciones de ocultación',
        displayTitle: 'Editar las condiciones de visualización',
        questionRules: {
          add: 'Agregar una regla',
          expectedValue: 'Valor objetivo',
          operator: 'Operador',
          question: 'Pregunta',
          title: 'Reglas sobre las preguntas',
        },
        dataRules: {
          add: 'Agregar un dato',
          dataSlug: 'Dato',
          expectedValue: 'Valor objetivo',
          operator: 'Operador',
          title: 'Reglas sobre los datos de usuario',
        },
        externalRules: {
          add: 'Agregar un dato externo',
          dataSlug: 'Dato',
          expectedValue: 'Valor objetivo',
          operator: 'Operador',
          title: 'Reglas sobre los datos externos',
        },
      },
      dialogs: {
        delete: {
          content: '¿Estás seguro de que deseas eliminar este elemento?',
          title: 'Confirmar eliminación',
        },
      },
      display: {
        title: 'Visualización',
      },
      edit: {
        allLabels: 'Todos los labels',
        labels: 'Labels',
        modified: 'Se están realizando cambios. ¿Seguro que quieres salir?',
        saved: 'Formulario guardado',
        title: 'Configuración del formulario',
        allTags: 'Todos los tags',
        tags: 'Tags',
      },
      duplicate: {
        title: 'Configuración del formulario duplicado',
      },
      errors: {
        generic_error: 'Ocurrió un error...',
        invalid_fields: 'Campos inválidos o faltantes: %{invalidFields}',
        invalid_pattern: 'Formato inválido',
        required_field: 'Campo requerido',
        min_length: 'El campo debe contener al menos %{minLength} caracteres',
        max_length:
          'El campo debe contener como máximo %{maxLength} caracteres',
        unique_slug: 'El slug debe ser único',
      },
      list: {
        columns: {
          createdAt: 'Creación',
          description: 'Descripción',
          slug: 'Slug',
          title: 'Título',
        },
        title: 'Formularios',
        empty: 'No hay formularios',
      },
      operator: {
        EQUAL: 'Igual',
        NOT_EQUAL: 'Diferente',
      },
      patientFile: {
        empty: 'Aún no se ha completado ningún formulario',
      },
      questions: {
        slug: 'Slug',
        description: 'Descripción',
        formTitle: 'Editar la pregunta',
        label: 'Etiqueta CTA',
        secondaryLabel: 'Etiqueta CTA secundaria',
        hideTopBar: 'Ocultar la barra de progreso',
        options: 'Opciones',
        title: 'Título',
        type: 'Tipo',
        copy: {
          title: 'Copiar una pregunta',
          question: 'Pregunta a copiar',
          copy: 'Copiar',
        },
        requiredCTALabel:
          'El label del CTA es obligatorio si se proporcionan descripciones',
        maxSelection: 'Número máximo de selecciones',
        scale: {
          minimumLabel: 'Etiqueta mínima',
          maximumLabel: 'Etiqueta máxima',
          type: 'Tipo de slider',
        },
      },
      runner: {
        backClicked: 'Volver',
        canBack: 'Es posible volver',
        canceledForm: 'Formulario cancelado',
        completedForm:
          'Formulario completado. Puntuación: %{score}, Etiquetas: %{tags}',
        noAnswers: 'Sin respuestas',
        proAnalysis: 'Análisis de respuestas',
        questionCount: 'Número de preguntas',
        score: 'Puntuación',
        submitClicked: 'Validar',
        tags: 'Etiquetas',
        unlockedForm: 'Formulario no finalizado',
        addExternalData: 'Agregar datos externos',
        externalData: 'Datos externos',
      },
      scoreMode: {
        NUMBER: 'Número',
        TAG: 'Etiqueta',
      },
      scoringRule: {
        add: 'Agregar una regla de puntuación',
        expectedValue: 'Valor objetivo',
        formTitle: 'Regla de puntuación "%{question}"',
        operator: 'Operador',
        scoringValue: 'Valor si es cierto (%{number_or_string})',
        onboardingTag: 'Tag onboarding',
        actions: {
          NEXT_SCREEN: 'Mostrar una pantalla',
          USER_PROFILE: 'Añadir al perfil del usuario',
          PAYWALL: 'Paywall',
          NEXT_FORM: 'Formulario siguiente',
          END_ONBOARDING: 'Fin del onboarding',
          CHURN_SUBSCRIPTION: 'Churn',
          EVENT_TRACKING: 'Event tracking',
          SCHEDULE_SUBSCRIPTION_UPDATE: 'Promo abonnement',
          PAYMENT_SCREEN: 'Pantalla de pagamento',
        },
        remoteConfig: 'Clave remote config',
        screenType: 'Tipo de pantalla',
        screenName: 'Pantalla',
        planId: 'Stripe product ID',
        promotionCode: 'Promo code',
        event: 'Evento a loggear',
        trackedData: 'Datos a loggear (formato: key1:value1,key2:value2)',
        screenNames: {
          NOTIFICATION_OPT_IN_NO_PERSONA: 'opt-in notificaciones (sin persona)',
          NOTIFICATION_OPT_IN_PREGNANCY_CONTENT:
            'opt-in notificaciones (grossesse-content)',
          NOTIFICATION_OPT_IN_PREGNANCY_TOOLS:
            'opt-in notificaciones (grossesse-tools)',
          NOTIFICATION_OPT_IN_ALL_CHAT: 'opt-in notificaciones (chat)',
          NOTIFICATION_OPT_IN_PARENT_CONTENT:
            'opt-in notificaciones (parent-content)',
          NOTIFICATION_OPT_IN_PARENT_TOOLS:
            'opt-in notificaciones (parent-tools)',
          NOTIFICATION_OPT_IN_FERTILITY_CONTENT:
            'opt-in notificaciones (fertility-content)',
          NOTIFICATION_OPT_IN_FERTILITY_TOOLS:
            'opt-in notificaciones (fertility-tools)',
          FAMILY_CREATION: 'Creacion de familia',
          FEATURES_BABY_3D: 'Baby 3D',
          FEATURES_SLEEP: 'Dormir',
          FEATURES_DIAPER: 'Pañales',
          FEATURES_FEED: 'Alimentacion',
          FEATURES_CHILD_GROWTH_WEIGHT: 'Curva de peso',
          FEATURES_CHILD_GROWTH_HEIGHT: 'Curva de altura',
          FEATURES_CHILD_GROWTH_CRANIAL: 'Perimetro craneal',
          FEATURES_CONTENT_FERTILITY: 'Contenido fertilidad',
          FEATURES_CONTENT_CHILD: 'Contenido niño',
          FEATURES_CONTENT_PREGNANCY: 'Contenido embarazo',
          FEATURE_FERTILITY: 'Añadir sintomas de fertilidad',
          ONBOARDING_START: 'Bienvenida onboarding',
          ONBOARDING_INTRO_1: 'Estás segura',
          REMOTE_CONFIG: 'remote config',
        },
        profile: 'Clave de perfil',
        profileKeys: {
          sex: 'sexo',
          maritalStatus: 'estado civil',
          source: 'fuente',
          paid: 'Source (detail)',
          intent: 'intención',
        },
        sexOptions: {
          MASCULINE: 'Masculino',
          FEMININE: 'Femenino',
          UNKNOWN: 'Desconocido',
        },
        maritalStatusOptions: {
          COUPLE: 'En pareja',
          ALONE: 'Soltero',
          UNKNOWN: 'Desconocido',
        },
        sourceOptions: {
          PAID: 'Pago',
          STORE_DISCOVERY: 'Descubrimiento en tienda',
          FAMILY_AND_FRIENDS: 'Familia y amigos',
          DOCTOR: 'Médico',
          SEO: 'SEO',
          TV: 'TV',
          PODCAST: 'Podcast',
          OTHER: 'Otro',
          UNKNOWN: 'Desconocido',
          RECOMMENDATION: 'recomendación',
          MATERNITY_BOX: 'Maternidad (box)',
          ONLINE_DISCOVERY: 'descubrimiento online',
          MEDIA_EVENTS: 'eventos medios',
          INSTAGRAM: 'Instagram',
        },
        paidOriginOptions: {
          FACEBOOK: 'Facebook',
          INSTAGRAM: 'Instagram',
          GOOGLE: 'Google',
          YOUTUBE: 'Youtube',
          TIKTOK: 'Tiktok',
          PINTEREST: 'Pinterest',
          OTHER: 'Otro',
          UNKNOWN: 'Desconocido',
          SNAPCHAT: 'Snapchat',
          FRIENDS_AND_FAMILY: 'familia y amigos',
          HEALTHCARE_PROFESSIONNAL: 'médico',
          MATERNITY: 'maternidad (box)',
          SEO: 'SEO',
          STORE_DISCOVERY: 'descubrimiento en tienda',
          EVENTS: 'eventos medios',
          TV: 'TV',
          PODCAST: 'Podcast',
          JOURNAL: 'Revista',
        },
        subscribeModalName: 'Número de la modal',
        promoCode: 'Código promocional',
        targetTab: 'Tab cible',
        slugType: 'Tipo de slug',
        formSlug: 'Slug del formulario',
      },
      settings: {
        deleteTitle: '¿Estás seguro de que deseas eliminar este formulario?',
        deleteText:
          'Esta acción es irreversible. Si ya se han enviado respuestas, la eliminación fallará. Siempre puedes archivar el formulario.',
        description: 'Descripción',
        illustration: 'Ilustración',
        questionCount: 'Número de preguntas',
        questionCountHelper:
          'Déjalo vacío si no deseas mostrar una barra de progreso',
        sentInChat: 'Enviado en la mensajería',
        slug: 'Slug',
        status: 'Estado',
        title: 'Configuración',
        titleField: 'Título',
      },
      send: 'Enviar un formulario',
      status: {
        draft: 'Borradores',
        published: 'Publicado',
        archived: 'Archivado',
      },
      step: {
        createQuestion: 'Agregar una pregunta',
        copyQuestion: 'Copiar una pregunta',
        createTemplate: 'Agregar una plantilla',
        formTitle: 'Editar la etapa',
      },
      template: {
        formTitle: 'Editar la plantilla',
        slug: 'Slug',
      },
      viewer: {
        retry: 'Intentar de nuevo',
        runError: 'No se pudo iniciar el formulario, persiste una incoherencia',
      },
    },
    generic: {
      createSuccess: 'Creación realizada',
      filtered: 'Filtrado',
      maxValue: 'Valor máximo',
      minValue: 'Valor mínimo',
      notSaved: 'Tus cambios no se guardarán, ¿quieres continuar?',
      reset: 'Restablecer',
      save: 'Guardar',
      step: 'Paso',
      updateSuccess: 'Modificación realizada',
    },
    handover: {
      AISuggestion: '🎉 Sugerencia IA: ',
      acceptAllGeneration: '🎉 Aceptar todas las sugerencias',
      activeRevives: 'seguimientos activos',
      add: 'Agregar una relevo',
      adviceLong: 'Consejos/Acciones propuestas',
      advice: 'Consejos:',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      create: 'Crear una relevo',
      createWithRevive: 'Crear con seguimiento',
      deleteConfirmContent: '¿Estás seguro de que deseas eliminar esta relevo?',
      deleteConfirmTitle: '¿Eliminar esta relevo?',
      edit: 'Editar una relevo',
      editTooltip: 'Modificar la relevo',
      error: 'Error al crear el seguimiento: %{error}',
      noData: 'No hay relevos disponibles',
      noneAvailable: '¡No hay relevos disponibles!',
      observations: 'Obs:',
      observationsLong: 'Observaciones realizadas',
      preventEarlyLeave: 'Atención, tu relevo/seguimiento no está guardado',
      problem: 'Problema identificado',
      reviveAt: 'Fecha deseada de seguimiento',
      title: 'Relevos',
      titleForOne: 'Relevo',
    },
    help: {
      changeBrowser: 'Cambia de navegador. Si no puedes hacerlo…',
      contact:
        'Para cualquier duda técnica o reporte de errores, puedes contactarnos directamente en el canal de Slack #helpdesk-es.',
      help: 'Ayuda May',
      or: 'o',
      pressOn: 'Presiona',
      pressSimultaneously: 'Presiona simultáneamente',
      proKit: 'Kit Pro (solo Francia)',
      refresh: 'Actualizar el BO',
      refreshBrowser1: 'Chrome/Firefox y Windows/Linux',
      refreshBrowser2: 'Chrome y Mac',
      refreshBrowser3: 'Safari y Mac',
      refreshBrowser4: 'Internet Explorer',
      shiftExplanation:
        '(shift es la tecla cerca de Ctrl con una flecha hacia arriba, la tecla para hacer mayúsculas)',
    },
    history: {
      revives:
        '%{smart_count} seguimiento activo |||| %{smart_count} seguimientos activos',
      title: 'Relevos',
    },
    invoice: {
      admin: {
        list: 'Lista',
        summary: 'Resumen',
        title: 'Facturas - Admin',
      },
      adminList: {
        amountValidated: 'Monto validado',
        amountValidatedShort: '€ ?',
        comments: '💬',
        createdAt: 'Añadido',
        downloadButton:
          'Descargar una factura |||| Descargar las %{smart_count} facturas',
        downloadError: 'Error al descargar',
        downloadSuccess: 'Descarga exitosa',
        filters: 'Filtros',
        invoice: 'Factura',
        job: {
          doctor: 'Médico',
          midwife: 'Matrona',
          nurse: 'Enfermera pediátrica',
          title: 'Profesión',
        },
        notValidated: 'No validado',
        planning: 'Planificación',
        resetFilters: 'Restablecer filtros',
        staffUser: 'Profesional',
        validated: 'Validado',
        validateAndSepa: 'Validar + SEPA',
        validatedShort: '✔️',
      },
      bankTransfer: 'Transferencia bancaria',
      comments: 'Comentarios',
      deleteShort: 'Supr.',
      detailPerSlot: 'Detalle por franja horaria sin IVA',
      dialog: {
        amountErrorTitle: 'Error en el monto',
        amountSuccessTitle: 'Monto detectado en la factura',
        amountError:
          'No hemos detectado un monto correspondiente a tu balance (%{amount} €) en tu factura. Corrígelo si es necesario o deja un comentario abajo para justificar la diferencia.',
        amountSuccess:
          'Hemos detectado correctamente el monto correspondiente a tu balance (%{amount} €) en tu factura.',
        comments: 'Comentarios',
        commentsHelpTextWhenError:
          'Comentario obligatorio para justificar la diferencia',
        error: 'Error al crear la factura',
        errorRetrievingSummary: 'Error al recuperar el resumen',
        instructions: 'Instrucciones',
        instructions1: 'Tu documento debe estar en formato PDF',
        instructions2: 'La fecha debe coincidir con el mes de la prestación',
        instructions3:
          'El total debe coincidir con el monto en el balance (excepto si es un error nuestro: háznoslo saber)',
        success: 'Factura añadida con éxito',
        title: 'Agregar una factura',
        upload: 'Agregar una factura',
      },
      downloadXLS: 'Descargar XLSX',
      downloadAccountantSummary: 'Descargar resumen archivo contable',
      empty: 'No hay facturas por el momento.',
      errorGeneratingSepa:
        'Error al generar el archivo SEPA - verifica los IBAN/BIC',
      feesNurse: 'Honorarios de enfermera',
      feesDoctor: 'Honorarios médicos',
      format: 'formato',
      generateSepa: 'Generar documento de transferencia SEPA',
      invoiceFrom: 'Factura del',
      invoiceTo: 'al',
      lineChat: 'Desembolso de honorarios de profesionales en chat',
      mailSent: 'Correo enviado',
      mailSentPlural: 'Correos enviados',
      numberSelected: '1 seleccionado |||| %{smart_count} seleccionados',
      noActivity: 'Sin actividad en este plan',
      pendingInvoice: 'En espera de validación',
      see: 'Ver',
      sendSummaryMail: 'Enviar el resumen por correo',
      sendSummaryToAll: 'Enviar el resumen a todos por correo electrónico',
      sepaGenerated: 'Archivo SEPA generado',
      summary: 'Resumen',
      title: 'Facturas',
      total: 'Total calculado: %{total} €',
      totalTaxFree: 'Total sin impuestos',
      vat20: 'IVA 20%',
      totalWithTax: 'Total con IVA',
      viewerTitle: 'Factura',
      upload: 'Agregar una factura',
      validatedInvoice: 'Factura validada',
    },
    latestContent: {
      empty: 'No se ha creado contenido en los últimos 7 días. 😴',
      goToLibrary: 'Consultar la biblioteca',
      loadError: 'Imposible cargar los últimos contenidos',
      subtitle: 'Publicados hace menos de 7 días',
      title: 'Últimos contenidos',
    },
    library: {
      contentType: 'Tipo de contenido',
      dailyTips: 'Consejos diarios',
      empty: 'Sin resultados',
      guide: {
        carousel: 'Detalle de las guías',
        title: 'Guía',
      },
      explorer: 'Todos los contenidos',
      fertiTips: 'Consejos de fertilidad',
      folders: 'Carpetas',
      form: 'Formularios',
      guides: 'Guías',
      itemSuggestions: 'Puericultura: Guía de compras',
      loading: 'Cargando...',
      macros: 'Protocolos',
      masterclasses: 'Masterclasses',
      mode: 'Modo',
      post: 'Artículo',
      posts: 'Artículos',
      pregnancyQuestions: 'Embarazo: ¿Puedo...?',
      results: 'Resultados de la búsqueda',
      search: 'Contenidos a enviar',
      searchField: 'Buscar',
      shareContent: 'Compartir contenido',
      steps: 'Pasos',
      summary: 'Resumen',
      title: 'Biblioteca',
      tracks: 'Episodios',
      types: 'Tipos de contenido',
      weeklyTips: 'Consejos semanales',
    },
    locale: {
      days: {
        long: {
          friday: 'Viernes',
          monday: 'Lunes',
          saturday: 'Sábado',
          sunday: 'Domingo',
          thursday: 'Jueves',
          tuesday: 'Martes',
          wednesday: 'Miércoles',
        },
        short: {
          friday: 'Vie',
          monday: 'Lun',
          saturday: 'Sáb',
          sunday: 'Dom',
          thursday: 'Jue',
          tuesday: 'Mar',
          wednesday: 'Mié',
        },
      },
      months: {
        long: {
          april: 'Abril',
          august: 'Agosto',
          december: 'Diciembre',
          february: 'Febrero',
          january: 'Enero',
          july: 'Julio',
          june: 'Junio',
          march: 'Marzo',
          may: 'Mayo',
          november: 'Noviembre',
          october: 'Octubre',
          september: 'Septiembre',
        },
        number: {
          '1': 'Enero',
          '2': 'Febrero',
          '3': 'Marzo',
          '4': 'April',
          '5': 'Mayo',
          '6': 'Junio',
          '7': 'Julio',
          '8': 'Agosto',
          '9': 'Septiembre',
          '10': 'Octubre',
          '11': 'Noviembre',
          '12': 'Diciembre',
        },
        short: {
          april: 'Abr',
          august: 'Ago',
          december: 'Dic',
          february: 'Feb',
          january: 'Ene',
          july: 'Jul',
          june: 'Jun',
          march: 'Mar',
          may: 'May',
          november: 'Nov',
          october: 'Oct',
          september: 'Sep',
        },
      },
    },
    macro: {
      behavior: 'Conducta a seguir',
      noProtocol: 'No se ha definido ninguna CAT por el momento',
      protocol: 'CAT',
    },
    medicalFollowUp: {
      add: 'Agregar un seguimiento',
      deleteConfirmTitle: '¿Eliminar esta observación?',
      deleteConfirmContent:
        '¿Estás seguro de que deseas eliminar esta observación?',
      fields: {
        content: 'Observaciones',
      },
      noData: 'No hay observaciones disponibles',
      preventEarlyLeave: 'Atención, tu seguimiento no está guardado',
      title: 'Seguimiento global',
    },
    midwifeSubjects: {
      title: 'Chat matrona',
    },
    nurseSubjects: {
      title: 'Chat EP',
    },
    patients: {
      deactivated: 'Paciente desactivado',
      childrenNumber: '1 niño/a |||| %{smart_count} niños/as.',
      lastBornChildBirthday: 'Último nacido el %{date}',
      title: 'Padres',
      unknown: 'Desconocido',
    },
    patientFile: {
      title: 'Expediente del paciente',
    },
    partner: {
      livi: {
        description: '7 días a la semana, de 7 a 12',
        title: 'Consulta en línea con un médico',
      },
    },
    plan: {
      accessLevel: 'Nivel de acceso',
      add: 'Agregar un plan',
      consultationsNumber: 'Número de consultas ofrecidas',
      monthsNumber: 'Número de meses válidos',
      name: 'Plan',
    },
    planning: {
      availabilities: {
        title: 'Disponibilidades',
        cantUpdate: 'No puedes modificar las disponibilidades de este plan',
        explanationTitle:
          'Algunos recordatorios para mejorar el llenado del plan:',
        explanationItem1:
          'Ofrece disponibilidades en la mayor cantidad posible de DÍAS dentro del mes',
        explanationItem2:
          'Ofrece disponibilidades en la mayor cantidad posible de FRANJAS HORARIAS dentro de un día',
        explanationItem3:
          'Llena tus preferencias de manera que "encuadren" lo que el algoritmo te asigna entre todas estas disponibilidades',
        explanationItem4:
          'Marca ✅ si estás disponible en la franja, de lo contrario, no marques nada. Si marcaste ✅ por error, puedes hacer clic nuevamente para que la casilla quede vacía (= no disponible)',
        notSaved:
          'Tus cambios no están guardados. ¿De todas formas deseas salir?',
      },
      bias: 'Sesgo en el plan',
      chosePlanningHours: 'Elige los horarios del plan',
      copyPlanning: 'Copiar el plan',
      create: 'Crear un nuevo plan',
      deadlineAvailabilities: 'Fecha límite para llenar las disponibilidades',
      dialog: {
        deletion: 'Eliminación',
        deletionText: '¿Estás seguro de que deseas eliminar este plan?',
        editSlots: 'Modificar las franjas horarias',
        endRegistration: 'Finalizar las inscripciones',
        endRegistrationCTA: 'Cerrar la inscripción',
        openToPro: '¿Permitir que los profesionales se inscriban?',
        openToProCTA: 'Abrir la inscripción profesional',
        seeAgenda: 'Ver el plan',
        summary: 'Resumen/Facturas',
        validate: 'Validar el plan',
      },
      displayedUsers: 'Usuarios mostrados',
      displaySelector: {
        displayUnattributed: 'Mostrar las franjas no asignadas',
      },
      edit: 'Modificar el plan',
      errorLoading: 'Error al cargar los planes',
      evaluation: {
        BORING: 'Un poco demasiado tranquilo 😴',
        CALM: 'Bastante tranquilo 😌',
        feedback: '¿Cómo fue tu turno?',
        INTENSE: 'Intenso pero manejable 💪',
        PERFECT: 'Justo perfecto 👌',
        summary:
          'Durante este turno, participaste en %{participatedSubjects} preguntas y cerraste %{closedSubjects}.\n\n¡Gracias 👍!',
        summaryNoCounts: '¡Gracias por tu comentario!',
        title: 'Comentarios sobre tu turno',
        TOO_INTENSE: 'Demasiado intenso, por lo tanto incómodo 🥵',
        error: 'Ocurrió un error, ¿puedes intentarlo de nuevo?',
      },
      event: {
        additionalHour: 'Hora adicional',
        attribution: 'Asignación',
        cancelAttribution: 'Liberar la franja',
        cancelAttributionConfirmText:
          '¿Estás seguro de que deseas liberar esta franja?',
        create: 'Agregar una franja',
        created: 'Franja creada',
        delete: 'Eliminar esta franja',
        deleteError: 'Error al eliminar la franja',
        deleted: 'Franja eliminada',
        edit: 'Modificar una franja',
        end: 'Fin',
        error: 'Error al crear/modificar la franja',
        getAttribution: 'Asignarse la franja',
        getAttributionConfirmText:
          '¿Estás seguro de que deseas asignarte esta franja?',
        isTriage: 'IAO',
        noOtherAvailable: 'Sin disponibilidad',
        onCall: 'De guardia',
        onCallActivated: 'Guardia activada',
        otherAvailable:
          '%{smart_count} disponibilidad |||| %{smart_count} disponibilidades',
        slot: 'Franja',
        start: 'Inicio',
        type: 'Tipo de franja',
        updated: 'Franja actualizada',
      },
      fields: {
        title: 'Planificación',
        dates: 'Fechas',
        totalInvoices: 'Facturas',
        totalValidated: 'Validadas',
        totalInvalidAmount: 'Errores',
        totalExpectedInvoices: 'Profesionales',
      },
      goToPlanning: 'Ver la planificación',
      hoursAlreadyValidated: 'Imposible: los horarios ya han sido validados',
      job: {
        nurse: 'Enfermera pediátrica',
        midwife: 'Matrona',
        doctor: 'Médico/Pediatra',
      },
      mine: 'Mis planes',
      modelGlobal: 'Modelo global',
      modelPerWeek: 'Modelo por semana',
      noMatch: 'No se encuentra ningún plan',
      notAttributed: 'No asignado',
      notify: {
        errorSubmittingPlanning: 'Error al enviar el plan',
        create: 'Plan creado',
        edit: 'Plan modificado',
      },
      notYetOpen:
        '🤷 Los planes aún no están abiertos para la entrada de disponibilidades futuras.',
      preferences: {
        unsaved: 'Tus cambios no se guardarán, ¿quieres continuar?',
        confirmClose: 'Confirmar cierre',
        confirmCloseText: 'Tus cambios no se guardarán, ¿quieres continuar?',
        title: 'Preferencias',
        maximumPerPlanning: 'Máximo de turnos en todo el plan',
        maximumSlotsOnWeekends:
          'Máximo de fines de semana trabajados en el mes (sábado o domingo)',
        maximumPerWeek:
          'Máximo de turnos por semana (sin contar fines de semana)',
        maximumNightsPerWeek:
          'Máximo de turnos nocturnos por semana (sin contar fines de semana)',
        minimumSlotsWanted: 'Mínimo de turnos deseados en total',
        minimumSlotsWantedError: '¡El mínimo debe ser inferior al máximo!',
        noWeekEndAvailable:
          'No has dado disponibilidad el fin de semana este mes, ¿es un olvido?',
        save: 'Actualizar mis preferencias',
        setFirst: 'Ingresa primero las preferencias',
        reminderTitle: 'Recordatorio',
        reminderText:
          'Es mejor completar tus preferencias primero para beneficiarte de la guarda automática',
        tooMuchSlotsWanted:
          'Estás pidiendo más turnos de los días con disponibilidad, no podremos satisfacer tu solicitud.',
        workWholeWeekend:
          '¿Aceptas trabajar dos días diferentes en el mismo fin de semana?',
      },
      priorityBias: 'Sesgo para franjas prioritarias',
      results: {
        additionalHours: 'Refuerzo',
        attributions: 'Asignaciones',
        attributionCount: 'Asignaciones %{count}/%{total}',
        availabilities: 'Disponibilidades',
        summary: 'Resumen',
      },
      status: {
        adminSlotsAssigned: 'Entrada de disponibilidades',
        calculationPerformed: 'Por validar',
        done: 'Validado',
        draft: 'Borrador',
        healthProAssigned: 'Entrada cerrada',
        slotsCreated: 'Plantilla creada',
      },
      summary: {
        maxNightPerWeek: '# máximo por noche/semana',
        nbMaxPerWeek: '# máximo/semana',
        nbMin: '# mínimo',
        nbNight: '# noches',
        nbOnCalls: '# guardias',
        nbSlots: '# turnos',
        pro: 'Profesional',
        weekend: 'Fin de semana',
      },
      title: 'Planificación',
      titleAdmin: 'Planificación admin',
      topToolbar: {
        byJob: 'Por profesión',
        byUser: 'Por persona',
      },
    },
    pregnancy: {
      born: 'Nacido/a',
      empty: 'No hay embarazo en curso',
      expectedEnd: 'Fecha probable de parto',
      interrupted: 'Interrumpido',
      multiple: 'Embarazo múltiple',
      multipleVeryShort: 'Mult.',
      plural: 'Embarazos',
    },
    pregnancyWeekContent: {
      amenorrheaWeek: 'SA',
      height: 'Talla',
      weight: 'Peso',
    },
    pricingProCustom: {
      add: 'Agregar un precio',
      base: 'Base',
      night: 'Noche',
      rateOnCall: 'Coeficiente de guardia',
      sundayHoliday: 'Feriado',
      vat: 'IVA',
    },
    pricingProGeneric: {
      add: 'Agregar un precio',
      base: 'Base',
      job: 'Profesión',
      night: 'Noche',
      rateOnCall: 'Coeficiente de guardia',
      sundayHoliday: 'Feriado',
    },
    profile: {
      changePassword: 'Cambiar mi contraseña',
      commercialName: 'Nombre comercial (facturas)',
      confirmPassword: 'Confirmar la nueva contraseña',
      jobTitle: 'Especialidad',
      newPassword: 'Nueva contraseña',
      passwordsDoNotMatch: 'Las contraseñas no coinciden',
      phone: 'Teléfono (formato +336...)',
      title: 'Mi perfil',
      update: 'Actualizar mi información',
    },
    revives: {
      attributed: 'Asignada',
      attributedStaffUserId: 'Asignada a',
      by: 'por:',
      close: 'Cerrar el seguimiento',
      closeQuestion: '¿Cerrar el seguimiento?',
      createOne: 'Crear un seguimiento',
      edit: 'Modificar el seguimiento',
      errorCreate: 'Error al crear el seguimiento',
      errorClosing: 'Error al cerrar el seguimiento',
      errorSendingMessage: 'Error al enviar el mensaje',
      loading: 'Cargando...',
      noRevives: 'Sin seguimientos',
      nonAttributed: 'No asignada',
      pendingRevivesBeforeSlotCancel:
        '1 seguimiento estaba asignado a esta franja. Ha sido desasignado. |||| %{smart_count} seguimientos estaban asignados a esta franja. Han sido desasignados.',
      performAt: 'Reprogramar el: ',
      performedAt: 'Seguimiento realizado el: ',
      planned:
        'Tienes %{smart_count} seguimiento planificado para este turno: |||| Tienes %{smart_count} seguimientos planificados para este turno:',
      plannedText: 'Texto del seguimiento',
      reviveAt: 'Fecha deseada del seguimiento',
      revives: '1 seguimiento |||| %{smart_count} seguimientos',
      sendRevive: '¿Enviar el seguimiento?',
      sendText: 'Enviar el texto del seguimiento',
      slot: 'Franja',
      successCreate: 'Seguimiento creado con éxito',
      title: 'Seguimiento',
      todo: 'Seguimientos por hacer',
      unattributed: 'no asignada |||| no asignadas',
    },
    roles: {
      cannotCreateAdminRoleDueToPermission:
        'No tienes los permisos suficientes para crear un rol de administrador',
      cannotCreateRoleWithSameRole: 'Este usuario ya tiene este rol',
      created: 'Rol creado',
      deleted: 'Rol eliminado',
      role: 'Rol',
      roles: 'Roles',
    },
    scenario: {
      add: 'Agregar un escenario',
      created: 'Creado el',
    },
    similarQuestion: {
      button: 'Mostrar preguntas similares',
      noMatch: 'No se encontraron preguntas similares.',
      noMatchNB:
        'ℹ️ Para los seguimientos, no se muestran preguntas similares. Tampoco aquellas donde los profesionales han iniciado una conversación',
      title: 'Preguntas similares',
    },
    slots: {
      atLeastOneRequired: 'Se requiere al menos una franja',
      empty: 'No hay franjas programadas por el momento.',
      startBeforeEnd:
        'Las franjas deben tener una fecha de inicio anterior a la fecha de finalización',
      toComeTitle: 'Próximas franjas',
      toComeSubtitle: 'Se muestran un máximo de 10 resultados',
    },
    staffUser: {
      avatar: 'Avatar',
      bic: 'BIC',
      comfortArea: 'Cómodo con',
      commercialName: 'Nombre comercial (factura)',
      consultationPrice: 'Tarifa de consulta',
      email: 'Correo electrónico',
      expertiseArea: 'Área de especialización',
      firstName: 'Nombre',
      iban: 'IBAN',
      job: {
        admin: 'Administrador',
        childPsychiatrist: 'Pediatra-psiquiatra',
        generalPractitioner: 'Médico general',
        midwife: 'Matrona',
        nurseryNurse: 'Enfermera pediátrica',
        nutritionistDoctor: 'Médico-nutricionista',
        pediatrician: 'Pediatra',
        psychologist: 'Psicólogo',
      },
      jobTitle: 'Profesión',
      language: 'Idioma',
      lastName: 'Apellido',
      password: 'Contraseña',
      passwordHelperText:
        'La contraseña debe contener al menos 8 caracteres, incluyendo una mayúscula, una minúscula y un número o carácter especial.',
      phone: 'Teléfono',
      phoneWithFormat: 'Teléfono (formato +336...)',
      timezone: 'Zona horaria',
      title: 'Equipo may',
    },
    subject: {
      actives: 'En curso',
      category: 'Categoría',
      inactives: 'Terminadas',
      medicalIntervention: 'Opiniones médicas',
      midwifeQuestionsTooltip: 'Preguntas de matrona esperando respuesta de EP',
      medicalQuestions: 'Preguntas con opinión médica',
      myQuestions: 'Mis preguntas',
      myQuestionsTooltip: 'Número de preguntas asignadas: personal (total)',
      noCategory: 'Preguntas sin categoría',
      notAttributed: 'No asignadas',
      notAttributedTooltip: 'Número de preguntas no asignadas',
      notPrio: 'No prioritarias',
      noQuestionFilter: 'Ninguna pregunta coincide con este/estos filtro(s)',
      ongoingQuestions: 'Preguntas en curso',
      prio: 'Prioritarias',
      priority: '¿Prioritaria?',
      priorityQuestions: 'Preguntas prioritarias',
      revives: 'Seguimientos',
      selectOne: 'Selecciona una pregunta en la lista de la izquierda',
      uncategorized: 'No categorizadas',
      withMedicalIntervention: '👩🏻‍⚕️ ¿Se solicitó opinión médica?',
      withMedicalInterventionShort: 'Médico requerido',
      withoutMedicalInterventionShort: 'Médico',
      withNurseIntervention: '¿Intervención de EP?',
      withNurseInterventionShort: 'EP requerido',
      withoutNurseInterventionShort: 'EP',
      zenTooltip: 'Preguntas en curso\n(esperando respuesta de nuestra parte)',
    },
    subjectExplorer: {
      category: 'Categoría',
      discussion: '# de discusión',
      end: 'Fin',
      goToDiscussion: 'Ir a la discusión',
      keywords: 'Palabras clave',
      noMessage: 'Sin mensajes...',
      noSubjectWithFilters:
        'Ninguna pregunta coincide con este/estos filtro(s)',
      pro: 'Profesional',
      start: 'Inicio',
      title: 'Super explorador',
    },
    subscription: {
      activateBonus: 'Crear bonus',
      affiliated: 'Afiliado',
      bonus: 'Cuenta gratuita',
      deactivateBonus: 'Desactivar bonus',
      empty: 'Sin suscripción por el momento',
      none: 'Nada',
      paid: 'Suscripción de pago',
      refresh: 'Actualizar',
      title: 'Suscripciones',
      trial: 'Período de prueba',
    },
    timezoneSelector: {
      label: 'Zona horaria',
      title: 'Zona horaria',
    },
    title: {
      admin: 'Interfaz de administración',
      calendars: 'Calendarios',
      dashboard: 'Tablero de control',
      help: 'Ayuda',
      moreInfo: 'Más información',
      patient: 'Padres',
    },
    tools: {
      appert: {
        babyBottles: 'Número de biberones',
        childWeight: 'Peso del niño (en g)',
        description: 'Calculadora método Appert',
        formula: 'Fórmula utilizada (Appert): 250 mL + (peso en gramos) / 10',
        quantityInBottles: 'Es decir: %{bottleQuantity} mL por biberón',
        quantityRecommended: 'Cantidad de leche recomendada: %{milk} mL / 24h',
        title: 'Método Appert',
      },
      choseChildren: 'Elige uno o más niños',
      common: {
        oneYearShort: '1a',
      },
      cranial: 'Perímetro craneal',
      diapers: 'Pañales',
      exportSuccess: 'La exportación se realizó con éxito',
      exportError: 'La exportación ha fallado.',
      feeding: 'Alimentación',
      feedingAndSleep: {
        legend: {
          babyBottle: 'Biberón',
          breastfeed: 'Lactancia',
          sleep: 'Sueño',
        },
        title: 'Seguimiento sueño/alimentación',
        tooltip: {
          duration: 'Duración: %{duration} min',
          quantity: 'Cantidad: %{quantity} mL',
        },
        statistics: 'Estadísticas',
      },
      height: 'Altura',
      sleep: 'Sueño',
      title: 'Herramientas',
      weight: {
        dueDate: 'Fecha probable de parto',
        start: 'Inicio',
        title: 'Peso',
      },
    },
    therapy: {
      audio: '🎧 Audio',
      guide: '📄 Guía',
      post: '🗞️ Artículo',
      unknown: 'Desconocido',
      video: '🎞️ Video',
      withConsultation: 'con consulta',
      sessionCount: 'Sesión %{count}',
      chapterCount: 'Capítulo %{count}',
    },
    todoListSuggestion: {
      todoTasks: 'Tareas por hacer',
    },
    validation: {
      integer: 'Se espera un número entero',
    },
  },
};
