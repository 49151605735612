import { sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { StaffUser } from '@boTypes/staffUser';
import { TabObject } from '@boTypes/tab';
import { AvatarAndText } from '@components/fields/AvatarField';
import { SelectInput } from '@components/forms/selectInput';
import {
  AdminPanelSettings,
  Check,
  Construction,
  Edit,
  OpenInNew,
} from '@mui/icons-material';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Stack,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { CustomToolbar } from './dataGridToolbar';
import {
  useDoneTasks,
  useRefreshOwnership,
  useTasksInProgress,
  useTasksSummary,
  useTasksToAttribute,
  useTasksToComplete,
  useTasksToUpload,
  useTasksToValidate,
  useTranslatorUsers,
} from './hooks';
import { TaskCommentsDialog } from './taskCommentsDialog';
import { ContentDetail } from './translationList';
import { i18nProvider } from '../../../../App';
import { cmsAdminURL } from '../../../../common/url';
import {
  CMSContent,
  ContentType,
} from '../../../../entities/library/contentTextList';
import { useSelector } from '../../../../store';
import { setTargetLocale } from '../../../../store/contentTranslationFilters';
import {
  editableDocumentURLRender,
  renderCellContent,
  renderCellPairReviewer,
} from '../../../common/contentTranslation/gridUtils';
import { GridCellStatusExpand } from '../../../common/contentTranslation/taskStatusCard';
import {
  TaskStatus,
  TaskStatusMode,
  TranslationTask,
  TranslationTaskStatus,
  UserLocale,
} from '../../../common/contentTranslation/types';
import {
  cmsUidFromType,
  contentTypeToIndexUid,
} from '../../../common/contentTranslation/utils';
import { filterPanelProps } from '../../../common/dataGrid/styles';

export enum TranslationTab {
  TO_ATTRIBUTE = 'toAttribute',
  IN_PROGRESS = 'inProgress',
  TO_APPROVE = 'toApprove',
  TO_UPLOAD = 'toUpload',
  TO_COMPLETE = 'toComplete',
  DONE = 'done',
  SUMMARY = 'summary',
}

const StatusChip = (params: { row: { status?: TranslationTaskStatus[] } }) => {
  const translate = useTranslate();
  const status = params.row.status?.[0]?.status;
  const comments = params.row.status?.[0]?.comments;
  const statusString = translate(`contentTranslation.status.${status}`);
  return status === TaskStatus.CMS_UPLOAD_FAILED ? (
    <Tooltip title={comments}>
      <Chip
        size="small"
        label={statusString}
        color={status === 'cms_upload_failed' ? 'error' : 'success'}
      />
    </Tooltip>
  ) : (
    statusString
  );
};

export const translationsTabs = () => [
  {
    id: TranslationTab.TO_ATTRIBUTE,
    name: i18nProvider.translate(
      `contentTranslation.tab.${TranslationTab.TO_ATTRIBUTE}`,
    ),
  },
  {
    id: TranslationTab.IN_PROGRESS,
    name: i18nProvider.translate(
      `contentTranslation.tab.${TranslationTab.IN_PROGRESS}`,
    ),
  },
  {
    id: TranslationTab.TO_APPROVE,
    name: i18nProvider.translate(
      `contentTranslation.tab.${TranslationTab.TO_APPROVE}`,
    ),
  },
  {
    id: TranslationTab.TO_UPLOAD,
    name: i18nProvider.translate(
      `contentTranslation.tab.${TranslationTab.TO_UPLOAD}`,
    ),
  },
  {
    id: TranslationTab.TO_COMPLETE,
    name: i18nProvider.translate(
      `contentTranslation.tab.${TranslationTab.TO_COMPLETE}`,
    ),
  },
  {
    id: TranslationTab.DONE,
    name: i18nProvider.translate(
      `contentTranslation.tab.${TranslationTab.DONE}`,
    ),
  },
  {
    id: TranslationTab.SUMMARY,
    name: i18nProvider.translate(
      `contentTranslation.tab.${TranslationTab.SUMMARY}`,
    ),
  },
];

type AttributionInput = {
  approverId: string;
};

type FilterInput = {
  targetLocale: UserLocale;
  approverId?: StaffUser['id'];
};

export function renderCellStatusExpand(
  params: GridRenderCellParams<any, string>,
) {
  return (
    <GridCellStatusExpand
      value={params.row.allStatus}
      width={params.colDef.computedWidth}
      ownerId={params.row.ownerId}
    />
  );
}

export const ContentTranslationDashboard = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const notify = useNotify();
  const dispatch = useDispatch();
  const userTargetLocale = useSelector(
    (state) => state.contentTranslation.targetLocale,
  );

  // Datagrid state
  const [rowSelectionModel, setRowSelectionModel] = useState<
    TranslationTask['id'][]
  >([]);

  // Tab state
  const [tab, setTab] = useState<TabObject>({
    name: translate(`contentTranslation.tab.${TranslationTab.TO_ATTRIBUTE}`),
    id: TranslationTab.TO_ATTRIBUTE,
  });
  const tabs = useMemo(translationsTabs, []);
  const handleTabChange = useCallback(
    (_, status: TranslationTab) => {
      tab.id !== status && setTab(tabs.find((t) => t.id === status));
      setRowSelectionModel([]);
    },
    [tabs, setTab, tab.id],
  );

  // Modal state
  const [modalTaskId, setModalTaskId] = useState<TranslationTask['id'] | null>(
    null,
  );
  const [modalMode, setModalMode] = useState<TaskStatusMode | null>(null);

  // CMS Content preview
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedType, setSelectedType] = useState<ContentType | null>(null);
  const [selectedContent, setSelectedContent] =
    useState<CMSContent<ContentType> | null>(null);
  const [translatedSelectedContent, setTranslatedSelectedContent] =
    useState<CMSContent<ContentType> | null>(null);

  // Control filters
  const { control: controlFilters, watch } = useForm<FilterInput>({
    defaultValues: {
      targetLocale: userTargetLocale ?? UserLocale.EN,
      approverId: undefined,
    },
  });
  const [targetLocale, approverId] = watch(['targetLocale', 'approverId']);
  const [filteredOnPairReviewer, setFilterOnPairReviewer] = useState(true);

  useEffect(() => {
    dispatch(setTargetLocale({ targetLocale }));
  }, [targetLocale, dispatch]);

  // Tasks
  const {
    tasksToAttribute,
    isPending: isTasksToAttributePending,
    multiSearchIdMapping: multiSearchIdMappingAttribute,
    updateTask,
    isAttributionPending,
    refetch: refetchTaskToAttribute,
    retryPostCreateActions: retryPostCreateActionsMutation,
  } = useTasksToAttribute({ targetLocale });

  const { refreshOwnership } = useRefreshOwnership();

  const refreshOwnershipCallback = useCallback(() => {
    refreshOwnership({ taskIds: rowSelectionModel as TranslationTask['id'][] });
    notify('admin.contentTranslation.refreshOwnershipSuccess', {
      type: 'success',
    });
    setRowSelectionModel([]);
  }, [refreshOwnership, notify, rowSelectionModel, setRowSelectionModel]);

  const retryPostCreateActions = useCallback(async () => {
    await retryPostCreateActionsMutation({
      taskIds: rowSelectionModel as TranslationTask['id'][],
    });
    setRowSelectionModel([]);
    notify('admin.contentTranslation.retryPostCreateActionsSuccess', {
      type: 'success',
    });
  }, [
    retryPostCreateActionsMutation,
    notify,
    rowSelectionModel,
    setRowSelectionModel,
  ]);

  const {
    tasksInProgress,
    isPending: isTasksInProgressPending,
    multiSearchIdMapping: multiSearchIdMappingInProgress,
    refetch: refetchTasksInProgress,
  } = useTasksInProgress({ targetLocale, approverId });

  const {
    tasksToValidate,
    isPending: isTasksToValidatePending,
    refetch: refetchTasksToValidate,
    multiSearchIdMapping: multiSearchIdMappingValidate,
  } = useTasksToValidate({ targetLocale, approverId });

  const {
    tasksToUpload,
    isPending: isTasksToUploadPending,
    multiSearchIdMapping: multiSearchIdMappingUpload,
    setTaskAsUploaded,
    refetch: refetchTasksToUpload,
  } = useTasksToUpload({ targetLocale, approverId });

  const {
    tasksToComplete,
    isPending: isTasksToCompletePending,
    multiSearchIdMapping: multiSearchIdMappingComplete,
    setTaskAsCompleted,
    refetch: refetchTasksToComplete,
  } = useTasksToComplete(
    { targetLocale, approverId, filteredOnPairReviewer },
    { enabled: tab.id === TranslationTab.TO_COMPLETE },
  );

  const {
    doneTasks,
    isPending: isDoneTasksPending,
    multiSearchIdMapping: multiSearchIdMappingDone,
  } = useDoneTasks(
    { targetLocale },
    { enabled: tab.id === TranslationTab.DONE },
  );

  // Summary
  const { summary, isPending: isSummaryPending } = useTasksSummary({
    targetLocale,
  });

  // Approvers state
  const {
    control: controlAttribution,
    handleSubmit: handleAttributionSubmit,
    reset: resetAttributionForm,
  } = useForm<AttributionInput>({
    defaultValues: {
      approverId: '',
    },
  });

  // Users
  const { users } = useTranslatorUsers(targetLocale);

  // DataGrid columns
  const columnsToAttribute = useMemo<GridColDef<TranslationTask>[]>(
    () => [
      {
        field: 'status',
        headerName: translate('contentTranslation.fields.status'),
        valueGetter: (value: TranslationTaskStatus[]) => {
          const status = value[0]?.status;
          return status ? translate(`contentTranslation.status.${status}`) : '';
        },
        filterable: false,
      },
      {
        field: 'editableDocumentURL',
        headerName: translate('contentTranslation.fields.documentUrl'),
        width: 60,
        renderCell: editableDocumentURLRender,
        filterable: false,
      },
      {
        field: 'documentTitle',
        headerName: translate('contentTranslation.fields.documentTitle'),
        flex: 1,
        renderCell: (args: GridRenderCellParams<TranslationTask, string>) =>
          renderCellContent({
            ...args,
            open: openDetail,
            setOpen: setOpenDetail,
            setSelectedContent,
            setTranslatedSelectedContent,
            setSelectedType,
            multiSearchIdMapping: multiSearchIdMappingAttribute,
          }),
        valueGetter: (_value, row) => {
          const document =
            multiSearchIdMappingAttribute?.[
              contentTypeToIndexUid(row.translationDocument?.type)
            ]?.[row.translationDocument?.documentId];
          return document?.title;
        },
      },
      {
        field: 'type',
        headerName: translate('contentTranslation.fields.type'),
        width: 100,
        valueGetter: (_value, row) => {
          return translate(
            `admin.contentTranslation.contentType.${row.translationDocument?.type}`,
          );
        },
      },
      {
        field: 'scope',
        headerName: translate('contentTranslation.fields.scope'),
        valueGetter: (value) =>
          translate(`admin.contentTranslation.scopes.${value}`),
      },
    ],
    [multiSearchIdMappingAttribute, translate, openDetail],
  );

  const columnsInProgress = useMemo<GridColDef<TranslationTask>[]>(
    () => [
      {
        field: 'approver',
        width: 140,
        align: 'center',
        headerName: translate('contentTranslation.approver'),
        renderCell: (params) => (
          <Box sx={{ display: 'flex', p: 1 }}>
            <AvatarAndText user={params.row.approver} />
          </Box>
        ),
        valueGetter: (value: StaffUser) =>
          [value.firstName, value.lastName].join(' '),
      },
      {
        field: 'status',
        width: 40,
        headerName: translate('contentTranslation.fields.status'),
        renderCell: (params) => <StatusChip row={params.row} />,
      },
      {
        field: 'editableDocumentURL',
        headerName: translate('contentTranslation.fields.documentUrl'),
        width: 60,
        renderCell: editableDocumentURLRender,
      },
      {
        field: 'documentTitle',
        headerName: translate('contentTranslation.fields.documentTitle'),
        flex: 1,
        renderCell: (args: GridRenderCellParams<any, string>) =>
          renderCellContent({
            ...args,
            open: openDetail,
            setOpen: setOpenDetail,
            setSelectedContent,
            setTranslatedSelectedContent,
            setSelectedType,
            multiSearchIdMapping: multiSearchIdMappingInProgress,
          }),
        valueGetter: (_value, row) => {
          const document =
            multiSearchIdMappingInProgress?.[
              contentTypeToIndexUid(row.translationDocument?.type)
            ]?.[row.translationDocument?.documentId];
          return document?.title;
        },
      },
      {
        field: 'type',
        headerName: translate('contentTranslation.fields.type'),
        width: 100,
        valueGetter: (_value, row) => {
          return translate(
            `admin.contentTranslation.contentType.${row.translationDocument?.type}`,
          );
        },
      },
      {
        field: 'scope',
        headerName: translate('contentTranslation.fields.scope'),
        valueGetter: (value) =>
          translate(`admin.contentTranslation.scopes.${value}`),
      },
      {
        field: 'allStatus',
        align: 'center',
        headerName: translate('contentTranslation.fields.allStatus'),
        renderCell: renderCellStatusExpand,
        valueGetter: (value: TranslationTaskStatus[]) =>
          value?.reduce((acc, itm) => {
            return acc + (itm.timeSpent || 0);
          }, 0),
      },
      {
        field: 'cmsURL',
        headerName: translate('contentTranslation.fields.cmsURL'),
        width: 60,
        renderCell: (params) => (
          <Link
            href={`${cmsAdminURL}${cmsUidFromType(params.row.translationDocument?.type)}${params.row.translationDocument?.documentId}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ p: 1 }}
          >
            <OpenInNew color="info" sx={{ fontSize: '1rem' }} />
          </Link>
        ),
        filterable: false,
      },
    ],
    [multiSearchIdMappingInProgress, translate, openDetail],
  );

  const columnsToValidate = useMemo<GridColDef<TranslationTask>[]>(
    () => [
      {
        field: 'validate',
        headerName: translate('common.validate'),
        width: 100,
        renderCell: (params) => (
          <>
            <IconButton
              size="small"
              aria-label={translate('contentTranslation.requestChanges')}
              onClick={() => {
                setModalMode(TaskStatusMode.REQUEST_CHANGES);
                setModalTaskId(params.row.id);
              }}
            >
              <Edit color="warning" />
            </IconButton>
            <IconButton
              size="small"
              aria-label={translate('common.validate')}
              color="primary"
              onClick={() => {
                setModalMode(TaskStatusMode.VALIDATE);
                setModalTaskId(params.row.id);
              }}
            >
              <Check />
            </IconButton>
          </>
        ),
      },
      {
        field: 'approver',
        width: 140,
        align: 'center',
        headerName: translate('contentTranslation.approver'),
        renderCell: (params) => (
          <Box sx={{ display: 'flex', p: 1 }}>
            <AvatarAndText user={params.row.approver} />
          </Box>
        ),
        valueGetter: (value: StaffUser) =>
          [value.firstName, value.lastName].join(' '),
      },
      {
        field: 'status',
        width: 40,
        headerName: translate('contentTranslation.fields.status'),
        valueGetter: (_value, row) => {
          const hasChangesRequested = row?.allStatus.some(
            (status) => status.status === TaskStatus.CHANGES_REQUESTED,
          );
          return hasChangesRequested ? '♻️' : '👀';
        },
      },
      {
        field: 'editableDocumentURL',
        headerName: translate('contentTranslation.fields.documentUrl'),
        width: 60,
        renderCell: editableDocumentURLRender,
      },
      {
        field: 'documentTitle',
        headerName: translate('contentTranslation.fields.documentTitle'),
        flex: 1,
        renderCell: (args: GridRenderCellParams<any, string>) =>
          renderCellContent({
            ...args,
            open: openDetail,
            setOpen: setOpenDetail,
            setSelectedContent,
            setTranslatedSelectedContent,
            setSelectedType,
            multiSearchIdMapping: multiSearchIdMappingValidate,
          }),
        valueGetter: (_value, row) => {
          const document =
            multiSearchIdMappingValidate?.[
              contentTypeToIndexUid(row.translationDocument?.type)
            ]?.[row.translationDocument?.documentId];
          return document?.title;
        },
      },
      {
        field: 'type',
        headerName: translate('contentTranslation.fields.type'),
        width: 100,
        valueGetter: (_value, row) => {
          return translate(
            `admin.contentTranslation.contentType.${row.translationDocument?.type}`,
          );
        },
      },
      {
        field: 'scope',
        headerName: translate('contentTranslation.fields.scope'),
        valueGetter: (value) =>
          translate(`admin.contentTranslation.scopes.${value}`),
      },
      {
        field: 'allStatus',
        align: 'center',
        headerName: translate('contentTranslation.fields.allStatus'),
        renderCell: renderCellStatusExpand,
        valueGetter: (_value, row) => row.allStatus,
      },
      {
        field: 'cmsURL',
        headerName: translate('contentTranslation.fields.cmsURL'),
        width: 60,
        renderCell: (params) => (
          <Link
            href={`${cmsAdminURL}${cmsUidFromType(params.row.translationDocument?.type)}${params.row.translationDocument?.documentId}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ p: 1 }}
          >
            <OpenInNew color="info" sx={{ fontSize: '1rem' }} />
          </Link>
        ),
      },
    ],
    [
      multiSearchIdMappingValidate,
      translate,
      setModalMode,
      setModalTaskId,
      openDetail,
    ],
  );

  const columnsToUpload = useMemo<GridColDef<TranslationTask>[]>(
    () => [
      {
        field: 'status',
        headerName: translate('contentTranslation.fields.status'),
        renderCell: (params) => <StatusChip row={params.row} />,
        filterable: false,
      },
      {
        field: 'approver',
        width: 140,
        align: 'center',
        headerName: translate('contentTranslation.approver'),
        renderCell: (params) => (
          <Box sx={{ display: 'flex', p: 1 }}>
            <AvatarAndText user={params.row.approver} />
          </Box>
        ),
        valueGetter: (value: StaffUser) =>
          [value.firstName, value.lastName].join(' '),
      },
      {
        field: 'editableDocumentURL',
        headerName: translate('contentTranslation.fields.documentUrl'),
        width: 60,
        renderCell: editableDocumentURLRender,
      },
      {
        field: 'documentTitle',
        headerName: translate('contentTranslation.fields.documentTitle'),
        flex: 1,
        renderCell: (args: GridRenderCellParams<any, string>) =>
          renderCellContent({
            ...args,
            open: openDetail,
            setOpen: setOpenDetail,
            setSelectedContent,
            setTranslatedSelectedContent,
            setSelectedType,
            multiSearchIdMapping: multiSearchIdMappingUpload,
          }),
        valueGetter: (_value, row) => {
          const document =
            multiSearchIdMappingUpload?.[
              contentTypeToIndexUid(row.translationDocument?.type)
            ]?.[row.translationDocument?.documentId];
          return document?.title;
        },
      },
      {
        field: 'type',
        headerName: translate('contentTranslation.fields.type'),
        width: 100,
        valueGetter: (_value, row) => {
          return translate(
            `admin.contentTranslation.contentType.${row.translationDocument?.type}`,
          );
        },
      },
      {
        field: 'scope',
        headerName: translate('contentTranslation.fields.scope'),
        valueGetter: (value) =>
          translate(`admin.contentTranslation.scopes.${value}`),
      },
      {
        field: 'allStatus',
        align: 'center',
        headerName: translate('contentTranslation.fields.allStatus'),
        renderCell: renderCellStatusExpand,
        valueGetter: (value: TranslationTaskStatus[]) =>
          value?.reduce((acc, itm) => {
            return acc + (itm.timeSpent || 0);
          }, 0),
      },
      {
        field: 'cmsURL',
        headerName: translate('contentTranslation.fields.cmsURL'),
        width: 60,
        renderCell: (params) => (
          <Link
            href={`${cmsAdminURL}${cmsUidFromType(params.row.translationDocument?.type)}${params.row.translationDocument?.documentId}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ p: 1 }}
          >
            <OpenInNew color="info" sx={{ fontSize: '1rem' }} />
          </Link>
        ),
        filterable: false,
      },
    ],
    [multiSearchIdMappingUpload, translate, openDetail],
  );

  const columnsToComplete = useMemo<GridColDef<TranslationTask>[]>(
    () => [
      {
        field: 'pairReviewerId',
        width: 140,
        align: 'center',
        headerName: translate('contentTranslation.uploader'),
        renderCell: (args: GridRenderCellParams<TranslationTask, string>) =>
          renderCellPairReviewer({
            ...args,
            userId: args.row.pairReviewerId,
          }),
      },
      {
        field: 'status',
        headerName: translate('contentTranslation.fields.status'),
        renderCell: (params) => <StatusChip row={params.row} />,
        filterable: false,
        width: 230,
      },
      {
        field: 'approver',
        width: 140,
        align: 'center',
        headerName: translate('contentTranslation.approver'),
        renderCell: (params) => (
          <Box sx={{ display: 'flex', p: 1 }}>
            <AvatarAndText user={params.row.approver} />
          </Box>
        ),
        valueGetter: (value: StaffUser) =>
          [value.firstName, value.lastName].join(' '),
      },
      {
        field: 'editableDocumentURL',
        headerName: translate('contentTranslation.fields.documentUrl'),
        width: 60,
        renderCell: editableDocumentURLRender,
      },
      {
        field: 'documentTitle',
        headerName: translate('contentTranslation.fields.documentTitle'),
        flex: 1,
        renderCell: (args: GridRenderCellParams<TranslationTask, string>) =>
          renderCellContent({
            ...args,
            open: openDetail,
            setOpen: setOpenDetail,
            setSelectedContent,
            setTranslatedSelectedContent,
            setSelectedType,
            multiSearchIdMapping: multiSearchIdMappingComplete,
          }),
        valueGetter: (_value, row) => {
          // Return both title in original language and translated to ease search
          const document = row.translationDocument;
          const documentType = contentTypeToIndexUid(document?.type);
          const uploadId = row.upload?.createdDocumentId;
          const mapping = multiSearchIdMappingComplete?.[documentType];

          return `${mapping?.[document?.documentId]?.title ?? ''} ${uploadId ? (mapping?.[uploadId]?.title ?? '') : ''}`.trim();
        },
      },
      {
        field: 'type',
        headerName: translate('contentTranslation.fields.type'),
        width: 100,
        valueGetter: (_value, row) => {
          return translate(
            `admin.contentTranslation.contentType.${row.translationDocument?.type}`,
          );
        },
      },
      {
        field: 'cmsURL',
        headerName: translate('contentTranslation.fields.cmsURL'),
        width: 90,
        renderCell: ({ row }) => {
          const {
            upload,
            translationDocument,
            targetLocale: _targetLocale,
          } = row;

          return (
            <Link
              href={
                upload?.id
                  ? `${cmsAdminURL}${cmsUidFromType(translationDocument?.type)}${upload.createdDocumentId}?plugins[i18n][locale]=${_targetLocale}&plugins[i18n][relatedEntityId]=${translationDocument?.documentId}`
                  : `${cmsAdminURL}${cmsUidFromType(translationDocument?.type)}${translationDocument?.documentId}`
              }
              target="_blank"
              rel="noopener noreferrer"
              sx={{ p: 1 }}
            >
              <OpenInNew color="info" sx={{ fontSize: '1rem' }} /> CMS
            </Link>
          );
        },
        filterable: false,
      },
    ],
    [multiSearchIdMappingComplete, translate, openDetail],
  );

  const doneColumns = useMemo<GridColDef<TranslationTask>[]>(
    () => [
      {
        field: 'uploader',
        width: 140,
        align: 'center',
        headerName: translate('contentTranslation.uploader'),
        renderCell: (args: GridRenderCellParams<TranslationTask, string>) =>
          renderCellPairReviewer({
            ...args,
            userId: args.row.status?.[0]?.ownerId,
          }),
        filterable: false,
      },
      {
        field: 'editableDocumentURL',
        headerName: translate('contentTranslation.fields.documentUrl'),
        width: 60,
        renderCell: editableDocumentURLRender,
      },
      {
        field: 'documentTitle',
        headerName: translate('contentTranslation.fields.documentTitle'),
        flex: 1,
        renderCell: (args: GridRenderCellParams<TranslationTask, string>) =>
          renderCellContent({
            ...args,
            open: openDetail,
            setOpen: setOpenDetail,
            setSelectedContent,
            setTranslatedSelectedContent,
            setSelectedType,
            multiSearchIdMapping: multiSearchIdMappingDone,
          }),
        valueGetter: (_value, row) => {
          // Return both title in original language and translated to ease search
          const document = row.translationDocument;
          const documentType = contentTypeToIndexUid(document?.type);
          const uploadId = row.upload?.createdDocumentId;
          const mapping = multiSearchIdMappingDone?.[documentType];

          return `${mapping?.[document?.documentId]?.title ?? ''} ${uploadId ? (mapping?.[uploadId]?.title ?? '') : ''}`.trim();
        },
      },
      {
        field: 'type',
        headerName: translate('contentTranslation.fields.type'),
        width: 100,
        valueGetter: (_value, row) => {
          return translate(
            `admin.contentTranslation.contentType.${row.translationDocument?.type}`,
          );
        },
      },
      {
        field: 'cmsURL',
        headerName: translate('contentTranslation.fields.cmsURL'),
        width: 90,
        renderCell: ({ row }) => {
          const {
            upload,
            translationDocument,
            targetLocale: _targetLocale,
          } = row;

          return (
            <Link
              href={
                upload?.id
                  ? `${cmsAdminURL}${cmsUidFromType(translationDocument?.type)}${upload.createdDocumentId}?plugins[i18n][locale]=${_targetLocale}&plugins[i18n][relatedEntityId]=${translationDocument?.documentId}`
                  : `${cmsAdminURL}${cmsUidFromType(translationDocument?.type)}${translationDocument?.documentId}`
              }
              target="_blank"
              rel="noopener noreferrer"
              sx={{ p: 1 }}
            >
              <OpenInNew color="info" sx={{ fontSize: '1rem' }} /> CMS
            </Link>
          );
        },
        filterable: false,
      },
    ],
    [multiSearchIdMappingDone, users, translate, openDetail],
  );

  const columnsSummary = useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'approverId',
        width: 300,
        headerName: translate('contentTranslation.approver'),
        renderCell: (params) => (
          <AvatarAndText
            user={users?.find(
              (user) => user.id === Number(params.row.approverId),
            )}
          />
        ),
        valueGetter: (value: StaffUser) =>
          [value.firstName, value.lastName].join(' '),
      },
      {
        field: 'timeSpent',
        headerName: translate('contentTranslation.fields.timeSpent'),
        width: 200,
      },
      {
        field: 'count',
        headerName: translate('contentTranslation.fields.count'),
        width: 200,
      },
      {
        field: 'meanTimeSpent',
        headerName: translate('contentTranslation.fields.meanTimeSpent'),
        width: 200,
      },
    ],
    [translate, users],
  );

  const columnsExtraSummary = useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'staffUserId',
        width: 300,
        headerName: translate('contentTranslation.approver'),
        renderCell: (params) => (
          <AvatarAndText
            user={users?.find(
              (user) => user.id === Number(params.row.staffUserId),
            )}
          />
        ),
        valueGetter: (value: StaffUser) =>
          [value.firstName, value.lastName].join(' '),
      },
      {
        field: 'date',
        headerName: translate('common.date'),
        width: 200,
      },
      {
        field: 'timeSpent',
        headerName: translate('contentTranslation.fields.timeSpent'),
        width: 200,
      },
      {
        field: 'comments',
        headerName: translate('contentTranslation.fields.comments'),
        flex: 1,
      },
    ],
    [translate, users],
  );

  const onAttributionSubmit = useCallback(
    async (data: AttributionInput) => {
      await updateTask({ ...data, taskIds: rowSelectionModel });
      notify('admin.contentTranslation.taskAttributed', { type: 'success' });
      setRowSelectionModel([]);
      resetAttributionForm();
      refetchTaskToAttribute();
      refetchTasksInProgress();
    },
    [
      rowSelectionModel,
      updateTask,
      notify,
      refetchTaskToAttribute,
      refetchTasksInProgress,
      resetAttributionForm,
    ],
  );

  const setAsUploaded = useCallback(
    async (data: TranslationTask['id'][]) => {
      await setTaskAsUploaded(data);
      setRowSelectionModel([]);
      refetchTasksToUpload();
      notify('generic.updateSuccess', { type: 'success' });
    },
    [setTaskAsUploaded, notify, refetchTasksToUpload],
  );

  const setAsCompleted = useCallback(
    async (data: TranslationTask['id'][]) => {
      await setTaskAsCompleted(data);
      setRowSelectionModel([]);
      refetchTasksToComplete();
      notify('generic.updateSuccess', { type: 'success' });
    },
    [setTaskAsCompleted, notify, refetchTasksToComplete],
  );

  return (
    <Paper sx={{ padding: '1rem', minHeight: '100%' }} elevation={0}>
      <Stack
        flexDirection="row"
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={1}
      >
        <Typography variant="h1" color="primary" sx={{ fontWeight: 'bold' }}>
          {translate('admin.contentTranslation.dashboardTitle')}
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            navigate('/admin/content-translation/dashboard/leaderboard');
          }}
          startIcon={<MilitaryTechIcon />}
        >
          Leaderboard
        </Button>
        <Stack gap={1} flexDirection="row" justifyContent={'flex-end'}>
          <SelectInput
            name="approverId"
            variant="standard"
            control={controlFilters}
            label={translate('staffUser.title')}
            sx={{ minWidth: '140px' }}
          >
            <MenuItem value={undefined}>----</MenuItem>
            {(users ?? []).map((value) => (
              <MenuItem key={value.id} value={value.id}>
                <AvatarAndText user={value} />
              </MenuItem>
            ))}
          </SelectInput>
          <SelectInput
            variant="standard"
            control={controlFilters}
            name="targetLocale"
            label={translate('admin.contentTranslation.targetLocale')}
            required
            sx={{ minWidth: '140px' }}
          >
            {Object.values(UserLocale).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectInput>
        </Stack>
      </Stack>
      <Tabs
        variant="fullWidth"
        centered
        value={tab.id}
        onChange={handleTabChange}
        indicatorColor="primary"
      >
        {translationsTabs().map(({ id, name }) => (
          <Tab key={id} label={name} value={id} />
        ))}
      </Tabs>
      <Divider />
      {tab.id === TranslationTab.TO_ATTRIBUTE && (
        <>
          {rowSelectionModel.length > 0 && (
            <form onSubmit={handleAttributionSubmit(onAttributionSubmit)}>
              <Stack
                sx={{ width: '100%' }}
                gap={2}
                flexDirection="row"
                alignItems="flex-end"
              >
                <Typography sx={{ pb: '0.5rem' }}>
                  {translate('admin.contentTranslation.selectApprover')}
                </Typography>
                <SelectInput
                  name="approverId"
                  variant="standard"
                  control={controlAttribution}
                  label={translate('staffUser.title')}
                  sx={{ minWidth: '140px' }}
                >
                  {(users ?? []).map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      <AvatarAndText user={value} />
                    </MenuItem>
                  ))}
                </SelectInput>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    rowSelectionModel.length === 0 || isAttributionPending
                  }
                  onClick={handleAttributionSubmit(onAttributionSubmit)}
                  sx={{ mb: '0.5rem' }}
                >
                  {isAttributionPending && (
                    <CircularProgress size={16} sx={{ mr: 1 }} />
                  )}
                  {translate('common.submit')}
                </Button>
                <IconButton
                  color="info"
                  onClick={retryPostCreateActions}
                  sx={{ mb: '0.5rem' }}
                >
                  <Tooltip
                    title={translate(
                      'admin.contentTranslation.retryPostCreateActions',
                    )}
                  >
                    <Construction />
                  </Tooltip>
                </IconButton>
                <IconButton
                  color="info"
                  onClick={refreshOwnershipCallback}
                  sx={{ mb: '0.5rem' }}
                >
                  <Tooltip
                    title={translate(
                      'admin.contentTranslation.refreshOwnership',
                    )}
                  >
                    <AdminPanelSettings />
                  </Tooltip>
                </IconButton>
              </Stack>
            </form>
          )}
          <DataGrid
            rows={tasksToAttribute}
            columns={columnsToAttribute}
            loading={isTasksToAttributePending}
            density="compact"
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(
                newRowSelectionModel as TranslationTask['id'][],
              );
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            ignoreDiacritics
            slots={{ toolbar: CustomToolbar }}
            slotProps={{ filterPanel: filterPanelProps }}
          />
        </>
      )}
      {tab.id === TranslationTab.IN_PROGRESS && (
        <>
          {rowSelectionModel.length > 0 && (
            <form onSubmit={handleAttributionSubmit(onAttributionSubmit)}>
              <Stack
                sx={{ width: '100%' }}
                gap={2}
                flexDirection="row"
                alignItems="flex-end"
              >
                <Typography sx={{ pb: '0.5rem' }}>
                  {translate('admin.contentTranslation.changeApprover')}
                </Typography>
                <SelectInput
                  name="approverId"
                  variant="standard"
                  control={controlAttribution}
                  label={translate('staffUser.title')}
                  sx={{ minWidth: '140px' }}
                >
                  {(users ?? []).map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      <AvatarAndText user={value} />
                    </MenuItem>
                  ))}
                </SelectInput>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!rowSelectionModel.length}
                  onClick={handleAttributionSubmit(onAttributionSubmit)}
                  sx={{ mb: '0.5rem' }}
                >
                  {translate('common.submit')}
                </Button>
                <IconButton
                  color="info"
                  onClick={refreshOwnershipCallback}
                  sx={{ mb: '0.5rem' }}
                >
                  <Tooltip
                    title={translate(
                      'admin.contentTranslation.refreshOwnership',
                    )}
                  >
                    <AdminPanelSettings />
                  </Tooltip>
                </IconButton>
              </Stack>
            </form>
          )}
          <DataGrid
            rows={tasksInProgress}
            columns={columnsInProgress}
            loading={isTasksInProgressPending}
            density="compact"
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(
                newRowSelectionModel as TranslationTask['id'][],
              );
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            ignoreDiacritics
            slots={{ toolbar: CustomToolbar }}
            slotProps={{ filterPanel: filterPanelProps }}
          />
        </>
      )}
      {tab.id === TranslationTab.TO_APPROVE && (
        <>
          <DataGrid
            rows={tasksToValidate}
            columns={columnsToValidate}
            loading={isTasksToValidatePending}
            density="compact"
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            ignoreDiacritics
            slots={{ toolbar: CustomToolbar }}
            slotProps={{ filterPanel: filterPanelProps }}
          />
          <TaskCommentsDialog
            taskId={modalTaskId}
            mode={modalMode}
            setTaskId={setModalTaskId}
            refetch={() => {
              refetchTasksToValidate();
              modalMode === TaskStatusMode.VALIDATE && refetchTasksToUpload();
              modalMode === TaskStatusMode.REQUEST_CHANGES &&
                refetchTasksInProgress();
            }}
          />
        </>
      )}
      {tab.id === TranslationTab.TO_UPLOAD && (
        <>
          {rowSelectionModel.length > 0 && (
            <Stack
              sx={{ width: '100%' }}
              gap={2}
              flexDirection="row"
              alignItems="flex-end"
            >
              <Button
                variant="contained"
                color="primary"
                disabled={!rowSelectionModel.length}
                onClick={() => setAsUploaded(rowSelectionModel)}
                sx={{ mb: '0.5rem' }}
              >
                {translate('admin.contentTranslation.uploadCMS')}
              </Button>
            </Stack>
          )}
          <DataGrid
            rows={tasksToUpload}
            columns={columnsToUpload}
            loading={isTasksToUploadPending}
            density="compact"
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            ignoreDiacritics
            slots={{ toolbar: CustomToolbar }}
            slotProps={{ filterPanel: filterPanelProps }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(
                newRowSelectionModel as TranslationTask['id'][],
              );
            }}
            rowSelectionModel={rowSelectionModel}
            checkboxSelection
          />
        </>
      )}
      {tab.id === TranslationTab.TO_COMPLETE && (
        <>
          <Stack
            sx={{ width: '100%' }}
            gap={2}
            flexDirection="row"
            alignItems="flex-end"
            justifyContent={'space-between'}
          >
            <Stack
              gap={1}
              alignItems={'center'}
              justifyContent={'flex-start'}
              direction="row"
              sx={{ my: '0.5rem' }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={filteredOnPairReviewer}
                    onChange={(e) => setFilterOnPairReviewer(e.target.checked)}
                  />
                }
                label={translate('generic.filtered')}
              />
              {rowSelectionModel.length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!rowSelectionModel.length}
                  onClick={() => setAsCompleted(rowSelectionModel)}
                  // sx={{ my: '0.5rem' }}
                  startIcon={<Check />}
                >
                  {translate('admin.contentTranslation.complete')}
                </Button>
              )}
            </Stack>
            {rowSelectionModel.length > 0 && (
              <Button
                variant="outlined"
                disabled={!rowSelectionModel.length}
                onClick={() => setAsUploaded(rowSelectionModel)}
                sx={{ my: '0.625rem' }}
                size="small"
              >
                {translate('admin.contentTranslation.uploadCMS')}
              </Button>
            )}
          </Stack>
          <DataGrid
            rows={tasksToComplete}
            columns={columnsToComplete}
            loading={!tasksToComplete && isTasksToCompletePending}
            density="compact"
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            ignoreDiacritics
            slots={{ toolbar: CustomToolbar }}
            slotProps={{ filterPanel: filterPanelProps }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(
                newRowSelectionModel as TranslationTask['id'][],
              );
            }}
            rowSelectionModel={rowSelectionModel}
            checkboxSelection
          />
        </>
      )}
      {tab.id === TranslationTab.DONE && (
        <>
          <DataGrid
            rows={doneTasks}
            columns={doneColumns}
            loading={!doneTasks && isDoneTasksPending}
            density="compact"
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            ignoreDiacritics
            slots={{ toolbar: CustomToolbar }}
            slotProps={{ filterPanel: filterPanelProps }}
          />
        </>
      )}
      {tab.id === TranslationTab.SUMMARY && (
        <>
          {summary && (
            <Stack gap={2}>
              {sortBy(Object.entries(summary), ([key]) => key)
                .reverse()
                .map(([key, value]) => (
                  <>
                    <Typography
                      key={key}
                      variant="h6"
                      sx={{ fontWeight: 'bold', color: 'primary' }}
                    >
                      {translate(`locale.months.number.${key.slice(4)}`)}{' '}
                      {key.slice(0, 4)}
                    </Typography>
                    {/* @ts-expect-error */}
                    {value.extra && (
                      <Stack gap={1}>
                        <Typography variant="body2">
                          {translate('admin.contentTranslation.extra')}
                        </Typography>
                        <DataGrid
                          // @ts-expect-error
                          rows={value.extra}
                          columns={columnsExtraSummary}
                          loading={isSummaryPending}
                          density="compact"
                          disableRowSelectionOnClick
                          keepNonExistentRowsSelected
                          ignoreDiacritics
                          slotProps={{ filterPanel: filterPanelProps }}
                          hideFooter
                        />
                      </Stack>
                    )}
                    {Object.entries(value).map(([_key, _value]) => {
                      if (_key === 'extra') {
                        return;
                      }
                      return (
                        <Stack key={_key} gap={1}>
                          <Typography variant="body2">
                            {translate(
                              `admin.contentTranslation.contentType.${_key}`,
                            )}
                            {` - Tot. ${Math.round(_value.aggregate.timeSpent / 60)} h - Mean: ${Math.round(_value.aggregate.meanTimeSpent)} min`}
                          </Typography>
                          <DataGrid
                            rows={Object.entries(_value)
                              .filter(([key2, _]) => key2 !== 'aggregate')
                              .map(([approverIdKey, dataAgg]) => ({
                                approverId: Number(approverIdKey),
                                // @ts-expect-error
                                ...dataAgg,
                              }))}
                            columns={columnsSummary}
                            loading={isSummaryPending}
                            density="compact"
                            disableRowSelectionOnClick
                            keepNonExistentRowsSelected
                            ignoreDiacritics
                            slotProps={{ filterPanel: filterPanelProps }}
                            getRowId={(row) => row.approverId}
                            hideFooter
                          />
                        </Stack>
                      );
                    })}
                  </>
                ))}
            </Stack>
          )}
        </>
      )}
      <ContentDetail
        type={selectedType}
        open={openDetail}
        setOpen={setOpenDetail}
        item={selectedContent}
        translatedItem={translatedSelectedContent}
      />
    </Paper>
  );
};
