import { ContentType as CMSContentType } from '../../../../entities/library/contentTextList';
import { ContentType } from '../../../common/contentTranslation/types';

const typeMapping = {
  // [ContentType.CHILD_MONTH_CONTENT]: CMSContentType.
  [ContentType.APPOINTMENT_SUGGESTIONS]: CMSContentType.APPOINTMENT_SUGGESTIONS,
  [ContentType.DAILY_TIPS]: CMSContentType.DAILY_TIPS,
  [ContentType.FERTILITY_TIPS]: CMSContentType.FERTI_TIPS,
  [ContentType.GUIDE]: CMSContentType.GUIDES,
  [ContentType.MACRO]: CMSContentType.MACRO,
  [ContentType.MASTERCLASS]: CMSContentType.MASTERCLASSES,
  [ContentType.POST]: CMSContentType.POSTS,
  [ContentType.PREGNANCY_WEEK_CONTENT]: CMSContentType.PREGNANCY_WEEK_CONTENT,
  [ContentType.PREGNANCY_QUESTIONS]: CMSContentType.PREGNANCY_QUESTIONS,
  [ContentType.TODO_LIST_SUGGESTIONS]: CMSContentType.TODO_LIST_SUGGESTIONS,
  [ContentType.WEEKLY_TIPS]: CMSContentType.WEEKLY_TIPS,
};

export const fromTranslationTypeToContentType = (
  translationType: ContentType,
) => typeMapping[translationType];
