import ReactMarkdown from 'react-markdown';

import { AppointmentSuggestion } from '@boTypes/appointmentSuggestion';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import { DialogTitle } from '../generic/Dialog';

export const WIDTH = 148;
export const HEIGHT = 120;

const InnerAppointmentSuggestion = ({
  item,
}: {
  item: AppointmentSuggestion;
}) => {
  const author = (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      gap={1}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {item.authors?.map((_author) => (
          <>
            <Avatar
              src={_author.image.url}
              sx={{
                width: 24,
                height: 24,
                borderWidth: 1,
                borderColor: 'background.grey',
                marginRight: 1,
              }}
            />
            <Typography variant="body2">{_author.name}</Typography>
          </>
        ))}
      </Box>
    </Stack>
  );

  return (
    <>
      {author}
      <Box
        sx={{
          maxWidth: '100%',
          '& p > img': { width: '100%' },
          '& p': { wordWrap: 'break-word' },
        }}
      >
        <ReactMarkdown>{item.description}</ReactMarkdown>
      </Box>
    </>
  );
};

export const AppointmentSuggestionDialog = ({
  item,
  translatedItem,
  open,
  close,
}: {
  item: AppointmentSuggestion;
  translatedItem?: AppointmentSuggestion;
  open: boolean;
  close: () => void;
}) => (
  <Dialog
    open={open}
    onClose={close}
    aria-labelledby={item.title}
    aria-describedby={item.title}
    maxWidth={translatedItem ? 'xl' : 'md'}
  >
    <DialogTitle onClose={close}>
      {translatedItem ? (
        <Stack direction="row" gap={6} sx={{ flex: 1, width: '100%' }}>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            {item.title}
          </Stack>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            {translatedItem.title}
          </Stack>
        </Stack>
      ) : (
        item.title
      )}
    </DialogTitle>
    <DialogContent>
      {translatedItem ? (
        <Stack
          direction="row"
          justifyContent={'space-between'}
          gap={1}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{ width: '100%' }}
        >
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            <InnerAppointmentSuggestion item={item} />
          </Stack>
          <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
            <InnerAppointmentSuggestion item={translatedItem} />
          </Stack>
        </Stack>
      ) : (
        <InnerAppointmentSuggestion item={item} />
      )}
    </DialogContent>
  </Dialog>
);
