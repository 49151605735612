import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { FieldArrayWithId, useFormContext, useWatch } from 'react-hook-form';
import { useStore } from 'reactflow';

import { SelectInput } from '@components/forms/selectInput';
import { TextInput } from '@components/forms/textInput';
import { Box, MenuItem, Select } from '@mui/material';
import {
  Operator,
  oppositeOperators,
  Question,
  QuestionType,
} from '@teammay/form-core';

import { type ConditionForm } from './ConditionEdit';

export const QuestionRuleEdit = ({
  onBlur,
  field,
  index,
  questions,
  reversedLogic = false,
}: {
  onBlur: () => void;
  field: FieldArrayWithId<ConditionForm, 'questionRules', 'id'>;
  index: number;
  questions: any[];
  reversedLogic?: boolean;
}) => {
  const translate = useTranslate();
  const { control } = useFormContext<ConditionForm>();

  const [selectedQuestionId, expectedValue] = useWatch({
    control,
    name: [
      `questionRules.${index}.questionId`,
      `questionRules.${index}.expectedValue`,
    ],
  });
  const selectedQuestion = useStore((store) =>
    selectedQuestionId
      ? (store
          .getNodes()
          .find((node) => node.data?.question?.id === selectedQuestionId)?.data
          ?.question as Question)
      : null,
  );
  const [selectedKey, setSelectedKey] = useState<string | undefined>(
    expectedValue,
  );
  const { setValue } = useFormContext<ConditionForm>();

  return (
    <Box
      key={field.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'primary.main10',
        p: '2rem',
        borderRadius: '1rem',
        flex: 1,
      }}
    >
      <SelectInput
        variant="outlined"
        control={control}
        name={`questionRules.${index}.questionId`}
        label={translate('forms.conditions.questionRules.question')}
        defaultValue={questions[0]?.id ?? ''}
      >
        {questions.map((question) => (
          <MenuItem key={question.id} value={question.id}>
            {question.title}
          </MenuItem>
        ))}
      </SelectInput>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {selectedQuestion?.type === QuestionType.RADIO ||
        selectedQuestion?.type === QuestionType.SELECT ? (
          <Select
            value={selectedKey ?? selectedQuestion.options[0]?.key ?? ''}
            onChange={(e) => {
              setSelectedKey(e.target.value);
              setValue(`questionRules.${index}.expectedValue`, e.target.value);
            }}
          >
            {(
              selectedQuestion.options as Partial<
                Question<QuestionType.RADIO | QuestionType.SELECT>
              >['options']
            )?.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
        ) : selectedQuestion?.type === QuestionType.IMAGE ? (
          <Select
            value={selectedKey ?? selectedQuestion.options[0]?.key ?? ''}
            onChange={(e) => {
              setSelectedKey(e.target.value);
              setValue(`questionRules.${index}.expectedValue`, e.target.value);
            }}
          >
            <MenuItem
              key={
                (selectedQuestion as Question<QuestionType.IMAGE>).options
                  .primaryKey
              }
              value={
                (selectedQuestion as Question<QuestionType.IMAGE>).options
                  .primaryKey
              }
            >
              {
                (selectedQuestion as Question<QuestionType.IMAGE>).options
                  .primaryDescription
              }
            </MenuItem>
            <MenuItem
              key={
                (selectedQuestion as Question<QuestionType.IMAGE>).options
                  .secondaryKey
              }
              value={
                (selectedQuestion as Question<QuestionType.IMAGE>).options
                  .secondaryKey
              }
            >
              {
                (selectedQuestion as Question<QuestionType.IMAGE>).options
                  .secondaryDescription
              }
            </MenuItem>
          </Select>
        ) : (
          <TextInput
            sx={{ flex: 1 }}
            control={control}
            name={`questionRules.${index}.expectedValue`}
            label={translate('forms.conditions.questionRules.expectedValue')}
            rules={{ required: true }}
            onBlur={onBlur}
          />
        )}
      </Box>
      <SelectInput
        variant="outlined"
        control={control}
        name={`questionRules.${index}.operator`}
        label={translate('forms.conditions.questionRules.operator')}
        rules={{ required: true }}
        defaultValue={Operator.EQUAL}
      >
        {Object.values(Operator).map((operator) => (
          <MenuItem key={operator} value={operator}>
            {reversedLogic ? oppositeOperators[operator] : operator}
          </MenuItem>
        ))}
      </SelectInput>
    </Box>
  );
};
