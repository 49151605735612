import { i18nProvider } from '../App';
import { JobTitleToString } from '../types/user';

export const sexChoices = () => [
  { id: 'm', name: i18nProvider.translate('common.male') },
  { id: 'f', name: i18nProvider.translate('common.female') },
];

export const subscriptionChoices = () => [
  { id: 'active', name: i18nProvider.translate('subscription.paid') },
  { id: 'trialing', name: i18nProvider.translate('subscription.trial') },
  { id: 'bonus', name: i18nProvider.translate('subscription.bonus') },
  { id: 'affiliated', name: i18nProvider.translate('subscription.affiliated') },
];

export const localeChoices = () => [
  { id: 'fr', name: i18nProvider.translate('common.french') },
  { id: 'en', name: i18nProvider.translate('common.english') },
  { id: 'es', name: i18nProvider.translate('common.spanish') },
];

export const titleChoices = () =>
  Object.entries(JobTitleToString).map(([job, value]) => ({
    id: job,
    name: i18nProvider.translate(value),
  }));

export enum countryCode {
  FRANCE = 'FR',
  SPAIN = 'ES',
  UNITED_KINGDOM = 'GB',
}

export const countries = () => [
  {
    id: countryCode.FRANCE,
    name: i18nProvider.translate('common.countries.fr'),
  },
  {
    id: countryCode.SPAIN,
    name: i18nProvider.translate('common.countries.es'),
  },
  {
    id: countryCode.UNITED_KINGDOM,
    name: i18nProvider.translate('common.countries.en'),
  },
];

export const currencies = () => [
  { id: 'EUR', name: i18nProvider.translate('common.euro') },
  { id: 'GBP', name: i18nProvider.translate('common.pound') },
  { id: 'USD', name: i18nProvider.translate('common.dollar') },
];
